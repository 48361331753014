import { combineReducers } from 'redux'

import Global from './GlobalReducer'
import Loading from './LoadingReducer'
import Auth from './AuthReducer'

import Admin from './Admin'
import Manager from './Manager'
import Technician from './Technician'

export default combineReducers({
	Global,
	Loading,
	Auth,
	Admin,
	Manager,
	Technician,
})
