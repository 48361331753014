import styled from 'styled-components'
import { TEPanel } from 'react-tec'

export const Title = styled.h1`
	${(props) => {
		return `
			color: ${props.theme.primary};
			text-align: center;
			margin: 50px 0px;
			font-size: 40px;
		`
	}}
`

export const Panel = styled(TEPanel)`
	margin: 0px auto;
	max-width: 600px;
`
