import { colors } from './../../../config/styles'
//import images from './../../../config/images';

export default {
	container: {
		alignItems: 'center',
		justifyContent: 'center',
		minHeight: 'calc(100vh - 160px)',
		backgroundColor: colors.lightestGray,
	},
	panel: {
		maxWidth: 600,
		borderRadius: 5,
		paddingTop: 60,
		paddingBottom: 60,
		paddingRight: 60,
		paddingLeft: 60,
	},
	title: {
		textAlign: 'center',
		marginTop: 0,
		marginBottom: 20,
		fontSize: 38,
		color: colors.primary,
	},
	subtitle: {
		marginTop: 0,
		marginBottom: 20,
		fontWeight: 400,
		fontSize: 16,
		color: colors.gray,
		lineHeight: 1.25,
	},
	downloadWrapper: {
		display: 'flex',
	},
	downloadColumn: {
		flex: 1,
		paddingLeft: 10,
		paddingRight: 10,
	},
	downloadTitle: {
		fontSize: 22,
	},
	downloadSubitle: {
		fontWeight: 400,
		fontSize: 16,
	},
	downloadUl: {
		margin: 0,
	},
	downloadLi: {
		fontSize: 14,
		marginBottom: 10,
	},
	activationKeyButton: {},
}
