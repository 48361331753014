export const tableColumns = [
	{
		id: 'site',
		Header: 'Site',
		accessor: 'site',
		style: { 'whiteSpace': 'normal','overflow': 'unset','textOverflow': 'unset','wordBreak': 'break-all' }
	},
	{
		id: 'region',
		Header: 'Region',
		accessor: 'region',
		style: { 'whiteSpace': 'normal','overflow': 'unset','textOverflow': 'unset','wordBreak': 'break-all' }
	},
	{
		id: 'accountNumber',
		Header: 'Account #',
		accessor: 'accountNumber',
		style: { 'whiteSpace': 'normal','overflow': 'unset','textOverflow': 'unset','wordBreak': 'break-all' }
	},
	{
		id: 'address',
		Header: 'Address',
		accessor: 'address',
		style: { 'whiteSpace': 'normal','overflow': 'unset','textOverflow': 'unset','wordBreak': 'break-all' }
	},
	{
		id: 'city',
		Header: 'City',
		accessor: 'city',
		style: { 'whiteSpace': 'normal','overflow': 'unset','textOverflow': 'unset','wordBreak': 'break-all' }
	},
	{
		id: 'st',
		Header: 'State',
		accessor: 'st',
		style: { 'whiteSpace': 'normal','overflow': 'unset','textOverflow': 'unset','wordBreak': 'break-all' }
	},
	{
		id: 'zip',
		Header: 'Zipcode',
		accessor: 'zip',
		style: { 'whiteSpace': 'normal','overflow': 'unset','textOverflow': 'unset','wordBreak': 'break-all' }
	},
	{
		id: 'pmStatus',
		Header: 'PM Status',
		accessor: 'pmStatus',
		style: { 'whiteSpace': 'normal','overflow': 'unset','textOverflow': 'unset','wordBreak': 'break-all' }
	},
	{
		id: 'pmServiceDate',
		Header: 'PM Service Date',
		accessor: 'pmServiceDate',
		style: { 'whiteSpace': 'normal','overflow': 'unset','textOverflow': 'unset','wordBreak': 'break-all' }
	},
	{
		id: 'pmServiceETA',
		Header: 'PM Service ETA',
		accessor: 'pmServiceETA',
		style: { 'whiteSpace': 'normal','overflow': 'unset','textOverflow': 'unset','wordBreak': 'break-all' }
	},
	{
		id: 'pmWorkOrder',
		Header: 'PM Work Order',
		accessor: 'pmWorkOrder',
		style: { 'whiteSpace': 'normal','overflow': 'unset','textOverflow': 'unset','wordBreak': 'break-all' }
	},
	{
		id: 'serviceTechnician',
		Header: 'Service Technician',
		accessor: 'serviceTechnician',
		style: { 'whiteSpace': 'normal','overflow': 'unset','textOverflow': 'unset','wordBreak': 'break-all' }
	},
	{
		id: 'technicianDOB',
		Header: 'Technician DOB (mm/dd)',
		accessor: 'technicianDOB',
		style: { 'whiteSpace': 'normal','overflow': 'unset','textOverflow': 'unset','wordBreak': 'break-all' }
	},
	{
		id: 'amazonITPOCName',
		Header: 'Amazon IT POC Name',
		accessor: 'amazonITPOCName',
		style: { 'whiteSpace': 'normal','overflow': 'unset','textOverflow': 'unset','wordBreak': 'break-all' }
	},
	{
		id: 'amazonITPOCPhone',
		Header: 'Amazon IT POC Phone #',
		accessor: 'amazonITPOCPhone',
		style: { 'whiteSpace': 'normal','overflow': 'unset','textOverflow': 'unset','wordBreak': 'break-all' }
	},
	{
		id: 'serviceProvider',
		Header: 'Service Provider',
		accessor: 'serviceProvider',
		style: { 'whiteSpace': 'normal','overflow': 'unset','textOverflow': 'unset','wordBreak': 'break-all' }
	},
	{
		id: 'quantity',
		Header: 'Qty.',
		accessor: 'quantity',
		style: { 'whiteSpace': 'normal','overflow': 'unset','textOverflow': 'unset','wordBreak': 'break-all' }
	},
	{
		id: 'model',
		Header: 'Model',
		accessor: 'model',
		style: { 'whiteSpace': 'normal','overflow': 'unset','textOverflow': 'unset','wordBreak': 'break-all' }
	},
	{
		id: 'poNumber',
		Header: 'PO Number',
		accessor: 'poNumber',
		style: { 'whiteSpace': 'normal','overflow': 'unset','textOverflow': 'unset','wordBreak': 'break-all' }
	},
	{
		id: 'siteITEmail',
		Header: 'Site IT Email',
		accessor: 'siteITEmail',
		style: { 'whiteSpace': 'normal','overflow': 'unset','textOverflow': 'unset','wordBreak': 'break-all' }
	},
	{
		id: 'siteRMEEmail',
		Header: 'Site RME Email',
		accessor: 'siteRMEEmail',
		style: { 'whiteSpace': 'normal','overflow': 'unset','textOverflow': 'unset','wordBreak': 'break-all' }
	},
	{
		id: 'siteITManagerEmail',
		Header: 'Site IT Manager Email',
		accessor: 'siteITManagerEmail',
		style: { 'whiteSpace': 'normal','overflow': 'unset','textOverflow': 'unset','wordBreak': 'break-all' }
	},
	{
		id: 'regionalITManagerEmail',
		Header: 'Regional IT Manager Email',
		accessor: 'regionalITManagerEmail',
		style: { 'whiteSpace': 'normal','overflow': 'unset','textOverflow': 'unset','wordBreak': 'break-all' }
	}
]
