import { LoadingKeys } from './../actions/types'

const INITIAL_STATE = {
    userCheckComplete: false,
    appVersions: undefined,
    appVersionsLoaded: false,

    userModel: undefined,
    userModelLoaded: false,
    userWatcher: undefined,
}

export default (state = INITIAL_STATE, action) => {
    const {
        USER_CHECK_COMPLETE,
        USER_CHECK_STARTED,
        FETCH_APP_VERSIONS,

        FETCH_USER,
        SAVE_USER_WATCHER,
        REMOVE_USER_WATCHER,
    } = LoadingKeys

    switch (action.type) {
        case USER_CHECK_STARTED:
            // return { ...state, userCheckComplete: false }
            return state
        case USER_CHECK_COMPLETE:
            return { ...state, userCheckComplete: true }
        case FETCH_APP_VERSIONS:
            return { ...state, appVersions: action.payload, appVersionsLoaded: true }

        case FETCH_USER:
            if (!action.payload) {
                return { ...state, userModel: action.payload, userModelLoaded: true }
            }
            action.payload.displayName = `${action.payload.firstName || ''} ${action.payload
                .lastName || ''}`.trim()
            return { ...state, userModel: action.payload, userModelLoaded: true }
        case SAVE_USER_WATCHER:
            return { ...state, userWatcher: action.payload }
        case REMOVE_USER_WATCHER:
            return {
                ...state,
                userModel: INITIAL_STATE.userModel,
                userModelLoaded: INITIAL_STATE.userModelLoaded,
                userWatcher: INITIAL_STATE.userWatcher,
            }

        default:
            return state
    }
}
