import { colors } from './../../../config/styles'

export default {
    container: {
        marginBottom: 10,
        display: 'inline-block',
        verticalAlign: 'top',
    },
    title: {
        textAlign: 'left',
        display: 'block',
        color: colors.darkerGray,
        marginBottom: 3,
        letterSpacing: 0.5,
        fontWeight: 100,
        fontSize: 18,
    },
    inputWrapper: (scrolling) => {
        const style = {
            display: 'flex',
            flexWrap: 'wrap',
        }
        if (scrolling) {
            style.height = 300
            style.overflowY = 'scroll'
            style.backgroundColor = colors.white
            style.padding = 10
            style.border = `1px solid ${colors.lightGray}`
            style.borderRadius = 5
        }
        return style
    },
    rowStyle: {
        marginBottom: 5,
        display: 'flex',
        alignItems: 'center',
    },
    input: {
        display: 'inline-block',
        verticalAlign: 'middle',
        cursor: 'pointer',
    },
    label: {
        width: 'auto',
        display: 'inline-block',
        verticalAlign: 'middle',
        paddingLeft: 10,
        marginBottom: 0,
        fontWeight: 300,
        cursor: 'pointer',
        fontSize: 14,
        color: colors.gray,

        '@media (max-width: 550px)': {
            fontSize: 12,
        },
    },
}
