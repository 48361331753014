// import { colors } from './../../../config/styles'

export default {
	bodyContainer: {
		backgroundColor 	: '#f2f2f2',
	},
	bodyCopy: {
		color 				: '#666666',
		fontSize 			: 16,
		lineHeight 			: '150%',
		msoLineHeightRule 	: 'exactly !important',
	},
}
