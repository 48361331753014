import React from 'react'

import TELink from './../../../../component/Navigation/TELink'
import TEButton from './../../../../component/Form/TEButton'

import { JobTypes } from './../../../../config/localData'
import { convertToDateTimeShort, filterDateColumn } from './../../../../helpers'
import styles from './styles'

export const tableColumns = ({
	handleRemoveProduct,
	handleDuplicateProduct,
	status,
	jobTypeUID,
	jobUID,
	isAmazonJob
}) => [
	{
		id: 'productNumber',
		Header: '#',
		width: 40,
		Cell: (d) => d.index + 1,
	},
	{
		id: 'dateCreated',
		Header: 'Date Created',
		width: 160,
		filterMethod: (filter, row) => filterDateColumn(convertToDateTimeShort, filter, row),
		accessor: 'dateCreated',
		Cell: (d) => convertToDateTimeShort(d.value),
	},
	{
		id: 'productName',
		Header: 'Product',
		accessor: (d) =>
			JobTypes[jobTypeUID] &&
			JobTypes[jobTypeUID].ProductTypes[d.productTypeUID] &&
			JobTypes[jobTypeUID].ProductTypes[d.productTypeUID].name,
	},
	{
		id: 'remove',
		Header: '',
		sortable: false,
		filterable: false,
		maxWidth: 140,
		className: 'actionCell',
		Cell: (d) => (
			<div stlye={styles.tableActionsWrapper}>
				{!isAmazonJob && (
					<TELink
						to={`/manager/jobs/${status}/${jobUID}/${d.original.uid}`}
						style={styles.link}
						wrapperStyle={styles.linkWrapper}
					>
						Details
					</TELink>
				)}
				{isAmazonJob && (
					<TELink
						to={`/manager/jobs/${status}/${jobUID}/${d.original.uid}`}
						style={styles.link}
						wrapperStyle={styles.linkWrapper}
					>
						Checklist
					</TELink>
				)}
				{status === 'live' && (<div><TEButton onClick={() => handleDuplicateProduct(d.original)} style={styles.link}>
					Duplicate
				</TEButton><TEButton onClick={() => handleRemoveProduct(d.original)} style={styles.link}>
					Delete
				</TEButton></div>)}
			</div>
		),
	},
]

export const sparePartTableColumns = ({
	handleRemoveSparePart,
	handleEditSparePart,
	status,
	jobUID,
}) => [
	{
		id: 'partNumber',
		Header: 'Part Number',
		accessor: 'partNumber'
	},
	{
		id: 'partDescription',
		Header: 'Part Description',
		accessor: 'partDescription'
	},
	{
		id: 'quantity',
		Header: 'Quantity',
		accessor: 'quantity'
	},
	{
		id: 'inStock',
		Header: 'In Stock?',
		accessor: 'inStock',
		Cell: (d) => (d.value ? "Yes" : "No")
	},
	{
		id: 'dateCreated',
		Header: 'Date Created',
		width: 160,
		filterMethod: (filter, row) => filterDateColumn(convertToDateTimeShort, filter, row),
		accessor: 'dateCreated',
		Cell: (d) => convertToDateTimeShort(d.value),
	},
	{
		id: 'remove',
		Header: '',
		sortable: false,
		filterable: false,
		maxWidth: 140,
		className: 'actionCell',
		Cell: (d) => (
			<div stlye={styles.tableActionsWrapper}>
				{status === 'live' && (
					<div>
						<TEButton onClick={() => handleEditSparePart(d.original)} style={styles.link}>Edit</TEButton>
						<TEButton onClick={() => handleRemoveSparePart(d.original)} style={styles.link}>Delete</TEButton>
					</div>
				)}
			</div>
		),
	},
]