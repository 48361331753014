import React, { Component, Fragment } from 'react'

import TEInputRow from './../../../../component/Form/TEInputRow'
import TEDatetimeRow from './../../../../component/Form/TEDatetimeRow'
import TEFileManagerRow from './../../../../component/Form/TEFileManagerRow'
import TEImageRow from './../../../../component/Form/TEImageRow'
import TESegmentedGroup from './../../../../component/Form/TESegmentedGroup'
import TESearchSelectRow from './../../../../component/Form/TESearchSelectRow'

// import styles from './styles'

//TODO: Break Into Separate Components
class Input extends Component {
	render() {
		const {
			inputData,
			inputUID,
			state,
			setState,
			handleUploadImage,
			handleRemoveImage,
			handleUploadFile,
			handleRemoveFile,
			isLive,
		} = this.props
		const {
			name,
			inputType,
			type,
			accept,
			pattern,
			maxNumber,
			options,
			notes,
		} = inputData
		let { notesRequiredValues } = inputData
		if(notesRequiredValues === undefined)
			notesRequiredValues = [];
		switch (inputType) {
			case 'input':
				switch (type) {
					case 'text':
					case 'textarea':
						return (
							<TEInputRow
								type={type}
								labelForKey={inputUID}
								title={name}
								value={state[inputUID] || ''} //So it doesn't yell about not being controlled
								onChange={(e) => setState({ [inputUID]: e.target.value })}
								disabled={!isLive}
								// placeholder="name"
							/>
						)
					case 'upload':
						if (accept === 'image/*') {
							return (
								<TEImageRow
									labelForKey={inputUID}
									title={name}
									imgSrc={state[inputUID]}
									onRemove={({ uid, path }) =>
										handleRemoveImage({ uid, path, inputUID })
									}
									onUpload={({ file, editor }) =>
										handleUploadImage({
											file,
											editor,
											inputUID,
										})
									}
									onChange={(fileDataArray) =>
										setState({ [inputUID]: fileDataArray })
									}
									accept={accept}
									pattern={new RegExp(pattern)}
									maxNumber={maxNumber}
									disabled={!isLive}
								/>
							)
						}
						return (
							<TEFileManagerRow
								labelForKey={inputUID}
								title={name}
								fileSrc={state[inputUID]}
								onRemove={({ uid, path }) =>
									handleRemoveFile({ uid, path, inputUID })
								}
								onUpload={({ fileArray }) =>
									handleUploadFile({
										fileArray,
										inputUID,
									})
								}
								// onChange={(fileDataArray) => {
								// 	console.log('onChange', { fileDataArray })
								// 	setState({ [inputUID]: fileDataArray })
								// }}
								accept={accept}
								pattern={new RegExp(pattern)}
								maxNumber={maxNumber}
								disabled={!isLive}
							/>
						)
					case 'radio':
						//Segmented
						if (options.length < 5) {
							return (
								<Fragment>
									<TESegmentedGroup
										labelForKey={inputUID}
										title={name}
										onChange={(e) =>
											setState({
												[inputUID]: {
													...state[inputUID],
													value: e.target.value,
												},
											})
										}
										checkedValue={
											(state[inputUID] && state[inputUID].value) || ''
										} //So it doesn't yell about not being controlled
										buttonArray={options}
										disabled={!isLive}
									/>
									{notes && (
										<TEInputRow
											type="textarea"
											labelForKey={inputUID}
											title={`${name} Notes`}
											// labelStyles={{ display: 'none' }}
											value={(state[inputUID] && state[inputUID].note) || ''} //So it doesn't yell about not being controlled
											onChange={(e) =>
												setState({
													[inputUID]: {
														...state[inputUID],
														note: e.target.value,
													},
												})
											}
											required={
												state[inputUID] &&
												state[inputUID].value &&
												notesRequiredValues.includes(state[inputUID].value)
											}
											// placeholder="name"
											disabled={!isLive}
										/>
									)}
								</Fragment>
							)
						}

						//Search Select
						return (
							<Fragment>
								<TESearchSelectRow
									labelForKey={inputUID}
									title={name}
									value={(state[inputUID] && state[inputUID].value) || ''}
									onChange={({ option }) => {
										setState({
											[inputUID]: {
												...state[inputUID],
												value: option,
											},
										})
									}}
									options={options}
									// placeholder="products"
									disabled={!isLive}
								/>
								{notes && (
									<TEInputRow
										type="textarea"
										labelForKey={inputUID}
										title={`${name} Notes`}
										// labelStyles={{ display: 'none' }}
										value={(state[inputUID] && state[inputUID].note) || ''} //So it doesn't yell about not being controlled
										onChange={(e) =>
											setState({
												[inputUID]: {
													...state[inputUID],
													note: e.target.value,
												},
											})
										}
										// placeholder="name"
										required={
											state[inputUID] &&
											state[inputUID].value &&
											notesRequiredValues.includes(state[inputUID].value)
										}
										disabled={!isLive}
									/>
								)}
							</Fragment>
						)
					default:
						return (
							<TEInputRow
								labelForKey={inputUID}
								title={name}
								value={state[inputUID]}
								onChange={(e) =>
									setState({
										[inputUID]: e.target.value,
									})
								}
								disabled={!isLive}
								// placeholder="name"
							/>
						)
				}
			case 'datetimePicker':
				return (
					<TEDatetimeRow
						labelForKey={inputUID}
						title={name}
						value={state[inputUID]}
						onChange={(value) => setState({ [inputUID]: value })}
						// placeholder={'1/1/2019'}
						disabled={!isLive}
					/>
				)
			default:
				console.warn('Input Type Note Handled!!!', inputType)
				return (
					<div
						style={{
							marginBottom: 15,
						}}
					>
						<div>Input Name: {name}</div>
						<div>Input Type: {inputUID}</div>
						<div>Type: {type}</div>
					</div>
				)
		}
	}
}

export default Input
