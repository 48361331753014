import React, { Component } from 'react'
import { connect } from 'react-redux'
import Radium from 'radium'

import { setPageTitle } from './../../../actions'
import { startWatchingUser, stopWatchingUser, saveUser } from './../../../actions/Admin'

import PanelWrapper from './../../../component/Layout/PanelWrapper'
import Panel from './../../../component/Layout/Panel'

import TEHelmet from './../../../component/TEHelmet'
import TEForm from './../../../component/Form/TEForm'
import TEInputRow from './../../../component/Form/TEInputRow'
import TECheckboxGroup from './../../../component/Form/TECheckboxGroup'
import TESegmentedGroup from './../../../component/Form/TESegmentedGroup'
import TEButton from './../../../component/Form/TEButton'

import styles from './styles'

class User extends Component {
	state = { firstName: '', lastName: '', email: '', permissions: [], active: '', firstLoad: true }

	componentDidMount() {
		const { match, startWatchingUser, setPageTitle } = this.props
		const { uid } = match.params
		startWatchingUser(uid)
		setPageTitle('User')
	}
	componentWillUnmount() {
		this.props.stopWatchingUser()
	}

	static getDerivedStateFromProps(nextProps, previousState) {
		if (
			nextProps.userLoaded &&
			nextProps.user &&
			nextProps.userPermissionsLoaded &&
			nextProps.userPermissions &&
			previousState.firstLoad
		) {
			return {
				firstName: nextProps.user.firstName || '',
				lastName: nextProps.user.lastName || '',
				email: nextProps.user.email || '',
				permissions: Object.keys(nextProps.userPermissions),
				active: nextProps.user.active ? 'Yes' : 'No',
				firstLoad: false,
			}
		}

		return null
	}

	handleSave = () => {
		const { firstName, lastName, permissions, active } = this.state
		const { match, saveUser } = this.props
		const { uid } = match.params

		const data = { uid, firstName, lastName, permissions, active }

		saveUser(data)
	}

	render() {
		const meta = {
			title: 'Rittal | Admin User',
			description: '',
		}
		const { firstName, lastName, email, permissions, active } = this.state

		return (
			<PanelWrapper>
				<TEHelmet {...meta} />
				<Panel title="User Details" containerStyle={styles.editPanel}>
					<TEForm>
						<TEInputRow
							labelForKey="firstName"
							title="First Name"
							value={firstName}
							onChange={(e) => {
								this.setState({ firstName: e.target.value })
							}}
							placeholder="Matthew"
							required
							size="half"
						/>
						<TEInputRow
							labelForKey="lastName"
							title="Last Name"
							value={lastName}
							onChange={(e) => {
								this.setState({ lastName: e.target.value })
							}}
							placeholder="Stevens"
							required
							size="half"
							last
						/>
						<TEInputRow
							labelForKey="email"
							title="Email"
							value={email}
							onChange={() => {}}
							placeholder="matthew.stevens@gmail.com"
							required
							disabled
						/>
						<TECheckboxGroup
							labelForKey="permissions"
							title="User Permissions"
							checkedValues={permissions}
							onChange={(permissions) => this.setState({ permissions })}
							buttonArray={[
								{ label: 'Manager', value: 'manager' },
								{ label: 'Technician', value: 'technician' },
							]}
							required
						/>
						<TESegmentedGroup
							labelForKey="active"
							title="Account Active"
							checkedValue={active}
							onChange={(e) => this.setState({ active: e.target.value })}
							buttonArray={['Yes', 'No']}
							inline
						/>
						<TEButton onClick={this.handleSave}>Save</TEButton>
					</TEForm>
				</Panel>
			</PanelWrapper>
		)
	}
}

User.propTypes = {}

User.defaultProps = {}

const mapStateToProps = (state) => {
	const { userLoaded, user, userPermissionsLoaded, userPermissions } = state.Admin.Organizations
	return { userLoaded, user, userPermissionsLoaded, userPermissions }
}

export default connect(
	mapStateToProps,
	{ startWatchingUser, stopWatchingUser, setPageTitle, saveUser }
)(Radium(User))
