import React, { Component } from 'react'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Radium from 'radium'

import TESideNavLink from './../../../component/Navigation/TESideNavLink'
import TELink from './../../../component/Navigation/TELink'

//import { } from './../../../actions'
import { brandmark } from './../../../config/images'
import styles from './styles'

class SideNavbar extends Component {
	render() {
		const { location } = this.props
		return (
			<div style={styles.container}>
				<TELink to="/">
					<img src={brandmark} style={styles.logo} alt="brandmark" title="Rittal" />
				</TELink>
				<div style={styles.navContainer}>
					<ul style={styles.mainUl}>
						<TESideNavLink
							to="/manager/welcome"
							activePath="/manager/welcome"
							location={location}
							title="Welcome"
						/>
						<TESideNavLink
							to="/manager/jobs/live"
							activePath="/manager/jobs"
							location={location}
							title="Service Cases"
						/>
						<TESideNavLink
							to="/manager/organization/users"
							activePath="/manager/organization"
							location={location}
							title="Organization"
						/>
						<TESideNavLink
							to="/manager/mobile"
							activePath="/manager/mobile"
							location={location}
							title="Mobile"
						/>
						<TESideNavLink
							to="/manager/standup"
							activePath="/manager/standup"
							location={location}
							title="Standup Data"
						/>
					</ul>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	//const { } = state.Auth
	return {}
}

export default connect(
	mapStateToProps,
	{}
)(Radium(SideNavbar))
