import { useEffect, useState } from 'react'

import { recoverEmail, verifyEmail } from './requests'

export const useLoad = (props, popupDispatch) => {
	const { search } = props.location
	const [actionCode, setActionCode] = useState('')
	const [mode, setMode] = useState('loading')

	useEffect(() => {
		const query = new URLSearchParams(search)
		const actionCode = query.get('oobCode')
		const mode = query.get('mode')
		setActionCode(actionCode)
		setMode(mode)

		const data = {
			actionCode,
			popupDispatch,
		}

		switch (mode) {
			case 'recoverEmail':
				recoverEmail(data)
				break
			case 'verifyEmail':
				verifyEmail(data)
				break
			default:
				break
		}
	}, [search, popupDispatch])

	return { actionCode, mode }
}
