import React, { Component } from 'react'
import Radium from 'radium'

import settings from './../../../config/settings'
import { compareToLocalVersion } from './../../../helpers'

import styles from './styles'

class Footer extends Component {
	render() {
		const { appVersions } = this.props
		console.log(appVersions)
		const { behind } = compareToLocalVersion(appVersions.web.current)
		return (
			<div style={styles.container}>
				<div style={styles.copyrightContainer}>
					<p style={styles.version}>
						v{settings.VERSION}
						{behind && (
							<button
								style={styles.updateButton}
								onClick={() => window.location.reload(true)}
								key="UpdateButton"
							>
								Update Avaiable (v
								{appVersions.web.current})
							</button>
						)}
					</p>
					<a href="mailto:service@rittal.us" style={styles.email}>
						service@rittal.us
					</a>
					<p style={styles.copyrightCopy}>
						© {new Date().getFullYear()} Rittal - All rights reserved.
					</p>
				</div>
			</div>
		)
	}
}

Footer.propTypes = {}

Footer.defaultProps = {}

export default Radium(Footer)
