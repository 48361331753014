import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import Radium from 'radium'
import { Accordion, AccordionItem } from 'react-accessible-accordion'

import {
	startWatchingJob,
	stopWatchingJob,
	startWatchingProduct,
	stopWatchingProduct,
	confirmRemoveProduct,
	uploadImage,
	confirmRemoveImage,
	uploadFile,
	confirmRemoveFile,
	saveProduct,
} from './../../../../actions/Technician'

import TEHelmet from './../../../../component/TEHelmet'

import PanelWrapper from './../../../../component/Layout/PanelWrapper'
import Panel from './../../../../component/Layout/Panel'

import TEForm from './../../../../component/Form/TEForm'
import TEButton from './../../../../component/Form/TEButton'

import Title from './Title'
import Body from './Body'

import { JobTypes } from './../../../../config/localData'
import styles from './styles'

class Product extends Component {
	//TODO: Think about moving this to context for performance
	state = { formData: {} }
	componentDidMount() {
		const { match, userModel, startWatchingJob, startWatchingProduct } = this.props
		const { organizationUID } = userModel
		const { status, jobUID, productUID } = match.params
		startWatchingProduct(organizationUID, productUID, status)
		startWatchingJob(organizationUID, jobUID, status)
	}
	componentWillUnmount() {
		const { stopWatchingJob, stopWatchingProduct } = this.props
		stopWatchingJob()
		stopWatchingProduct()
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		const { product } = nextProps
		if (product && product !== prevState.product) {
			const { formData = {} } = product
			return { product, formData }
		}
		return { product }
	}

	handleRemoveProduct = () => {
		const { userModel, match, confirmRemoveProduct, product, job } = this.props
		const { organizationUID } = userModel
		const { productUID } = match.params
		const { jobTypeUID } = job

		const data = {
			organizationUID,
			product: { ...product, uid: productUID },
			JobTypes,
			jobTypeUID,
		}

		confirmRemoveProduct(data)
	}

	handleUploadImage = ({ editor, inputUID }) => {
		const { formData } = this.state
		const { uploadImage, userModel, match } = this.props
		const { productUID } = match.params
		const { organizationUID } = userModel

		const data = { editor, inputUID, productUID, organizationUID, formData }

		return uploadImage(data)
	}
	handleRemoveImage = ({ uid, path, inputUID }) => {
		const { formData } = this.state
		const { confirmRemoveImage, userModel, match } = this.props
		const { productUID } = match.params
		const { organizationUID } = userModel

		const data = { organizationUID, productUID, inputUID, path, uid, formData }
		confirmRemoveImage(data)
	}
	handleUploadFile = ({ fileArray, inputUID }) => {
		const { formData } = this.state
		const { uploadFile, userModel, match } = this.props
		const { productUID } = match.params
		const { organizationUID } = userModel

		const data = { fileArray, inputUID, productUID, organizationUID, formData }

		return uploadFile(data)
	}
	handleRemoveFile = ({ uid, path, inputUID }) => {
		const { formData } = this.state
		const { confirmRemoveFile, userModel, match } = this.props
		const { productUID } = match.params
		const { organizationUID } = userModel

		const data = { organizationUID, productUID, inputUID, path, uid, formData }
		confirmRemoveFile(data)
	}
	handleSaveProduct = () => {
		const { formData } = this.state
		const { match, userModel, saveProduct, job, product } = this.props
		const { productUID } = match.params
		const { organizationUID } = userModel

		const { jobTypeUID } = job
		const { productTypeUID } = product
		const { Sections } = JobTypes[jobTypeUID].ProductTypes[productTypeUID]
		let inputs = {}
		for (const sectionKey in Sections) {
			const { Inputs } = Sections[sectionKey]
			for (const inputKey in Inputs) {
				inputs[inputKey] = { ...Inputs[inputKey], section: Sections[sectionKey].name }
			}
		}

		const data = { organizationUID, productUID, inputs, ...formData }
		saveProduct(data)
	}
	render() {
		const meta = {
			title: 'Rittal | Product',
			description: '',
		}
		const { formData } = this.state
		const { match, job, jobLoaded, product, productLoaded } = this.props
		const { status } = match.params
		const isLive = status === 'live'
		if (!job || !product) {
			if (jobLoaded && productLoaded) {
				return <h1 style={{ textAlign: 'center', marginTop: 50 }}>Error Loading Product</h1>
			} else {
				return null //Still Loading
			}
		}
		const { jobTypeUID } = job
		const { productTypeUID } = product

		let sectionArray = []
		let sections = {}
		const { SectionArray, Sections, name } = JobTypes[jobTypeUID].ProductTypes[productTypeUID]
		sectionArray = SectionArray
		sections = Sections

		return (
			<Fragment>
				<TEHelmet {...meta} />
				<PanelWrapper>
					<Panel
						title={`Equipment Checklist - ${name}`}
						rightComponent={isLive && (
							<TEButton onClick={this.handleRemoveProduct}>Delete Product</TEButton>
						)}
					>
						<TEForm>
							<Accordion>
								{sectionArray.map((sectionUID) => {
									const section = sections[sectionUID]

									return (
										<AccordionItem
											key={`section_${sectionUID}`}
											style={styles.sectionItem}
										>
											<Title section={section} />
											<Body
												section={section}
												state={this.state.formData}
												setState={(object) => {
													this.setState({
														formData: { ...formData, ...object },
													})
												}}
												handleUploadImage={this.handleUploadImage}
												handleRemoveImage={this.handleRemoveImage}
												handleUploadFile={this.handleUploadFile}
												handleRemoveFile={this.handleRemoveFile}
												isLive={isLive}
											/>
										</AccordionItem>
									)
								})}
							</Accordion>
							{isLive && <TEButton onClick={this.handleSaveProduct}>Save</TEButton>}
						</TEForm>
					</Panel>
				</PanelWrapper>
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => {
	const { userModel } = state.Loading
	const { job, jobLoaded } = state.Technician.Jobs
	const { product, productLoaded } = state.Technician.Products

	return {
		userModel,
		job,
		jobLoaded,
		product,
		productLoaded,
	}
}

export default connect(
	mapStateToProps,
	{
		startWatchingJob,
		stopWatchingJob,
		startWatchingProduct,
		stopWatchingProduct,
		confirmRemoveProduct,
		uploadImage,
		confirmRemoveImage,
		uploadFile,
		confirmRemoveFile,
		saveProduct,
	}
)(Radium(Product))
