import React, { useContext, useState } from 'react'
import { TEForm, TEInputRow, TEButton, TEPopupContext, TEPanelWrapper } from 'react-tec'

import { Title, Panel } from './styledComponents'

import { useLoad } from './hooks'
import { resetPassword } from './requests'

const EmailActions = (props) => {
	const { dispatch: popupDispatch } = useContext(TEPopupContext)
	const [password, setPassword] = useState('')
	const { mode, actionCode } = useLoad(props, popupDispatch)

	const handleResetPassword = () => {
		const { history } = props
		const data = {
			password,
			actionCode,
			popupDispatch,
			history,
		}

		resetPassword(data)
	}

	switch (mode) {
		case 'loading':
			return null
		case 'recoverEmail':
			return (
				<TEPanelWrapper wrapperHeight={200}>
					<Panel>
						<Title>Recover Email</Title>
					</Panel>
				</TEPanelWrapper>
			)
		case 'verifyEmail':
			return (
				<TEPanelWrapper wrapperHeight={200}>
					<Panel>
						<Title>Email Verification</Title>
					</Panel>
				</TEPanelWrapper>
			)
		case 'resetPassword':
			return (
				<TEPanelWrapper wrapperHeight={200}>
					<Panel title="Reset Password">
						<TEForm>
							<TEInputRow
								labelForKey="password"
								type="password"
								title="New Password"
								value={password}
								onChange={(e) => setPassword(e.target.value)}
								required
							/>
							<TEButton onClick={handleResetPassword}>Reset Password</TEButton>
						</TEForm>
					</Panel>
				</TEPanelWrapper>
			)
		default:
			return (
				<TEPanelWrapper wrapperHeight={200}>
					<Panel>
						<Title>Error Loading The Appropriate Content.</Title>
					</Panel>
				</TEPanelWrapper>
			)
	}
}

export default EmailActions
