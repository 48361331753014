import validate from 'validate.js'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/storage'
import Compressor from 'compressorjs'

import {
	SHOW_ALERT,
	SHOW_CONFIRM,
	HIDE_CONFIRM,
	SHOW_NETWORK_ACTIVITY,
	HIDE_NETWORK_ACTIVITY,
	ManagerKeys,
} from './../types'

export const startWatchingProducts = (orgUID, jobUID) => {
	const { FETCH_PRODUCTS, SAVE_PRODUCTS_WATCHER } = ManagerKeys

	return (dispatch) => {
		const productsWatcherRef = firebase
			.database()
			.ref(`Products/${orgUID}`)
			.orderByChild('jobUID')
			.equalTo(jobUID)
		productsWatcherRef.on(
			'value',
			(snapshot) => {
				dispatch({
					type: FETCH_PRODUCTS,
					payload: snapshot.val(),
				})
			},
			(error) => {
				console.log('Loading Error: ', error)
			}
		)
		dispatch({
			type: SAVE_PRODUCTS_WATCHER,
			payload: productsWatcherRef,
		})
	}
}
export const stopWatchingProducts = () => {
	const { REMOVE_PRODUCTS_WATCHER } = ManagerKeys
	return (dispatch, getState) => {
		const { productsWatcherRef } = getState().Manager.Products
		if (productsWatcherRef) {
			productsWatcherRef.off()
			dispatch({ type: REMOVE_PRODUCTS_WATCHER })
		}
	}
}

export const addProduct = (data) => {
	const { selectedProduct, organizationUID, jobUID, history } = data

	const validatorConstraints = {
		selectedProduct: {
			presence: {
				allowEmpty: false,
			},
		},
	}
	const validationResponse = validate(data, validatorConstraints)
	if (validationResponse) {
		return (dispatch) => {
			return new Promise((res, rej) => {
				dispatch({
					type: SHOW_ALERT,
					payload: {
						alertTitle: 'Error',
						alertMessage: Object.values(validationResponse)[0][0],
					},
				})
				return rej()
			})
		}
	}

	return (dispatch) =>
		new Promise((res, rej) => {
			dispatch({
				type: SHOW_NETWORK_ACTIVITY,
				payload: 'Adding Equipment...',
			})

			const { uid } = firebase.auth().currentUser
			firebase
				.database()
				.ref(`Products/${organizationUID}`)
				.push({
					jobUID,
					productTypeUID: selectedProduct.uid,
					dateCreated: new Date().getTime(),
					createdByUID: uid,
					organizationUID,
				})
				.then((response) => {
					const productUID = response.key
					dispatch({ type: HIDE_NETWORK_ACTIVITY })
					dispatch({
						type: SHOW_CONFIRM,
						payload: {
							confirmTitle: 'Success',
							confirmMessage:
								'Equipment has been created. Would you like to view the equipment you just created?',
							// confirmLeftOnClick: () => {
							// 	dispatch({ type: HIDE_CONFIRM })
							// },
							confirmLeftTitle: 'Cancel',
							confirmRightOnClick: () => {
								dispatch({ type: HIDE_CONFIRM })
								history.push(`/manager/jobs/live/${jobUID}/${productUID}`)
							},
							confirmRightTitle: 'View Equipment',
						},
					})
					return res()
				})
				.catch((error) => {
					console.log(error)
					dispatch({ type: HIDE_NETWORK_ACTIVITY })
					dispatch({
						type: SHOW_ALERT,
						payload: {
							alertTitle: 'Error',
							alertMessage: 'Error adding equipment',
						},
					})
					return rej()
				})
		})
}

export const confirmDuplicateProduct = (data) => {
	const { uid, organizationUID } = data

	return (dispatch) => {
		const duplicateProduct = () => {
			dispatch({ type: HIDE_CONFIRM })
			dispatch({
				type: SHOW_NETWORK_ACTIVITY,
				payload: 'Duplicating Equipment...',
			})

			firebase
				.database()
				.ref(`Products/${organizationUID}/${uid}`)
				.once('value')
				.then((snapshot) => {
					if (!snapshot.val()) {
						return Promise.reject()
					}
					return firebase
						.database()
						.ref(`Products/${organizationUID}`)
						.push({
							...snapshot.val(),
							createdByUID: firebase.auth().currentUser.uid,
							dateCreated: firebase.database.ServerValue.TIMESTAMP,
						})
				})
				.then(() => {
					dispatch({ type: HIDE_NETWORK_ACTIVITY })
					dispatch({
						type: SHOW_ALERT,
						payload: {
							alertTitle: 'Success',
							alertMessage: 'Equipment Duplicated',
						},
					})
				})
				.catch((error) => {
					console.log(error)
					dispatch({ type: HIDE_NETWORK_ACTIVITY })
					dispatch({
						type: SHOW_ALERT,
						payload: {
							alertTitle: 'Error',
							alertMessage: 'Error duplicating equipment',
						},
					})
				})
		}
		dispatch({
			type: SHOW_CONFIRM,
			payload: {
				confirmTitle: 'Duplicate Equipment',
				confirmMessage:
					'Are you sure you want to duplicate this equipment? Files and images will not be duplicated in this process.',
				confirmLeftTitle: 'Cancel',
				confirmRightOnClick: duplicateProduct,
				confirmRightTitle: 'Duplicate Equipment',
			},
		})
	}
}
export const confirmRemoveProduct = (data) => {
	const { organizationUID, product, JobTypes, jobTypeUID } = data
	const { uid, productTypeUID, formData } = product

	return (dispatch) => {
		const removeProduct = () => {
			dispatch({ type: HIDE_CONFIRM })
			dispatch({
				type: SHOW_NETWORK_ACTIVITY,
				payload: 'Deleting Equipment...',
			})

			//Collecting all Inputs
			let productInputs = {}
			const { Sections } = JobTypes[jobTypeUID].ProductTypes[productTypeUID]
			for (const sectionUID in Sections) {
				const { Inputs } = Sections[sectionUID]
				productInputs = { ...productInputs, ...Inputs }
			}

			//Checking for File Upload Inputs
			const filePathArray = []
			for (const fieldUID in formData) {
				if (productInputs[fieldUID] && productInputs[fieldUID].type === 'upload') {
					for (const fileUID in formData[fieldUID]) {
						filePathArray.push(formData[fieldUID][fileUID].path)
					}
				}
			}

			//Delete Files
			const fileDeleteRequestArray = filePathArray.map((path) =>
				firebase
					.storage()
					.ref(path)
					.delete()
			)
			Promise.all(fileDeleteRequestArray)
				.then(() => {
					return firebase
						.database()
						.ref(`Products/${organizationUID}/${uid}`)
						.remove()
				})
				.then(() => {
					dispatch({ type: HIDE_NETWORK_ACTIVITY })
					dispatch({
						type: SHOW_ALERT,
						payload: {
							alertTitle: 'Success',
							alertMessage: 'Equipment Deleted',
						},
					})
				})
				.catch((error) => {
					console.log(error)
					dispatch({ type: HIDE_NETWORK_ACTIVITY })
					dispatch({
						type: SHOW_ALERT,
						payload: {
							alertTitle: 'Error',
							alertMessage: 'Error deleting equipment',
						},
					})
				})
		}
		dispatch({
			type: SHOW_CONFIRM,
			payload: {
				confirmTitle: 'Delete Equipment',
				confirmMessage:
					'Are you sure you want to delete this equipment? This will delete all data and file related to this equipment. This action is not reversible.',
				confirmLeftTitle: 'Cancel',
				confirmRightOnClick: removeProduct,
				confirmRightTitle: 'Delete Equipment',
			},
		})
	}
}

export const startWatchingProduct = (orgUID, productUID) => {
	const { FETCH_PRODUCT, SAVE_PRODUCT_WATCHER } = ManagerKeys

	return (dispatch) => {
		const productWatcherRef = firebase.database().ref(`Products/${orgUID}/${productUID}`)
		productWatcherRef.on(
			'value',
			(snapshot) => {
				dispatch({
					type: FETCH_PRODUCT,
					payload: snapshot.val(),
				})
			},
			(error) => {
				console.log('Loading Error: ', error)
			}
		)
		dispatch({
			type: SAVE_PRODUCT_WATCHER,
			payload: productWatcherRef,
		})
	}
}
export const stopWatchingProduct = () => {
	const { REMOVE_PRODUCT_WATCHER } = ManagerKeys
	return (dispatch, getState) => {
		const { productWatcherRef } = getState().Manager.Products
		if (productWatcherRef) {
			productWatcherRef.off()
			dispatch({ type: REMOVE_PRODUCT_WATCHER })
		}
	}
}

export const uploadImage = (data) => {
	return (dispatch) =>
		new Promise((res, rej) => {
			dispatch({
				type: SHOW_NETWORK_ACTIVITY,
				payload: 'Uploading Image...',
			})

			const { editor, inputUID, productUID, organizationUID, formData } = data

			editor.getImage().toBlob((blob) => {
				new Compressor(blob, {
					maxWidth: 2480,
					maxHeight: 2480,
					quality: 0.8,
					convertSize: 1000000, //1 mb
					success: (result) => {
						const fileKey = firebase
							.database()
							.ref(`Products/${organizationUID}/${productUID}/formData/${inputUID}`)
							.push().key
						const type = result.type.split('/')[1]
						const uploadTask = firebase
							.storage()
							.ref(
								`Products/${organizationUID}/${productUID}/${inputUID}/${fileKey}.${type}`
							)
							.put(result, { contentType: result.type })
						uploadTask.on(
							'state_changed',
							(snapshot) => {
								const { bytesTransferred, totalBytes } = snapshot
								const progress = Math.round(bytesTransferred / totalBytes) * 100
								dispatch({
									type: SHOW_NETWORK_ACTIVITY,
									payload: `Image Uploading (${progress}%)...`,
								})
							},
							(error) => {
								console.log(error)
								dispatch({ type: HIDE_NETWORK_ACTIVITY })
								dispatch({
									type: SHOW_ALERT,
									payload: {
										alertTitle: 'Error',
										alertMessage: 'Error Uploading Image',
									},
								})
								return rej(error)
							},
							() => {
								uploadTask.snapshot.ref
									.getDownloadURL()
									.then((downloadURL) => {
										//TODO: Figure out of there is a better way to smart update a key in an object
										let combinedData = { ...formData }
										if (combinedData && combinedData[inputUID]) {
											combinedData[inputUID][fileKey] = {
												src: downloadURL,
												path: uploadTask.snapshot.ref.fullPath,
											}
										} else if (combinedData) {
											combinedData[inputUID] = {
												[fileKey]: {
													src: downloadURL,
													path: uploadTask.snapshot.ref.fullPath,
												},
											}
										} else {
											combinedData = {
												[inputUID]: {
													[fileKey]: {
														src: downloadURL,
														path: uploadTask.snapshot.ref.fullPath,
													},
												},
											}
										}

										return firebase
											.database()
											.ref(`Products/${organizationUID}/${productUID}`)
											.update({
												formData: combinedData,
											})
									})
									.then((response) => {
										dispatch({ type: HIDE_NETWORK_ACTIVITY })
										dispatch({
											type: SHOW_ALERT,
											payload: {
												alertTitle: 'Success',
												alertMessage: 'Successfully Uploaded Image',
											},
										})
										return res()
									})
									.catch((error) => {
										console.log(error)
										dispatch({ type: HIDE_NETWORK_ACTIVITY })
										dispatch({
											type: SHOW_ALERT,
											payload: {
												alertTitle: 'Error',
												alertMessage: 'Error Uploading Image',
											},
										})
										return rej(error)
									})
							}
						)
					},
					error: (error) => {
						console.log(error)
						dispatch({ type: HIDE_NETWORK_ACTIVITY })
						dispatch({
							type: SHOW_ALERT,
							payload: {
								alertTitle: 'Error',
								alertMessage: 'Error Uploading Image',
							},
						})
						return rej()
					},
				})
			})
		})
}
export const confirmRemoveImage = (data) => {
	return (dispatch) => {
		const removeImage = () => {
			dispatch({ type: HIDE_CONFIRM })
			dispatch({
				type: SHOW_NETWORK_ACTIVITY,
				payload: 'Deleting Image...',
			})

			const { organizationUID, productUID, inputUID, path, uid, formData } = data

			firebase
				.storage()
				.ref(path)
				.delete()
				.then(() => {
					return firebase
						.database()
						.ref(`Products/${organizationUID}/${productUID}`)
						.update({ formData })
				})
				.then((response) => {
					return firebase
						.database()
						.ref(
							`Products/${organizationUID}/${productUID}/formData/${inputUID}/${uid}`
						)
						.remove()
				})
				.then((response) => {
					dispatch({ type: HIDE_NETWORK_ACTIVITY })
					dispatch({
						type: SHOW_ALERT,
						payload: {
							alertTitle: 'Success',
							alertMessage: 'Image Deleted',
						},
					})
				})
				.catch((error) => {
					console.log(error)
					dispatch({ type: HIDE_NETWORK_ACTIVITY })
					dispatch({
						type: SHOW_ALERT,
						payload: {
							alertTitle: 'Error',
							alertMessage: 'Error Deleting Image',
						},
					})
				})
		}

		dispatch({
			type: SHOW_CONFIRM,
			payload: {
				confirmTitle: 'Delete Image',
				confirmMessage:
					'Are you sure you want to delete this image? This action cannot be undone.',
				confirmLeftTitle: 'Cancel',
				confirmRightOnClick: removeImage,
				confirmRightTitle: 'Delete Image',
			},
		})
	}
}

export const uploadFile = (data) => {
	return (dispatch) => {
		return new Promise((res, rej) => {
			dispatch({
				type: SHOW_NETWORK_ACTIVITY,
				payload: 'Uploading File...',
			})

			const { fileArray, inputUID, productUID, organizationUID, formData } = data
			if (fileArray.length === 0) {
				dispatch({ type: HIDE_NETWORK_ACTIVITY })
				dispatch({
					type: SHOW_ALERT,
					payload: {
						alertTitle: 'Error',
						alertMessage: 'No file provided but upload attempted',
					},
				})
				return rej()
			}

			const file = fileArray[0]
			const { name, type } = file
			const nameParts = name.split('.')
			const fileEnding = nameParts.pop()
			const filename = nameParts.join('.')
			// console.log(nameParts, filename, fileEnding)
			//TODO: Add some limit on file size

			const fileKey = firebase
				.database()
				.ref(`Products/${organizationUID}/${productUID}/formData/${inputUID}`)
				.push().key
			const uploadTask = firebase
				.storage()
				.ref(
					`Products/${organizationUID}/${productUID}/${inputUID}/${filename}_${fileKey}.${fileEnding}`
				)
				.put(file, { contentType: type })
			uploadTask.on(
				'state_changed',
				(snapshot) => {
					const { bytesTransferred, totalBytes } = snapshot
					const progress = Math.round(bytesTransferred / totalBytes) * 100
					dispatch({
						type: SHOW_NETWORK_ACTIVITY,
						payload: `File Uploading (${progress}%)...`,
					})
				},
				(error) => {
					console.log(error)
					dispatch({ type: HIDE_NETWORK_ACTIVITY })
					dispatch({
						type: SHOW_ALERT,
						payload: {
							alertTitle: 'Error',
							alertMessage: 'Error Uploading File',
						},
					})
					return rej(error)
				},
				() => {
					uploadTask.snapshot.ref
						.getDownloadURL()
						.then((downloadURL) => {
							//TODO: Figure out of there is a better way to smart update a key in an object
							let combinedData = { ...formData }
							if (combinedData && combinedData[inputUID]) {
								combinedData[inputUID][fileKey] = {
									src: downloadURL,
									path: uploadTask.snapshot.ref.fullPath,
									filename,
									fileEnding,
									type,
								}
							} else if (combinedData) {
								combinedData[inputUID] = {
									[fileKey]: {
										src: downloadURL,
										path: uploadTask.snapshot.ref.fullPath,
										filename,
										fileEnding,
										type,
									},
								}
							} else {
								combinedData = {
									[inputUID]: {
										[fileKey]: {
											src: downloadURL,
											path: uploadTask.snapshot.ref.fullPath,
											filename,
											fileEnding,
											type,
										},
									},
								}
							}

							return firebase
								.database()
								.ref(`Products/${organizationUID}/${productUID}`)
								.update({
									formData: combinedData,
								})
						})
						.then((response) => {
							dispatch({ type: HIDE_NETWORK_ACTIVITY })
							dispatch({
								type: SHOW_ALERT,
								payload: {
									alertTitle: 'Success',
									alertMessage: 'Successfully Uploaded File',
								},
							})
							return res()
						})
						.catch((error) => {
							console.log(error)
							dispatch({ type: HIDE_NETWORK_ACTIVITY })
							dispatch({
								type: SHOW_ALERT,
								payload: {
									alertTitle: 'Error',
									alertMessage: 'Error Uploading File',
								},
							})
							return rej(error)
						})
				}
			)
		})
	}
}
export const confirmRemoveFile = (data) => {
	return (dispatch) => {
		const removeFile = () => {
			dispatch({ type: HIDE_CONFIRM })
			dispatch({
				type: SHOW_NETWORK_ACTIVITY,
				payload: 'Deleting File...',
			})

			const { organizationUID, productUID, inputUID, path, uid, formData } = data

			firebase
				.storage()
				.ref(path)
				.delete()
				.then(() => {
					return firebase
						.database()
						.ref(`Products/${organizationUID}/${productUID}`)
						.update({ formData })
				})
				.then((response) => {
					return firebase
						.database()
						.ref(
							`Products/${organizationUID}/${productUID}/formData/${inputUID}/${uid}`
						)
						.remove()
				})
				.then((response) => {
					dispatch({ type: HIDE_NETWORK_ACTIVITY })
					dispatch({
						type: SHOW_ALERT,
						payload: {
							alertTitle: 'Success',
							alertMessage: 'File Deleted',
						},
					})
				})
				.catch((error) => {
					console.log(error)
					dispatch({ type: HIDE_NETWORK_ACTIVITY })
					dispatch({
						type: SHOW_ALERT,
						payload: {
							alertTitle: 'Error',
							alertMessage: 'Error Deleting File',
						},
					})
				})
		}

		dispatch({
			type: SHOW_CONFIRM,
			payload: {
				confirmTitle: 'Delete File',
				confirmMessage:
					'Are you sure you want to delete this image? This action cannot be undone.',
				confirmLeftTitle: 'Cancel',
				confirmRightOnClick: removeFile,
				confirmRightTitle: 'Delete File',
			},
		})
	}
}

export const saveProduct = (data) => {
	const { organizationUID, inputs, productUID, ...rest } = data

	for (const inputKey in rest) {
		const inputValue = rest[inputKey]
		const inputType = inputs[inputKey]

		//Check Required Note Case
		if (
			inputType &&
			inputType.notesRequiredValues && //There is a required Value
			inputType.notesRequiredValues.includes(inputValue.value) && //The required value is set
			!inputValue.note //There isn't a note
		) {
			return (dispatch) => {
				dispatch({
					type: SHOW_ALERT,
					payload: {
						alertTitle: 'Required Field Not Complete',
						alertMessage: `${inputType.section} / ${
							inputType.name
						} - requires you must provide a note.`,
					},
				})
			}
		}
	}

	return (dispatch) => {
		dispatch({
			type: SHOW_NETWORK_ACTIVITY,
			payload: 'Saving Equipment...',
		})

		firebase
			.database()
			.ref(`Products/${organizationUID}/${productUID}`)
			.update({ formData: { ...rest } })
			.then(() => {
				dispatch({ type: HIDE_NETWORK_ACTIVITY })
				dispatch({
					type: SHOW_ALERT,
					payload: {
						alertTitle: 'Success',
						alertMessage: 'Equipment Saved',
					},
				})
			})
			.catch((error) => {
				console.log(error)
				dispatch({ type: HIDE_NETWORK_ACTIVITY })
				dispatch({
					type: SHOW_ALERT,
					payload: {
						alertTitle: 'Error',
						alertMessage: 'Error saving equipment',
					},
				})
			})
	}
}
