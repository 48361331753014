import { createSelector } from 'reselect'

export const getOrganizations = (state) => state.Admin.Organizations.organizations
export const getOrganizationArray = createSelector(getOrganizations, (organizations) => {
	const orgArray = []
	for (const key in organizations) {
		orgArray.push({ ...organizations[key], uid: key })
	}
	return orgArray
})

export const getOrganizationUsers = (state) => state.Admin.Organizations.organizationUsers
export const getUsersPerimissons = (state) => state.Admin.Organizations.usersPermissions
export const getOrganizationUserArray = createSelector(
	getOrganizationUsers,
	getUsersPerimissons,
	(users, usersPermissions) => {
		const userArray = []
		if (users && usersPermissions) {
			for (const key in users) {
				const displayName = `${users[key].firstName || ''} ${users[key].lastName ||
					''}`.trim()
				const permissions = usersPermissions[key]
				let permissionsString = ''
				if (permissions) {
					permissionsString = Object.keys(permissions)
						.sort()
						.join('; ')
				}
				userArray.push({
					...users[key],
					uid: key,
					permissions,
					permissionsString,
					displayName,
				})
			}
		}
		return userArray
	}
)
