import React, { Component } from 'react'
import { connect } from 'react-redux'
import Radium from 'radium'

import { createOrganization } from './../../../../actions/Admin'

import TEPopupForm from './../../../../component/Popup/TEPopupForm'

import TEInputRow from './../../../../component/Form/TEInputRow'

import styles from './styles'

class CreateOrganizationsPopup extends Component {
	state = { name: '' }

	handleSubmit = () => {
		const { name } = this.state
		const { createOrganization, onClose } = this.props

		const data = { name }

		createOrganization(data)
			.then(() => {
				onClose()
				this.setState({ name: '' })
			})
			.catch((error) => console.log(error))
	}

	render() {
		const { name } = this.state
		const { visible, onClose } = this.props
		return (
			<TEPopupForm
				visible={visible}
				contentStyles={styles.content}
				onClose={onClose}
				onSubmit={this.handleSubmit}
				onSubmitTitle="Create Organization"
			>
				<h1 style={styles.formTitle}>Create Organization</h1>
				<TEInputRow
					labelForKey="name"
					title="Organization Name"
					value={name}
					onChange={(e) => {
						this.setState({ name: e.target.value })
					}}
					placeholder="name"
					required
				/>
			</TEPopupForm>
		)
	}
}

CreateOrganizationsPopup.propTypes = {}

CreateOrganizationsPopup.defaultProps = {}

const mapStateToProps = (state) => {
	return {}
}

export default connect(
	mapStateToProps,
	{
		createOrganization,
	}
)(Radium(CreateOrganizationsPopup))
