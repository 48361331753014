import React, { Fragment } from 'react'
import { Box, Item, Span } from 'react-html-email'

import TEEmailLayout from './../TEEmailLayout'

import { JobTypes } from './../../../config/localData'
import { convertToDateShort } from './../../../helpers'
import styles from './styles'

const SummaryEmail = (props) => {
	const { job, summaryData, userModel, notes, emailSubject, emailText, customerName, jobDescription } = props

	const {
		dateOfService,
		clientAddress,
		clientContact,
		clientPhone,
		jobTypeUID,
	} = job
	const { firstName, lastName } = userModel

	return (
		<TEEmailLayout emailTitle={emailSubject}>
			<Fragment>
				<Box width="100%" cellPadding={20} style={styles.bodyContainer}>
					<Item>
						<Box width="100%" cellPadding={10}>
							<Item style={{ textAlign: 'center' }}>
								<Span style={styles.titleCopy}>{emailSubject}</Span>
							</Item>
							<Item>
								<Span style={styles.bodyCopy}>
									{emailText} scheduled for {convertToDateShort(dateOfService)}.
								</Span>
							</Item>
							<Item>
								<table width="100%">
									<thead>
										<tr>
											<th style={styles.th}>Date of Service</th>
											<th style={styles.th}>Customer</th>
											<th style={styles.th}>Address</th>
											<th style={styles.th}>Contact Person</th>
											<th style={styles.th}>Contact Person Phone</th>
											<th style={styles.th}>Assigned By</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td style={styles.td}>
												{convertToDateShort(dateOfService)}
											</td>
											<td style={styles.td}>{customerName}</td>
											<td style={styles.td}>{clientAddress}</td>
											<td style={styles.td}>{clientContact}</td>
											<td style={styles.td}>{clientPhone}</td>
											<td style={styles.td}>
												{`${firstName || ''} ${lastName || ''}`.trim()}
											</td>
										</tr>
									</tbody>
								</table>
							</Item>
							{summaryData && summaryData.products && summaryData.products.length > 0 && (
								<div>
									<Item>
										<Span style={styles.sectionTitle}>Products</Span>
									</Item>
									<Item>
										<table width="100%">
											<thead>
												<tr>
													{summaryData.productCountArray.map((product) => (
														<th style={styles.th} key={product.uid}>
															{
																JobTypes[jobTypeUID].ProductTypes[
																	product.uid
																].name
															}
														</th>
													))}
												</tr>
											</thead>
											<tbody>
												<tr>
													{summaryData.productCountArray.map((product) => (
														<td style={styles.td} key={product.uid}>
															{summaryData.productData[product.uid].count}
														</td>
													))}
												</tr>
											</tbody>
										</table>
									</Item>
									{summaryData.inputArray.map((inputTypeData) => {
										const { name, products } = inputTypeData

										if (!products || products.length === 0) {
											return null
										}

										return (
											<Fragment key={name}>
												<Item>
													<Span style={styles.sectionTitle}>{name}</Span>
												</Item>
												<Item>
													<table width="100%">
														<thead>
															<tr>
																<th style={styles.th}>Product</th>
																{Object.keys(products[0])
																	.filter(
																		(input) =>
																			input !== 'name' &&
																			input !== 'uid'
																	)
																	.map((input) => (
																		<th key={input} style={styles.th}>
																			{input}
																		</th>
																	))}
															</tr>
														</thead>
														<tbody>
															{products.map((product) => {
																return (
																	<tr key={product.uid}>
																		<td style={styles.td}>
																			{product.name}
																		</td>
																		{Object.keys(products[0])
																			.filter(
																				(input) =>
																					input !== 'name' &&
																					input !== 'uid'
																			)
																			.map((input) => (
																				<td
																					key={product.uid}
																					style={styles.td}
																				>
																					{product[input]}
																				</td>
																			))}
																	</tr>
																)
															})}
														</tbody>
													</table>
												</Item>
											</Fragment>
										)
									})}
								</div>
							)}	
							{jobDescription && jobDescription !== '' && (
								<div>
									<Item>
										<Span style={styles.sectionTitle}>Job Description</Span>
									</Item>
									<Item>
										<Span style={{ ...styles.bodyCopy, whiteSpace: 'pre-line' }}>
											{jobDescription}
										</Span>
									</Item>
								</div>
							)}						
							<Item>
								<Span style={styles.sectionTitle}>Notes</Span>
							</Item>
							<Item>
								<Span style={{ ...styles.bodyCopy, whiteSpace: 'pre-line' }}>
									{notes}
								</Span>
							</Item>
						</Box>
					</Item>
				</Box>
			</Fragment>
		</TEEmailLayout>
	)
}

export default SummaryEmail
