import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
// import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'

import PrivateRoute from './../../../component/PrivateRoute'
import NotFound from './../../NotFound'

import { setPageTitle } from './../../../actions'
import { startWatchingOrganization, stopWatchingOrganization } from './../../../actions/Admin'

import TESubNavbar from './../../../component/Navigation/TESubNavbar'

import Users from './Users'
import Details from './Details'

// import styles from './styles'

class Organization extends Component {
	componentDidMount() {
		const { userModel, setPageTitle, startWatchingOrganization } = this.props
		const { organizationUID } = userModel
		setPageTitle('Organization')
		startWatchingOrganization(organizationUID)
	}
	componentWillUnmount() {
		this.props.stopWatchingOrganization()
	}

	render() {
		return (
			<Fragment>
				<TESubNavbar
					links={[
						{
							title: 'Users',
							to: `/manager/organization/users`,
						},
						{
							title: 'Details',
							to: `/manager/organization/details`,
						},
					]}
				/>
				<Switch>
					<PrivateRoute
						path="/manager/organization/users"
						component={Users}
						requiredUserType="manager"
					/>
					<PrivateRoute
						path="/manager/organization/details"
						component={Details}
						requiredUserType="manager"
					/>

					<Route component={NotFound} />
				</Switch>
			</Fragment>
		)
	}
}

Organization.propTypes = {}

Organization.defaultProps = {}

const mapStateToProps = (state) => {
	const { userModel } = state.Loading
	return { userModel }
}

export default connect(
	mapStateToProps,
	{
		setPageTitle,
		startWatchingOrganization,
		stopWatchingOrganization,
	}
)(Organization)
