// import { colors } from './../../../../config/styles'

export default {
	formTitle: {
		marginTop: 15,
		marginBottom: 30,
		textAlign: 'center',
		fontSize: 32,
		lineHeight: 1,
		fontWeight: 300,
	},
}
