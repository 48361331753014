//Change Log Not Update In Database
//
// - 1/15/19 - '*' => '.'
// - 1/24/19 - Visual Inspection / Functional Check options updated to ['Okay', 'Repair Necessary', 'Unable To Evaluate'] and matching notes required value
// - 1/24/19 - Add Visual Inspection / Functional Check to all PM Products > General
// - 1/24/19 - Added Summary Indicator

export const JobTypeArray = [
  '-LWDLWPtERYakuWxK-65',
  '-LWDLWXWe2ZTB0rEtACE',
  '-LWDLWcx7quSSG2zYcWL',
  '-LWDLWkXieJQHfcxwMig',
  '-NAA8oPA7-MrbJ7qkCqm'
]
export const JobTypes = {
  '-LWDLWPtERYakuWxK-65': {
    ProductTypeArray: [
      '-LWDLWPuo-lXx6Ss2wJ6',
      '-LWDLWR6ujx3LWXjfsCw',
      '-LWDLWTavWcdAfkUbH9I',
      '-LWDLWU_Q8uCEkoGJLwx',
      '-LWDLWWgDaLXpFuBrkCp',
    ],
    ProductTypes: {
      '-LWDLWPuo-lXx6Ss2wJ6': {
        SectionArray: [
          '-LWDLWPvoFjeSkh1WP75',
          '-LWDLWQJq--Z_7n5_LJO',
          '-LWDLWQNNjIqfjNThiaL',
          '-LWDLWQbRlVs_n17OGk8',
          '-LWDLWQiKYbWx_kdq0Ma',
          '-LWDLWQyfEn1EyP8GKfg',
        ],
        Sections: {
          '-LWDLWPvoFjeSkh1WP75': {
            InputArray: [
              '-LWDLWQ-eRF5FUgAD9rB',
              '-LWDLWQ1U2_XgW8le7I_',
              '-LWDLWQ4Qcriktfzfk2Z',
              '-LWDLWQ7gxFCKBiEaunM',
              '-LWDLWQApbaWOWq47BVV',
              '-LWDLWQDQh9qYPjPxHRK',
              '-LWDLWQFFuK7jau86g43',
              '-LWDLWQIFQTSTgkx3AOP',
            ],
            Inputs: {
              '-LWDLWQ-eRF5FUgAD9rB': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Location ID',
                productUID: '-LWDLWPuo-lXx6Ss2wJ6',
                sectionUID: '-LWDLWPvoFjeSkh1WP75',
                type: 'text',
              },
              '-LWDLWQ1U2_XgW8le7I_': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Article / Model Number',
                productUID: '-LWDLWPuo-lXx6Ss2wJ6',
                sectionUID: '-LWDLWPvoFjeSkh1WP75',
                type: 'text',
              },
              '-LWDLWQ4Qcriktfzfk2Z': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Serial Number',
                productUID: '-LWDLWPuo-lXx6Ss2wJ6',
                sectionUID: '-LWDLWPvoFjeSkh1WP75',
                type: 'text',
              },
              '-LWDLWQ7gxFCKBiEaunM': {
                inputType: 'datetimePicker',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Date of manufacture',
                productUID: '-LWDLWPuo-lXx6Ss2wJ6',
                sectionUID: '-LWDLWPvoFjeSkh1WP75',
                type: 'date',
              },
              '-LWDLWQApbaWOWq47BVV': {
                accept: 'image/*',
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                maxNumber: 3,
                name: 'Data plate',
                pattern: 'image-*',
                productUID: '-LWDLWPuo-lXx6Ss2wJ6',
                sectionUID: '-LWDLWPvoFjeSkh1WP75',
                type: 'upload',
              },
              '-LWDLWQDQh9qYPjPxHRK': {
                accept: 'image/*',
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                maxNumber: 5,
                name: 'General Images',
                pattern: 'image-*',
                productUID: '-LWDLWPuo-lXx6Ss2wJ6',
                sectionUID: '-LWDLWPvoFjeSkh1WP75',
                type: 'upload',
              },
              '-LWDLWQFFuK7jau86g43': {
                accept: '.',
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                maxNumber: 0,
                name: 'General Files',
                pattern: '.',
                productUID: '-LWDLWPuo-lXx6Ss2wJ6',
                sectionUID: '-LWDLWPvoFjeSkh1WP75',
                type: 'upload',
              },
              '-LWDLWQIFQTSTgkx3AOP': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Final Remarks',
                productUID: '-LWDLWPuo-lXx6Ss2wJ6',
                sectionUID: '-LWDLWPvoFjeSkh1WP75',
                type: 'textarea',
              },
            },
            jobUID: '-LWDLWPtERYakuWxK-65',
            name: 'General',
            productUID: '-LWDLWPuo-lXx6Ss2wJ6',
          },
          '-LWDLWQJq--Z_7n5_LJO': {
            InputArray: ['-LWDLWQLaYnUJWdrRUUk'],
            Inputs: {
              '-LWDLWQLaYnUJWdrRUUk': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Air filter',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWPuo-lXx6Ss2wJ6',
                sectionUID: '-LWDLWQJq--Z_7n5_LJO',
                type: 'radio',
              },
            },
            jobUID: '-LWDLWPtERYakuWxK-65',
            name: 'Air Filter',
            productUID: '-LWDLWPuo-lXx6Ss2wJ6',
          },
          '-LWDLWQNNjIqfjNThiaL': {
            InputArray: [
              '-LWDLWQQPO6JFlEwy5Ns',
              '-LWDLWQTdLIJ768yb5nO',
              '-LWDLWQW8ASkJsCPAU5d',
              '-LWDLWQaQLveZblptlp_',
            ],
            Inputs: {
              '-LWDLWQQPO6JFlEwy5Ns': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Air temp inside',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWPuo-lXx6Ss2wJ6',
                sectionUID: '-LWDLWQNNjIqfjNThiaL',
                type: 'radio',
              },
              '-LWDLWQTdLIJ768yb5nO': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Air temp outside',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWPuo-lXx6Ss2wJ6',
                sectionUID: '-LWDLWQNNjIqfjNThiaL',
                type: 'radio',
              },
              '-LWDLWQW8ASkJsCPAU5d': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Insulation',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWPuo-lXx6Ss2wJ6',
                sectionUID: '-LWDLWQNNjIqfjNThiaL',
                type: 'radio',
              },
              '-LWDLWQaQLveZblptlp_': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Drain',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWPuo-lXx6Ss2wJ6',
                sectionUID: '-LWDLWQNNjIqfjNThiaL',
                type: 'radio',
              },
            },
            jobUID: '-LWDLWPtERYakuWxK-65',
            name: 'Cooler',
            productUID: '-LWDLWPuo-lXx6Ss2wJ6',
          },
          '-LWDLWQbRlVs_n17OGk8': {
            InputArray: ['-LWDLWQemFsrdSnZ8NBI', '-LWDLWQiKYbWx_kdq0M_'],
            Inputs: {
              '-LWDLWQemFsrdSnZ8NBI': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Ambient temp',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWPuo-lXx6Ss2wJ6',
                sectionUID: '-LWDLWQbRlVs_n17OGk8',
                type: 'radio',
              },
              '-LWDLWQiKYbWx_kdq0M_': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: '°T condenser',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWPuo-lXx6Ss2wJ6',
                sectionUID: '-LWDLWQbRlVs_n17OGk8',
                type: 'radio',
              },
            },
            jobUID: '-LWDLWPtERYakuWxK-65',
            name: 'Condenser',
            productUID: '-LWDLWPuo-lXx6Ss2wJ6',
          },
          '-LWDLWQiKYbWx_kdq0Ma': {
            InputArray: [
              '-LWDLWQlL3R1usw_lxQG',
              '-LWDLWQnKrL9ncHBZ5K2',
              '-LWDLWQqdo0cGUOueDkv',
              '-LWDLWQuPC8CnpLWoWw8',
              '-LWDLWQxN9xgGZxxE_m-',
            ],
            Inputs: {
              '-LWDLWQlL3R1usw_lxQG': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Set point controller',
                productUID: '-LWDLWPuo-lXx6Ss2wJ6',
                sectionUID: '-LWDLWQiKYbWx_kdq0Ma',
                type: 'text',
              },
              '-LWDLWQnKrL9ncHBZ5K2': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Control voltage',
                productUID: '-LWDLWPuo-lXx6Ss2wJ6',
                sectionUID: '-LWDLWQiKYbWx_kdq0Ma',
                type: 'text',
              },
              '-LWDLWQqdo0cGUOueDkv': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Door limit switch',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWPuo-lXx6Ss2wJ6',
                sectionUID: '-LWDLWQiKYbWx_kdq0Ma',
                type: 'radio',
              },
              '-LWDLWQuPC8CnpLWoWw8': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Display function',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWPuo-lXx6Ss2wJ6',
                sectionUID: '-LWDLWQiKYbWx_kdq0Ma',
                type: 'radio',
              },
              '-LWDLWQxN9xgGZxxE_m-': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Software version (Update if needed)',
                productUID: '-LWDLWPuo-lXx6Ss2wJ6',
                sectionUID: '-LWDLWQiKYbWx_kdq0Ma',
                type: 'text',
              },
            },
            jobUID: '-LWDLWPtERYakuWxK-65',
            name: 'Electrical Components',
            productUID: '-LWDLWPuo-lXx6Ss2wJ6',
          },
          '-LWDLWQyfEn1EyP8GKfg': {
            InputArray: ['-LWDLWR-ugH3QXn_GtEC', '-LWDLWR2bZM3FLW-9wJE', '-LWDLWR6ujx3LWXjfsCv'],
            Inputs: {
              '-LWDLWR-ugH3QXn_GtEC': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Evaporation temperature',
                productUID: '-LWDLWPuo-lXx6Ss2wJ6',
                sectionUID: '-LWDLWQyfEn1EyP8GKfg',
                type: 'text',
              },
              '-LWDLWR2bZM3FLW-9wJE': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Hot gas temperature',
                productUID: '-LWDLWPuo-lXx6Ss2wJ6',
                sectionUID: '-LWDLWQyfEn1EyP8GKfg',
                type: 'text',
              },
              '-LWDLWR6ujx3LWXjfsCv': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Refrigerant charge',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWPuo-lXx6Ss2wJ6',
                sectionUID: '-LWDLWQyfEn1EyP8GKfg',
                type: 'radio',
              },
            },
            jobUID: '-LWDLWPtERYakuWxK-65',
            name: 'Compressor',
            productUID: '-LWDLWPuo-lXx6Ss2wJ6',
          },
        },
        jobUID: '-LWDLWPtERYakuWxK-65',
        name: 'AC',
      },
      '-LWDLWR6ujx3LWXjfsCw': {
        SectionArray: [
          '-LWDLWR6ujx3LWXjfsCx',
          '-LWDLWRYuyDmOfdYU8Ws',
          '-LWDLWRodIN-pGsoykHe',
          '-LWDLWSCXpk8iZoMy1SI',
          '-LWDLWScAq3TP8H0pbt-',
          '-LWDLWSyX25Li2lMJ-Uc',
          '-LWDLWTGVG07vLfDHdWF',
          '-LWDLWTJ79Coxk36sdTy',
          '-LWDLWTXoQaQaeKyBlG0',
        ],
        Sections: {
          '-LWDLWR6ujx3LWXjfsCx': {
            InputArray: [
              '-LWDLWRABeVQR3wFhg3S',
              '-LWDLWRCEWQmVwxnYiOG',
              '-LWDLWRGVbts-ZMqoxVX',
              '-LWDLWRJbt8ootq0-b8t',
              '-LWDLWRLQFTm7t29qEV5',
              '-LWDLWROp7bGMWG3-qEc',
              '-LWDLWRSrN3joYGRuL4c',
              '-LWDLWRU9Hhwpc2zOvxw',
              '-LWDLWRXXgod9u7x8wjE',
            ],
            Inputs: {
              '-LWDLWRABeVQR3wFhg3S': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Location ID',
                productUID: '-LWDLWR6ujx3LWXjfsCw',
                sectionUID: '-LWDLWR6ujx3LWXjfsCx',
                type: 'text',
              },
              '-LWDLWRCEWQmVwxnYiOG': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Article / Model Number',
                productUID: '-LWDLWR6ujx3LWXjfsCw',
                sectionUID: '-LWDLWR6ujx3LWXjfsCx',
                type: 'text',
              },
              '-LWDLWRGVbts-ZMqoxVX': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Serial Number',
                productUID: '-LWDLWR6ujx3LWXjfsCw',
                sectionUID: '-LWDLWR6ujx3LWXjfsCx',
                type: 'text',
              },
              '-LWDLWRJbt8ootq0-b8t': {
                inputType: 'datetimePicker',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Date of manufacture',
                productUID: '-LWDLWR6ujx3LWXjfsCw',
                sectionUID: '-LWDLWR6ujx3LWXjfsCx',
                type: 'date',
              },
              '-LWDLWRLQFTm7t29qEV5': {
                accept: 'image/*',
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                maxNumber: 3,
                name: 'Data plate',
                pattern: 'image-*',
                productUID: '-LWDLWR6ujx3LWXjfsCw',
                sectionUID: '-LWDLWR6ujx3LWXjfsCx',
                type: 'upload',
              },
              '-LWDLWROp7bGMWG3-qEc': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Software version',
                productUID: '-LWDLWR6ujx3LWXjfsCw',
                sectionUID: '-LWDLWR6ujx3LWXjfsCx',
                type: 'text',
              },
              '-LWDLWRSrN3joYGRuL4c': {
                accept: 'image/*',
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                maxNumber: 5,
                name: 'General Images',
                pattern: 'image-*',
                productUID: '-LWDLWR6ujx3LWXjfsCw',
                sectionUID: '-LWDLWR6ujx3LWXjfsCx',
                type: 'upload',
              },
              '-LWDLWRU9Hhwpc2zOvxw': {
                accept: '.',
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                maxNumber: 0,
                name: 'General Files',
                pattern: '.',
                productUID: '-LWDLWR6ujx3LWXjfsCw',
                sectionUID: '-LWDLWR6ujx3LWXjfsCx',
                type: 'upload',
              },
              '-LWDLWRXXgod9u7x8wjE': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Final Remarks',
                productUID: '-LWDLWR6ujx3LWXjfsCw',
                sectionUID: '-LWDLWR6ujx3LWXjfsCx',
                type: 'textarea',
              },
            },
            jobUID: '-LWDLWPtERYakuWxK-65',
            name: 'General',
            productUID: '-LWDLWR6ujx3LWXjfsCw',
          },
          '-LWDLWRYuyDmOfdYU8Ws': {
            InputArray: [
              '-LWDLWRbUQuaFo3GVz74',
              '-LWDLWRdPRD18hbI9xzQ',
              '-LWDLWRhoRZIi2Kgxooa',
              '-LWDLWRk3naXgPRNUYZ5',
              '-LWDLWRmytD-Oe2ft7V4',
            ],
            Inputs: {
              '-LWDLWRbUQuaFo3GVz74': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Check for transport damage',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWR6ujx3LWXjfsCw',
                sectionUID: '-LWDLWRYuyDmOfdYU8Ws',
                type: 'radio',
              },
              '-LWDLWRdPRD18hbI9xzQ': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Check and if needed tight all electrical connections',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWR6ujx3LWXjfsCw',
                sectionUID: '-LWDLWRYuyDmOfdYU8Ws',
                type: 'radio',
              },
              '-LWDLWRhoRZIi2Kgxooa': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Verify installation completed according to specification (if available)',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWR6ujx3LWXjfsCw',
                sectionUID: '-LWDLWRYuyDmOfdYU8Ws',
                type: 'radio',
              },
              '-LWDLWRk3naXgPRNUYZ5': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'System aligned and leveled',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWR6ujx3LWXjfsCw',
                sectionUID: '-LWDLWRYuyDmOfdYU8Ws',
                type: 'radio',
              },
              '-LWDLWRmytD-Oe2ft7V4': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Appropriate distance to other units',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWR6ujx3LWXjfsCw',
                sectionUID: '-LWDLWRYuyDmOfdYU8Ws',
                type: 'radio',
              },
            },
            jobUID: '-LWDLWPtERYakuWxK-65',
            name: 'Mechanical',
            productUID: '-LWDLWR6ujx3LWXjfsCw',
          },
          '-LWDLWRodIN-pGsoykHe': {
            InputArray: [
              '-LWDLWRqXam9zukX8fZK',
              '-LWDLWRtdxGcGlKEr3YB',
              '-LWDLWRwVISCEppCMfS8',
              '-LWDLWRzbXxdUZ-LyNcB',
              '-LWDLWS1Pvga2O2YZiaG',
              '-LWDLWS5W_QNC4xzpRHw',
              '-LWDLWS7-WT49jdkCToD',
              '-LWDLWSAojst9hStQwRJ',
              '-LWDLWSBQOEkhnhkn3Th',
            ],
            Inputs: {
              '-LWDLWRqXam9zukX8fZK': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Power supply connection according to country specific rules',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWR6ujx3LWXjfsCw',
                sectionUID: '-LWDLWRodIN-pGsoykHe',
                type: 'radio',
              },
              '-LWDLWRtdxGcGlKEr3YB': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Water supply/flow, shut-off device is integrated',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWR6ujx3LWXjfsCw',
                sectionUID: '-LWDLWRodIN-pGsoykHe',
                type: 'radio',
              },
              '-LWDLWRwVISCEppCMfS8': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Connection water return shut-off exists',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWR6ujx3LWXjfsCw',
                sectionUID: '-LWDLWRodIN-pGsoykHe',
                type: 'radio',
              },
              '-LWDLWRzbXxdUZ-LyNcB': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Distance to wall/ceiling sufficient (for air cooled)',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWR6ujx3LWXjfsCw',
                sectionUID: '-LWDLWRodIN-pGsoykHe',
                type: 'radio',
              },
              '-LWDLWS1Pvga2O2YZiaG': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Visual inspection of the filled medium for contamination',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWR6ujx3LWXjfsCw',
                sectionUID: '-LWDLWRodIN-pGsoykHe',
                type: 'radio',
              },
              '-LWDLWS5W_QNC4xzpRHw': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Portion of the glycol according to operating instructions',
                productUID: '-LWDLWR6ujx3LWXjfsCw',
                sectionUID: '-LWDLWRodIN-pGsoykHe',
                type: 'text',
              },
              '-LWDLWS7-WT49jdkCToD': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Refrigerant type',
                productUID: '-LWDLWR6ujx3LWXjfsCw',
                sectionUID: '-LWDLWRodIN-pGsoykHe',
                type: 'text',
              },
              '-LWDLWSAojst9hStQwRJ': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Refrigerant capacity',
                productUID: '-LWDLWR6ujx3LWXjfsCw',
                sectionUID: '-LWDLWRodIN-pGsoykHe',
                type: 'text',
              },
              '-LWDLWSBQOEkhnhkn3Th': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Leak test interval',
                productUID: '-LWDLWR6ujx3LWXjfsCw',
                sectionUID: '-LWDLWRodIN-pGsoykHe',
                type: 'text',
              },
            },
            jobUID: '-LWDLWPtERYakuWxK-65',
            name: 'Installation',
            productUID: '-LWDLWR6ujx3LWXjfsCw',
          },
          '-LWDLWSCXpk8iZoMy1SI': {
            InputArray: [
              '-LWDLWSFUgRbq0mSJdFl',
              '-LWDLWSJlBaYAgPTX-Gh',
              '-LWDLWSMv8NUcpx_eop0',
              '-LWDLWSQywBp-HHqDnnl',
              '-LWDLWSSKjIf7b6IOf7k',
              '-LWDLWSUBcDDvMoRyQOb',
              '-LWDLWSXDQJ9fGR8Ov3M',
              '-LWDLWS_wFNIqFkvsYr8',
              '-LWDLWScAq3TP8H0pbsz',
            ],
            Inputs: {
              '-LWDLWSFUgRbq0mSJdFl': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Examination of the voltage and current values (when load available) - L1 V',
                productUID: '-LWDLWR6ujx3LWXjfsCw',
                sectionUID: '-LWDLWSCXpk8iZoMy1SI',
                type: 'text',
              },
              '-LWDLWSJlBaYAgPTX-Gh': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Examination of the voltage and current values (when load available) - L2 V',
                productUID: '-LWDLWR6ujx3LWXjfsCw',
                sectionUID: '-LWDLWSCXpk8iZoMy1SI',
                type: 'text',
              },
              '-LWDLWSMv8NUcpx_eop0': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Examination of the voltage and current values (when load available) - L3 V',
                productUID: '-LWDLWR6ujx3LWXjfsCw',
                sectionUID: '-LWDLWSCXpk8iZoMy1SI',
                type: 'text',
              },
              '-LWDLWSQywBp-HHqDnnl': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Examination of the voltage and current values (when load available) - L1 A',
                productUID: '-LWDLWR6ujx3LWXjfsCw',
                sectionUID: '-LWDLWSCXpk8iZoMy1SI',
                type: 'text',
              },
              '-LWDLWSSKjIf7b6IOf7k': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Examination of the voltage and current values (when load available) - L2 A',
                productUID: '-LWDLWR6ujx3LWXjfsCw',
                sectionUID: '-LWDLWSCXpk8iZoMy1SI',
                type: 'text',
              },
              '-LWDLWSUBcDDvMoRyQOb': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Examination of the voltage and current values (when load available) - L3 A',
                productUID: '-LWDLWR6ujx3LWXjfsCw',
                sectionUID: '-LWDLWSCXpk8iZoMy1SI',
                type: 'text',
              },
              '-LWDLWSXDQJ9fGR8Ov3M': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Setting bypass valve check (if exists)',
                productUID: '-LWDLWR6ujx3LWXjfsCw',
                sectionUID: '-LWDLWSCXpk8iZoMy1SI',
                type: 'text',
              },
              '-LWDLWS_wFNIqFkvsYr8': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Check of the Takko setter (if exists)',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWR6ujx3LWXjfsCw',
                sectionUID: '-LWDLWSCXpk8iZoMy1SI',
                type: 'radio',
              },
              '-LWDLWScAq3TP8H0pbsz': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: "Flow rate of the BYPASS Takko's if ext. consumers closed (specify liter)",
                productUID: '-LWDLWR6ujx3LWXjfsCw',
                sectionUID: '-LWDLWSCXpk8iZoMy1SI',
                type: 'text',
              },
            },
            jobUID: '-LWDLWPtERYakuWxK-65',
            name: 'Current and Water',
            productUID: '-LWDLWR6ujx3LWXjfsCw',
          },
          '-LWDLWScAq3TP8H0pbt-': {
            InputArray: [
              '-LWDLWSg_xlt158QVYW9',
              '-LWDLWSjMV9IV7IIw9yR',
              '-LWDLWSlsNX5Isiuu26-',
              '-LWDLWSo_bOgjFFQkY2f',
              '-LWDLWSrs6_Ak2mhGVrm',
              '-LWDLWStuz-iPaZNDX1r',
              '-LWDLWSxUFfuLE4bbH_S',
            ],
            Inputs: {
              '-LWDLWSg_xlt158QVYW9': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name:
                  'With closed systems: check the primary pressure, pay attention to the height difference',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWR6ujx3LWXjfsCw',
                sectionUID: '-LWDLWScAq3TP8H0pbt-',
                type: 'radio',
              },
              '-LWDLWSjMV9IV7IIw9yR': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Bleed the system of air, refill medium',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWR6ujx3LWXjfsCw',
                sectionUID: '-LWDLWScAq3TP8H0pbt-',
                type: 'radio',
              },
              '-LWDLWSlsNX5Isiuu26-': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name:
                  'Cooling water circuit active (water-cooled condenser)? Is the customer side provided',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWR6ujx3LWXjfsCw',
                sectionUID: '-LWDLWScAq3TP8H0pbt-',
                type: 'radio',
              },
              '-LWDLWSo_bOgjFFQkY2f': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Water Cooled Condenser Supply Water Temp if available',
                productUID: '-LWDLWR6ujx3LWXjfsCw',
                sectionUID: '-LWDLWScAq3TP8H0pbt-',
                type: 'text',
              },
              '-LWDLWSrs6_Ak2mhGVrm': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Check connecting tubes and fittings for tightness',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWR6ujx3LWXjfsCw',
                sectionUID: '-LWDLWScAq3TP8H0pbt-',
                type: 'radio',
              },
              '-LWDLWStuz-iPaZNDX1r': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name:
                  'Check of the bypass control (test in short-circuit operation, value of the valve)',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWR6ujx3LWXjfsCw',
                sectionUID: '-LWDLWScAq3TP8H0pbt-',
                type: 'radio',
              },
              '-LWDLWSxUFfuLE4bbH_S': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Is the parameter setting according to the specification / manual',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWR6ujx3LWXjfsCw',
                sectionUID: '-LWDLWScAq3TP8H0pbt-',
                type: 'radio',
              },
            },
            jobUID: '-LWDLWPtERYakuWxK-65',
            name: 'Commissioning',
            productUID: '-LWDLWR6ujx3LWXjfsCw',
          },
          '-LWDLWSyX25Li2lMJ-Uc': {
            InputArray: [
              '-LWDLWT-_BG3lMH-IWwQ',
              '-LWDLWT1Ww8z9PpQmhQZ',
              '-LWDLWT4vsvvx6WoiZyv',
              '-LWDLWT88FoCeIE9n0aI',
              '-LWDLWTBbGvwCJmm41iE',
              '-LWDLWTDOaZ8yYC3B0DW',
              '-LWDLWTFwPBUUJT21NUT',
            ],
            Inputs: {
              '-LWDLWT-_BG3lMH-IWwQ': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'HP Pressure gauge',
                productUID: '-LWDLWR6ujx3LWXjfsCw',
                sectionUID: '-LWDLWSyX25Li2lMJ-Uc',
                type: 'text',
              },
              '-LWDLWT1Ww8z9PpQmhQZ': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'LP pressure gauge',
                productUID: '-LWDLWR6ujx3LWXjfsCw',
                sectionUID: '-LWDLWSyX25Li2lMJ-Uc',
                type: 'text',
              },
              '-LWDLWT4vsvvx6WoiZyv': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Condenser',
                productUID: '-LWDLWR6ujx3LWXjfsCw',
                sectionUID: '-LWDLWSyX25Li2lMJ-Uc',
                type: 'text',
              },
              '-LWDLWT88FoCeIE9n0aI': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Condenser Delta T',
                productUID: '-LWDLWR6ujx3LWXjfsCw',
                sectionUID: '-LWDLWSyX25Li2lMJ-Uc',
                type: 'text',
              },
              '-LWDLWTBbGvwCJmm41iE': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Temperature before e-valve',
                productUID: '-LWDLWR6ujx3LWXjfsCw',
                sectionUID: '-LWDLWSyX25Li2lMJ-Uc',
                type: 'text',
              },
              '-LWDLWTDOaZ8yYC3B0DW': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Temperature before Evaporator',
                productUID: '-LWDLWR6ujx3LWXjfsCw',
                sectionUID: '-LWDLWSyX25Li2lMJ-Uc',
                type: 'text',
              },
              '-LWDLWTFwPBUUJT21NUT': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Temperature after evaporator',
                productUID: '-LWDLWR6ujx3LWXjfsCw',
                sectionUID: '-LWDLWSyX25Li2lMJ-Uc',
                type: 'text',
              },
            },
            jobUID: '-LWDLWPtERYakuWxK-65',
            name: 'Measured data refrigeration system',
            productUID: '-LWDLWR6ujx3LWXjfsCw',
          },
          '-LWDLWTGVG07vLfDHdWF': {
            InputArray: ['-LWDLWTIiG5SiHdoYlUp'],
            Inputs: {
              '-LWDLWTIiG5SiHdoYlUp': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Test results',
                productUID: '-LWDLWR6ujx3LWXjfsCw',
                sectionUID: '-LWDLWTGVG07vLfDHdWF',
                type: 'text',
              },
            },
            jobUID: '-LWDLWPtERYakuWxK-65',
            name: 'Leak testing',
            productUID: '-LWDLWR6ujx3LWXjfsCw',
          },
          '-LWDLWTJ79Coxk36sdTy': {
            InputArray: [
              '-LWDLWTLF1LBbSvJytdM',
              '-LWDLWTOobUSOI9JTdes',
              '-LWDLWTRHDqdehTD68ai',
              '-LWDLWTUOmNmb3A2AOHe',
              '-LWDLWTWsdWvImG3elE2',
            ],
            Inputs: {
              '-LWDLWTLF1LBbSvJytdM': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Controller functions',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWR6ujx3LWXjfsCw',
                sectionUID: '-LWDLWTJ79Coxk36sdTy',
                type: 'radio',
              },
              '-LWDLWTOobUSOI9JTdes': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Set point',
                productUID: '-LWDLWR6ujx3LWXjfsCw',
                sectionUID: '-LWDLWTJ79Coxk36sdTy',
                type: 'text',
              },
              '-LWDLWTRHDqdehTD68ai': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Maintenance',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWR6ujx3LWXjfsCw',
                sectionUID: '-LWDLWTJ79Coxk36sdTy',
                type: 'radio',
              },
              '-LWDLWTUOmNmb3A2AOHe': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Water level / Water pressure',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWR6ujx3LWXjfsCw',
                sectionUID: '-LWDLWTJ79Coxk36sdTy',
                type: 'radio',
              },
              '-LWDLWTWsdWvImG3elE2': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Safety instructions',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWR6ujx3LWXjfsCw',
                sectionUID: '-LWDLWTJ79Coxk36sdTy',
                type: 'radio',
              },
            },
            jobUID: '-LWDLWPtERYakuWxK-65',
            name: 'User training',
            productUID: '-LWDLWR6ujx3LWXjfsCw',
          },
          '-LWDLWTXoQaQaeKyBlG0': {
            InputArray: ['-LWDLWT_3FYdPkhGqoQS'],
            Inputs: {
              '-LWDLWT_3FYdPkhGqoQS': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name:
                  'The customer was informed by our project manager / engineer that this rimatrix chiller has an active alarm and information system. The threshold will come in demand of the customer settings. The results of the alarms and messages should be switched to a higher-level building management systems or similar. The reaction time will be very fast in case of problems. This is a precondition for a safe operation of the unit.',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWR6ujx3LWXjfsCw',
                sectionUID: '-LWDLWTXoQaQaeKyBlG0',
                type: 'radio',
              },
            },
            jobUID: '-LWDLWPtERYakuWxK-65',
            name: 'Alarms',
            productUID: '-LWDLWR6ujx3LWXjfsCw',
          },
        },
        jobUID: '-LWDLWPtERYakuWxK-65',
        name: 'Chiller',
      },
      '-LWDLWTavWcdAfkUbH9I': {
        SectionArray: [
          '-LWDLWTavWcdAfkUbH9J',
          '-LWDLWTtR4S8s3s5l9SX',
          '-LWDLWU7U449pnMHYv3o',
          '-LWDLWUJPE72X3I1N5Ot',
          '-LWDLWUOmCXQ-uGt_mGr',
        ],
        Sections: {
          '-LWDLWTavWcdAfkUbH9J': {
            InputArray: [
              '-LWDLWTcROxoT9bKZmSA',
              '-LWDLWTfWrv5Caja9OR5',
              '-LWDLWTgiJTzDz15hjez',
              '-LWDLWTjYzgH8k3Dqx6V',
              '-LWDLWTl6DCpyVrLMn1P',
              '-LWDLWToQ4dmmVlstx0f',
              '-LWDLWTqdAtXZagqARjT',
              '-LWDLWTsSJsXyNas-Vup',
            ],
            Inputs: {
              '-LWDLWTcROxoT9bKZmSA': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Location ID',
                productUID: '-LWDLWTavWcdAfkUbH9I',
                sectionUID: '-LWDLWTavWcdAfkUbH9J',
                type: 'text',
              },
              '-LWDLWTfWrv5Caja9OR5': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Article / Model Number',
                productUID: '-LWDLWTavWcdAfkUbH9I',
                sectionUID: '-LWDLWTavWcdAfkUbH9J',
                type: 'text',
              },
              '-LWDLWTgiJTzDz15hjez': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Serial Number',
                productUID: '-LWDLWTavWcdAfkUbH9I',
                sectionUID: '-LWDLWTavWcdAfkUbH9J',
                type: 'text',
              },
              '-LWDLWTjYzgH8k3Dqx6V': {
                inputType: 'datetimePicker',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Date of manufacture',
                productUID: '-LWDLWTavWcdAfkUbH9I',
                sectionUID: '-LWDLWTavWcdAfkUbH9J',
                type: 'date',
              },
              '-LWDLWTl6DCpyVrLMn1P': {
                accept: 'image/*',
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                maxNumber: 3,
                name: 'Data plate',
                pattern: 'image-*',
                productUID: '-LWDLWTavWcdAfkUbH9I',
                sectionUID: '-LWDLWTavWcdAfkUbH9J',
                type: 'upload',
              },
              '-LWDLWToQ4dmmVlstx0f': {
                accept: 'image/*',
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                maxNumber: 5,
                name: 'General Images',
                pattern: 'image-*',
                productUID: '-LWDLWTavWcdAfkUbH9I',
                sectionUID: '-LWDLWTavWcdAfkUbH9J',
                type: 'upload',
              },
              '-LWDLWTqdAtXZagqARjT': {
                accept: '.',
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                maxNumber: 0,
                name: 'General Files',
                pattern: '.',
                productUID: '-LWDLWTavWcdAfkUbH9I',
                sectionUID: '-LWDLWTavWcdAfkUbH9J',
                type: 'upload',
              },
              '-LWDLWTsSJsXyNas-Vup': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Final Remarks',
                productUID: '-LWDLWTavWcdAfkUbH9I',
                sectionUID: '-LWDLWTavWcdAfkUbH9J',
                type: 'textarea',
              },
            },
            jobUID: '-LWDLWPtERYakuWxK-65',
            name: 'General',
            productUID: '-LWDLWTavWcdAfkUbH9I',
          },
          '-LWDLWTtR4S8s3s5l9SX': {
            InputArray: [
              '-LWDLWTvAN7WPrixBylW',
              '-LWDLWTyFnifJs_yKJjv',
              '-LWDLWU0nXHN0G0R4K8b',
              '-LWDLWU12-2rT8sQhRgX',
              '-LWDLWU5Dukl0pM1XOxt',
              '-LWDLWU7U449pnMHYv3n',
            ],
            Inputs: {
              '-LWDLWTvAN7WPrixBylW': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Check alignment to server rack(s)',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWTavWcdAfkUbH9I',
                sectionUID: '-LWDLWTtR4S8s3s5l9SX',
                type: 'radio',
              },
              '-LWDLWTyFnifJs_yKJjv': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Connection of the LCP to the water main supply',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWTavWcdAfkUbH9I',
                sectionUID: '-LWDLWTtR4S8s3s5l9SX',
                type: 'radio',
              },
              '-LWDLWU0nXHN0G0R4K8b': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name:
                  'The electrical connection must be installed. Check fans for correct function - all fans must be running',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWTavWcdAfkUbH9I',
                sectionUID: '-LWDLWTtR4S8s3s5l9SX',
                type: 'radio',
              },
              '-LWDLWU12-2rT8sQhRgX': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Set the nominal temperature to the desired value',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWTavWcdAfkUbH9I',
                sectionUID: '-LWDLWTtR4S8s3s5l9SX',
                type: 'radio',
              },
              '-LWDLWU5Dukl0pM1XOxt': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Check, if the cold air from the LCP enters the server rack.',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWTavWcdAfkUbH9I',
                sectionUID: '-LWDLWTtR4S8s3s5l9SX',
                type: 'radio',
              },
              '-LWDLWU7U449pnMHYv3n': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name:
                  'Check the entire installation for correct sealing (slots, blanking plates, etc.)',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWTavWcdAfkUbH9I',
                sectionUID: '-LWDLWTtR4S8s3s5l9SX',
                type: 'radio',
              },
            },
            jobUID: '-LWDLWPtERYakuWxK-65',
            name: 'LCP+ / LCP Inline',
            productUID: '-LWDLWTavWcdAfkUbH9I',
          },
          '-LWDLWU7U449pnMHYv3o': {
            InputArray: [
              '-LWDLWU9Nc9K1bBV51Nn',
              '-LWDLWUBdYj_RsrqbnNI',
              '-LWDLWUF1Av301FxGn-1',
              '-LWDLWUIY7J_tkyQaeBq',
            ],
            Inputs: {
              '-LWDLWU9Nc9K1bBV51Nn': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Check the flow rate',
                productUID: '-LWDLWTavWcdAfkUbH9I',
                sectionUID: '-LWDLWU7U449pnMHYv3o',
                type: 'text',
              },
              '-LWDLWUBdYj_RsrqbnNI': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Manually switch the 4 fan levels and document the change in fan speed',
                productUID: '-LWDLWTavWcdAfkUbH9I',
                sectionUID: '-LWDLWU7U449pnMHYv3o',
                type: 'text',
              },
              '-LWDLWUF1Av301FxGn-1': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'CMC Basic serial number see general setup',
                productUID: '-LWDLWTavWcdAfkUbH9I',
                sectionUID: '-LWDLWU7U449pnMHYv3o',
                type: 'text',
              },
              '-LWDLWUIY7J_tkyQaeBq': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Software version',
                productUID: '-LWDLWTavWcdAfkUbH9I',
                sectionUID: '-LWDLWU7U449pnMHYv3o',
                type: 'text',
              },
            },
            jobUID: '-LWDLWPtERYakuWxK-65',
            name: 'Connection CMC',
            productUID: '-LWDLWTavWcdAfkUbH9I',
          },
          '-LWDLWUJPE72X3I1N5Ot': {
            InputArray: ['-LWDLWULBNgZfj2AUDmH', '-LWDLWUN_jcdL_9Z52S0'],
            Inputs: {
              '-LWDLWULBNgZfj2AUDmH': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name:
                  'Supply voltage and frequency must match with the nominal values shown on the nameplate.',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWTavWcdAfkUbH9I',
                sectionUID: '-LWDLWUJPE72X3I1N5Ot',
                type: 'radio',
              },
              '-LWDLWUN_jcdL_9Z52S0': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name:
                  'For overcurrent protection, the fuse specified on the nameplate is provided.',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWTavWcdAfkUbH9I',
                sectionUID: '-LWDLWUJPE72X3I1N5Ot',
                type: 'radio',
              },
            },
            jobUID: '-LWDLWPtERYakuWxK-65',
            name: 'Electrical connection',
            productUID: '-LWDLWTavWcdAfkUbH9I',
          },
          '-LWDLWUOmCXQ-uGt_mGr': {
            InputArray: [
              '-LWDLWUQ3OkiqqFbXt9J',
              '-LWDLWUSHvGj-P4TfAtj',
              '-LWDLWUVEXuS6x5dED4g',
              '-LWDLWUX6TJsS0effxNr',
              '-LWDLWUZfJ2WTyMGMzGL',
            ],
            Inputs: {
              '-LWDLWUQ3OkiqqFbXt9J': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name:
                  'Water connection is established by means of pressure resistant pressed flexible tubes.',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWTavWcdAfkUbH9I',
                sectionUID: '-LWDLWUOmCXQ-uGt_mGr',
                type: 'radio',
              },
              '-LWDLWUSHvGj-P4TfAtj': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Filter and pressure control valve must be present',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWTavWcdAfkUbH9I',
                sectionUID: '-LWDLWUOmCXQ-uGt_mGr',
                type: 'radio',
              },
              '-LWDLWUVEXuS6x5dED4g': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'The water quality complies with the requirements (sight check).',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWTavWcdAfkUbH9I',
                sectionUID: '-LWDLWUOmCXQ-uGt_mGr',
                type: 'radio',
              },
              '-LWDLWUX6TJsS0effxNr': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Condensate connection is installed',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWTavWcdAfkUbH9I',
                sectionUID: '-LWDLWUOmCXQ-uGt_mGr',
                type: 'radio',
              },
              '-LWDLWUZfJ2WTyMGMzGL': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name:
                  'The customer was informed by our project manager / engineer that this LCP+/Inline has an active alarm and information system. The threshold will come in demand of the customer settings. The results of the alarms and messages should be switched to a higher-level building management systems or similar. The reaction time will be very fast in case of problems. This is a precondition for a safe operation of the unit.',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWTavWcdAfkUbH9I',
                sectionUID: '-LWDLWUOmCXQ-uGt_mGr',
                type: 'radio',
              },
            },
            jobUID: '-LWDLWPtERYakuWxK-65',
            name: 'Cooling water connection',
            productUID: '-LWDLWTavWcdAfkUbH9I',
          },
        },
        jobUID: '-LWDLWPtERYakuWxK-65',
        name: 'LCP-CW',
      },
      '-LWDLWU_Q8uCEkoGJLwx': {
        SectionArray: [
          '-LWDLWU_Q8uCEkoGJLwy',
          '-LWDLWUtW9blm4A3vP5i',
          '-LWDLWVHaizUqiZgXGM3',
          '-LWDLWVJ0NFQWBCSp4Co',
          '-LWDLWVTdWJSqhsaX9Ga',
          '-LWDLWVaiW2GG32F6DvJ',
          '-LWDLWVh-mnyRGWVPufd',
          '-LWDLWW3IHcGMzvh4naU',
          '-LWDLWWEGoysh5DfRiJa',
          '-LWDLWWMqSvpR9Hi0Xig',
        ],
        Sections: {
          '-LWDLWU_Q8uCEkoGJLwy': {
            InputArray: [
              '-LWDLWUbce1A_okkiDkT',
              '-LWDLWUd-OiKU3YsNtxV',
              '-LWDLWUg4FoDw7tJe1bI',
              '-LWDLWUjug7yUSjkY-iz',
              '-LWDLWUmhGKUyBTvA6P2',
              '-LWDLWUow2xbzsCetBgg',
              '-LWDLWUqEESi6I4kg9sf',
              '-LWDLWUsph-rh2s_IHbo',
            ],
            Inputs: {
              '-LWDLWUbce1A_okkiDkT': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Location ID',
                productUID: '-LWDLWU_Q8uCEkoGJLwx',
                sectionUID: '-LWDLWU_Q8uCEkoGJLwy',
                type: 'text',
              },
              '-LWDLWUd-OiKU3YsNtxV': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Article / Model Number',
                productUID: '-LWDLWU_Q8uCEkoGJLwx',
                sectionUID: '-LWDLWU_Q8uCEkoGJLwy',
                type: 'text',
              },
              '-LWDLWUg4FoDw7tJe1bI': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Serial Number',
                productUID: '-LWDLWU_Q8uCEkoGJLwx',
                sectionUID: '-LWDLWU_Q8uCEkoGJLwy',
                type: 'text',
              },
              '-LWDLWUjug7yUSjkY-iz': {
                inputType: 'datetimePicker',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Date of manufacture',
                productUID: '-LWDLWU_Q8uCEkoGJLwx',
                sectionUID: '-LWDLWU_Q8uCEkoGJLwy',
                type: 'date',
              },
              '-LWDLWUmhGKUyBTvA6P2': {
                accept: 'image/*',
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                maxNumber: 3,
                name: 'Data plate',
                pattern: 'image-*',
                productUID: '-LWDLWU_Q8uCEkoGJLwx',
                sectionUID: '-LWDLWU_Q8uCEkoGJLwy',
                type: 'upload',
              },
              '-LWDLWUow2xbzsCetBgg': {
                accept: 'image/*',
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                maxNumber: 5,
                name: 'General Images',
                pattern: 'image-*',
                productUID: '-LWDLWU_Q8uCEkoGJLwx',
                sectionUID: '-LWDLWU_Q8uCEkoGJLwy',
                type: 'upload',
              },
              '-LWDLWUqEESi6I4kg9sf': {
                accept: '.',
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                maxNumber: 0,
                name: 'General Files',
                pattern: '.',
                productUID: '-LWDLWU_Q8uCEkoGJLwx',
                sectionUID: '-LWDLWU_Q8uCEkoGJLwy',
                type: 'upload',
              },
              '-LWDLWUsph-rh2s_IHbo': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Final Remarks',
                productUID: '-LWDLWU_Q8uCEkoGJLwx',
                sectionUID: '-LWDLWU_Q8uCEkoGJLwy',
                type: 'textarea',
              },
            },
            jobUID: '-LWDLWPtERYakuWxK-65',
            name: 'General',
            productUID: '-LWDLWU_Q8uCEkoGJLwx',
          },
          '-LWDLWUtW9blm4A3vP5i': {
            InputArray: [
              '-LWDLWUwyoNnmTXzUquh',
              '-LWDLWUymOnVGzOQlOyB',
              '-LWDLWV0-teSm_DiyiFB',
              '-LWDLWV2tud_wWyjtGz8',
              '-LWDLWV5PQezk-iOe8mS',
              '-LWDLWV9NVh7P6OlqWX9',
              '-LWDLWVBfdH9ygjKE42V',
              '-LWDLWVDWDHe-3o51WU_',
              '-LWDLWVG1h16bYUry2VB',
            ],
            Inputs: {
              '-LWDLWUwyoNnmTXzUquh': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Check for damage',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWU_Q8uCEkoGJLwx',
                sectionUID: '-LWDLWUtW9blm4A3vP5i',
                type: 'radio',
              },
              '-LWDLWUymOnVGzOQlOyB': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Check the insulation',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWU_Q8uCEkoGJLwx',
                sectionUID: '-LWDLWUtW9blm4A3vP5i',
                type: 'radio',
              },
              '-LWDLWV0-teSm_DiyiFB': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Check the solder points (where visible)',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWU_Q8uCEkoGJLwx',
                sectionUID: '-LWDLWUtW9blm4A3vP5i',
                type: 'radio',
              },
              '-LWDLWV2tud_wWyjtGz8': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Check the component arrangement',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWU_Q8uCEkoGJLwx',
                sectionUID: '-LWDLWUtW9blm4A3vP5i',
                type: 'radio',
              },
              '-LWDLWV5PQezk-iOe8mS': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Check parts against parts list (see device documentation)',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWU_Q8uCEkoGJLwx',
                sectionUID: '-LWDLWUtW9blm4A3vP5i',
                type: 'radio',
              },
              '-LWDLWV9NVh7P6OlqWX9': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Check external climate control installation, piping regarding handbook',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWU_Q8uCEkoGJLwx',
                sectionUID: '-LWDLWUtW9blm4A3vP5i',
                type: 'radio',
              },
              '-LWDLWVBfdH9ygjKE42V': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Connection of the condensate discharge hose',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWU_Q8uCEkoGJLwx',
                sectionUID: '-LWDLWUtW9blm4A3vP5i',
                type: 'radio',
              },
              '-LWDLWVDWDHe-3o51WU_': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Does the discharge function correctly?',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWU_Q8uCEkoGJLwx',
                sectionUID: '-LWDLWUtW9blm4A3vP5i',
                type: 'radio',
              },
              '-LWDLWVG1h16bYUry2VB': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Construction adjusted and even?',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWU_Q8uCEkoGJLwx',
                sectionUID: '-LWDLWUtW9blm4A3vP5i',
                type: 'radio',
              },
            },
            jobUID: '-LWDLWPtERYakuWxK-65',
            name: 'Mechanical',
            productUID: '-LWDLWU_Q8uCEkoGJLwx',
          },
          '-LWDLWVHaizUqiZgXGM3': {
            InputArray: ['-LWDLWVJ0NFQWBCSp4Cn'],
            Inputs: {
              '-LWDLWVJ0NFQWBCSp4Cn': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Connection current supply referring to VDE-guide line',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWU_Q8uCEkoGJLwx',
                sectionUID: '-LWDLWVHaizUqiZgXGM3',
                type: 'radio',
              },
            },
            jobUID: '-LWDLWPtERYakuWxK-65',
            name: 'Installation',
            productUID: '-LWDLWU_Q8uCEkoGJLwx',
          },
          '-LWDLWVJ0NFQWBCSp4Co': {
            InputArray: [
              '-LWDLWVL6gMTQzoR1Kgl',
              '-LWDLWVPOmrbQfWsp5Ob',
              '-LWDLWVRUSgU4S_oWwQI',
              '-LWDLWVTdWJSqhsaX9G_',
            ],
            Inputs: {
              '-LWDLWVL6gMTQzoR1Kgl': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Distance to wall/ceiling sufficient',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWU_Q8uCEkoGJLwx',
                sectionUID: '-LWDLWVJ0NFQWBCSp4Co',
                type: 'radio',
              },
              '-LWDLWVPOmrbQfWsp5Ob': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Have all the guidelines been followed (see device documentation)',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWU_Q8uCEkoGJLwx',
                sectionUID: '-LWDLWVJ0NFQWBCSp4Co',
                type: 'radio',
              },
              '-LWDLWVRUSgU4S_oWwQI': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Construction filled with refrigerant?',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWU_Q8uCEkoGJLwx',
                sectionUID: '-LWDLWVJ0NFQWBCSp4Co',
                type: 'radio',
              },
              '-LWDLWVTdWJSqhsaX9G_': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name:
                  'Refrigerant charge according nameplate 2.8kg to 5m of pipe, from 5m 20g per meter of pipe',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWU_Q8uCEkoGJLwx',
                sectionUID: '-LWDLWVJ0NFQWBCSp4Co',
                type: 'radio',
              },
            },
            jobUID: '-LWDLWPtERYakuWxK-65',
            name: 'Installation external condenser',
            productUID: '-LWDLWU_Q8uCEkoGJLwx',
          },
          '-LWDLWVTdWJSqhsaX9Ga': {
            InputArray: ['-LWDLWVWwMwIpJ9oblgp', '-LWDLWVYlkpF4IxjjxOU', '-LWDLWV_rTM78NEm3a_M'],
            Inputs: {
              '-LWDLWVWwMwIpJ9oblgp': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Type',
                productUID: '-LWDLWU_Q8uCEkoGJLwx',
                sectionUID: '-LWDLWVTdWJSqhsaX9Ga',
                type: 'text',
              },
              '-LWDLWVYlkpF4IxjjxOU': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Capacity',
                productUID: '-LWDLWU_Q8uCEkoGJLwx',
                sectionUID: '-LWDLWVTdWJSqhsaX9Ga',
                type: 'text',
              },
              '-LWDLWV_rTM78NEm3a_M': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Leak test interval',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWU_Q8uCEkoGJLwx',
                sectionUID: '-LWDLWVTdWJSqhsaX9Ga',
                type: 'radio',
              },
            },
            jobUID: '-LWDLWPtERYakuWxK-65',
            name: 'Refrigerant',
            productUID: '-LWDLWU_Q8uCEkoGJLwx',
          },
          '-LWDLWVaiW2GG32F6DvJ': {
            InputArray: ['-LWDLWVcLH1hi_CvgY58', '-LWDLWVffddhKR8WGUlk', '-LWDLWVh-mnyRGWVPufc'],
            Inputs: {
              '-LWDLWVcLH1hi_CvgY58': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Check of the voltage - L1 V',
                productUID: '-LWDLWU_Q8uCEkoGJLwx',
                sectionUID: '-LWDLWVaiW2GG32F6DvJ',
                type: 'text',
              },
              '-LWDLWVffddhKR8WGUlk': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Check of the voltage - L2 V',
                productUID: '-LWDLWU_Q8uCEkoGJLwx',
                sectionUID: '-LWDLWVaiW2GG32F6DvJ',
                type: 'text',
              },
              '-LWDLWVh-mnyRGWVPufc': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Check of the voltage - L3 V',
                productUID: '-LWDLWU_Q8uCEkoGJLwx',
                sectionUID: '-LWDLWVaiW2GG32F6DvJ',
                type: 'text',
              },
            },
            jobUID: '-LWDLWPtERYakuWxK-65',
            name: 'Check of network supply',
            productUID: '-LWDLWU_Q8uCEkoGJLwx',
          },
          '-LWDLWVh-mnyRGWVPufd': {
            InputArray: [
              '-LWDLWVkeITd_rHbuyQd',
              '-LWDLWVmWyjL6nToDbML',
              '-LWDLWVqIdXveIyerKYp',
              '-LWDLWVsvPo-i-FR1hli',
              '-LWDLWVtSR-QvTYC81J1',
              '-LWDLWVxShb8XYPAOTxm',
              '-LWDLWVzn2n_JP3TCRel',
              '-LWDLWW1YP4svKDIrN73',
              '-LWDLWW3IHcGMzvh4naT',
            ],
            Inputs: {
              '-LWDLWVkeITd_rHbuyQd': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Power consumption - A',
                productUID: '-LWDLWU_Q8uCEkoGJLwx',
                sectionUID: '-LWDLWVh-mnyRGWVPufd',
                type: 'text',
              },
              '-LWDLWVmWyjL6nToDbML': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Rated current (per manufacturer)',
                productUID: '-LWDLWU_Q8uCEkoGJLwx',
                sectionUID: '-LWDLWVh-mnyRGWVPufd',
                type: 'text',
              },
              '-LWDLWVqIdXveIyerKYp': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Evaporation pressure',
                productUID: '-LWDLWU_Q8uCEkoGJLwx',
                sectionUID: '-LWDLWVh-mnyRGWVPufd',
                type: 'text',
              },
              '-LWDLWVsvPo-i-FR1hli': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Condenser pressure',
                productUID: '-LWDLWU_Q8uCEkoGJLwx',
                sectionUID: '-LWDLWVh-mnyRGWVPufd',
                type: 'text',
              },
              '-LWDLWVtSR-QvTYC81J1': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'suction gas temperature',
                productUID: '-LWDLWU_Q8uCEkoGJLwx',
                sectionUID: '-LWDLWVh-mnyRGWVPufd',
                type: 'text',
              },
              '-LWDLWVxShb8XYPAOTxm': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Hot gas temperature',
                productUID: '-LWDLWU_Q8uCEkoGJLwx',
                sectionUID: '-LWDLWVh-mnyRGWVPufd',
                type: 'text',
              },
              '-LWDLWVzn2n_JP3TCRel': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Low pressure',
                productUID: '-LWDLWU_Q8uCEkoGJLwx',
                sectionUID: '-LWDLWVh-mnyRGWVPufd',
                type: 'text',
              },
              '-LWDLWW1YP4svKDIrN73': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'High pressure',
                productUID: '-LWDLWU_Q8uCEkoGJLwx',
                sectionUID: '-LWDLWVh-mnyRGWVPufd',
                type: 'text',
              },
              '-LWDLWW3IHcGMzvh4naT': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Operating hours',
                productUID: '-LWDLWU_Q8uCEkoGJLwx',
                sectionUID: '-LWDLWVh-mnyRGWVPufd',
                type: 'text',
              },
            },
            jobUID: '-LWDLWPtERYakuWxK-65',
            name: 'Compressor',
            productUID: '-LWDLWU_Q8uCEkoGJLwx',
          },
          '-LWDLWW3IHcGMzvh4naU': {
            InputArray: [
              '-LWDLWW6yljBYazzW7OG',
              '-LWDLWW8olwYqn8u1Dkl',
              '-LWDLWWBXR1k1uEyN3S-',
              '-LWDLWWEGoysh5DfRiJ_',
            ],
            Inputs: {
              '-LWDLWW6yljBYazzW7OG': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Turn on LCP-DX',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWU_Q8uCEkoGJLwx',
                sectionUID: '-LWDLWW3IHcGMzvh4naU',
                type: 'radio',
              },
              '-LWDLWW8olwYqn8u1Dkl': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Check sight glass Moisture / filling quantity',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWU_Q8uCEkoGJLwx',
                sectionUID: '-LWDLWW3IHcGMzvh4naU',
                type: 'radio',
              },
              '-LWDLWWBXR1k1uEyN3S-': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'LCP-DX supervisor parameters table',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWU_Q8uCEkoGJLwx',
                sectionUID: '-LWDLWW3IHcGMzvh4naU',
                type: 'radio',
              },
              '-LWDLWWEGoysh5DfRiJ_': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Software version',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWU_Q8uCEkoGJLwx',
                sectionUID: '-LWDLWW3IHcGMzvh4naU',
                type: 'radio',
              },
            },
            jobUID: '-LWDLWPtERYakuWxK-65',
            name: 'Commissioning of construction',
            productUID: '-LWDLWU_Q8uCEkoGJLwx',
          },
          '-LWDLWWEGoysh5DfRiJa': {
            InputArray: ['-LWDLWWGqCY6yiEQdwY6', '-LWDLWWIIFd3kwx0SDbg', '-LWDLWWLfX9Mkkki9mxN'],
            Inputs: {
              '-LWDLWWGqCY6yiEQdwY6': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'SNMP card connected and operating',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWU_Q8uCEkoGJLwx',
                sectionUID: '-LWDLWWEGoysh5DfRiJa',
                type: 'radio',
              },
              '-LWDLWWIIFd3kwx0SDbg': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'SNMP card configured and tested',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWU_Q8uCEkoGJLwx',
                sectionUID: '-LWDLWWEGoysh5DfRiJa',
                type: 'radio',
              },
              '-LWDLWWLfX9Mkkki9mxN': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Fault messages configured and tested according to the alarm concept',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWU_Q8uCEkoGJLwx',
                sectionUID: '-LWDLWWEGoysh5DfRiJa',
                type: 'radio',
              },
            },
            jobUID: '-LWDLWPtERYakuWxK-65',
            name: 'SNMP Card',
            productUID: '-LWDLWU_Q8uCEkoGJLwx',
          },
          '-LWDLWWMqSvpR9Hi0Xig': {
            InputArray: [
              '-LWDLWWOG_fbGxRtEPAe',
              '-LWDLWWQYPCdxZUrf4vg',
              '-LWDLWWUj5ntS490UL7j',
              '-LWDLWWXNssuTW4c-ZDZ',
              '-LWDLWWZAV2QSSvFYdFA',
            ],
            Inputs: {
              '-LWDLWWOG_fbGxRtEPAe': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Control unit function',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWU_Q8uCEkoGJLwx',
                sectionUID: '-LWDLWWMqSvpR9Hi0Xig',
                type: 'radio',
              },
              '-LWDLWWQYPCdxZUrf4vg': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Set point',
                productUID: '-LWDLWU_Q8uCEkoGJLwx',
                sectionUID: '-LWDLWWMqSvpR9Hi0Xig',
                type: 'text',
              },
              '-LWDLWWUj5ntS490UL7j': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Control behavior',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWU_Q8uCEkoGJLwx',
                sectionUID: '-LWDLWWMqSvpR9Hi0Xig',
                type: 'radio',
              },
              '-LWDLWWXNssuTW4c-ZDZ': {
                inputType: 'datetimePicker',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Date of first maintenance',
                productUID: '-LWDLWU_Q8uCEkoGJLwx',
                sectionUID: '-LWDLWWMqSvpR9Hi0Xig',
                type: 'date',
              },
              '-LWDLWWZAV2QSSvFYdFA': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name:
                  'The customer was informed by our project manager / engineer that this LCP-DX has an active alarm and information system. The threshold will come in demand of the customer settings. The results of the alarms and messages should be switched to a higher-level building management systems or similar. The reaction time will be very fast in case of problems. This is a precondition for a safe operation of the unit.',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWU_Q8uCEkoGJLwx',
                sectionUID: '-LWDLWWMqSvpR9Hi0Xig',
                type: 'radio',
              },
            },
            jobUID: '-LWDLWPtERYakuWxK-65',
            name: 'Instruction of the user',
            productUID: '-LWDLWU_Q8uCEkoGJLwx',
          },
        },
        jobUID: '-LWDLWPtERYakuWxK-65',
        name: 'LCP-DX',
      },
      '-LWDLWWgDaLXpFuBrkCp': {
        SectionArray: ['-LWDLWWgDaLXpFuBrkCq', '-LWDLWWz7tBGJX8qSUBc'],
        Sections: {
          '-LWDLWWgDaLXpFuBrkCq': {
            InputArray: [
              '-LWDLWWjIhG3Z05VtbtA',
              '-LWDLWWlkNf-etaPF2De',
              '-LWDLWWn34tfi7cJd1-U',
              '-LWDLWWqKXxktdAUy6AY',
              '-LWDLWWson0O1DRTsz20',
              '-LWDLWWubte6cUXH1g3p',
              '-LWDLWWwFw9SpyzcW6wO',
              '-LWDLWWz7tBGJX8qSUBb',
            ],
            Inputs: {
              '-LWDLWWjIhG3Z05VtbtA': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Location ID',
                productUID: '-LWDLWWgDaLXpFuBrkCp',
                sectionUID: '-LWDLWWgDaLXpFuBrkCq',
                type: 'text',
              },
              '-LWDLWWlkNf-etaPF2De': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Article / Model Number',
                productUID: '-LWDLWWgDaLXpFuBrkCp',
                sectionUID: '-LWDLWWgDaLXpFuBrkCq',
                type: 'text',
              },
              '-LWDLWWn34tfi7cJd1-U': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Serial Number',
                productUID: '-LWDLWWgDaLXpFuBrkCp',
                sectionUID: '-LWDLWWgDaLXpFuBrkCq',
                type: 'text',
              },
              '-LWDLWWqKXxktdAUy6AY': {
                inputType: 'datetimePicker',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Date of manufacture',
                productUID: '-LWDLWWgDaLXpFuBrkCp',
                sectionUID: '-LWDLWWgDaLXpFuBrkCq',
                type: 'date',
              },
              '-LWDLWWson0O1DRTsz20': {
                accept: 'image/*',
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                maxNumber: 3,
                name: 'Data plate',
                pattern: 'image-*',
                productUID: '-LWDLWWgDaLXpFuBrkCp',
                sectionUID: '-LWDLWWgDaLXpFuBrkCq',
                type: 'upload',
              },
              '-LWDLWWubte6cUXH1g3p': {
                accept: 'image/*',
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                maxNumber: 5,
                name: 'General Images',
                pattern: 'image-*',
                productUID: '-LWDLWWgDaLXpFuBrkCp',
                sectionUID: '-LWDLWWgDaLXpFuBrkCq',
                type: 'upload',
              },
              '-LWDLWWwFw9SpyzcW6wO': {
                accept: '.',
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                maxNumber: 0,
                name: 'General Files',
                pattern: '.',
                productUID: '-LWDLWWgDaLXpFuBrkCp',
                sectionUID: '-LWDLWWgDaLXpFuBrkCq',
                type: 'upload',
              },
              '-LWDLWWz7tBGJX8qSUBb': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Final Remarks',
                productUID: '-LWDLWWgDaLXpFuBrkCp',
                sectionUID: '-LWDLWWgDaLXpFuBrkCq',
                type: 'textarea',
              },
            },
            jobUID: '-LWDLWPtERYakuWxK-65',
            name: 'General',
            productUID: '-LWDLWWgDaLXpFuBrkCp',
          },
          '-LWDLWWz7tBGJX8qSUBc': {
            InputArray: [
              '-LWDLWX1Oz9FHZaTlzMr',
              '-LWDLWX3f9JLTAtucHX6',
              '-LWDLWX5C7Q9vF9Uyb6b',
              '-LWDLWX8u7z96MhTd91B',
              '-LWDLWXA-aMQAdF4v-bV',
              '-LWDLWXDr5MnFvgraaJL',
              '-LWDLWXGmEuMuL7CL2XS',
              '-LWDLWXH0aWqk469HWiL',
              '-LWDLWXJXWjE_laCt66X',
              '-LWDLWXLn7yM2KeK6KHb',
              '-LWDLWXObPWKHbtsbcBn',
              '-LWDLWXQtOxvesufs6bG',
              '-LWDLWXTjbbTxAdqHUPZ',
              '-LWDLWXWe2ZTB0rEtACD',
            ],
            Inputs: {
              '-LWDLWX1Oz9FHZaTlzMr': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Instruction concerning the functions of the fire extinguishing system',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWWgDaLXpFuBrkCp',
                sectionUID: '-LWDLWWz7tBGJX8qSUBc',
                type: 'radio',
              },
              '-LWDLWX3f9JLTAtucHX6': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Installation in the upper section of the enclosure',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWWgDaLXpFuBrkCp',
                sectionUID: '-LWDLWWz7tBGJX8qSUBc',
                type: 'radio',
              },
              '-LWDLWX5C7Q9vF9Uyb6b': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'If needed, check the enclosure openings',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWWgDaLXpFuBrkCp',
                sectionUID: '-LWDLWWz7tBGJX8qSUBc',
                type: 'radio',
              },
              '-LWDLWX8u7z96MhTd91B': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Test door blocking',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWWgDaLXpFuBrkCp',
                sectionUID: '-LWDLWWz7tBGJX8qSUBc',
                type: 'radio',
              },
              '-LWDLWXA-aMQAdF4v-bV': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Test smoke alarm with smoke',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWWgDaLXpFuBrkCp',
                sectionUID: '-LWDLWWz7tBGJX8qSUBc',
                type: 'radio',
              },
              '-LWDLWXDr5MnFvgraaJL': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Calibrate airflow',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWWgDaLXpFuBrkCp',
                sectionUID: '-LWDLWWz7tBGJX8qSUBc',
                type: 'radio',
              },
              '-LWDLWXGmEuMuL7CL2XS': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Document how many slaves if any',
                productUID: '-LWDLWWgDaLXpFuBrkCp',
                sectionUID: '-LWDLWWz7tBGJX8qSUBc',
                type: 'text',
              },
              '-LWDLWXH0aWqk469HWiL': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Check cabinet sealed 100%',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWWgDaLXpFuBrkCp',
                sectionUID: '-LWDLWWz7tBGJX8qSUBc',
                type: 'radio',
              },
              '-LWDLWXJXWjE_laCt66X': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Instruction about the methods of message transfer',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWWgDaLXpFuBrkCp',
                sectionUID: '-LWDLWWz7tBGJX8qSUBc',
                type: 'radio',
              },
              '-LWDLWXLn7yM2KeK6KHb': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Information about the necessity of checks by the customer',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWWgDaLXpFuBrkCp',
                sectionUID: '-LWDLWWz7tBGJX8qSUBc',
                type: 'radio',
              },
              '-LWDLWXObPWKHbtsbcBn': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Information about the necessity of a maintenance contract',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWWgDaLXpFuBrkCp',
                sectionUID: '-LWDLWWz7tBGJX8qSUBc',
                type: 'radio',
              },
              '-LWDLWXQtOxvesufs6bG': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Handover/explanation of the handbook',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWWgDaLXpFuBrkCp',
                sectionUID: '-LWDLWWz7tBGJX8qSUBc',
                type: 'radio',
              },
              '-LWDLWXTjbbTxAdqHUPZ': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name: 'Activating (safety-splint/key switch)',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWWgDaLXpFuBrkCp',
                sectionUID: '-LWDLWWz7tBGJX8qSUBc',
                type: 'radio',
              },
              '-LWDLWXWe2ZTB0rEtACD': {
                inputType: 'input',
                jobUID: '-LWDLWPtERYakuWxK-65',
                name:
                  'The customer was informed by our project manager / engineer that this fire extinguishing system has an active alarm and information system. The threshold will come in demand of the customer settings. The results of the alarms and messages should be switched to a higher-level building management systems or similar. The reaction time will be very fast in case of problems. This is a precondition for a safe operation of the unit.',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWWgDaLXpFuBrkCp',
                sectionUID: '-LWDLWWz7tBGJX8qSUBc',
                type: 'radio',
              },
            },
            jobUID: '-LWDLWPtERYakuWxK-65',
            name: 'Rack',
            productUID: '-LWDLWWgDaLXpFuBrkCp',
          },
        },
        jobUID: '-LWDLWPtERYakuWxK-65',
        name: 'DET-AC',
      },
    },
    name: 'Commissioning',
  },
  '-LWDLWXWe2ZTB0rEtACE': {
    ProductTypeArray: [
      '-LWDLWXWe2ZTB0rEtACF',
      '-LWDLWYa3iGJDwSITYDH',
      '-LWDLWZd4gCIk1FyVjmF',
      '-LWDLW_b9v4_tSRiiLXU',
      '-LWDLWaVl3RbNXZWE-oc',
      '-LWDLWbLf1eJN-_omWS2',
      '-LWDLWc7JB7w3rZy1QZF',
    ],
    ProductTypes: {
      '-LWDLWXWe2ZTB0rEtACF': {
        SectionArray: ['-LWDLWXWe2ZTB0rEtACG'],
        Sections: {
          '-LWDLWXWe2ZTB0rEtACG': {
            InputArray: [
              '-LWDLWXZe0bRuZZ_7UwN',
              '-LWDLWXb1AXsYU59LHxi',
              '-LWDLWXdPtM5QLqnw6Hc',
              '-LWDLWXeuYCcAGu3kfNA',
              '-LWDLWXhPxHxjd6f4cwL',
              '-LWDLWXjF3PrxjKlm2pm',
              '-LWDLWXlXzLztfbcxhz8',
              '-LWDLWXnH7Eq1C27Vh4a',
              '-LWDLWXqwdI3ZTnPtP18',
              '-LWDLWXtAox4Co9Hl8fr',
              '-LWDLWXySrQQ_KKcqVXi',
              '-LWDLWY-dpAU5OPY3xcr',
              '-LWDLWY3PJXRJVh56STg',
              '-LWDLWY6yeyaP1TqaptJ',
              '-LWDLWY9oYn9i_qWd27O',
              '-LWDLWYA17SaASpAryfe',
              '-LWDLWYD5sQhqbZ80nVc',
              '-LWDLWYGVnqB1eO0-kqJ',
              '-LWDLWYIR8dYyLikRbb0',
              '-LWDLWYLlIeNhSUzV0iT',
              '-LWDLWYNlBu7Y8jxu3J0',
              '-LWDLWYP-3T92DJXY60k',
              '-LWDLWYSkipwBMHNcpYc',
              '-LWDLWYVPxQQ4gSbureR',
              '-LWDLWYX9nPsEmr_ONVC',
              '-LWDLWY_u4xiBrhk7_Rl',
            ],
            Inputs: {
              '-LWDLWXZe0bRuZZ_7UwN': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Location ID',
                productUID: '-LWDLWXWe2ZTB0rEtACF',
                sectionUID: '-LWDLWXWe2ZTB0rEtACG',
                type: 'text',
              },
              '-LWDLWXb1AXsYU59LHxi': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Article / Model Number',
                productUID: '-LWDLWXWe2ZTB0rEtACF',
                sectionUID: '-LWDLWXWe2ZTB0rEtACG',
                type: 'text',
              },
              '-LWDLWXdPtM5QLqnw6Hc': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Serial Number',
                productUID: '-LWDLWXWe2ZTB0rEtACF',
                sectionUID: '-LWDLWXWe2ZTB0rEtACG',
                type: 'text',
              },
              '-LWDLWXeuYCcAGu3kfNA': {
                inputType: 'datetimePicker',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Date of manufacture',
                productUID: '-LWDLWXWe2ZTB0rEtACF',
                sectionUID: '-LWDLWXWe2ZTB0rEtACG',
                type: 'date',
              },
              '-LWDLWXhPxHxjd6f4cwL': {
                accept: 'image/*',
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                maxNumber: 3,
                name: 'Data plate',
                pattern: 'image-*',
                productUID: '-LWDLWXWe2ZTB0rEtACF',
                sectionUID: '-LWDLWXWe2ZTB0rEtACG',
                type: 'upload',
              },
              '-LWDLWXjF3PrxjKlm2pm': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Air filter',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWXWe2ZTB0rEtACF',
                sectionUID: '-LWDLWXWe2ZTB0rEtACG',
                type: 'radio',
              },
              '-LWDLWXlXzLztfbcxhz8': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Air filter contamination',
                options: ['Low (clean)', 'Medium', 'Heavy'],
                productUID: '-LWDLWXWe2ZTB0rEtACF',
                sectionUID: '-LWDLWXWe2ZTB0rEtACG',
                type: 'radio',
                summary: true,
              },
              '-LWDLWXnH7Eq1C27Vh4a': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Maintenance condition',
                options: ['Good', 'Medium', 'Poor'],
                productUID: '-LWDLWXWe2ZTB0rEtACF',
                sectionUID: '-LWDLWXWe2ZTB0rEtACG',
                type: 'radio',
              },
              '-LWDLWXqwdI3ZTnPtP18': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Unit contamination',
                options: ['Low (clean)', 'Medium', 'Heavy'],
                productUID: '-LWDLWXWe2ZTB0rEtACF',
                sectionUID: '-LWDLWXWe2ZTB0rEtACG',
                type: 'radio',
              },
              '-LWDLWXtAox4Co9Hl8fr': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'SetPoint °',
                productUID: '-LWDLWXWe2ZTB0rEtACF',
                sectionUID: '-LWDLWXWe2ZTB0rEtACG',
                type: 'text',
              },
              '-LWDLWXySrQQ_KKcqVXi': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Ambient °',
                productUID: '-LWDLWXWe2ZTB0rEtACF',
                sectionUID: '-LWDLWXWe2ZTB0rEtACG',
                type: 'text',
              },
              '-LWDLWY-dpAU5OPY3xcr': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Errors',
                productUID: '-LWDLWXWe2ZTB0rEtACF',
                sectionUID: '-LWDLWXWe2ZTB0rEtACG',
                type: 'text',
              },
              '-LWDLWY3PJXRJVh56STg': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Cooling',
                options: ['Yes', 'No'],
                productUID: '-LWDLWXWe2ZTB0rEtACF',
                sectionUID: '-LWDLWXWe2ZTB0rEtACG',
                type: 'radio',
              },
              '-LWDLWY6yeyaP1TqaptJ': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Voltage - L1-L2',
                productUID: '-LWDLWXWe2ZTB0rEtACF',
                sectionUID: '-LWDLWXWe2ZTB0rEtACG',
                type: 'text',
              },
              '-LWDLWY9oYn9i_qWd27O': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Voltage - L2-L3',
                productUID: '-LWDLWXWe2ZTB0rEtACF',
                sectionUID: '-LWDLWXWe2ZTB0rEtACG',
                type: 'text',
              },
              '-LWDLWYA17SaASpAryfe': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Voltage - L1-L3',
                productUID: '-LWDLWXWe2ZTB0rEtACF',
                sectionUID: '-LWDLWXWe2ZTB0rEtACG',
                type: 'text',
              },
              '-LWDLWYD5sQhqbZ80nVc': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Condenser Delta °T',
                productUID: '-LWDLWXWe2ZTB0rEtACF',
                sectionUID: '-LWDLWXWe2ZTB0rEtACG',
                type: 'text',
              },
              '-LWDLWYGVnqB1eO0-kqJ': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Condenser Airflow OK?',
                options: ['Yes', 'No'],
                productUID: '-LWDLWXWe2ZTB0rEtACF',
                sectionUID: '-LWDLWXWe2ZTB0rEtACG',
                type: 'radio',
              },
              '-LWDLWYIR8dYyLikRbb0': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Evaporator Delta °T',
                productUID: '-LWDLWXWe2ZTB0rEtACF',
                sectionUID: '-LWDLWXWe2ZTB0rEtACG',
                type: 'text',
              },
              '-LWDLWYLlIeNhSUzV0iT': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Evaporator Airflow OK?',
                options: ['Yes', 'No'],
                productUID: '-LWDLWXWe2ZTB0rEtACF',
                sectionUID: '-LWDLWXWe2ZTB0rEtACG',
                type: 'radio',
              },
              '-LWDLWYNlBu7Y8jxu3J0': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Cabinet Type',
                productUID: '-LWDLWXWe2ZTB0rEtACF',
                sectionUID: '-LWDLWXWe2ZTB0rEtACG',
                type: 'text',
              },
              '-LWDLWYP-3T92DJXY60k': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Cabinet Size',
                productUID: '-LWDLWXWe2ZTB0rEtACF',
                sectionUID: '-LWDLWXWe2ZTB0rEtACG',
                type: 'text',
              },
              '-LWDLWYSkipwBMHNcpYc': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Visual Inspection / Functional Check',
                notes: true,
                notesRequiredValues: ['Repair Necessary'],
                options: ['Okay', 'Repair Necessary', 'Unable To Evaluate'],
                productUID: '-LWDLWXWe2ZTB0rEtACF',
                sectionUID: '-LWDLWXWe2ZTB0rEtACG',
                type: 'radio',
                summary: true,
              },
              '-LWDLWYVPxQQ4gSbureR': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Maintenance necessary?',
                options: ['Yes', 'No'],
                productUID: '-LWDLWXWe2ZTB0rEtACF',
                sectionUID: '-LWDLWXWe2ZTB0rEtACG',
                type: 'radio',
                summary: true,
              },
              '-LWDLWYX9nPsEmr_ONVC': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Action recommendation',
                productUID: '-LWDLWXWe2ZTB0rEtACF',
                sectionUID: '-LWDLWXWe2ZTB0rEtACG',
                type: 'text',
              },
              '-LWDLWY_u4xiBrhk7_Rl': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Comments',
                productUID: '-LWDLWXWe2ZTB0rEtACF',
                sectionUID: '-LWDLWXWe2ZTB0rEtACG',
                type: 'textarea',
              },
            },
            jobUID: '-LWDLWXWe2ZTB0rEtACE',
            name: 'General',
            productUID: '-LWDLWXWe2ZTB0rEtACF',
          },
        },
        jobUID: '-LWDLWXWe2ZTB0rEtACE',
        name: 'AC',
      },
      '-LWDLWYa3iGJDwSITYDH': {
        SectionArray: ['-LWDLWYa3iGJDwSITYDI'],
        Sections: {
          '-LWDLWYa3iGJDwSITYDI': {
            InputArray: [
              '-LWDLWYdjWU3eaDLtWtZ',
              '-LWDLWYgdScAlf-A8_DX',
              '-LWDLWYivyUHknX_C2JY',
              '-LWDLWYlceP1uPUs-pcN',
              '-LWDLWYn9XpiA_UhPuPI',
              '-LWDLWYqNqGPElWf2amx',
              '-LWDLWYsB-Heuefex9tM',
              '-LWDLWYuM3w5MIRnjSD3',
              '-LWDLWYxU47I6KK3GCja',
              '-LWDLWYzGVfm6oiNfddG',
              '-LWDLWZ02w6v03YnPbUK',
              '-LWDLWZ3IS9Ds0jgkMi2',
              '-LWDLWZ5Iwc55LnGjBh6',
              '-LWDLWZ8hCFNy21rP3jg',
              '-LWDLWZAbpTAacAkriUW',
              '-LWDLWZDXqkAk6Pnn9HI',
              '-LWDLWZFI-oTo_d_H63p',
              '-LWDLWZImlXEi8xQDOyM',
              '-LWDLWZLrskiH0NgRXVL',
              '-LWDLWZOb9qUyeTRid23',
              '-LWDLWZQuAUTMdOn_2t3',
              '-LWDLWZTAdX63Q7HS_Ky',
              '-LWDLWZVmlCrZQcT3sXY',
              '-LWDLWZYbeDenHjSdPij',
              '-LWDLWZ_Q2EEc6lKCzgd',
              '-LWDLWZcF_lq84cy0rHQ',
            ],
            Inputs: {
              '-LWDLWYdjWU3eaDLtWtZ': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Location ID',
                productUID: '-LWDLWYa3iGJDwSITYDH',
                sectionUID: '-LWDLWYa3iGJDwSITYDI',
                type: 'text',
              },
              '-LWDLWYgdScAlf-A8_DX': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Article / Model Number',
                productUID: '-LWDLWYa3iGJDwSITYDH',
                sectionUID: '-LWDLWYa3iGJDwSITYDI',
                type: 'text',
              },
              '-LWDLWYivyUHknX_C2JY': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Serial Number',
                productUID: '-LWDLWYa3iGJDwSITYDH',
                sectionUID: '-LWDLWYa3iGJDwSITYDI',
                type: 'text',
              },
              '-LWDLWYlceP1uPUs-pcN': {
                inputType: 'datetimePicker',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Date of manufacture',
                productUID: '-LWDLWYa3iGJDwSITYDH',
                sectionUID: '-LWDLWYa3iGJDwSITYDI',
                type: 'date',
              },
              '-LWDLWYn9XpiA_UhPuPI': {
                accept: 'image/*',
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                maxNumber: 3,
                name: 'Data plate',
                pattern: 'image-*',
                productUID: '-LWDLWYa3iGJDwSITYDH',
                sectionUID: '-LWDLWYa3iGJDwSITYDI',
                type: 'upload',
              },
              '-LWDLWYqNqGPElWf2amx': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Air filter',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWYa3iGJDwSITYDH',
                sectionUID: '-LWDLWYa3iGJDwSITYDI',
                type: 'radio',
              },
              '-LWDLWYsB-Heuefex9tM': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Air filter contamination',
                options: ['Low (clean)', 'Medium', 'Heavy'],
                productUID: '-LWDLWYa3iGJDwSITYDH',
                sectionUID: '-LWDLWYa3iGJDwSITYDI',
                type: 'radio',
                summary: true,
              },
              '-LWDLWYuM3w5MIRnjSD3': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Maintenance condition',
                options: ['Good', 'Medium', 'Poor'],
                productUID: '-LWDLWYa3iGJDwSITYDH',
                sectionUID: '-LWDLWYa3iGJDwSITYDI',
                type: 'radio',
              },
              '-LWDLWYxU47I6KK3GCja': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Unit contamination',
                options: ['Low (clean)', 'Medium', 'Heavy'],
                productUID: '-LWDLWYa3iGJDwSITYDH',
                sectionUID: '-LWDLWYa3iGJDwSITYDI',
                type: 'radio',
              },
              '-LWDLWYzGVfm6oiNfddG': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'SetPoint °',
                productUID: '-LWDLWYa3iGJDwSITYDH',
                sectionUID: '-LWDLWYa3iGJDwSITYDI',
                type: 'text',
              },
              '-LWDLWZ02w6v03YnPbUK': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Ambient °',
                productUID: '-LWDLWYa3iGJDwSITYDH',
                sectionUID: '-LWDLWYa3iGJDwSITYDI',
                type: 'text',
              },
              '-LWDLWZ3IS9Ds0jgkMi2': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Errors',
                productUID: '-LWDLWYa3iGJDwSITYDH',
                sectionUID: '-LWDLWYa3iGJDwSITYDI',
                type: 'text',
              },
              '-LWDLWZ5Iwc55LnGjBh6': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Cooling',
                options: ['Yes', 'No'],
                productUID: '-LWDLWYa3iGJDwSITYDH',
                sectionUID: '-LWDLWYa3iGJDwSITYDI',
                type: 'radio',
              },
              '-LWDLWZ8hCFNy21rP3jg': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Voltage - L1-L2',
                productUID: '-LWDLWYa3iGJDwSITYDH',
                sectionUID: '-LWDLWYa3iGJDwSITYDI',
                type: 'text',
              },
              '-LWDLWZAbpTAacAkriUW': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Voltage - L2-L3',
                productUID: '-LWDLWYa3iGJDwSITYDH',
                sectionUID: '-LWDLWYa3iGJDwSITYDI',
                type: 'text',
              },
              '-LWDLWZDXqkAk6Pnn9HI': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Voltage - L1-L3',
                productUID: '-LWDLWYa3iGJDwSITYDH',
                sectionUID: '-LWDLWYa3iGJDwSITYDI',
                type: 'text',
              },
              '-LWDLWZFI-oTo_d_H63p': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Condenser Delta °T',
                productUID: '-LWDLWYa3iGJDwSITYDH',
                sectionUID: '-LWDLWYa3iGJDwSITYDI',
                type: 'text',
              },
              '-LWDLWZImlXEi8xQDOyM': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Condenser Airflow OK?',
                options: ['Yes', 'No'],
                productUID: '-LWDLWYa3iGJDwSITYDH',
                sectionUID: '-LWDLWYa3iGJDwSITYDI',
                type: 'radio',
              },
              '-LWDLWZLrskiH0NgRXVL': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Evaporator Delta °T',
                productUID: '-LWDLWYa3iGJDwSITYDH',
                sectionUID: '-LWDLWYa3iGJDwSITYDI',
                type: 'text',
              },
              '-LWDLWZOb9qUyeTRid23': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Evaporator Airflow OK?',
                options: ['Yes', 'No'],
                productUID: '-LWDLWYa3iGJDwSITYDH',
                sectionUID: '-LWDLWYa3iGJDwSITYDI',
                type: 'radio',
              },
              '-LWDLWZQuAUTMdOn_2t3': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Cabinet Type',
                productUID: '-LWDLWYa3iGJDwSITYDH',
                sectionUID: '-LWDLWYa3iGJDwSITYDI',
                type: 'text',
              },
              '-LWDLWZTAdX63Q7HS_Ky': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Cabinet Size',
                productUID: '-LWDLWYa3iGJDwSITYDH',
                sectionUID: '-LWDLWYa3iGJDwSITYDI',
                type: 'text',
              },
              '-LWDLWZVmlCrZQcT3sXY': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Visual Inspection / Functional Check',
                notes: true,
                notesRequiredValues: ['Repair Necessary'],
                options: ['Okay', 'Repair Necessary', 'Unable To Evaluate'],
                productUID: '-LWDLWYa3iGJDwSITYDH',
                sectionUID: '-LWDLWYa3iGJDwSITYDI',
                type: 'radio',
                summary: true,
              },
              '-LWDLWZYbeDenHjSdPij': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Maintenance necessary?',
                options: ['Yes', 'No'],
                productUID: '-LWDLWYa3iGJDwSITYDH',
                sectionUID: '-LWDLWYa3iGJDwSITYDI',
                type: 'radio',
                summary: true,
              },
              '-LWDLWZ_Q2EEc6lKCzgd': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Action recommendation',
                productUID: '-LWDLWYa3iGJDwSITYDH',
                sectionUID: '-LWDLWYa3iGJDwSITYDI',
                type: 'text',
              },
              '-LWDLWZcF_lq84cy0rHQ': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Comments',
                productUID: '-LWDLWYa3iGJDwSITYDH',
                sectionUID: '-LWDLWYa3iGJDwSITYDI',
                type: 'textarea',
              },
            },
            jobUID: '-LWDLWXWe2ZTB0rEtACE',
            name: 'General',
            productUID: '-LWDLWYa3iGJDwSITYDH',
          },
        },
        jobUID: '-LWDLWXWe2ZTB0rEtACE',
        name: 'CMC',
      },
      '-LWDLWZd4gCIk1FyVjmF': {
        SectionArray: ['-LWDLWZd4gCIk1FyVjmG'],
        Sections: {
          '-LWDLWZd4gCIk1FyVjmG': {
            InputArray: [
              '-LWDLWZfR3AbDIIoNVYs',
              '-LWDLWZitWMOlMzlhlc9',
              '-LWDLWZlQFqfbyI3rJyI',
              '-LWDLWZojQz-_hhiy_zs',
              '-LWDLWZq-VdNHSoJlxy7',
              '-LWDLWZt05j5Fnu8cu90',
              '-LWDLWZvmqMsPiEO85nX',
              '-LWDLWZxIQzp0jN9Z-1X',
              '-LWDLWZzMVwaulDXSCdz',
              '-LWDLW_0ZqVU1sfDwA2O',
              '-LWDLW_32y-nqYHRTnBj',
              '-LWDLW_4eDZUR865AHAt',
              '-LWDLW_6lBkDT9hSzbMd',
              '-LWDLW_9vQ-VHN1iiLCF',
              '-LWDLW_BEYb2JAkzCtk2',
              '-LWDLW_Dqg2H9oEbZnC8',
              '-LWDLW_GFCbs1iuHYc3X',
              '-LWDLW_IFgLDY-o8oPeM',
              '-LWDLW_KnS_yR9-8R0fv',
              '-LWDLW_MNedjwsppoYHY',
              '-LWDLW_P0-Dpp1ca718g',
              '-LWDLW_Se6JJkK2PO7lR',
              '-LWDLW_TMAk9e3OB75mn',
              '-LWDLW_WyD6j8mpLfFzX',
              '-LWDLW_YpOeNryeSeSoK',
              '-LWDLW_aBLdhCPj6Q_I1',
            ],
            Inputs: {
              '-LWDLWZfR3AbDIIoNVYs': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Location ID',
                productUID: '-LWDLWZd4gCIk1FyVjmF',
                sectionUID: '-LWDLWZd4gCIk1FyVjmG',
                type: 'text',
              },
              '-LWDLWZitWMOlMzlhlc9': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Article / Model Number',
                productUID: '-LWDLWZd4gCIk1FyVjmF',
                sectionUID: '-LWDLWZd4gCIk1FyVjmG',
                type: 'text',
              },
              '-LWDLWZlQFqfbyI3rJyI': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Serial Number',
                productUID: '-LWDLWZd4gCIk1FyVjmF',
                sectionUID: '-LWDLWZd4gCIk1FyVjmG',
                type: 'text',
              },
              '-LWDLWZojQz-_hhiy_zs': {
                inputType: 'datetimePicker',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Date of manufacture',
                productUID: '-LWDLWZd4gCIk1FyVjmF',
                sectionUID: '-LWDLWZd4gCIk1FyVjmG',
                type: 'date',
              },
              '-LWDLWZq-VdNHSoJlxy7': {
                accept: 'image/*',
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                maxNumber: 3,
                name: 'Data plate',
                pattern: 'image-*',
                productUID: '-LWDLWZd4gCIk1FyVjmF',
                sectionUID: '-LWDLWZd4gCIk1FyVjmG',
                type: 'upload',
              },
              '-LWDLWZt05j5Fnu8cu90': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Air filter',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWZd4gCIk1FyVjmF',
                sectionUID: '-LWDLWZd4gCIk1FyVjmG',
                type: 'radio',
              },
              '-LWDLWZvmqMsPiEO85nX': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Air filter contamination',
                options: ['Low (clean)', 'Medium', 'Heavy'],
                productUID: '-LWDLWZd4gCIk1FyVjmF',
                sectionUID: '-LWDLWZd4gCIk1FyVjmG',
                type: 'radio',
                summary: true,
              },
              '-LWDLWZxIQzp0jN9Z-1X': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Maintenance condition',
                options: ['Good', 'Medium', 'Poor'],
                productUID: '-LWDLWZd4gCIk1FyVjmF',
                sectionUID: '-LWDLWZd4gCIk1FyVjmG',
                type: 'radio',
              },
              '-LWDLWZzMVwaulDXSCdz': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Unit contamination',
                options: ['Low (clean)', 'Medium', 'Heavy'],
                productUID: '-LWDLWZd4gCIk1FyVjmF',
                sectionUID: '-LWDLWZd4gCIk1FyVjmG',
                type: 'radio',
              },
              '-LWDLW_0ZqVU1sfDwA2O': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'SetPoint °',
                productUID: '-LWDLWZd4gCIk1FyVjmF',
                sectionUID: '-LWDLWZd4gCIk1FyVjmG',
                type: 'text',
              },
              '-LWDLW_32y-nqYHRTnBj': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Ambient °',
                productUID: '-LWDLWZd4gCIk1FyVjmF',
                sectionUID: '-LWDLWZd4gCIk1FyVjmG',
                type: 'text',
              },
              '-LWDLW_4eDZUR865AHAt': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Errors',
                productUID: '-LWDLWZd4gCIk1FyVjmF',
                sectionUID: '-LWDLWZd4gCIk1FyVjmG',
                type: 'text',
              },
              '-LWDLW_6lBkDT9hSzbMd': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Cooling',
                options: ['Yes', 'No'],
                productUID: '-LWDLWZd4gCIk1FyVjmF',
                sectionUID: '-LWDLWZd4gCIk1FyVjmG',
                type: 'radio',
              },
              '-LWDLW_9vQ-VHN1iiLCF': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Voltage - L1-L2',
                productUID: '-LWDLWZd4gCIk1FyVjmF',
                sectionUID: '-LWDLWZd4gCIk1FyVjmG',
                type: 'text',
              },
              '-LWDLW_BEYb2JAkzCtk2': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Voltage - L2-L3',
                productUID: '-LWDLWZd4gCIk1FyVjmF',
                sectionUID: '-LWDLWZd4gCIk1FyVjmG',
                type: 'text',
              },
              '-LWDLW_Dqg2H9oEbZnC8': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Voltage - L1-L3',
                productUID: '-LWDLWZd4gCIk1FyVjmF',
                sectionUID: '-LWDLWZd4gCIk1FyVjmG',
                type: 'text',
              },
              '-LWDLW_GFCbs1iuHYc3X': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Condenser Delta °T',
                productUID: '-LWDLWZd4gCIk1FyVjmF',
                sectionUID: '-LWDLWZd4gCIk1FyVjmG',
                type: 'text',
              },
              '-LWDLW_IFgLDY-o8oPeM': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Condenser Airflow OK?',
                options: ['Yes', 'No'],
                productUID: '-LWDLWZd4gCIk1FyVjmF',
                sectionUID: '-LWDLWZd4gCIk1FyVjmG',
                type: 'radio',
              },
              '-LWDLW_KnS_yR9-8R0fv': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Evaporator Delta °T',
                productUID: '-LWDLWZd4gCIk1FyVjmF',
                sectionUID: '-LWDLWZd4gCIk1FyVjmG',
                type: 'text',
              },
              '-LWDLW_MNedjwsppoYHY': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Evaporator Airflow OK?',
                options: ['Yes', 'No'],
                productUID: '-LWDLWZd4gCIk1FyVjmF',
                sectionUID: '-LWDLWZd4gCIk1FyVjmG',
                type: 'radio',
              },
              '-LWDLW_P0-Dpp1ca718g': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Cabinet Type',
                productUID: '-LWDLWZd4gCIk1FyVjmF',
                sectionUID: '-LWDLWZd4gCIk1FyVjmG',
                type: 'text',
              },
              '-LWDLW_Se6JJkK2PO7lR': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Cabinet Size',
                productUID: '-LWDLWZd4gCIk1FyVjmF',
                sectionUID: '-LWDLWZd4gCIk1FyVjmG',
                type: 'text',
              },
              '-LWDLW_TMAk9e3OB75mn': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Visual Inspection / Functional Check',
                notes: true,
                notesRequiredValues: ['Repair Necessary'],
                options: ['Okay', 'Repair Necessary', 'Unable To Evaluate'],
                productUID: '-LWDLWZd4gCIk1FyVjmF',
                sectionUID: '-LWDLWZd4gCIk1FyVjmG',
                type: 'radio',
                summary: true,
              },
              '-LWDLW_WyD6j8mpLfFzX': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Maintenance necessary?',
                options: ['Yes', 'No'],
                productUID: '-LWDLWZd4gCIk1FyVjmF',
                sectionUID: '-LWDLWZd4gCIk1FyVjmG',
                type: 'radio',
                summary: true,
              },
              '-LWDLW_YpOeNryeSeSoK': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Action recommendation',
                productUID: '-LWDLWZd4gCIk1FyVjmF',
                sectionUID: '-LWDLWZd4gCIk1FyVjmG',
                type: 'text',
              },
              '-LWDLW_aBLdhCPj6Q_I1': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Comments',
                productUID: '-LWDLWZd4gCIk1FyVjmF',
                sectionUID: '-LWDLWZd4gCIk1FyVjmG',
                type: 'textarea',
              },
            },
            jobUID: '-LWDLWXWe2ZTB0rEtACE',
            name: 'General',
            productUID: '-LWDLWZd4gCIk1FyVjmF',
          },
        },
        jobUID: '-LWDLWXWe2ZTB0rEtACE',
        name: 'Chiller',
      },
      '-LWDLW_b9v4_tSRiiLXU': {
        SectionArray: ['-LWDLW_b9v4_tSRiiLXV'],
        Sections: {
          '-LWDLW_b9v4_tSRiiLXV': {
            InputArray: [
              '-LWDLW_dJPeper-3uwpm',
              '-LWDLW_ffIyofnR-GN8z',
              '-LWDLW_hoAsGlN5GFb4F',
              '-LWDLW_jk0va8eRCB_Mq',
              '-LWDLW_l1mOn6eeajcND',
              '-LWDLW_nAfdAoBkkDeEL',
              '-LWDLW_p1KXQhEkTf0K5',
              '-LWDLW_rI7TLvm3vyJW8',
              '-LWDLW_tOcc9ru9eUZQk',
              '-LWDLW_w4ooAt4lraer0',
              '-LWDLW_yymCqG3nN1-zP',
              '-LWDLW_zT42xgVNCrDCi',
              '-LWDLWa0eXPH9WsUin3j',
              '-LWDLWa7nnv_IZaSWgsc',
              '-LWDLWa9liQKo4pukZ6h',
              '-LWDLWaAdHvXrhwcZzFl',
              '-LWDLWaCMA-7vZfNTKr6',
              '-LWDLWaD8UfVFMCAfi61',
              '-LWDLWaHkv5SUdpv66fz',
              '-LWDLWaIQtRLYEB4Abqb',
              '-LWDLWaLiVEal3knRlXx',
              '-LWDLWaNROi7zjew2wvi',
              '-LWDLWaP0i7E3lLHWdW-',
              '-LWDLWaR8Q9lPaFxsaPg',
              '-LWDLWaUeQ9drnlISVvc',
              '-LWDLWaVl3RbNXZWE-ob',
            ],
            Inputs: {
              '-LWDLW_dJPeper-3uwpm': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Location ID',
                productUID: '-LWDLW_b9v4_tSRiiLXU',
                sectionUID: '-LWDLW_b9v4_tSRiiLXV',
                type: 'text',
              },
              '-LWDLW_ffIyofnR-GN8z': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Article / Model Number',
                productUID: '-LWDLW_b9v4_tSRiiLXU',
                sectionUID: '-LWDLW_b9v4_tSRiiLXV',
                type: 'text',
              },
              '-LWDLW_hoAsGlN5GFb4F': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Serial Number',
                productUID: '-LWDLW_b9v4_tSRiiLXU',
                sectionUID: '-LWDLW_b9v4_tSRiiLXV',
                type: 'text',
              },
              '-LWDLW_jk0va8eRCB_Mq': {
                inputType: 'datetimePicker',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Date of manufacture',
                productUID: '-LWDLW_b9v4_tSRiiLXU',
                sectionUID: '-LWDLW_b9v4_tSRiiLXV',
                type: 'date',
              },
              '-LWDLW_l1mOn6eeajcND': {
                accept: 'image/*',
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                maxNumber: 3,
                name: 'Data plate',
                pattern: 'image-*',
                productUID: '-LWDLW_b9v4_tSRiiLXU',
                sectionUID: '-LWDLW_b9v4_tSRiiLXV',
                type: 'upload',
              },
              '-LWDLW_nAfdAoBkkDeEL': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Air filter',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLW_b9v4_tSRiiLXU',
                sectionUID: '-LWDLW_b9v4_tSRiiLXV',
                type: 'radio',
              },
              '-LWDLW_p1KXQhEkTf0K5': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Air filter contamination',
                options: ['Low (clean)', 'Medium', 'Heavy'],
                productUID: '-LWDLW_b9v4_tSRiiLXU',
                sectionUID: '-LWDLW_b9v4_tSRiiLXV',
                type: 'radio',
                summary: true,
              },
              '-LWDLW_rI7TLvm3vyJW8': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Maintenance condition',
                options: ['Good', 'Medium', 'Poor'],
                productUID: '-LWDLW_b9v4_tSRiiLXU',
                sectionUID: '-LWDLW_b9v4_tSRiiLXV',
                type: 'radio',
              },
              '-LWDLW_tOcc9ru9eUZQk': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Unit contamination',
                options: ['Low (clean)', 'Medium', 'Heavy'],
                productUID: '-LWDLW_b9v4_tSRiiLXU',
                sectionUID: '-LWDLW_b9v4_tSRiiLXV',
                type: 'radio',
              },
              '-LWDLW_w4ooAt4lraer0': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'SetPoint °',
                productUID: '-LWDLW_b9v4_tSRiiLXU',
                sectionUID: '-LWDLW_b9v4_tSRiiLXV',
                type: 'text',
              },
              '-LWDLW_yymCqG3nN1-zP': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Ambient °',
                productUID: '-LWDLW_b9v4_tSRiiLXU',
                sectionUID: '-LWDLW_b9v4_tSRiiLXV',
                type: 'text',
              },
              '-LWDLW_zT42xgVNCrDCi': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Errors',
                productUID: '-LWDLW_b9v4_tSRiiLXU',
                sectionUID: '-LWDLW_b9v4_tSRiiLXV',
                type: 'text',
              },
              '-LWDLWa0eXPH9WsUin3j': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Cooling',
                options: ['Yes', 'No'],
                productUID: '-LWDLW_b9v4_tSRiiLXU',
                sectionUID: '-LWDLW_b9v4_tSRiiLXV',
                type: 'radio',
              },
              '-LWDLWa7nnv_IZaSWgsc': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Voltage - L1-L2',
                productUID: '-LWDLW_b9v4_tSRiiLXU',
                sectionUID: '-LWDLW_b9v4_tSRiiLXV',
                type: 'text',
              },
              '-LWDLWa9liQKo4pukZ6h': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Voltage - L2-L3',
                productUID: '-LWDLW_b9v4_tSRiiLXU',
                sectionUID: '-LWDLW_b9v4_tSRiiLXV',
                type: 'text',
              },
              '-LWDLWaAdHvXrhwcZzFl': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Voltage - L1-L3',
                productUID: '-LWDLW_b9v4_tSRiiLXU',
                sectionUID: '-LWDLW_b9v4_tSRiiLXV',
                type: 'text',
              },
              '-LWDLWaCMA-7vZfNTKr6': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Condenser Delta °T',
                productUID: '-LWDLW_b9v4_tSRiiLXU',
                sectionUID: '-LWDLW_b9v4_tSRiiLXV',
                type: 'text',
              },
              '-LWDLWaD8UfVFMCAfi61': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Condenser Airflow OK?',
                options: ['Yes', 'No'],
                productUID: '-LWDLW_b9v4_tSRiiLXU',
                sectionUID: '-LWDLW_b9v4_tSRiiLXV',
                type: 'radio',
              },
              '-LWDLWaHkv5SUdpv66fz': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Evaporator Delta °T',
                productUID: '-LWDLW_b9v4_tSRiiLXU',
                sectionUID: '-LWDLW_b9v4_tSRiiLXV',
                type: 'text',
              },
              '-LWDLWaIQtRLYEB4Abqb': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Evaporator Airflow OK?',
                options: ['Yes', 'No'],
                productUID: '-LWDLW_b9v4_tSRiiLXU',
                sectionUID: '-LWDLW_b9v4_tSRiiLXV',
                type: 'radio',
              },
              '-LWDLWaLiVEal3knRlXx': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Cabinet Type',
                productUID: '-LWDLW_b9v4_tSRiiLXU',
                sectionUID: '-LWDLW_b9v4_tSRiiLXV',
                type: 'text',
              },
              '-LWDLWaNROi7zjew2wvi': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Cabinet Size',
                productUID: '-LWDLW_b9v4_tSRiiLXU',
                sectionUID: '-LWDLW_b9v4_tSRiiLXV',
                type: 'text',
              },
              '-LWDLWaP0i7E3lLHWdW-': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Visual Inspection / Functional Check',
                notes: true,
                notesRequiredValues: ['Repair Necessary'],
                options: ['Okay', 'Repair Necessary', 'Unable To Evaluate'],
                productUID: '-LWDLW_b9v4_tSRiiLXU',
                sectionUID: '-LWDLW_b9v4_tSRiiLXV',
                type: 'radio',
                summary: true,
              },
              '-LWDLWaR8Q9lPaFxsaPg': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Maintenance necessary?',
                options: ['Yes', 'No'],
                productUID: '-LWDLW_b9v4_tSRiiLXU',
                sectionUID: '-LWDLW_b9v4_tSRiiLXV',
                type: 'radio',
                summary: true,
              },
              '-LWDLWaUeQ9drnlISVvc': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Action recommendation',
                productUID: '-LWDLW_b9v4_tSRiiLXU',
                sectionUID: '-LWDLW_b9v4_tSRiiLXV',
                type: 'text',
              },
              '-LWDLWaVl3RbNXZWE-ob': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Comments',
                productUID: '-LWDLW_b9v4_tSRiiLXU',
                sectionUID: '-LWDLW_b9v4_tSRiiLXV',
                type: 'textarea',
              },
            },
            jobUID: '-LWDLWXWe2ZTB0rEtACE',
            name: 'General',
            productUID: '-LWDLW_b9v4_tSRiiLXU',
          },
        },
        jobUID: '-LWDLWXWe2ZTB0rEtACE',
        name: 'LCP-CW',
      },
      '-LWDLWaVl3RbNXZWE-oc': {
        SectionArray: ['-LWDLWaVl3RbNXZWE-od'],
        Sections: {
          '-LWDLWaVl3RbNXZWE-od': {
            InputArray: [
              '-LWDLWaYIh6ublg4qE3M',
              '-LWDLWaZIVr_k6KMbcCI',
              '-LWDLWabf92-VA_mBImn',
              '-LWDLWadMTDMJeN_QCsP',
              '-LWDLWagK4YTW40mDC7m',
              '-LWDLWaigl-eYto44B86',
              '-LWDLWakqmkfYmXBbiKq',
              '-LWDLWamlJ2nLVR9tqQ3',
              '-LWDLWapdlAzR97rbuIG',
              '-LWDLWaqaBJ0UuDpfl-c',
              '-LWDLWasn_9ExiCnCdUY',
              '-LWDLWauLa2pNExIWETm',
              '-LWDLWawn2YlQaUx69Hn',
              '-LWDLWaxduwJCaBzORZa',
              '-LWDLWazGmikWG_3sTWE',
              '-LWDLWb0LeHswZzSxymN',
              '-LWDLWb20JaP8RsDJArb',
              '-LWDLWb48qYPmA_2qP6S',
              '-LWDLWb6ujd5ED0A11dw',
              '-LWDLWb9AiJHVRieEMln',
              '-LWDLWbB5rRgOiru_ZDi',
              '-LWDLWbCgCEODyfFOIsl',
              '-LWDLWbFjKDYN2IZTyz3',
              '-LWDLWbHPn0n5y3wu8oc',
              '-LWDLWbJyLjIPvEzOixV',
              '-LWDLWbLf1eJN-_omWS1',
            ],
            Inputs: {
              '-LWDLWaYIh6ublg4qE3M': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Location ID',
                productUID: '-LWDLWaVl3RbNXZWE-oc',
                sectionUID: '-LWDLWaVl3RbNXZWE-od',
                type: 'text',
              },
              '-LWDLWaZIVr_k6KMbcCI': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Article / Model Number',
                productUID: '-LWDLWaVl3RbNXZWE-oc',
                sectionUID: '-LWDLWaVl3RbNXZWE-od',
                type: 'text',
              },
              '-LWDLWabf92-VA_mBImn': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Serial Number',
                productUID: '-LWDLWaVl3RbNXZWE-oc',
                sectionUID: '-LWDLWaVl3RbNXZWE-od',
                type: 'text',
              },
              '-LWDLWadMTDMJeN_QCsP': {
                inputType: 'datetimePicker',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Date of manufacture',
                productUID: '-LWDLWaVl3RbNXZWE-oc',
                sectionUID: '-LWDLWaVl3RbNXZWE-od',
                type: 'date',
              },
              '-LWDLWagK4YTW40mDC7m': {
                accept: 'image/*',
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                maxNumber: 3,
                name: 'Data plate',
                pattern: 'image-*',
                productUID: '-LWDLWaVl3RbNXZWE-oc',
                sectionUID: '-LWDLWaVl3RbNXZWE-od',
                type: 'upload',
              },
              '-LWDLWaigl-eYto44B86': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Air filter',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWaVl3RbNXZWE-oc',
                sectionUID: '-LWDLWaVl3RbNXZWE-od',
                type: 'radio',
              },
              '-LWDLWakqmkfYmXBbiKq': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Air filter contamination',
                options: ['Low (clean)', 'Medium', 'Heavy'],
                productUID: '-LWDLWaVl3RbNXZWE-oc',
                sectionUID: '-LWDLWaVl3RbNXZWE-od',
                type: 'radio',
                summary: true,
              },
              '-LWDLWamlJ2nLVR9tqQ3': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Maintenance condition',
                options: ['Good', 'Medium', 'Poor'],
                productUID: '-LWDLWaVl3RbNXZWE-oc',
                sectionUID: '-LWDLWaVl3RbNXZWE-od',
                type: 'radio',
              },
              '-LWDLWapdlAzR97rbuIG': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Unit contamination',
                options: ['Low (clean)', 'Medium', 'Heavy'],
                productUID: '-LWDLWaVl3RbNXZWE-oc',
                sectionUID: '-LWDLWaVl3RbNXZWE-od',
                type: 'radio',
              },
              '-LWDLWaqaBJ0UuDpfl-c': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'SetPoint °',
                productUID: '-LWDLWaVl3RbNXZWE-oc',
                sectionUID: '-LWDLWaVl3RbNXZWE-od',
                type: 'text',
              },
              '-LWDLWasn_9ExiCnCdUY': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Ambient °',
                productUID: '-LWDLWaVl3RbNXZWE-oc',
                sectionUID: '-LWDLWaVl3RbNXZWE-od',
                type: 'text',
              },
              '-LWDLWauLa2pNExIWETm': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Errors',
                productUID: '-LWDLWaVl3RbNXZWE-oc',
                sectionUID: '-LWDLWaVl3RbNXZWE-od',
                type: 'text',
              },
              '-LWDLWawn2YlQaUx69Hn': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Cooling',
                options: ['Yes', 'No'],
                productUID: '-LWDLWaVl3RbNXZWE-oc',
                sectionUID: '-LWDLWaVl3RbNXZWE-od',
                type: 'radio',
              },
              '-LWDLWaxduwJCaBzORZa': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Voltage - L1-L2',
                productUID: '-LWDLWaVl3RbNXZWE-oc',
                sectionUID: '-LWDLWaVl3RbNXZWE-od',
                type: 'text',
              },
              '-LWDLWazGmikWG_3sTWE': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Voltage - L2-L3',
                productUID: '-LWDLWaVl3RbNXZWE-oc',
                sectionUID: '-LWDLWaVl3RbNXZWE-od',
                type: 'text',
              },
              '-LWDLWb0LeHswZzSxymN': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Voltage - L1-L3',
                productUID: '-LWDLWaVl3RbNXZWE-oc',
                sectionUID: '-LWDLWaVl3RbNXZWE-od',
                type: 'text',
              },
              '-LWDLWb20JaP8RsDJArb': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Condenser Delta °T',
                productUID: '-LWDLWaVl3RbNXZWE-oc',
                sectionUID: '-LWDLWaVl3RbNXZWE-od',
                type: 'text',
              },
              '-LWDLWb48qYPmA_2qP6S': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Condenser Airflow OK?',
                options: ['Yes', 'No'],
                productUID: '-LWDLWaVl3RbNXZWE-oc',
                sectionUID: '-LWDLWaVl3RbNXZWE-od',
                type: 'radio',
              },
              '-LWDLWb6ujd5ED0A11dw': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Evaporator Delta °T',
                productUID: '-LWDLWaVl3RbNXZWE-oc',
                sectionUID: '-LWDLWaVl3RbNXZWE-od',
                type: 'text',
              },
              '-LWDLWb9AiJHVRieEMln': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Evaporator Airflow OK?',
                options: ['Yes', 'No'],
                productUID: '-LWDLWaVl3RbNXZWE-oc',
                sectionUID: '-LWDLWaVl3RbNXZWE-od',
                type: 'radio',
              },
              '-LWDLWbB5rRgOiru_ZDi': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Cabinet Type',
                productUID: '-LWDLWaVl3RbNXZWE-oc',
                sectionUID: '-LWDLWaVl3RbNXZWE-od',
                type: 'text',
              },
              '-LWDLWbCgCEODyfFOIsl': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Cabinet Size',
                productUID: '-LWDLWaVl3RbNXZWE-oc',
                sectionUID: '-LWDLWaVl3RbNXZWE-od',
                type: 'text',
              },
              '-LWDLWbFjKDYN2IZTyz3': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Visual Inspection / Functional Check',
                notes: true,
                notesRequiredValues: ['Repair Necessary'],
                options: ['Okay', 'Repair Necessary', 'Unable To Evaluate'],
                productUID: '-LWDLWaVl3RbNXZWE-oc',
                sectionUID: '-LWDLWaVl3RbNXZWE-od',
                type: 'radio',
                summary: true,
              },
              '-LWDLWbHPn0n5y3wu8oc': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Maintenance necessary?',
                options: ['Yes', 'No'],
                productUID: '-LWDLWaVl3RbNXZWE-oc',
                sectionUID: '-LWDLWaVl3RbNXZWE-od',
                type: 'radio',
                summary: true,
              },
              '-LWDLWbJyLjIPvEzOixV': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Action recommendation',
                productUID: '-LWDLWaVl3RbNXZWE-oc',
                sectionUID: '-LWDLWaVl3RbNXZWE-od',
                type: 'text',
              },
              '-LWDLWbLf1eJN-_omWS1': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Comments',
                productUID: '-LWDLWaVl3RbNXZWE-oc',
                sectionUID: '-LWDLWaVl3RbNXZWE-od',
                type: 'textarea',
              },
            },
            jobUID: '-LWDLWXWe2ZTB0rEtACE',
            name: 'General',
            productUID: '-LWDLWaVl3RbNXZWE-oc',
          },
        },
        jobUID: '-LWDLWXWe2ZTB0rEtACE',
        name: 'LCP-DX',
      },
      '-LWDLWbLf1eJN-_omWS2': {
        SectionArray: ['-LWDLWbLf1eJN-_omWS3'],
        Sections: {
          '-LWDLWbLf1eJN-_omWS3': {
            InputArray: [
              '-LWDLWbN4PtoW4GLQdHC',
              '-LWDLWbPDLIGSxOrhDK5',
              '-LWDLWbRP0wNKMCRHWj2',
              '-LWDLWbT6yY2LQ5-rhUs',
              '-LWDLWbVSQQXs0xtlJuj',
              '-LWDLWbXsTUePs53BdGh',
              '-LWDLWbZ-QgfDfhuJCk8',
              '-LWDLWb_I9i4-484cd4M',
              '-LWDLWbcijHgAitSQ9BI',
              '-LWDLWbeU1a5w33XcBkA',
              '-LWDLWbhWJwPYv-hXwA1',
              '-LWDLWbjGYj1ntjslnGP',
              '-LWDLWblbIcqAgjaWajL',
              '-LWDLWbn1Ceh4_I6v4VG',
              '-LWDLWboYau3613TNNJK',
              '-LWDLWbq6N8-QMCNrejo',
              '-LWDLWbs2-SmX0dhJoLO',
              '-LWDLWbuqBzZEHjF89-8',
              '-LWDLWbvfDdhm92EohMg',
              '-LWDLWbwCYLbrdTFQOHS',
              '-LWDLWbytL05PUBqtvkY',
              '-LWDLWc0xS169pywHr9K',
              '-LWDLWc2YE4IVyUv50wx',
              '-LWDLWc5T9akurzNXgIJ',
              '-LWDLWc5T9akurzNXgIK',
              '-LWDLWc7JB7w3rZy1QZE',
            ],
            Inputs: {
              '-LWDLWbN4PtoW4GLQdHC': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Location ID',
                productUID: '-LWDLWbLf1eJN-_omWS2',
                sectionUID: '-LWDLWbLf1eJN-_omWS3',
                type: 'text',
              },
              '-LWDLWbPDLIGSxOrhDK5': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Article / Model Number',
                productUID: '-LWDLWbLf1eJN-_omWS2',
                sectionUID: '-LWDLWbLf1eJN-_omWS3',
                type: 'text',
              },
              '-LWDLWbRP0wNKMCRHWj2': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Serial Number',
                productUID: '-LWDLWbLf1eJN-_omWS2',
                sectionUID: '-LWDLWbLf1eJN-_omWS3',
                type: 'text',
              },
              '-LWDLWbT6yY2LQ5-rhUs': {
                inputType: 'datetimePicker',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Date of manufacture',
                productUID: '-LWDLWbLf1eJN-_omWS2',
                sectionUID: '-LWDLWbLf1eJN-_omWS3',
                type: 'date',
              },
              '-LWDLWbVSQQXs0xtlJuj': {
                accept: 'image/*',
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                maxNumber: 3,
                name: 'Data plate',
                pattern: 'image-*',
                productUID: '-LWDLWbLf1eJN-_omWS2',
                sectionUID: '-LWDLWbLf1eJN-_omWS3',
                type: 'upload',
              },
              '-LWDLWbXsTUePs53BdGh': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Air filter',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWbLf1eJN-_omWS2',
                sectionUID: '-LWDLWbLf1eJN-_omWS3',
                type: 'radio',
              },
              '-LWDLWbZ-QgfDfhuJCk8': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Air filter contamination',
                options: ['Low (clean)', 'Medium', 'Heavy'],
                productUID: '-LWDLWbLf1eJN-_omWS2',
                sectionUID: '-LWDLWbLf1eJN-_omWS3',
                type: 'radio',
                summary: true,
              },
              '-LWDLWb_I9i4-484cd4M': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Maintenance condition',
                options: ['Good', 'Medium', 'Poor'],
                productUID: '-LWDLWbLf1eJN-_omWS2',
                sectionUID: '-LWDLWbLf1eJN-_omWS3',
                type: 'radio',
              },
              '-LWDLWbcijHgAitSQ9BI': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Unit contamination',
                options: ['Low (clean)', 'Medium', 'Heavy'],
                productUID: '-LWDLWbLf1eJN-_omWS2',
                sectionUID: '-LWDLWbLf1eJN-_omWS3',
                type: 'radio',
              },
              '-LWDLWbeU1a5w33XcBkA': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'SetPoint °',
                productUID: '-LWDLWbLf1eJN-_omWS2',
                sectionUID: '-LWDLWbLf1eJN-_omWS3',
                type: 'text',
              },
              '-LWDLWbhWJwPYv-hXwA1': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Ambient °',
                productUID: '-LWDLWbLf1eJN-_omWS2',
                sectionUID: '-LWDLWbLf1eJN-_omWS3',
                type: 'text',
              },
              '-LWDLWbjGYj1ntjslnGP': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Errors',
                productUID: '-LWDLWbLf1eJN-_omWS2',
                sectionUID: '-LWDLWbLf1eJN-_omWS3',
                type: 'text',
              },
              '-LWDLWblbIcqAgjaWajL': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Cooling',
                options: ['Yes', 'No'],
                productUID: '-LWDLWbLf1eJN-_omWS2',
                sectionUID: '-LWDLWbLf1eJN-_omWS3',
                type: 'radio',
              },
              '-LWDLWbn1Ceh4_I6v4VG': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Voltage - L1-L2',
                productUID: '-LWDLWbLf1eJN-_omWS2',
                sectionUID: '-LWDLWbLf1eJN-_omWS3',
                type: 'text',
              },
              '-LWDLWboYau3613TNNJK': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Voltage - L2-L3',
                productUID: '-LWDLWbLf1eJN-_omWS2',
                sectionUID: '-LWDLWbLf1eJN-_omWS3',
                type: 'text',
              },
              '-LWDLWbq6N8-QMCNrejo': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Voltage - L1-L3',
                productUID: '-LWDLWbLf1eJN-_omWS2',
                sectionUID: '-LWDLWbLf1eJN-_omWS3',
                type: 'text',
              },
              '-LWDLWbs2-SmX0dhJoLO': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Condenser Delta °T',
                productUID: '-LWDLWbLf1eJN-_omWS2',
                sectionUID: '-LWDLWbLf1eJN-_omWS3',
                type: 'text',
              },
              '-LWDLWbuqBzZEHjF89-8': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Condenser Airflow OK?',
                options: ['Yes', 'No'],
                productUID: '-LWDLWbLf1eJN-_omWS2',
                sectionUID: '-LWDLWbLf1eJN-_omWS3',
                type: 'radio',
              },
              '-LWDLWbvfDdhm92EohMg': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Evaporator Delta °T',
                productUID: '-LWDLWbLf1eJN-_omWS2',
                sectionUID: '-LWDLWbLf1eJN-_omWS3',
                type: 'text',
              },
              '-LWDLWbwCYLbrdTFQOHS': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Evaporator Airflow OK?',
                options: ['Yes', 'No'],
                productUID: '-LWDLWbLf1eJN-_omWS2',
                sectionUID: '-LWDLWbLf1eJN-_omWS3',
                type: 'radio',
              },
              '-LWDLWbytL05PUBqtvkY': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Cabinet Type',
                productUID: '-LWDLWbLf1eJN-_omWS2',
                sectionUID: '-LWDLWbLf1eJN-_omWS3',
                type: 'text',
              },
              '-LWDLWc0xS169pywHr9K': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Cabinet Size',
                productUID: '-LWDLWbLf1eJN-_omWS2',
                sectionUID: '-LWDLWbLf1eJN-_omWS3',
                type: 'text',
              },
              '-LWDLWc2YE4IVyUv50wx': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Visual Inspection / Functional Check',
                notes: true,
                notesRequiredValues: ['Repair Necessary'],
                options: ['Okay', 'Repair Necessary', 'Unable To Evaluate'],
                productUID: '-LWDLWbLf1eJN-_omWS2',
                sectionUID: '-LWDLWbLf1eJN-_omWS3',
                type: 'radio',
                summary: true,
              },
              '-LWDLWc5T9akurzNXgIJ': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Maintenance necessary?',
                options: ['Yes', 'No'],
                productUID: '-LWDLWbLf1eJN-_omWS2',
                sectionUID: '-LWDLWbLf1eJN-_omWS3',
                type: 'radio',
                summary: true,
              },
              '-LWDLWc5T9akurzNXgIK': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Action recommendation',
                productUID: '-LWDLWbLf1eJN-_omWS2',
                sectionUID: '-LWDLWbLf1eJN-_omWS3',
                type: 'text',
              },
              '-LWDLWc7JB7w3rZy1QZE': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Comments',
                productUID: '-LWDLWbLf1eJN-_omWS2',
                sectionUID: '-LWDLWbLf1eJN-_omWS3',
                type: 'textarea',
              },
            },
            jobUID: '-LWDLWXWe2ZTB0rEtACE',
            name: 'General',
            productUID: '-LWDLWbLf1eJN-_omWS2',
          },
        },
        jobUID: '-LWDLWXWe2ZTB0rEtACE',
        name: 'Micro-Data-Center',
      },
      '-LWDLWc7JB7w3rZy1QZF': {
        SectionArray: ['-LWDLWc8BSWAhsTObodP'],
        Sections: {
          '-LWDLWc8BSWAhsTObodP': {
            InputArray: [
              '-LWDLWcA4CnB3n42J3gS',
              '-LWDLWcCG6FvevCOH6lN',
              '-LWDLWcD9vf1Kq51qDEj',
              '-LWDLWcGPNr9xYAL1h51',
              '-LWDLWcHtYcz9hYreubK',
              '-LWDLWcJNKp3eBF19l2H',
              '-LWDLWcLIMY_PsZRsUmd',
              '-LWDLWcNCjiQa6cuLcp9',
              '-LWDLWcPo2CcYTyemqTl',
              '-LWDLWcRd43cFk0oYtwN',
              '-LWDLWcUvmr2t0oBjdor',
              '-LWDLWcW-zoYSrfTRunv',
              '-LWDLWcYX26BW69VHDac',
              '-LWDLWc_gwnj9UNjlEeL',
              '-LWDLWcapoHDVh290LJB',
              '-LWDLWccvvHsdrJS58Ll',
              '-LWDLWceNRo9zy-VuGhH',
              '-LWDLWcg04OcAw3tRva1',
              '-LWDLWcjiwa0Q-whC5k-',
              '-LWDLWclLCKLKgIya38e',
              '-LWDLWcnbR8bdwHPgKAv',
              '-LWDLWcpmRKz-aKcxdlj',
              '-LWDLWcreI_nc1IJpu7Y',
              '-LWDLWctCIZ-nPLAQV2b',
              '-LWDLWcvo4dukC-C2M34',
              '-LWDLWcx7quSSG2zYcWK',
            ],
            Inputs: {
              '-LWDLWcA4CnB3n42J3gS': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Location ID',
                productUID: '-LWDLWc7JB7w3rZy1QZF',
                sectionUID: '-LWDLWc8BSWAhsTObodP',
                type: 'text',
              },
              '-LWDLWcCG6FvevCOH6lN': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Article / Model Number',
                productUID: '-LWDLWc7JB7w3rZy1QZF',
                sectionUID: '-LWDLWc8BSWAhsTObodP',
                type: 'text',
              },
              '-LWDLWcD9vf1Kq51qDEj': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Serial Number',
                productUID: '-LWDLWc7JB7w3rZy1QZF',
                sectionUID: '-LWDLWc8BSWAhsTObodP',
                type: 'text',
              },
              '-LWDLWcGPNr9xYAL1h51': {
                inputType: 'datetimePicker',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Date of manufacture',
                productUID: '-LWDLWc7JB7w3rZy1QZF',
                sectionUID: '-LWDLWc8BSWAhsTObodP',
                type: 'date',
              },
              '-LWDLWcHtYcz9hYreubK': {
                accept: 'image/*',
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                maxNumber: 3,
                name: 'Data plate',
                pattern: 'image-*',
                productUID: '-LWDLWc7JB7w3rZy1QZF',
                sectionUID: '-LWDLWc8BSWAhsTObodP',
                type: 'upload',
              },
              '-LWDLWcJNKp3eBF19l2H': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Air filter',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWc7JB7w3rZy1QZF',
                sectionUID: '-LWDLWc8BSWAhsTObodP',
                type: 'radio',
              },
              '-LWDLWcLIMY_PsZRsUmd': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Air filter contamination',
                options: ['Low (clean)', 'Medium', 'Heavy'],
                productUID: '-LWDLWc7JB7w3rZy1QZF',
                sectionUID: '-LWDLWc8BSWAhsTObodP',
                type: 'radio',
                summary: true,
              },
              '-LWDLWcNCjiQa6cuLcp9': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Maintenance condition',
                options: ['Good', 'Medium', 'Poor'],
                productUID: '-LWDLWc7JB7w3rZy1QZF',
                sectionUID: '-LWDLWc8BSWAhsTObodP',
                type: 'radio',
              },
              '-LWDLWcPo2CcYTyemqTl': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Unit contamination',
                options: ['Low (clean)', 'Medium', 'Heavy'],
                productUID: '-LWDLWc7JB7w3rZy1QZF',
                sectionUID: '-LWDLWc8BSWAhsTObodP',
                type: 'radio',
              },
              '-LWDLWcRd43cFk0oYtwN': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'SetPoint °',
                productUID: '-LWDLWc7JB7w3rZy1QZF',
                sectionUID: '-LWDLWc8BSWAhsTObodP',
                type: 'text',
              },
              '-LWDLWcUvmr2t0oBjdor': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Ambient °',
                productUID: '-LWDLWc7JB7w3rZy1QZF',
                sectionUID: '-LWDLWc8BSWAhsTObodP',
                type: 'text',
              },
              '-LWDLWcW-zoYSrfTRunv': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Errors',
                productUID: '-LWDLWc7JB7w3rZy1QZF',
                sectionUID: '-LWDLWc8BSWAhsTObodP',
                type: 'text',
              },
              '-LWDLWcYX26BW69VHDac': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Cooling',
                options: ['Yes', 'No'],
                productUID: '-LWDLWc7JB7w3rZy1QZF',
                sectionUID: '-LWDLWc8BSWAhsTObodP',
                type: 'radio',
              },
              '-LWDLWc_gwnj9UNjlEeL': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Voltage - L1-L2',
                productUID: '-LWDLWc7JB7w3rZy1QZF',
                sectionUID: '-LWDLWc8BSWAhsTObodP',
                type: 'text',
              },
              '-LWDLWcapoHDVh290LJB': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Voltage - L2-L3',
                productUID: '-LWDLWc7JB7w3rZy1QZF',
                sectionUID: '-LWDLWc8BSWAhsTObodP',
                type: 'text',
              },
              '-LWDLWccvvHsdrJS58Ll': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Voltage - L1-L3',
                productUID: '-LWDLWc7JB7w3rZy1QZF',
                sectionUID: '-LWDLWc8BSWAhsTObodP',
                type: 'text',
              },
              '-LWDLWceNRo9zy-VuGhH': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Condenser Delta °T',
                productUID: '-LWDLWc7JB7w3rZy1QZF',
                sectionUID: '-LWDLWc8BSWAhsTObodP',
                type: 'text',
              },
              '-LWDLWcg04OcAw3tRva1': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Condenser Airflow OK?',
                options: ['Yes', 'No'],
                productUID: '-LWDLWc7JB7w3rZy1QZF',
                sectionUID: '-LWDLWc8BSWAhsTObodP',
                type: 'radio',
              },
              '-LWDLWcjiwa0Q-whC5k-': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Evaporator Delta °T',
                productUID: '-LWDLWc7JB7w3rZy1QZF',
                sectionUID: '-LWDLWc8BSWAhsTObodP',
                type: 'text',
              },
              '-LWDLWclLCKLKgIya38e': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Evaporator Airflow OK?',
                options: ['Yes', 'No'],
                productUID: '-LWDLWc7JB7w3rZy1QZF',
                sectionUID: '-LWDLWc8BSWAhsTObodP',
                type: 'radio',
              },
              '-LWDLWcnbR8bdwHPgKAv': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Cabinet Type',
                productUID: '-LWDLWc7JB7w3rZy1QZF',
                sectionUID: '-LWDLWc8BSWAhsTObodP',
                type: 'text',
              },
              '-LWDLWcpmRKz-aKcxdlj': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Cabinet Size',
                productUID: '-LWDLWc7JB7w3rZy1QZF',
                sectionUID: '-LWDLWc8BSWAhsTObodP',
                type: 'text',
              },
              '-LWDLWcreI_nc1IJpu7Y': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Visual Inspection / Functional Check',
                notes: true,
                notesRequiredValues: ['Repair Necessary'],
                options: ['Okay', 'Repair Necessary', 'Unable To Evaluate'],
                productUID: '-LWDLWc7JB7w3rZy1QZF',
                sectionUID: '-LWDLWc8BSWAhsTObodP',
                type: 'radio',
                summary: true,
              },
              '-LWDLWctCIZ-nPLAQV2b': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Maintenance necessary?',
                options: ['Yes', 'No'],
                productUID: '-LWDLWc7JB7w3rZy1QZF',
                sectionUID: '-LWDLWc8BSWAhsTObodP',
                type: 'radio',
                summary: true,
              },
              '-LWDLWcvo4dukC-C2M34': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Action recommendation',
                productUID: '-LWDLWc7JB7w3rZy1QZF',
                sectionUID: '-LWDLWc8BSWAhsTObodP',
                type: 'text',
              },
              '-LWDLWcx7quSSG2zYcWK': {
                inputType: 'input',
                jobUID: '-LWDLWXWe2ZTB0rEtACE',
                name: 'Comments',
                productUID: '-LWDLWc7JB7w3rZy1QZF',
                sectionUID: '-LWDLWc8BSWAhsTObodP',
                type: 'textarea',
              },
            },
            jobUID: '-LWDLWXWe2ZTB0rEtACE',
            name: 'General',
            productUID: '-LWDLWc7JB7w3rZy1QZF',
          },
        },
        jobUID: '-LWDLWXWe2ZTB0rEtACE',
        name: 'DET-AC',
      },
    },
    name: 'Inspection',
  },
  '-LWDLWcx7quSSG2zYcWL': {
    ProductTypeArray: [
      '-LWDLWcyfSTKf6MzNW2a',
      '-LWDLWdsL2kXbQOLkDR9',
      '-LWDLWePOBNBZV0iYLs_',
      '-LWDLWfqmLRniOY8WEDf',
      '-LWDLWgqCSqkFCkLqGSL',
      '-LWDLWieYAytLGGxcDic',
      '-LWDLWjh1C82nN8-BTNO',
    ],
    ProductTypes: {
      '-LWDLWcyfSTKf6MzNW2a': {
        SectionArray: [
          '-LWDLWcyfSTKf6MzNW2b',
          '-LWDLWdC49K30p9tF_5C',
          '-LWDLWdHQ33uybN0p8H3',
          '-LWDLWdQyyzJT9Bipw-Y',
          '-LWDLWdUXuLeEAbWwGzK',
          '-LWDLWdn8EbnRVTGfuJw',
        ],
        Sections: {
          '-LWDLWcyfSTKf6MzNW2b': {
            InputArray: [
              '-LWDLWcz5h34FCZK0P5y',
              '-LWDLWd1jRn7bywzpzzE',
              '-LWDLWd3I2lvHNv0qzLx',
              '-LWDLWd5hGYRdXasmD6U',
              '-LWDLWd6WCQ3FiX6S-02',
              '-LWDLWd9w5eGB_4y9IAC',
              '-LWDLWd9w5eGB_4y9IAD',
              '-LX-Uybw5PrPP2AyFNYy',
              '-LWDLWdBUrZuV8DwouGQ',
            ],
            Inputs: {
              '-LWDLWcz5h34FCZK0P5y': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Location ID',
                productUID: '-LWDLWcyfSTKf6MzNW2a',
                sectionUID: '-LWDLWcyfSTKf6MzNW2b',
                type: 'text',
              },
              '-LWDLWd1jRn7bywzpzzE': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Article / Model Number',
                productUID: '-LWDLWcyfSTKf6MzNW2a',
                sectionUID: '-LWDLWcyfSTKf6MzNW2b',
                type: 'text',
              },
              '-LWDLWd3I2lvHNv0qzLx': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Serial Number',
                productUID: '-LWDLWcyfSTKf6MzNW2a',
                sectionUID: '-LWDLWcyfSTKf6MzNW2b',
                type: 'text',
              },
              '-LWDLWd5hGYRdXasmD6U': {
                inputType: 'datetimePicker',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Date of manufacture',
                productUID: '-LWDLWcyfSTKf6MzNW2a',
                sectionUID: '-LWDLWcyfSTKf6MzNW2b',
                type: 'date',
              },
              '-LWDLWd6WCQ3FiX6S-02': {
                accept: 'image/*',
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                maxNumber: 3,
                name: 'Data plate',
                pattern: 'image-*',
                productUID: '-LWDLWcyfSTKf6MzNW2a',
                sectionUID: '-LWDLWcyfSTKf6MzNW2b',
                type: 'upload',
              },
              '-LWDLWd9w5eGB_4y9IAC': {
                accept: 'image/*',
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                maxNumber: 5,
                name: 'General Images',
                pattern: 'image-*',
                productUID: '-LWDLWcyfSTKf6MzNW2a',
                sectionUID: '-LWDLWcyfSTKf6MzNW2b',
                type: 'upload',
              },
              '-LWDLWd9w5eGB_4y9IAD': {
                accept: '.',
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                maxNumber: 0,
                name: 'General Files',
                pattern: '.',
                productUID: '-LWDLWcyfSTKf6MzNW2a',
                sectionUID: '-LWDLWcyfSTKf6MzNW2b',
                type: 'upload',
              },
              '-LX-Uybw5PrPP2AyFNYy': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Visual Inspection / Functional Check',
                notes: true,
                notesRequiredValues: ['Repair Necessary'],
                options: ['Okay', 'Repair Necessary', 'Unable To Evaluate'],
                productUID: '-LWDLWcyfSTKf6MzNW2a',
                sectionUID: '-LWDLWcyfSTKf6MzNW2b',
                type: 'radio',
                summary: true,
              },
              '-LWDLWdBUrZuV8DwouGQ': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Final Remarks',
                productUID: '-LWDLWcyfSTKf6MzNW2a',
                sectionUID: '-LWDLWcyfSTKf6MzNW2b',
                type: 'textarea',
              },
            },
            jobUID: '-LWDLWcx7quSSG2zYcWL',
            name: 'General',
            productUID: '-LWDLWcyfSTKf6MzNW2a',
          },
          '-LWDLWdC49K30p9tF_5C': {
            InputArray: ['-LWDLWdE2KNdKfHeFypZ', '-LWDLWdGuYFh3x7QpT-p'],
            Inputs: {
              '-LWDLWdE2KNdKfHeFypZ': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Air filter',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWcyfSTKf6MzNW2a',
                sectionUID: '-LWDLWdC49K30p9tF_5C',
                type: 'radio',
              },
              '-LWDLWdGuYFh3x7QpT-p': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Air filter contamination',
                options: ['Low (clean)', 'Medium', 'Heavy'],
                productUID: '-LWDLWcyfSTKf6MzNW2a',
                sectionUID: '-LWDLWdC49K30p9tF_5C',
                type: 'radio',
                summary: true,
              },
            },
            jobUID: '-LWDLWcx7quSSG2zYcWL',
            name: 'Air filter',
            productUID: '-LWDLWcyfSTKf6MzNW2a',
          },
          '-LWDLWdHQ33uybN0p8H3': {
            InputArray: [
              '-LWDLWdJpbNcFqVxSWGP',
              '-LWDLWdMYsUk2eKPgWwp',
              '-LWDLWdN11_e6O3BmPXD',
              '-LWDLWdQyyzJT9Bipw-X',
            ],
            Inputs: {
              '-LWDLWdJpbNcFqVxSWGP': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Air temp inlet',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWcyfSTKf6MzNW2a',
                sectionUID: '-LWDLWdHQ33uybN0p8H3',
                type: 'radio',
              },
              '-LWDLWdMYsUk2eKPgWwp': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Air temp outlet',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWcyfSTKf6MzNW2a',
                sectionUID: '-LWDLWdHQ33uybN0p8H3',
                type: 'radio',
              },
              '-LWDLWdN11_e6O3BmPXD': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Insulation',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWcyfSTKf6MzNW2a',
                sectionUID: '-LWDLWdHQ33uybN0p8H3',
                type: 'radio',
              },
              '-LWDLWdQyyzJT9Bipw-X': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Condensate drain clear of debris',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWcyfSTKf6MzNW2a',
                sectionUID: '-LWDLWdHQ33uybN0p8H3',
                type: 'radio',
              },
            },
            jobUID: '-LWDLWcx7quSSG2zYcWL',
            name: 'Cooler',
            productUID: '-LWDLWcyfSTKf6MzNW2a',
          },
          '-LWDLWdQyyzJT9Bipw-Y': {
            InputArray: ['-LWDLWdSDy8lKAxs0ap8', '-LWDLWdUXuLeEAbWwGzJ'],
            Inputs: {
              '-LWDLWdSDy8lKAxs0ap8': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Ambient temp',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWcyfSTKf6MzNW2a',
                sectionUID: '-LWDLWdQyyzJT9Bipw-Y',
                type: 'radio',
              },
              '-LWDLWdUXuLeEAbWwGzJ': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: '°T condenser',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWcyfSTKf6MzNW2a',
                sectionUID: '-LWDLWdQyyzJT9Bipw-Y',
                type: 'radio',
              },
            },
            jobUID: '-LWDLWcx7quSSG2zYcWL',
            name: 'Condenser',
            productUID: '-LWDLWcyfSTKf6MzNW2a',
          },
          '-LWDLWdUXuLeEAbWwGzK': {
            InputArray: [
              '-LWDLWdWyzlOUNg3PnNj',
              '-LWDLWdYQBXNrVmxzSbD',
              '-LWDLWdaJ6zlKolHy0TN',
              '-LWDLWdcOAUHEDTyKAHS',
              '-LWDLWdeB0ZqdhRnD28L',
              '-LWDLWdmMsR8LSQeby-1',
            ],
            Inputs: {
              '-LWDLWdWyzlOUNg3PnNj': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Set point controller',
                productUID: '-LWDLWcyfSTKf6MzNW2a',
                sectionUID: '-LWDLWdUXuLeEAbWwGzK',
                type: 'text',
              },
              '-LWDLWdYQBXNrVmxzSbD': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Control voltage (actual)',
                productUID: '-LWDLWcyfSTKf6MzNW2a',
                sectionUID: '-LWDLWdUXuLeEAbWwGzK',
                type: 'text',
              },
              '-LWDLWdaJ6zlKolHy0TN': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Door limit switch',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWcyfSTKf6MzNW2a',
                sectionUID: '-LWDLWdUXuLeEAbWwGzK',
                type: 'radio',
              },
              '-LWDLWdcOAUHEDTyKAHS': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Display function',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWcyfSTKf6MzNW2a',
                sectionUID: '-LWDLWdUXuLeEAbWwGzK',
                type: 'radio',
              },
              '-LWDLWdeB0ZqdhRnD28L': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Software version',
                productUID: '-LWDLWcyfSTKf6MzNW2a',
                sectionUID: '-LWDLWdUXuLeEAbWwGzK',
                type: 'text',
              },
              '-LWDLWdmMsR8LSQeby-1': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Master/slave function',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWcyfSTKf6MzNW2a',
                sectionUID: '-LWDLWdUXuLeEAbWwGzK',
                type: 'radio',
              },
            },
            jobUID: '-LWDLWcx7quSSG2zYcWL',
            name: 'Electrical components',
            productUID: '-LWDLWcyfSTKf6MzNW2a',
          },
          '-LWDLWdn8EbnRVTGfuJw': {
            InputArray: ['-LWDLWdo0z4EojC-MCxe', '-LWDLWdqaOxFS8-A8xp1', '-LWDLWdsL2kXbQOLkDR8'],
            Inputs: {
              '-LWDLWdo0z4EojC-MCxe': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Evaporation temperature',
                productUID: '-LWDLWcyfSTKf6MzNW2a',
                sectionUID: '-LWDLWdn8EbnRVTGfuJw',
                type: 'text',
              },
              '-LWDLWdqaOxFS8-A8xp1': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Hot gas temperature',
                productUID: '-LWDLWcyfSTKf6MzNW2a',
                sectionUID: '-LWDLWdn8EbnRVTGfuJw',
                type: 'text',
              },
              '-LWDLWdsL2kXbQOLkDR8': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Refrigerant charge',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWcyfSTKf6MzNW2a',
                sectionUID: '-LWDLWdn8EbnRVTGfuJw',
                type: 'radio',
              },
            },
            jobUID: '-LWDLWcx7quSSG2zYcWL',
            name: 'Compressor',
            productUID: '-LWDLWcyfSTKf6MzNW2a',
          },
        },
        jobUID: '-LWDLWcx7quSSG2zYcWL',
        name: 'AC',
      },
      '-LWDLWdsL2kXbQOLkDR9': {
        SectionArray: ['-LWDLWdsL2kXbQOLkDRA', '-LWDLWe8cMmiLlwvjRTx'],
        Sections: {
          '-LWDLWdsL2kXbQOLkDRA': {
            InputArray: [
              '-LWDLWdvr5BDAyvSImwj',
              '-LWDLWdx3KhASifjPnw-',
              '-LWDLWdzZu_tNPdO3NvB',
              '-LWDLWe-hDmGLNwSoOxF',
              '-LWDLWe2y1dLS-ekzcYY',
              '-LWDLWe3IYNCfi8X18zL',
              '-LWDLWe5nQip_-3HIdwc',
              '-LX-UybzDGdvpDhvFtfO',
              '-LWDLWe8cMmiLlwvjRTw',
            ],
            Inputs: {
              '-LWDLWdvr5BDAyvSImwj': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Location ID',
                productUID: '-LWDLWdsL2kXbQOLkDR9',
                sectionUID: '-LWDLWdsL2kXbQOLkDRA',
                type: 'text',
              },
              '-LWDLWdx3KhASifjPnw-': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Article / Model Number',
                productUID: '-LWDLWdsL2kXbQOLkDR9',
                sectionUID: '-LWDLWdsL2kXbQOLkDRA',
                type: 'text',
              },
              '-LWDLWdzZu_tNPdO3NvB': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Serial Number',
                productUID: '-LWDLWdsL2kXbQOLkDR9',
                sectionUID: '-LWDLWdsL2kXbQOLkDRA',
                type: 'text',
              },
              '-LWDLWe-hDmGLNwSoOxF': {
                inputType: 'datetimePicker',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Date of manufacture',
                productUID: '-LWDLWdsL2kXbQOLkDR9',
                sectionUID: '-LWDLWdsL2kXbQOLkDRA',
                type: 'date',
              },
              '-LWDLWe2y1dLS-ekzcYY': {
                accept: 'image/*',
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                maxNumber: 3,
                name: 'Data plate',
                pattern: 'image-*',
                productUID: '-LWDLWdsL2kXbQOLkDR9',
                sectionUID: '-LWDLWdsL2kXbQOLkDRA',
                type: 'upload',
              },
              '-LWDLWe3IYNCfi8X18zL': {
                accept: 'image/*',
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                maxNumber: 5,
                name: 'General Images',
                pattern: 'image-*',
                productUID: '-LWDLWdsL2kXbQOLkDR9',
                sectionUID: '-LWDLWdsL2kXbQOLkDRA',
                type: 'upload',
              },
              '-LWDLWe5nQip_-3HIdwc': {
                accept: '.',
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                maxNumber: 0,
                name: 'General Files',
                pattern: '.',
                productUID: '-LWDLWdsL2kXbQOLkDR9',
                sectionUID: '-LWDLWdsL2kXbQOLkDRA',
                type: 'upload',
              },
              '-LX-UybzDGdvpDhvFtfO': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Visual Inspection / Functional Check',
                notes: true,
                notesRequiredValues: ['Repair Necessary'],
                options: ['Okay', 'Repair Necessary', 'Unable To Evaluate'],
                productUID: '-LWDLWdsL2kXbQOLkDR9',
                sectionUID: '-LWDLWdsL2kXbQOLkDRA',
                type: 'radio',
                summary: true,
              },
              '-LWDLWe8cMmiLlwvjRTw': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Final Remarks',
                productUID: '-LWDLWdsL2kXbQOLkDR9',
                sectionUID: '-LWDLWdsL2kXbQOLkDRA',
                type: 'textarea',
              },
            },
            jobUID: '-LWDLWcx7quSSG2zYcWL',
            name: 'General',
            productUID: '-LWDLWdsL2kXbQOLkDR9',
          },
          '-LWDLWe8cMmiLlwvjRTx': {
            InputArray: [
              '-LWDLWeAfft4BRkExZ4c',
              '-LWDLWeCRkM-RkTSlcjy',
              '-LWDLWeEBoqq2LBEVpcY',
              '-LWDLWeGodeFGA76aCGa',
              '-LWDLWeJcj1BVNsYgCgO',
              '-LWDLWeLlDzy4XIMccus',
              '-LWDLWeNR5DxLVAIbG-l',
              '-LWDLWePOBNBZV0iYLsZ',
            ],
            Inputs: {
              '-LWDLWeAfft4BRkExZ4c': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Visual inspection for external mechanical damage',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWdsL2kXbQOLkDR9',
                sectionUID: '-LWDLWe8cMmiLlwvjRTx',
                type: 'radio',
              },
              '-LWDLWeCRkM-RkTSlcjy': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Check if all sensors, alarm, and data cables are properly fixed',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWdsL2kXbQOLkDR9',
                sectionUID: '-LWDLWe8cMmiLlwvjRTx',
                type: 'radio',
              },
              '-LWDLWeEBoqq2LBEVpcY': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Check the unit for tight mechanical fit',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWdsL2kXbQOLkDR9',
                sectionUID: '-LWDLWe8cMmiLlwvjRTx',
                type: 'radio',
              },
              '-LWDLWeGodeFGA76aCGa': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Do the IP addresses match the customer spec',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWdsL2kXbQOLkDR9',
                sectionUID: '-LWDLWe8cMmiLlwvjRTx',
                type: 'radio',
              },
              '-LWDLWeJcj1BVNsYgCgO': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Check the documentation for conformity with the current conditions',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWdsL2kXbQOLkDR9',
                sectionUID: '-LWDLWe8cMmiLlwvjRTx',
                type: 'radio',
              },
              '-LWDLWeLlDzy4XIMccus': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name:
                  'Check the entered message texts for reasonable compliance with the connected sensors',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWdsL2kXbQOLkDR9',
                sectionUID: '-LWDLWe8cMmiLlwvjRTx',
                type: 'radio',
              },
              '-LWDLWeNR5DxLVAIbG-l': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Individual check of each sensor for its function in the web manager',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWdsL2kXbQOLkDR9',
                sectionUID: '-LWDLWe8cMmiLlwvjRTx',
                type: 'radio',
              },
              '-LWDLWePOBNBZV0iYLsZ': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'In case of changes update the documentation',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWdsL2kXbQOLkDR9',
                sectionUID: '-LWDLWe8cMmiLlwvjRTx',
                type: 'radio',
              },
            },
            jobUID: '-LWDLWcx7quSSG2zYcWL',
            name: 'Inspection',
            productUID: '-LWDLWdsL2kXbQOLkDR9',
          },
        },
        jobUID: '-LWDLWcx7quSSG2zYcWL',
        name: 'CMC',
      },
      '-LWDLWePOBNBZV0iYLs_': {
        SectionArray: [
          '-LWDLWeQFOB7OMlBFtF0',
          '-LWDLWemRyCu1va-XKN_',
          '-LWDLWfD_a6x-ObRLyJM',
          '-LWDLWfOI94CivMO6SMQ',
          '-LWDLWfiAtpRoxJ9UXaW',
          '-LWDLWflPp1FYd5Tn9A5',
        ],
        Sections: {
          '-LWDLWeQFOB7OMlBFtF0': {
            InputArray: [
              '-LWDLWeRNZLEt7q4tesE',
              '-LWDLWeUROPzvn2_B-Si',
              '-LWDLWeXha0byTHGQTgV',
              '-LWDLWeZj2-xKC_QYlNJ',
              '-LWDLWebjQ2JZQqriuwU',
              '-LWDLWeeafgKFmjc1jDf',
              '-LWDLWegGzaG-a1msfHF',
              '-LWDLWejc4sG3je5oobf',
              '-LX-Uyc-trrB0xNnH5r6',
              '-LWDLWemRyCu1va-XKNZ',
            ],
            Inputs: {
              '-LWDLWeRNZLEt7q4tesE': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Location ID',
                productUID: '-LWDLWePOBNBZV0iYLs_',
                sectionUID: '-LWDLWeQFOB7OMlBFtF0',
                type: 'text',
              },
              '-LWDLWeUROPzvn2_B-Si': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Article / Model Number',
                productUID: '-LWDLWePOBNBZV0iYLs_',
                sectionUID: '-LWDLWeQFOB7OMlBFtF0',
                type: 'text',
              },
              '-LWDLWeXha0byTHGQTgV': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Serial Number',
                productUID: '-LWDLWePOBNBZV0iYLs_',
                sectionUID: '-LWDLWeQFOB7OMlBFtF0',
                type: 'text',
              },
              '-LWDLWeZj2-xKC_QYlNJ': {
                inputType: 'datetimePicker',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Date of manufacture',
                productUID: '-LWDLWePOBNBZV0iYLs_',
                sectionUID: '-LWDLWeQFOB7OMlBFtF0',
                type: 'date',
              },
              '-LWDLWebjQ2JZQqriuwU': {
                accept: 'image/*',
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                maxNumber: 3,
                name: 'Data plate',
                pattern: 'image-*',
                productUID: '-LWDLWePOBNBZV0iYLs_',
                sectionUID: '-LWDLWeQFOB7OMlBFtF0',
                type: 'upload',
              },
              '-LWDLWeeafgKFmjc1jDf': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Software version',
                productUID: '-LWDLWePOBNBZV0iYLs_',
                sectionUID: '-LWDLWeQFOB7OMlBFtF0',
                type: 'text',
              },
              '-LWDLWegGzaG-a1msfHF': {
                accept: 'image/*',
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                maxNumber: 5,
                name: 'General Images',
                pattern: 'image-*',
                productUID: '-LWDLWePOBNBZV0iYLs_',
                sectionUID: '-LWDLWeQFOB7OMlBFtF0',
                type: 'upload',
              },
              '-LWDLWejc4sG3je5oobf': {
                accept: '.',
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                maxNumber: 0,
                name: 'General Files',
                pattern: '.',
                productUID: '-LWDLWePOBNBZV0iYLs_',
                sectionUID: '-LWDLWeQFOB7OMlBFtF0',
                type: 'upload',
              },
              '-LX-Uyc-trrB0xNnH5r6': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Visual Inspection / Functional Check',
                notes: true,
                notesRequiredValues: ['Repair Necessary'],
                options: ['Okay', 'Repair Necessary', 'Unable To Evaluate'],
                productUID: '-LWDLWePOBNBZV0iYLs_',
                sectionUID: '-LWDLWeQFOB7OMlBFtF0',
                type: 'radio',
                summary: true,
              },
              '-LWDLWemRyCu1va-XKNZ': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Final Remarks',
                productUID: '-LWDLWePOBNBZV0iYLs_',
                sectionUID: '-LWDLWeQFOB7OMlBFtF0',
                type: 'textarea',
              },
            },
            jobUID: '-LWDLWcx7quSSG2zYcWL',
            name: 'General',
            productUID: '-LWDLWePOBNBZV0iYLs_',
          },
          '-LWDLWemRyCu1va-XKN_': {
            InputArray: [
              '-LWDLWeo-M_67mBiNLFH',
              '-LWDLWetTWxuXtZ4Hc9Y',
              '-LWDLWevgz9sNqoJPaMD',
              '-LWDLWewagLEdVZ2ZuPZ',
              '-LWDLWf-wXcHrri6qpKg',
              '-LWDLWf2dv-ncfUfmuvy',
              '-LWDLWf49_1xCaFYGXAT',
              '-LWDLWf7DzYq821vylqJ',
              '-LWDLWfAqua1Usdj5KTH',
              '-LWDLWfCfw4aMDIpwdCG',
            ],
            Inputs: {
              '-LWDLWeo-M_67mBiNLFH': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Air filter',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWePOBNBZV0iYLs_',
                sectionUID: '-LWDLWemRyCu1va-XKN_',
                type: 'radio',
              },
              '-LWDLWetTWxuXtZ4Hc9Y': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Air filter contamination',
                options: ['Low (clean)', 'Medium', 'Heavy'],
                productUID: '-LWDLWePOBNBZV0iYLs_',
                sectionUID: '-LWDLWemRyCu1va-XKN_',
                type: 'radio',
                summary: true,
              },
              '-LWDLWevgz9sNqoJPaMD': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Number/Type',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWePOBNBZV0iYLs_',
                sectionUID: '-LWDLWemRyCu1va-XKN_',
                type: 'radio',
              },
              '-LWDLWewagLEdVZ2ZuPZ': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: '°T signal',
                productUID: '-LWDLWePOBNBZV0iYLs_',
                sectionUID: '-LWDLWemRyCu1va-XKN_',
                type: 'text',
              },
              '-LWDLWf-wXcHrri6qpKg': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Water temperature inlet',
                productUID: '-LWDLWePOBNBZV0iYLs_',
                sectionUID: '-LWDLWemRyCu1va-XKN_',
                type: 'text',
              },
              '-LWDLWf2dv-ncfUfmuvy': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Water temperature outlet',
                productUID: '-LWDLWePOBNBZV0iYLs_',
                sectionUID: '-LWDLWemRyCu1va-XKN_',
                type: 'text',
              },
              '-LWDLWf49_1xCaFYGXAT': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Antifreeze',
                productUID: '-LWDLWePOBNBZV0iYLs_',
                sectionUID: '-LWDLWemRyCu1va-XKN_',
                type: 'text',
              },
              '-LWDLWf7DzYq821vylqJ': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Insulation',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWePOBNBZV0iYLs_',
                sectionUID: '-LWDLWemRyCu1va-XKN_',
                type: 'radio',
              },
              '-LWDLWfAqua1Usdj5KTH': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Cooling water controller',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWePOBNBZV0iYLs_',
                sectionUID: '-LWDLWemRyCu1va-XKN_',
                type: 'radio',
              },
              '-LWDLWfCfw4aMDIpwdCG': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Water and/or expansion tank',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWePOBNBZV0iYLs_',
                sectionUID: '-LWDLWemRyCu1va-XKN_',
                type: 'radio',
              },
            },
            jobUID: '-LWDLWcx7quSSG2zYcWL',
            name: 'Inspection',
            productUID: '-LWDLWePOBNBZV0iYLs_',
          },
          '-LWDLWfD_a6x-ObRLyJM': {
            InputArray: ['-LWDLWfG8tCHsyeMIeBH', '-LWDLWfJOZ7YqoQlXTH4', '-LWDLWfNFXci5-zOWCft'],
            Inputs: {
              '-LWDLWfG8tCHsyeMIeBH': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: '°T condenser',
                productUID: '-LWDLWePOBNBZV0iYLs_',
                sectionUID: '-LWDLWfD_a6x-ObRLyJM',
                type: 'text',
              },
              '-LWDLWfJOZ7YqoQlXTH4': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Ambient temp',
                productUID: '-LWDLWePOBNBZV0iYLs_',
                sectionUID: '-LWDLWfD_a6x-ObRLyJM',
                type: 'text',
              },
              '-LWDLWfNFXci5-zOWCft': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Condenser contamination',
                options: ['Low (clean)', 'Medium', 'Heavy'],
                productUID: '-LWDLWePOBNBZV0iYLs_',
                sectionUID: '-LWDLWfD_a6x-ObRLyJM',
                type: 'radio',
              },
            },
            jobUID: '-LWDLWcx7quSSG2zYcWL',
            name: 'Condenser',
            productUID: '-LWDLWePOBNBZV0iYLs_',
          },
          '-LWDLWfOI94CivMO6SMQ': {
            InputArray: [
              '-LWDLWfP_SQJ8CSnDu1g',
              '-LWDLWfSV2ycfELjMUTo',
              '-LWDLWfVEOon8qoaatfK',
              '-LWDLWfZEwrOThpJyUU0',
              '-LWDLWfbXMnWoMVJ_JNH',
              '-LWDLWfenuMc0119uNbg',
              '-LWDLWfgQQft4yYStmoo',
            ],
            Inputs: {
              '-LWDLWfP_SQJ8CSnDu1g': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Set point controller',
                productUID: '-LWDLWePOBNBZV0iYLs_',
                sectionUID: '-LWDLWfOI94CivMO6SMQ',
                type: 'text',
              },
              '-LWDLWfSV2ycfELjMUTo': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Control voltage',
                productUID: '-LWDLWePOBNBZV0iYLs_',
                sectionUID: '-LWDLWfOI94CivMO6SMQ',
                type: 'text',
              },
              '-LWDLWfVEOon8qoaatfK': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Master/slave function',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWePOBNBZV0iYLs_',
                sectionUID: '-LWDLWfOI94CivMO6SMQ',
                type: 'radio',
              },
              '-LWDLWfZEwrOThpJyUU0': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Door limit switch',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWePOBNBZV0iYLs_',
                sectionUID: '-LWDLWfOI94CivMO6SMQ',
                type: 'radio',
              },
              '-LWDLWfbXMnWoMVJ_JNH': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Display function',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWePOBNBZV0iYLs_',
                sectionUID: '-LWDLWfOI94CivMO6SMQ',
                type: 'radio',
              },
              '-LWDLWfenuMc0119uNbg': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Software version',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWePOBNBZV0iYLs_',
                sectionUID: '-LWDLWfOI94CivMO6SMQ',
                type: 'radio',
              },
              '-LWDLWfgQQft4yYStmoo': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Diagnostics RiDiag',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWePOBNBZV0iYLs_',
                sectionUID: '-LWDLWfOI94CivMO6SMQ',
                type: 'radio',
              },
            },
            jobUID: '-LWDLWcx7quSSG2zYcWL',
            name: 'Electrical components',
            productUID: '-LWDLWePOBNBZV0iYLs_',
          },
          '-LWDLWfiAtpRoxJ9UXaW': {
            InputArray: ['-LWDLWfjdBC56-zgy2Ue', '-LWDLWflPp1FYd5Tn9A4'],
            Inputs: {
              '-LWDLWfjdBC56-zgy2Ue': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Refrigerant type',
                productUID: '-LWDLWePOBNBZV0iYLs_',
                sectionUID: '-LWDLWfiAtpRoxJ9UXaW',
                type: 'text',
              },
              '-LWDLWflPp1FYd5Tn9A4': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Refrigerant capacity',
                productUID: '-LWDLWePOBNBZV0iYLs_',
                sectionUID: '-LWDLWfiAtpRoxJ9UXaW',
                type: 'text',
              },
            },
            jobUID: '-LWDLWcx7quSSG2zYcWL',
            name: 'Refrigerant',
            productUID: '-LWDLWePOBNBZV0iYLs_',
          },
          '-LWDLWflPp1FYd5Tn9A5': {
            InputArray: ['-LWDLWfoM0ToLTgxLIAw', '-LWDLWfpUaz3g8rJ4RkN'],
            Inputs: {
              '-LWDLWfoM0ToLTgxLIAw': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Evaporation temperature',
                productUID: '-LWDLWePOBNBZV0iYLs_',
                sectionUID: '-LWDLWflPp1FYd5Tn9A5',
                type: 'text',
              },
              '-LWDLWfpUaz3g8rJ4RkN': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Hot gas temperature',
                productUID: '-LWDLWePOBNBZV0iYLs_',
                sectionUID: '-LWDLWflPp1FYd5Tn9A5',
                type: 'text',
              },
            },
            jobUID: '-LWDLWcx7quSSG2zYcWL',
            name: 'Refrigeration System',
            productUID: '-LWDLWePOBNBZV0iYLs_',
          },
        },
        jobUID: '-LWDLWcx7quSSG2zYcWL',
        name: 'Chiller',
      },
      '-LWDLWfqmLRniOY8WEDf': {
        SectionArray: ['-LWDLWfqmLRniOY8WEDg', '-LWDLWg8J-nq_pExiqt7'],
        Sections: {
          '-LWDLWfqmLRniOY8WEDg': {
            InputArray: [
              '-LWDLWfsv0_7Ck6yFAOc',
              '-LWDLWfv0zY7JCfobXE6',
              '-LWDLWfxvT5rhL_eSBWE',
              '-LWDLWfyu0oYpeKJNVY0',
              '-LWDLWg-ST38qek-2PDv',
              '-LWDLWg1eVIoKk2M_2vQ',
              '-LWDLWg3D5mh_coddqo1',
              '-LWDLWg5H-Oh4lmEJCBz',
              '-LX-Uyc0dpaNdmaFJMM2',
              '-LWDLWg77xWVJXoJ9fTc',
            ],
            Inputs: {
              '-LWDLWfsv0_7Ck6yFAOc': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Location ID',
                productUID: '-LWDLWfqmLRniOY8WEDf',
                sectionUID: '-LWDLWfqmLRniOY8WEDg',
                type: 'text',
              },
              '-LWDLWfv0zY7JCfobXE6': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Article / Model Number',
                productUID: '-LWDLWfqmLRniOY8WEDf',
                sectionUID: '-LWDLWfqmLRniOY8WEDg',
                type: 'text',
              },
              '-LWDLWfxvT5rhL_eSBWE': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Serial Number',
                productUID: '-LWDLWfqmLRniOY8WEDf',
                sectionUID: '-LWDLWfqmLRniOY8WEDg',
                type: 'text',
              },
              '-LWDLWfyu0oYpeKJNVY0': {
                inputType: 'datetimePicker',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Date of manufacture',
                productUID: '-LWDLWfqmLRniOY8WEDf',
                sectionUID: '-LWDLWfqmLRniOY8WEDg',
                type: 'date',
              },
              '-LWDLWg-ST38qek-2PDv': {
                accept: 'image/*',
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                maxNumber: 3,
                name: 'Data plate',
                pattern: 'image-*',
                productUID: '-LWDLWfqmLRniOY8WEDf',
                sectionUID: '-LWDLWfqmLRniOY8WEDg',
                type: 'upload',
              },
              '-LWDLWg1eVIoKk2M_2vQ': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Software version',
                productUID: '-LWDLWfqmLRniOY8WEDf',
                sectionUID: '-LWDLWfqmLRniOY8WEDg',
                type: 'text',
              },
              '-LWDLWg3D5mh_coddqo1': {
                accept: 'image/*',
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                maxNumber: 5,
                name: 'General Images',
                pattern: 'image-*',
                productUID: '-LWDLWfqmLRniOY8WEDf',
                sectionUID: '-LWDLWfqmLRniOY8WEDg',
                type: 'upload',
              },
              '-LWDLWg5H-Oh4lmEJCBz': {
                accept: '.',
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                maxNumber: 0,
                name: 'General Files',
                pattern: '.',
                productUID: '-LWDLWfqmLRniOY8WEDf',
                sectionUID: '-LWDLWfqmLRniOY8WEDg',
                type: 'upload',
              },
              '-LX-Uyc0dpaNdmaFJMM2': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Visual Inspection / Functional Check',
                notes: true,
                notesRequiredValues: ['Repair Necessary'],
                options: ['Okay', 'Repair Necessary', 'Unable To Evaluate'],
                productUID: '-LWDLWfqmLRniOY8WEDf',
                sectionUID: '-LWDLWfqmLRniOY8WEDg',
                type: 'radio',
                summary: true,
              },
              '-LWDLWg77xWVJXoJ9fTc': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Final Remarks',
                productUID: '-LWDLWfqmLRniOY8WEDf',
                sectionUID: '-LWDLWfqmLRniOY8WEDg',
                type: 'textarea',
              },
            },
            jobUID: '-LWDLWcx7quSSG2zYcWL',
            name: 'General',
            productUID: '-LWDLWfqmLRniOY8WEDf',
          },
          '-LWDLWg8J-nq_pExiqt7': {
            InputArray: [
              '-LWDLWgA5UYCBQ9Rx6u0',
              '-LWDLWgCI0qiGTEBjvQw',
              '-LWDLWgEQ76LQLwTf5cz',
              '-LWDLWgG5Ud2fXw9ys4D',
              '-LWDLWgIG7fU5Lm2wxHI',
              '-LWDLWgKbqVWpH_TTsvl',
              '-LWDLWgM0Onkfd2ogBJy',
              '-LWDLWgP0qzmguXgXxwl',
              '-LWDLWgRjXJWa64HeFRa',
              '-LWDLWgUchZaTYAmxrov',
              '-LWDLWgWN3I79-XzP0E9',
              '-LWDLWgZrzXp-Sutqx-L',
              '-LWDLWgb3h7K9By61lDZ',
              '-LWDLWgeBDnwJNyPyWHY',
              '-LWDLWghEOpBt9RpXLEs',
              '-LWDLWgjwMdlZ-ZFAyCm',
              '-LWDLWgnZ12_4_HKu0_Q',
              '-LWDLWgpXib7OBmbHRbd',
            ],
            Inputs: {
              '-LWDLWgA5UYCBQ9Rx6u0': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Visual inspection for external mechanical damage',
                productUID: '-LWDLWfqmLRniOY8WEDf',
                sectionUID: '-LWDLWg8J-nq_pExiqt7',
                type: 'text',
              },
              '-LWDLWgCI0qiGTEBjvQw': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Visual inspection for dirt',
                productUID: '-LWDLWfqmLRniOY8WEDf',
                sectionUID: '-LWDLWg8J-nq_pExiqt7',
                type: 'text',
              },
              '-LWDLWgEQ76LQLwTf5cz': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Verify modules are correctly connected',
                productUID: '-LWDLWfqmLRniOY8WEDf',
                sectionUID: '-LWDLWg8J-nq_pExiqt7',
                type: 'text',
              },
              '-LWDLWgG5Ud2fXw9ys4D': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Inspect layout of condensation drain',
                productUID: '-LWDLWfqmLRniOY8WEDf',
                sectionUID: '-LWDLWg8J-nq_pExiqt7',
                type: 'text',
              },
              '-LWDLWgIG7fU5Lm2wxHI': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Check functionality of the condensate pump',
                productUID: '-LWDLWfqmLRniOY8WEDf',
                sectionUID: '-LWDLWg8J-nq_pExiqt7',
                type: 'text',
              },
              '-LWDLWgKbqVWpH_TTsvl': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Check complete system for leaks',
                productUID: '-LWDLWfqmLRniOY8WEDf',
                sectionUID: '-LWDLWg8J-nq_pExiqt7',
                type: 'text',
              },
              '-LWDLWgM0Onkfd2ogBJy': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Check each fan for dirt and noise',
                productUID: '-LWDLWfqmLRniOY8WEDf',
                sectionUID: '-LWDLWg8J-nq_pExiqt7',
                type: 'text',
              },
              '-LWDLWgP0qzmguXgXxwl': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Document fan level and fan speed as found',
                productUID: '-LWDLWfqmLRniOY8WEDf',
                sectionUID: '-LWDLWg8J-nq_pExiqt7',
                type: 'text',
              },
              '-LWDLWgRjXJWa64HeFRa': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Adjust the fan level and document level/speed',
                productUID: '-LWDLWfqmLRniOY8WEDf',
                sectionUID: '-LWDLWg8J-nq_pExiqt7',
                type: 'text',
              },
              '-LWDLWgUchZaTYAmxrov': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Verify set point',
                productUID: '-LWDLWfqmLRniOY8WEDf',
                sectionUID: '-LWDLWg8J-nq_pExiqt7',
                type: 'text',
              },
              '-LWDLWgWN3I79-XzP0E9': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Check the cooling function',
                productUID: '-LWDLWfqmLRniOY8WEDf',
                sectionUID: '-LWDLWg8J-nq_pExiqt7',
                type: 'text',
              },
              '-LWDLWgZrzXp-Sutqx-L': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name:
                  'Check the entire installation for correct sealing (slots, blanking plates, etc.)',
                productUID: '-LWDLWfqmLRniOY8WEDf',
                sectionUID: '-LWDLWg8J-nq_pExiqt7',
                type: 'text',
              },
              '-LWDLWgb3h7K9By61lDZ': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Check the connection to the CMC',
                productUID: '-LWDLWfqmLRniOY8WEDf',
                sectionUID: '-LWDLWg8J-nq_pExiqt7',
                type: 'text',
              },
              '-LWDLWgeBDnwJNyPyWHY': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Document valve % and flowrate as found before testing',
                productUID: '-LWDLWfqmLRniOY8WEDf',
                sectionUID: '-LWDLWg8J-nq_pExiqt7',
                type: 'text',
              },
              '-LWDLWghEOpBt9RpXLEs': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Adjust the magnetic valve to 100% and document flowrate',
                productUID: '-LWDLWfqmLRniOY8WEDf',
                sectionUID: '-LWDLWg8J-nq_pExiqt7',
                type: 'text',
              },
              '-LWDLWgjwMdlZ-ZFAyCm': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Test ADO function including pistons if available',
                productUID: '-LWDLWfqmLRniOY8WEDf',
                sectionUID: '-LWDLWg8J-nq_pExiqt7',
                type: 'text',
              },
              '-LWDLWgnZ12_4_HKu0_Q': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Check alarm log and document any alarms since last visit then erase',
                productUID: '-LWDLWfqmLRniOY8WEDf',
                sectionUID: '-LWDLWg8J-nq_pExiqt7',
                type: 'text',
              },
              '-LWDLWgpXib7OBmbHRbd': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Write date and initials on the label and stick to unit',
                productUID: '-LWDLWfqmLRniOY8WEDf',
                sectionUID: '-LWDLWg8J-nq_pExiqt7',
                type: 'text',
              },
            },
            jobUID: '-LWDLWcx7quSSG2zYcWL',
            name: 'LCP-CW PM',
            productUID: '-LWDLWfqmLRniOY8WEDf',
          },
        },
        jobUID: '-LWDLWcx7quSSG2zYcWL',
        name: 'LCP-CW',
      },
      '-LWDLWgqCSqkFCkLqGSL': {
        SectionArray: [
          '-LWDLWgqCSqkFCkLqGSM',
          '-LWDLWh9Zw62YKa-wuQh',
          '-LWDLWhQEAO08ZPhzhQA',
          '-LWDLWhfCDek4_QlqOjR',
          '-LWDLWi-SILCoZ-SfnEe',
          '-LWDLWiTVWbi-BkY2gcF',
          '-LWDLWiVeBjISYCYSS5a',
        ],
        Sections: {
          '-LWDLWgqCSqkFCkLqGSM': {
            InputArray: [
              '-LWDLWgst-8H22a0C_4p',
              '-LWDLWgvAKj3lF35sgku',
              '-LWDLWgyqQTZrEajwaju',
              '-LWDLWh-RjPqpLIz7cZv',
              '-LWDLWh1xrdzZbuqxBAG',
              '-LWDLWh4epsbMdfSUbtP',
              '-LWDLWh64q3sN3U38JDt',
              '-LX-Uyc3JfZcWOD4QfN6',
              '-LWDLWh9Zw62YKa-wuQg',
            ],
            Inputs: {
              '-LWDLWgst-8H22a0C_4p': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Location ID',
                productUID: '-LWDLWgqCSqkFCkLqGSL',
                sectionUID: '-LWDLWgqCSqkFCkLqGSM',
                type: 'text',
              },
              '-LWDLWgvAKj3lF35sgku': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Article / Model Number',
                productUID: '-LWDLWgqCSqkFCkLqGSL',
                sectionUID: '-LWDLWgqCSqkFCkLqGSM',
                type: 'text',
              },
              '-LWDLWgyqQTZrEajwaju': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Serial Number',
                productUID: '-LWDLWgqCSqkFCkLqGSL',
                sectionUID: '-LWDLWgqCSqkFCkLqGSM',
                type: 'text',
              },
              '-LWDLWh-RjPqpLIz7cZv': {
                inputType: 'datetimePicker',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Date of manufacture',
                productUID: '-LWDLWgqCSqkFCkLqGSL',
                sectionUID: '-LWDLWgqCSqkFCkLqGSM',
                type: 'date',
              },
              '-LWDLWh1xrdzZbuqxBAG': {
                accept: 'image/*',
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                maxNumber: 3,
                name: 'Data plate',
                pattern: 'image-*',
                productUID: '-LWDLWgqCSqkFCkLqGSL',
                sectionUID: '-LWDLWgqCSqkFCkLqGSM',
                type: 'upload',
              },
              '-LWDLWh4epsbMdfSUbtP': {
                accept: 'image/*',
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                maxNumber: 5,
                name: 'General Images',
                pattern: 'image-*',
                productUID: '-LWDLWgqCSqkFCkLqGSL',
                sectionUID: '-LWDLWgqCSqkFCkLqGSM',
                type: 'upload',
              },
              '-LWDLWh64q3sN3U38JDt': {
                accept: '.',
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                maxNumber: 0,
                name: 'General Files',
                pattern: '.',
                productUID: '-LWDLWgqCSqkFCkLqGSL',
                sectionUID: '-LWDLWgqCSqkFCkLqGSM',
                type: 'upload',
              },
              '-LX-Uyc3JfZcWOD4QfN6': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Visual Inspection / Functional Check',
                notes: true,
                notesRequiredValues: ['Repair Necessary'],
                options: ['Okay', 'Repair Necessary', 'Unable To Evaluate'],
                productUID: '-LWDLWgqCSqkFCkLqGSL',
                sectionUID: '-LWDLWgqCSqkFCkLqGSM',
                type: 'radio',
                summary: true,
              },
              '-LWDLWh9Zw62YKa-wuQg': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Final Remarks',
                productUID: '-LWDLWgqCSqkFCkLqGSL',
                sectionUID: '-LWDLWgqCSqkFCkLqGSM',
                type: 'textarea',
              },
            },
            jobUID: '-LWDLWcx7quSSG2zYcWL',
            name: 'General',
            productUID: '-LWDLWgqCSqkFCkLqGSL',
          },
          '-LWDLWh9Zw62YKa-wuQh': {
            InputArray: [
              '-LWDLWhBHq4QVqO-p2UI',
              '-LWDLWhEcdAQiZPcNEQU',
              '-LWDLWhGJ1N1FkPTzo1z',
              '-LWDLWhJ-8zcUAfqWs55',
              '-LWDLWhLr-KMYaQMDafI',
              '-LWDLWhNm3npIeI9uCBK',
              '-LWDLWhPj5FTB0GA8G5-',
            ],
            Inputs: {
              '-LWDLWhBHq4QVqO-p2UI': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Check for damage',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWgqCSqkFCkLqGSL',
                sectionUID: '-LWDLWh9Zw62YKa-wuQh',
                type: 'radio',
              },
              '-LWDLWhEcdAQiZPcNEQU': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Check the insulation',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWgqCSqkFCkLqGSL',
                sectionUID: '-LWDLWh9Zw62YKa-wuQh',
                type: 'radio',
              },
              '-LWDLWhGJ1N1FkPTzo1z': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Check the solder points (where visible)',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWgqCSqkFCkLqGSL',
                sectionUID: '-LWDLWh9Zw62YKa-wuQh',
                type: 'radio',
              },
              '-LWDLWhJ-8zcUAfqWs55': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Check the installation and piping for damage',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWgqCSqkFCkLqGSL',
                sectionUID: '-LWDLWh9Zw62YKa-wuQh',
                type: 'radio',
              },
              '-LWDLWhLr-KMYaQMDafI': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Connection of the condensate discharge hose',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWgqCSqkFCkLqGSL',
                sectionUID: '-LWDLWh9Zw62YKa-wuQh',
                type: 'radio',
              },
              '-LWDLWhNm3npIeI9uCBK': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Visual inspection of the condensate tray',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWgqCSqkFCkLqGSL',
                sectionUID: '-LWDLWh9Zw62YKa-wuQh',
                type: 'radio',
              },
              '-LWDLWhPj5FTB0GA8G5-': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Does the discharge function correctly?',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWgqCSqkFCkLqGSL',
                sectionUID: '-LWDLWh9Zw62YKa-wuQh',
                type: 'radio',
              },
            },
            jobUID: '-LWDLWcx7quSSG2zYcWL',
            name: 'Mechanical',
            productUID: '-LWDLWgqCSqkFCkLqGSL',
          },
          '-LWDLWhQEAO08ZPhzhQA': {
            InputArray: [
              '-LWDLWhTi0J7T9Qfm_40',
              '-LWDLWhViulpR-ErfRhc',
              '-LWDLWhYTPI8NJico_pT',
              '-LWDLWh_sCjlp-g-rBpc',
              '-LWDLWhbtckTh-P5aMfF',
              '-LWDLWhdDNyhOAqbRCNk',
              '-LWDLWhfCDek4_QlqOjQ',
            ],
            Inputs: {
              '-LWDLWhTi0J7T9Qfm_40': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Check for damage',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWgqCSqkFCkLqGSL',
                sectionUID: '-LWDLWhQEAO08ZPhzhQA',
                type: 'radio',
              },
              '-LWDLWhViulpR-ErfRhc': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Ambient temperature',
                productUID: '-LWDLWgqCSqkFCkLqGSL',
                sectionUID: '-LWDLWhQEAO08ZPhzhQA',
                type: 'text',
              },
              '-LWDLWhYTPI8NJico_pT': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Condenser outlet temperature',
                productUID: '-LWDLWgqCSqkFCkLqGSL',
                sectionUID: '-LWDLWhQEAO08ZPhzhQA',
                type: 'text',
              },
              '-LWDLWh_sCjlp-g-rBpc': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Condenser',
                productUID: '-LWDLWgqCSqkFCkLqGSL',
                sectionUID: '-LWDLWhQEAO08ZPhzhQA',
                type: 'text',
              },
              '-LWDLWhbtckTh-P5aMfF': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Control',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWgqCSqkFCkLqGSL',
                sectionUID: '-LWDLWhQEAO08ZPhzhQA',
                type: 'radio',
              },
              '-LWDLWhdDNyhOAqbRCNk': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Is the condenser clean?',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWgqCSqkFCkLqGSL',
                sectionUID: '-LWDLWhQEAO08ZPhzhQA',
                type: 'radio',
              },
              '-LWDLWhfCDek4_QlqOjQ': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Check grounding (visual)',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWgqCSqkFCkLqGSL',
                sectionUID: '-LWDLWhQEAO08ZPhzhQA',
                type: 'radio',
              },
            },
            jobUID: '-LWDLWcx7quSSG2zYcWL',
            name: 'Condenser',
            productUID: '-LWDLWgqCSqkFCkLqGSL',
          },
          '-LWDLWhfCDek4_QlqOjR': {
            InputArray: [
              '-LWDLWhiRm_WE6DQBHSG',
              '-LWDLWhk6Rz6-U5vYgRc',
              '-LWDLWhmh9Iqx9GfqKt3',
              '-LWDLWho59hVffkZI53m',
              '-LWDLWhq4CnGy7Z8meE3',
              '-LWDLWhslHzWsR6rcx7Q',
            ],
            Inputs: {
              '-LWDLWhiRm_WE6DQBHSG': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Controller set point',
                productUID: '-LWDLWgqCSqkFCkLqGSL',
                sectionUID: '-LWDLWhfCDek4_QlqOjR',
                type: 'text',
              },
              '-LWDLWhk6Rz6-U5vYgRc': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Controller voltage',
                productUID: '-LWDLWgqCSqkFCkLqGSL',
                sectionUID: '-LWDLWhfCDek4_QlqOjR',
                type: 'text',
              },
              '-LWDLWhmh9Iqx9GfqKt3': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Display function',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWgqCSqkFCkLqGSL',
                sectionUID: '-LWDLWhfCDek4_QlqOjR',
                type: 'radio',
              },
              '-LWDLWho59hVffkZI53m': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'LCP-DX supervisor parameters table',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWgqCSqkFCkLqGSL',
                sectionUID: '-LWDLWhfCDek4_QlqOjR',
                type: 'radio',
              },
              '-LWDLWhq4CnGy7Z8meE3': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Software version',
                productUID: '-LWDLWgqCSqkFCkLqGSL',
                sectionUID: '-LWDLWhfCDek4_QlqOjR',
                type: 'text',
              },
              '-LWDLWhslHzWsR6rcx7Q': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Control of the plug-type contacts and screw connections, e.g. sensors',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWgqCSqkFCkLqGSL',
                sectionUID: '-LWDLWhfCDek4_QlqOjR',
                type: 'radio',
              },
            },
            jobUID: '-LWDLWcx7quSSG2zYcWL',
            name: 'Electrical components',
            productUID: '-LWDLWgqCSqkFCkLqGSL',
          },
          '-LWDLWi-SILCoZ-SfnEe': {
            InputArray: [
              '-LWDLWi2xzVg72kjfSdZ',
              '-LWDLWi3tgj-VzK6pR4P',
              '-LWDLWi5_F8fHBZTfdu6',
              '-LWDLWi7W1K5AXrZtmWl',
              '-LWDLWiAkFA0mOn59H9f',
              '-LWDLWiC6uS3LCL8gPnq',
              '-LWDLWiDZLT5GYnLL9me',
              '-LWDLWiG-ac8fp26wI2R',
              '-LWDLWiI1wvg9A-5C9yO',
              '-LWDLWiKtJ5V8oj9Q2pg',
              '-LWDLWiMaHmCy6-ncr6J',
              '-LWDLWiObyefOa00SlVU',
              '-LWDLWiQPSoVGdNTTcWI',
              '-LWDLWiSzHTl6wtmr6Eo',
            ],
            Inputs: {
              '-LWDLWi2xzVg72kjfSdZ': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Power consumption - L1',
                productUID: '-LWDLWgqCSqkFCkLqGSL',
                sectionUID: '-LWDLWi-SILCoZ-SfnEe',
                type: 'text',
              },
              '-LWDLWi3tgj-VzK6pR4P': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Power consumption - L2',
                productUID: '-LWDLWgqCSqkFCkLqGSL',
                sectionUID: '-LWDLWi-SILCoZ-SfnEe',
                type: 'text',
              },
              '-LWDLWi5_F8fHBZTfdu6': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Power consumption - L3',
                productUID: '-LWDLWgqCSqkFCkLqGSL',
                sectionUID: '-LWDLWi-SILCoZ-SfnEe',
                type: 'text',
              },
              '-LWDLWi7W1K5AXrZtmWl': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Power consumption - A',
                productUID: '-LWDLWgqCSqkFCkLqGSL',
                sectionUID: '-LWDLWi-SILCoZ-SfnEe',
                type: 'text',
              },
              '-LWDLWiAkFA0mOn59H9f': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Rated current (per manufacturer)',
                productUID: '-LWDLWgqCSqkFCkLqGSL',
                sectionUID: '-LWDLWi-SILCoZ-SfnEe',
                type: 'text',
              },
              '-LWDLWiC6uS3LCL8gPnq': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Back pressure',
                productUID: '-LWDLWgqCSqkFCkLqGSL',
                sectionUID: '-LWDLWi-SILCoZ-SfnEe',
                type: 'text',
              },
              '-LWDLWiDZLT5GYnLL9me': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Condenser pressure',
                productUID: '-LWDLWgqCSqkFCkLqGSL',
                sectionUID: '-LWDLWi-SILCoZ-SfnEe',
                type: 'text',
              },
              '-LWDLWiG-ac8fp26wI2R': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Evaporation temperature',
                productUID: '-LWDLWgqCSqkFCkLqGSL',
                sectionUID: '-LWDLWi-SILCoZ-SfnEe',
                type: 'text',
              },
              '-LWDLWiI1wvg9A-5C9yO': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Hot gas temperature',
                productUID: '-LWDLWgqCSqkFCkLqGSL',
                sectionUID: '-LWDLWi-SILCoZ-SfnEe',
                type: 'text',
              },
              '-LWDLWiKtJ5V8oj9Q2pg': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Low pressure shutdown',
                productUID: '-LWDLWgqCSqkFCkLqGSL',
                sectionUID: '-LWDLWi-SILCoZ-SfnEe',
                type: 'text',
              },
              '-LWDLWiMaHmCy6-ncr6J': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'High pressure shutdown',
                productUID: '-LWDLWgqCSqkFCkLqGSL',
                sectionUID: '-LWDLWi-SILCoZ-SfnEe',
                type: 'text',
              },
              '-LWDLWiObyefOa00SlVU': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Operating hours',
                productUID: '-LWDLWgqCSqkFCkLqGSL',
                sectionUID: '-LWDLWi-SILCoZ-SfnEe',
                type: 'text',
              },
              '-LWDLWiQPSoVGdNTTcWI': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name:
                  'Refrigerant sight glass / refrigerant charge according to sight glass / moisture',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWgqCSqkFCkLqGSL',
                sectionUID: '-LWDLWi-SILCoZ-SfnEe',
                type: 'radio',
              },
              '-LWDLWiSzHTl6wtmr6Eo': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name:
                  'Coolant inspection glass / coolant filling acc. To inspection glass / moisture',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWgqCSqkFCkLqGSL',
                sectionUID: '-LWDLWi-SILCoZ-SfnEe',
                type: 'radio',
              },
            },
            jobUID: '-LWDLWcx7quSSG2zYcWL',
            name: 'Compressor',
            productUID: '-LWDLWgqCSqkFCkLqGSL',
          },
          '-LWDLWiTVWbi-BkY2gcF': {
            InputArray: ['-LWDLWiUiqQJAxMmhqUx'],
            Inputs: {
              '-LWDLWiUiqQJAxMmhqUx': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Check for leaks',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWgqCSqkFCkLqGSL',
                sectionUID: '-LWDLWiTVWbi-BkY2gcF',
                type: 'radio',
              },
            },
            jobUID: '-LWDLWcx7quSSG2zYcWL',
            name:
              'Certificate for maintenance and leakage test to EC regulation 842/2006 (F-gas regulation)',
            productUID: '-LWDLWgqCSqkFCkLqGSL',
          },
          '-LWDLWiVeBjISYCYSS5a': {
            InputArray: [
              '-LWDLWiX6YWO681_uQPc',
              '-LWDLWiZjk_S_d65Vd3H',
              '-LWDLWiasiSe6k3L7EAH',
              '-LWDLWicbD0zjkuvm6Mu',
              '-LWDLWieYAytLGGxcDib',
            ],
            Inputs: {
              '-LWDLWiX6YWO681_uQPc': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'SNMP card connected and operating',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWgqCSqkFCkLqGSL',
                sectionUID: '-LWDLWiVeBjISYCYSS5a',
                type: 'radio',
              },
              '-LWDLWiZjk_S_d65Vd3H': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'SNMP card configured and tested',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWgqCSqkFCkLqGSL',
                sectionUID: '-LWDLWiVeBjISYCYSS5a',
                type: 'radio',
              },
              '-LWDLWiasiSe6k3L7EAH': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Fault messages configured and tested according to the alarm concept',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWgqCSqkFCkLqGSL',
                sectionUID: '-LWDLWiVeBjISYCYSS5a',
                type: 'radio',
              },
              '-LWDLWicbD0zjkuvm6Mu': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Provide detailed instruction in the system for the customer',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWgqCSqkFCkLqGSL',
                sectionUID: '-LWDLWiVeBjISYCYSS5a',
                type: 'radio',
              },
              '-LWDLWieYAytLGGxcDib': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Verify if it is possible to transfer alarm and information messages',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWgqCSqkFCkLqGSL',
                sectionUID: '-LWDLWiVeBjISYCYSS5a',
                type: 'radio',
              },
            },
            jobUID: '-LWDLWcx7quSSG2zYcWL',
            name: 'SNMP Card',
            productUID: '-LWDLWgqCSqkFCkLqGSL',
          },
        },
        jobUID: '-LWDLWcx7quSSG2zYcWL',
        name: 'LCP-DX',
      },
      '-LWDLWieYAytLGGxcDic': {
        SectionArray: ['-LWDLWieYAytLGGxcDid', '-LWDLWiwQ7h4PTKIlKm4'],
        Sections: {
          '-LWDLWieYAytLGGxcDid': {
            InputArray: [
              '-LWDLWihcTNpaGV7uITK',
              '-LWDLWijib8ropahinsP',
              '-LWDLWikvXmEGWk3tDcL',
              '-LWDLWinP8sJv2yQExxo',
              '-LWDLWioOuguxVOQJEve',
              '-LWDLWiqiiGB-GbGrgTb',
              '-LWDLWit1CzmNSuaOF4E',
              '-LX-Uyc4pzNlIvxAHfNZ',
              '-LWDLWiv6OU8sre4LfHB',
            ],
            Inputs: {
              '-LWDLWihcTNpaGV7uITK': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Location ID',
                productUID: '-LWDLWieYAytLGGxcDic',
                sectionUID: '-LWDLWieYAytLGGxcDid',
                type: 'text',
              },
              '-LWDLWijib8ropahinsP': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Article / Model Number',
                productUID: '-LWDLWieYAytLGGxcDic',
                sectionUID: '-LWDLWieYAytLGGxcDid',
                type: 'text',
              },
              '-LWDLWikvXmEGWk3tDcL': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Serial Number',
                productUID: '-LWDLWieYAytLGGxcDic',
                sectionUID: '-LWDLWieYAytLGGxcDid',
                type: 'text',
              },
              '-LWDLWinP8sJv2yQExxo': {
                inputType: 'datetimePicker',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Date of manufacture',
                productUID: '-LWDLWieYAytLGGxcDic',
                sectionUID: '-LWDLWieYAytLGGxcDid',
                type: 'date',
              },
              '-LWDLWioOuguxVOQJEve': {
                accept: 'image/*',
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                maxNumber: 3,
                name: 'Data plate',
                pattern: 'image-*',
                productUID: '-LWDLWieYAytLGGxcDic',
                sectionUID: '-LWDLWieYAytLGGxcDid',
                type: 'upload',
              },
              '-LWDLWiqiiGB-GbGrgTb': {
                accept: 'image/*',
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                maxNumber: 5,
                name: 'General Images',
                pattern: 'image-*',
                productUID: '-LWDLWieYAytLGGxcDic',
                sectionUID: '-LWDLWieYAytLGGxcDid',
                type: 'upload',
              },
              '-LWDLWit1CzmNSuaOF4E': {
                accept: '.',
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                maxNumber: 0,
                name: 'General Files',
                pattern: '.',
                productUID: '-LWDLWieYAytLGGxcDic',
                sectionUID: '-LWDLWieYAytLGGxcDid',
                type: 'upload',
              },
              '-LX-Uyc4pzNlIvxAHfNZ': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Visual Inspection / Functional Check',
                notes: true,
                notesRequiredValues: ['Repair Necessary'],
                options: ['Okay', 'Repair Necessary', 'Unable To Evaluate'],
                productUID: '-LWDLWieYAytLGGxcDic',
                sectionUID: '-LWDLWieYAytLGGxcDid',
                type: 'radio',
                summary: true,
              },
              '-LWDLWiv6OU8sre4LfHB': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Final Remarks',
                productUID: '-LWDLWieYAytLGGxcDic',
                sectionUID: '-LWDLWieYAytLGGxcDid',
                type: 'textarea',
              },
            },
            jobUID: '-LWDLWcx7quSSG2zYcWL',
            name: 'General',
            productUID: '-LWDLWieYAytLGGxcDic',
          },
          '-LWDLWiwQ7h4PTKIlKm4': {
            InputArray: [
              '-LWDLWiyUOxiOhdpJ8fP',
              '-LWDLWj-diyZOXcoTEBd',
              '-LWDLWj3kLWSrzc_6lM6',
              '-LWDLWj4Vts0TXZb9Vvx',
              '-LWDLWj6RKXQhFosgxuB',
              '-LWDLWj8Odr-T0TvZ1uZ',
              '-LWDLWjAofsKJZkD8py_',
              '-LWDLWjC5Hah3yl0_itM',
              '-LWDLWjEz5cTQr9SxU3y',
              '-LWDLWjH5-oAytYk1j7Z',
              '-LWDLWjJdiEVCrtFPtqb',
              '-LWDLWjMGIdsg5-kOhew',
              '-LWDLWjPHFdqM03Di0nB',
              '-LWDLWjShF6QBuFQtPjN',
              '-LWDLWjU37c9sp2lsfrx',
              '-LWDLWjVA0P9y2Pb8G3-',
              '-LWDLWjXX95M1QagV6c9',
              '-LWDLWjZy1A4MFkea3Z2',
              '-LWDLWjb_07JvyRyu_U6',
              '-LWDLWjcW-9kuttzdMGu',
              '-LWDLWjeEnKuYEcZ8rvC',
              '-LWDLWjgjCqmdxI9INFV',
            ],
            Inputs: {
              '-LWDLWiyUOxiOhdpJ8fP': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Functional check of locks',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWieYAytLGGxcDic',
                sectionUID: '-LWDLWiwQ7h4PTKIlKm4',
                type: 'radio',
              },
              '-LWDLWj-diyZOXcoTEBd': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Check closing mechanism including bolts',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWieYAytLGGxcDic',
                sectionUID: '-LWDLWiwQ7h4PTKIlKm4',
                type: 'radio',
              },
              '-LWDLWj3kLWSrzc_6lM6': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Visual check of safety cell (locking elements)',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWieYAytLGGxcDic',
                sectionUID: '-LWDLWiwQ7h4PTKIlKm4',
                type: 'radio',
              },
              '-LWDLWj4Vts0TXZb9Vvx': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Check tightness of door systems',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWieYAytLGGxcDic',
                sectionUID: '-LWDLWiwQ7h4PTKIlKm4',
                type: 'radio',
              },
              '-LWDLWj6RKXQhFosgxuB': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Visual inspection of cable duct systems (leak tightness)',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWieYAytLGGxcDic',
                sectionUID: '-LWDLWiwQ7h4PTKIlKm4',
                type: 'radio',
              },
              '-LWDLWj8Odr-T0TvZ1uZ': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Check door seals for integrity and flexibility',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWieYAytLGGxcDic',
                sectionUID: '-LWDLWiwQ7h4PTKIlKm4',
                type: 'radio',
              },
              '-LWDLWjAofsKJZkD8py_': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Change seals (optional)',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWieYAytLGGxcDic',
                sectionUID: '-LWDLWiwQ7h4PTKIlKm4',
                type: 'radio',
              },
              '-LWDLWjC5Hah3yl0_itM': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Check free movement of hinges (inside and out)',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWieYAytLGGxcDic',
                sectionUID: '-LWDLWiwQ7h4PTKIlKm4',
                type: 'radio',
              },
              '-LWDLWjEz5cTQr9SxU3y': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Check free motion of all pull-out elements inside',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWieYAytLGGxcDic',
                sectionUID: '-LWDLWiwQ7h4PTKIlKm4',
                type: 'radio',
              },
              '-LWDLWjH5-oAytYk1j7Z': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Clean safe, if required',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWieYAytLGGxcDic',
                sectionUID: '-LWDLWiwQ7h4PTKIlKm4',
                type: 'radio',
              },
              '-LWDLWjJdiEVCrtFPtqb': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Functional test of air conditioning (compressor, internal and external fans',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWieYAytLGGxcDic',
                sectionUID: '-LWDLWiwQ7h4PTKIlKm4',
                type: 'radio',
              },
              '-LWDLWjMGIdsg5-kOhew': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Check cleanness of all parts of the air conditioning',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWieYAytLGGxcDic',
                sectionUID: '-LWDLWiwQ7h4PTKIlKm4',
                type: 'radio',
              },
              '-LWDLWjPHFdqM03Di0nB': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Check behavior of air conditioning in an alarm case',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWieYAytLGGxcDic',
                sectionUID: '-LWDLWiwQ7h4PTKIlKm4',
                type: 'radio',
              },
              '-LWDLWjShF6QBuFQtPjN': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Check set points of air conditioning',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWieYAytLGGxcDic',
                sectionUID: '-LWDLWiwQ7h4PTKIlKm4',
                type: 'radio',
              },
              '-LWDLWjU37c9sp2lsfrx': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name:
                  'Check correct function of controller, as long as the operating schedule is not disturbed',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWieYAytLGGxcDic',
                sectionUID: '-LWDLWiwQ7h4PTKIlKm4',
                type: 'radio',
              },
              '-LWDLWjVA0P9y2Pb8G3-': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Replace fuses if required',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWieYAytLGGxcDic',
                sectionUID: '-LWDLWiwQ7h4PTKIlKm4',
                type: 'radio',
              },
              '-LWDLWjXX95M1QagV6c9': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name:
                  'Check CMC temperature sensor as well as the transmission of alarms and warnings',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWieYAytLGGxcDic',
                sectionUID: '-LWDLWiwQ7h4PTKIlKm4',
                type: 'radio',
              },
              '-LWDLWjZy1A4MFkea3Z2': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name:
                  'Check over temperature thermostat, as long as the operating schedule is not disturbed',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWieYAytLGGxcDic',
                sectionUID: '-LWDLWiwQ7h4PTKIlKm4',
                type: 'radio',
              },
              '-LWDLWjb_07JvyRyu_U6': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Check the entire system: correct orientation / installation',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWieYAytLGGxcDic',
                sectionUID: '-LWDLWiwQ7h4PTKIlKm4',
                type: 'radio',
              },
              '-LWDLWjcW-9kuttzdMGu': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Introduce the customer to the handling / use',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWieYAytLGGxcDic',
                sectionUID: '-LWDLWiwQ7h4PTKIlKm4',
                type: 'radio',
              },
              '-LWDLWjeEnKuYEcZ8rvC': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Clean condenser (outer part), if existing at indoor/outdoor',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWieYAytLGGxcDic',
                sectionUID: '-LWDLWiwQ7h4PTKIlKm4',
                type: 'radio',
              },
              '-LWDLWjgjCqmdxI9INFV': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Check refrigerant lines for leaks',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWieYAytLGGxcDic',
                sectionUID: '-LWDLWiwQ7h4PTKIlKm4',
                type: 'radio',
              },
            },
            jobUID: '-LWDLWcx7quSSG2zYcWL',
            name: 'Modular Safe',
            productUID: '-LWDLWieYAytLGGxcDic',
          },
        },
        jobUID: '-LWDLWcx7quSSG2zYcWL',
        name: 'Micro-Data-Center',
      },
      '-LWDLWjh1C82nN8-BTNO': {
        SectionArray: ['-LWDLWjh1C82nN8-BTNP', '-LWDLWjw8sw3OR_jkp7U'],
        Sections: {
          '-LWDLWjh1C82nN8-BTNP': {
            InputArray: [
              '-LWDLWjjnP3-q3RvtA5Q',
              '-LWDLWjlIppskq7w9KEY',
              '-LWDLWjmJfAY53wcd4sH',
              '-LWDLWjoOvbOcQ8BTWSp',
              '-LWDLWjqvUsQeQyowD35',
              '-LWDLWjs5VClg0yzXr2t',
              '-LWDLWjt_pGS4g_1tmMD',
              '-LX-Uyc5TnWEse1evUX7',
              '-LWDLWjw8sw3OR_jkp7T',
            ],
            Inputs: {
              '-LWDLWjjnP3-q3RvtA5Q': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Location ID',
                productUID: '-LWDLWjh1C82nN8-BTNO',
                sectionUID: '-LWDLWjh1C82nN8-BTNP',
                type: 'text',
              },
              '-LWDLWjlIppskq7w9KEY': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Article / Model Number',
                productUID: '-LWDLWjh1C82nN8-BTNO',
                sectionUID: '-LWDLWjh1C82nN8-BTNP',
                type: 'text',
              },
              '-LWDLWjmJfAY53wcd4sH': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Serial Number',
                productUID: '-LWDLWjh1C82nN8-BTNO',
                sectionUID: '-LWDLWjh1C82nN8-BTNP',
                type: 'text',
              },
              '-LWDLWjoOvbOcQ8BTWSp': {
                inputType: 'datetimePicker',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Date of manufacture',
                productUID: '-LWDLWjh1C82nN8-BTNO',
                sectionUID: '-LWDLWjh1C82nN8-BTNP',
                type: 'date',
              },
              '-LWDLWjqvUsQeQyowD35': {
                accept: 'image/*',
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                maxNumber: 3,
                name: 'Data plate',
                pattern: 'image-*',
                productUID: '-LWDLWjh1C82nN8-BTNO',
                sectionUID: '-LWDLWjh1C82nN8-BTNP',
                type: 'upload',
              },
              '-LWDLWjs5VClg0yzXr2t': {
                accept: 'image/*',
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                maxNumber: 5,
                name: 'General Images',
                pattern: 'image-*',
                productUID: '-LWDLWjh1C82nN8-BTNO',
                sectionUID: '-LWDLWjh1C82nN8-BTNP',
                type: 'upload',
              },
              '-LWDLWjt_pGS4g_1tmMD': {
                accept: '.',
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                maxNumber: 0,
                name: 'General Files',
                pattern: '.',
                productUID: '-LWDLWjh1C82nN8-BTNO',
                sectionUID: '-LWDLWjh1C82nN8-BTNP',
                type: 'upload',
              },
              '-LX-Uyc5TnWEse1evUX7': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Visual Inspection / Functional Check',
                notes: true,
                notesRequiredValues: ['Repair Necessary'],
                options: ['Okay', 'Repair Necessary', 'Unable To Evaluate'],
                productUID: '-LWDLWjh1C82nN8-BTNO',
                sectionUID: '-LWDLWjh1C82nN8-BTNP',
                type: 'radio',
                summary: true,
              },
              '-LWDLWjw8sw3OR_jkp7T': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Final Remarks',
                productUID: '-LWDLWjh1C82nN8-BTNO',
                sectionUID: '-LWDLWjh1C82nN8-BTNP',
                type: 'textarea',
              },
            },
            jobUID: '-LWDLWcx7quSSG2zYcWL',
            name: 'General',
            productUID: '-LWDLWjh1C82nN8-BTNO',
          },
          '-LWDLWjw8sw3OR_jkp7U': {
            InputArray: [
              '-LWDLWjyTYaaWJEdIt1f',
              '-LWDLWk-Z7i9nz1VKmPm',
              '-LWDLWk1m2JOzlyHUGwm',
              '-LWDLWk2Tzp7O76UE0io',
              '-LWDLWk5cFjFmlK3asYy',
              '-LWDLWk69IDVY-e6m2GZ',
              '-LWDLWk9acMlgjDkS3XW',
              '-LWDLWkCJuMYJ2UfU1va',
              '-LWDLWkElHPaeASjA3jF',
              '-LWDLWkG3zQdAT3Wj798',
              '-LWDLWkISLxHI6TfmqcH',
              '-LWDLWkKKhG9ekgjT3mM',
              '-LWDLWkMqDfkQz8brves',
              '-LWDLWkNDlNO37PkSzxQ',
              '-LWDLWkPa0zu8kqkqtcm',
              '-LWDLWkRzTigakQn4PTv',
              '-LWDLWkT3IC_tRzFfNGz',
              '-LWDLWkV5m7Ee9b1xhhM',
              '-LWDLWkXieJQHfcxwMif',
            ],
            Inputs: {
              '-LWDLWjyTYaaWJEdIt1f': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Visual check for external damage',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWjh1C82nN8-BTNO',
                sectionUID: '-LWDLWjw8sw3OR_jkp7U',
                type: 'radio',
              },
              '-LWDLWk-Z7i9nz1VKmPm': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name:
                  'Check for alarm messages.  In case of alarms, record them and reset the unit',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWjh1C82nN8-BTNO',
                sectionUID: '-LWDLWjw8sw3OR_jkp7U',
                type: 'radio',
              },
              '-LWDLWk1m2JOzlyHUGwm': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Check the unit for a firm seat',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWjh1C82nN8-BTNO',
                sectionUID: '-LWDLWjw8sw3OR_jkp7U',
                type: 'radio',
              },
              '-LWDLWk2Tzp7O76UE0io': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name:
                  'Check the system regarding the following faults, as long as operating schedule is not impacted',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWjh1C82nN8-BTNO',
                sectionUID: '-LWDLWjw8sw3OR_jkp7U',
                type: 'radio',
              },
              '-LWDLWk5cFjFmlK3asYy': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Loss of extinguishing agent',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWjh1C82nN8-BTNO',
                sectionUID: '-LWDLWjw8sw3OR_jkp7U',
                type: 'radio',
              },
              '-LWDLWk69IDVY-e6m2GZ': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Power failure',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWjh1C82nN8-BTNO',
                sectionUID: '-LWDLWjw8sw3OR_jkp7U',
                type: 'radio',
              },
              '-LWDLWk9acMlgjDkS3XW': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Defect batteries',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWjh1C82nN8-BTNO',
                sectionUID: '-LWDLWjw8sw3OR_jkp7U',
                type: 'radio',
              },
              '-LWDLWkCJuMYJ2UfU1va': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Break of the release cable to the extinguishing tank',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWjh1C82nN8-BTNO',
                sectionUID: '-LWDLWjw8sw3OR_jkp7U',
                type: 'radio',
              },
              '-LWDLWkElHPaeASjA3jF': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name:
                  'Test of the complete alarm sequence including extinction release (extinguishing device previously to be blocked), as far as allowed by organizational conditions.',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWjh1C82nN8-BTNO',
                sectionUID: '-LWDLWjw8sw3OR_jkp7U',
                type: 'radio',
              },
              '-LWDLWkG3zQdAT3Wj798': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Release pre-alarm (observe optical and potential-free messages).',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWjh1C82nN8-BTNO',
                sectionUID: '-LWDLWjw8sw3OR_jkp7U',
                type: 'radio',
              },
              '-LWDLWkISLxHI6TfmqcH': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'The same procedure for the main alarm',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWjh1C82nN8-BTNO',
                sectionUID: '-LWDLWjw8sw3OR_jkp7U',
                type: 'radio',
              },
              '-LWDLWkKKhG9ekgjT3mM': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Opening the unit by taking away the front panel(s)',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWjh1C82nN8-BTNO',
                sectionUID: '-LWDLWjw8sw3OR_jkp7U',
                type: 'radio',
              },
              '-LWDLWkMqDfkQz8brves': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Visual inspection of inner parts for damages',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWjh1C82nN8-BTNO',
                sectionUID: '-LWDLWjw8sw3OR_jkp7U',
                type: 'radio',
              },
              '-LWDLWkNDlNO37PkSzxQ': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Check fuses, if necessary replace damaged items',
                productUID: '-LWDLWjh1C82nN8-BTNO',
                sectionUID: '-LWDLWjw8sw3OR_jkp7U',
                type: 'text',
              },
              '-LWDLWkPa0zu8kqkqtcm': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name:
                  'If rechargeable batteries have not been replaced during the last two years, replace them (not included in maintenance cost)!',
                productUID: '-LWDLWjh1C82nN8-BTNO',
                sectionUID: '-LWDLWjw8sw3OR_jkp7U',
                type: 'text',
              },
              '-LWDLWkRzTigakQn4PTv': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name:
                  'If internal filter has not been replaced during the last two years, replace them (not included in maintenance cost)!',
                productUID: '-LWDLWjh1C82nN8-BTNO',
                sectionUID: '-LWDLWjw8sw3OR_jkp7U',
                type: 'text',
              },
              '-LWDLWkT3IC_tRzFfNGz': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Documentation must be complete',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWjh1C82nN8-BTNO',
                sectionUID: '-LWDLWjw8sw3OR_jkp7U',
                type: 'radio',
              },
              '-LWDLWkV5m7Ee9b1xhhM': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Introduce the customer to the handling / use',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWjh1C82nN8-BTNO',
                sectionUID: '-LWDLWjw8sw3OR_jkp7U',
                type: 'radio',
              },
              '-LWDLWkXieJQHfcxwMif': {
                inputType: 'input',
                jobUID: '-LWDLWcx7quSSG2zYcWL',
                name: 'Mark service labels with date and initials, stick on back of unit',
                notes: true,
                notesRequiredValues: ['Not Okay'],
                options: ['Okay', 'Not Okay', 'Not Applicable'],
                productUID: '-LWDLWjh1C82nN8-BTNO',
                sectionUID: '-LWDLWjw8sw3OR_jkp7U',
                type: 'radio',
              },
            },
            jobUID: '-LWDLWcx7quSSG2zYcWL',
            name: 'Rack',
            productUID: '-LWDLWjh1C82nN8-BTNO',
          },
        },
        jobUID: '-LWDLWcx7quSSG2zYcWL',
        name: 'DET-AC',
      },
    },
    name: 'PM',
  },
  '-LWDLWkXieJQHfcxwMig': {
    ProductTypeArray: [
      '-LWDLWkYw_HRe0lmQogN',
      '-LWDLWn6j51t-esWyExc',
      '-LWDLWpbH3zRUp3NzQDR',
      '-LWDLWsaYBOhISkfkm9m',
      '-LWDLWvE0cpNQaAX32Ni',
      '-LWDLWxgxKgUZt2CnNB4',
      '-LWDLX-Kdvqdr44VUXAe',
    ],
    ProductTypes: {
      '-LWDLWkYw_HRe0lmQogN': {
        SectionArray: [
          '-LWDLWkYw_HRe0lmQogO',
          '-LWDLWkdwYLA5t5jJaS4',
          '-LWDLWlXFVHUViCQB8Q3',
          '-LWDLWlap4kI8757NP72',
          '-LWDLWm4ZIZzFmylC4sz',
          '-LWDLWmW4BqL3ZR68hzF',
          '-LWDLWmiwriXNOSS_oQ2',
          '-LWDLWmyUZe3RsmIoQJH',
        ],
        Sections: {
          '-LWDLWkYw_HRe0lmQogO': {
            InputArray: ['-LWDLWk_doBaTaDH5qOv', '-LWDLWkbYzxcwpXxjsRd', '-LWDLWkdwYLA5t5jJaS3'],
            Inputs: {
              '-LWDLWk_doBaTaDH5qOv': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Ticket number',
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWkYw_HRe0lmQogO',
                type: 'text',
              },
              '-LWDLWkbYzxcwpXxjsRd': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Customer reference number',
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWkYw_HRe0lmQogO',
                type: 'text',
              },
              '-LWDLWkdwYLA5t5jJaS3': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Purchase doc number',
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWkYw_HRe0lmQogO',
                type: 'text',
              },
            },
            jobUID: '-LWDLWkXieJQHfcxwMig',
            name: 'Service Numbers',
            productUID: '-LWDLWkYw_HRe0lmQogN',
          },
          '-LWDLWkdwYLA5t5jJaS4': {
            InputArray: [
              '-LWDLWkfqM1v-z8rkHGJ',
              '-LWDLWkhIRbjONK5QJri',
              '-LWDLWkjdkCO7gN1CyQC',
              '-LWDLWklRwTGb9OTRsz1',
              '-LWDLWkoAZcPn4m9ICud',
              '-LWDLWkqCy8_9rHOry-g',
              '-LWDLWksrXGI1P15ZiDn',
              '-LWDLWkvfEMOjxHswXs1',
              '-LWDLWkxGjfcqSycqixT',
              '-LWDLWl-XEyVSNyONk2t',
              '-LWDLWl0X7GdhM9BKDy4',
              '-LWDLWl3ZOa6oxlcCjWU',
              '-LWDLWl5aZ3TiAyUZsoj',
              '-LWDLWl7of2oePxiu_pS',
              '-LWDLWlAa_5UuU1cUrBA',
              '-LWDLWlCHFs9rLZyHuAA',
              '-LWDLWlE1DUiQRzZlSBo',
              '-LWDLWlG-_eddtmWyZo4',
              '-LWDLWlIUcZPhZFQkfhu',
              '-LWDLWlLp-7oBZIk7mYm',
              '-LWDLWlMN1OS-ohIrGS5',
              '-LWDLWlOiXjdAKotK73X',
              '-LWDLWlQ7FgYEVqCYwpA',
              '-LWDLWlT_znDf_GZYQqA',
              '-LWDLWlUNVcdc0hjw53I',
              '-LWDLWlWp4z1HmBG4h5t',
            ],
            Inputs: {
              '-LWDLWkfqM1v-z8rkHGJ': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Company name - Rittal Customer',
                options: ['Install', 'Maintenance', 'Repair', 'Assembly'],
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWkdwYLA5t5jJaS4',
                type: 'radio',
              },
              '-LWDLWkhIRbjONK5QJri': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Contact person - Rittal Customer',
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWkdwYLA5t5jJaS4',
                type: 'text',
              },
              '-LWDLWkjdkCO7gN1CyQC': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Customer - Rittal Customer',
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWkdwYLA5t5jJaS4',
                type: 'text',
              },
              '-LWDLWklRwTGb9OTRsz1': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Telephone number - Rittal Customer',
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWkdwYLA5t5jJaS4',
                type: 'text',
              },
              '-LWDLWkoAZcPn4m9ICud': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Address - Rittal Customer',
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWkdwYLA5t5jJaS4',
                type: 'text',
              },
              '-LWDLWkqCy8_9rHOry-g': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Email - Rittal Customer',
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWkdwYLA5t5jJaS4',
                type: 'text',
              },
              '-LWDLWksrXGI1P15ZiDn': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Company name - Service carried out by (company)',
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWkdwYLA5t5jJaS4',
                type: 'text',
              },
              '-LWDLWkvfEMOjxHswXs1': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Contact person - Service carried out by (company)',
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWkdwYLA5t5jJaS4',
                type: 'text',
              },
              '-LWDLWkxGjfcqSycqixT': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Customer - Service carried out by (company)',
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWkdwYLA5t5jJaS4',
                type: 'text',
              },
              '-LWDLWl-XEyVSNyONk2t': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Telephone number - Service carried out by (company)',
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWkdwYLA5t5jJaS4',
                type: 'text',
              },
              '-LWDLWl0X7GdhM9BKDy4': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Address - Service carried out by (company)',
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWkdwYLA5t5jJaS4',
                type: 'text',
              },
              '-LWDLWl3ZOa6oxlcCjWU': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Email - Service carried out by (company)',
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWkdwYLA5t5jJaS4',
                type: 'text',
              },
              '-LWDLWl5aZ3TiAyUZsoj': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Company name - Service carried out at (location)',
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWkdwYLA5t5jJaS4',
                type: 'text',
              },
              '-LWDLWl7of2oePxiu_pS': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Contact person - Service carried out at (location)',
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWkdwYLA5t5jJaS4',
                type: 'text',
              },
              '-LWDLWlAa_5UuU1cUrBA': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Customer - Service carried out at (location)',
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWkdwYLA5t5jJaS4',
                type: 'text',
              },
              '-LWDLWlCHFs9rLZyHuAA': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Telephone number - Service carried out at (location)',
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWkdwYLA5t5jJaS4',
                type: 'text',
              },
              '-LWDLWlE1DUiQRzZlSBo': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Address - Service carried out at (location)',
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWkdwYLA5t5jJaS4',
                type: 'text',
              },
              '-LWDLWlG-_eddtmWyZo4': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Email - Service carried out at (location)',
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWkdwYLA5t5jJaS4',
                type: 'text',
              },
              '-LWDLWlIUcZPhZFQkfhu': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Model number',
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWkdwYLA5t5jJaS4',
                type: 'text',
              },
              '-LWDLWlLp-7oBZIk7mYm': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Quantity',
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWkdwYLA5t5jJaS4',
                type: 'text',
              },
              '-LWDLWlMN1OS-ohIrGS5': {
                inputType: 'datetimePicker',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Date of production',
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWkdwYLA5t5jJaS4',
                type: 'date',
              },
              '-LWDLWlOiXjdAKotK73X': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Customer machine number',
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWkdwYLA5t5jJaS4',
                type: 'text',
              },
              '-LWDLWlQ7FgYEVqCYwpA': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'WA-Nr.',
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWkdwYLA5t5jJaS4',
                type: 'text',
              },
              '-LWDLWlT_znDf_GZYQqA': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Serial Number',
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWkdwYLA5t5jJaS4',
                type: 'text',
              },
              '-LWDLWlUNVcdc0hjw53I': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Service order prepared by (Hub name)',
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWkdwYLA5t5jJaS4',
                type: 'text',
              },
              '-LWDLWlWp4z1HmBG4h5t': {
                inputType: 'datetimePicker',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Date',
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWkdwYLA5t5jJaS4',
                type: 'date',
              },
            },
            jobUID: '-LWDLWkXieJQHfcxwMig',
            name: 'Basic info',
            productUID: '-LWDLWkYw_HRe0lmQogN',
          },
          '-LWDLWlXFVHUViCQB8Q3': {
            InputArray: ['-LWDLWlYwhj0ihdXeUeJ', '-LWDLWlap4kI8757NP71'],
            Inputs: {
              '-LWDLWlYwhj0ihdXeUeJ': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Photo Necessary',
                options: ['Yes', 'No'],
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWlXFVHUViCQB8Q3',
                type: 'radio',
              },
              '-LWDLWlap4kI8757NP71': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Problem description',
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWlXFVHUViCQB8Q3',
                type: 'text',
                summary: true,
              },
            },
            jobUID: '-LWDLWkXieJQHfcxwMig',
            name: 'Work to be done',
            productUID: '-LWDLWkYw_HRe0lmQogN',
          },
          '-LWDLWlap4kI8757NP72': {
            InputArray: [
              '-LWDLWlbHs0MopoVjczS',
              '-LWDLWlevpTWsYO-jyRg',
              '-LWDLWlknlS32c4b8LmA',
              '-LWDLWlmKw9Xoywy3dcj',
              '-LWDLWlolkKDmF2slQx_',
              '-LWDLWlp8bwKvUncNEJN',
              '-LWDLWlsemaSwo_EVsUa',
              '-LWDLWluBO36Riq-QJYh',
              '-LWDLWlwjq4PphbSsmw8',
              '-LWDLWlyiK-pEyFzt1qx',
              '-LWDLWm-7T2j7qO4veev',
              '-LWDLWm0azs_uH6_O9Wd',
              '-LWDLWm2TrgZ2NR0zpfS',
              '-LWDLWm3FoUpaKVGOaJ0',
            ],
            Inputs: {
              '-LWDLWlbHs0MopoVjczS': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Installed condition',
                options: ['Assembly', 'Complete Assembly'],
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWlap4kI8757NP72',
                type: 'radio',
              },
              '-LWDLWlevpTWsYO-jyRg': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Grade of dirt',
                options: ['Low (clean)', 'Medium', 'Heavy'],
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWlap4kI8757NP72',
                type: 'radio',
              },
              '-LWDLWlknlS32c4b8LmA': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Grade of maintenance',
                options: ['Good', 'Medium', 'Poor'],
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWlap4kI8757NP72',
                type: 'radio',
              },
              '-LWDLWlmKw9Xoywy3dcj': {
                inputType: 'datetimePicker',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Last maintenance on (date)',
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWlap4kI8757NP72',
                type: 'date',
              },
              '-LWDLWlolkKDmF2slQx_': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Room temp',
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWlap4kI8757NP72',
                type: 'text',
              },
              '-LWDLWlp8bwKvUncNEJN': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Temp inside enclosure',
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWlap4kI8757NP72',
                type: 'text',
              },
              '-LWDLWlsemaSwo_EVsUa': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Air Filter',
                options: ['Yes', 'No'],
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWlap4kI8757NP72',
                type: 'radio',
              },
              '-LWDLWluBO36Riq-QJYh': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Right air filter',
                options: ['Yes', 'No'],
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWlap4kI8757NP72',
                type: 'radio',
              },
              '-LWDLWlwjq4PphbSsmw8': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Air filter dirty',
                options: ['Low (clean)', 'Medium', 'Heavy'],
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWlap4kI8757NP72',
                type: 'radio',
              },
              '-LWDLWlyiK-pEyFzt1qx': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Failure indication (display)',
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWlap4kI8757NP72',
                type: 'text',
              },
              '-LWDLWm-7T2j7qO4veev': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Noise level',
                options: ['Normal', 'Noisy'],
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWlap4kI8757NP72',
                type: 'radio',
              },
              '-LWDLWm0azs_uH6_O9Wd': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Vibration on each unit',
                options: ['Low', 'Medium', 'Heavy'],
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWlap4kI8757NP72',
                type: 'radio',
              },
              '-LWDLWm2TrgZ2NR0zpfS': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Electrical connection, incoming voltage (V,Hz)',
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWlap4kI8757NP72',
                type: 'text',
              },
              '-LWDLWm3FoUpaKVGOaJ0': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Comments',
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWlap4kI8757NP72',
                type: 'textarea',
              },
            },
            jobUID: '-LWDLWkXieJQHfcxwMig',
            name: 'Status prior to servicing',
            productUID: '-LWDLWkYw_HRe0lmQogN',
          },
          '-LWDLWm4ZIZzFmylC4sz': {
            InputArray: [
              '-LWDLWm6YtcAG2_wIEmG',
              '-LWDLWm712l-53urXTFD',
              '-LWDLWm9h2jcA-GbsMN7',
              '-LWDLWmB7tmU9ZtE0gcL',
              '-LWDLWmDyYyh87oqi-ej',
              '-LWDLWmEUdLUb9A-aE35',
              '-LWDLWmGKAfgsLBDywig',
              '-LWDLWmIT8tRBFapOXhx',
              '-LWDLWmJA-3azXuwWBEQ',
              '-LWDLWmMU4VDRMLQmD7b',
              '-LWDLWmNyaC4RcevMAvo',
              '-LWDLWmPppeUf9_Is7O1',
              '-LWDLWmSam_hwb9MO7hH',
              '-LWDLWmUlOvn8CLLpEXE',
              '-LWDLWmW4BqL3ZR68hzE',
            ],
            Inputs: {
              '-LWDLWm6YtcAG2_wIEmG': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Cooling unit changed complete',
                options: ['Yes', 'No'],
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWm4ZIZzFmylC4sz',
                type: 'radio',
              },
              '-LWDLWm712l-53urXTFD': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Refrigerant replaced',
                options: ['Yes', 'No'],
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWm4ZIZzFmylC4sz',
                type: 'radio',
              },
              '-LWDLWm9h2jcA-GbsMN7': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Compressor changed',
                options: ['Yes', 'No'],
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWm4ZIZzFmylC4sz',
                type: 'radio',
              },
              '-LWDLWmB7tmU9ZtE0gcL': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Condenser fan changed',
                options: ['Yes', 'No'],
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWm4ZIZzFmylC4sz',
                type: 'radio',
              },
              '-LWDLWmDyYyh87oqi-ej': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Evaporator fan changed',
                options: ['Yes', 'No'],
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWm4ZIZzFmylC4sz',
                type: 'radio',
              },
              '-LWDLWmEUdLUb9A-aE35': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Condenser changed (data from component type plate necessary)',
                options: ['Yes', 'No'],
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWm4ZIZzFmylC4sz',
                type: 'radio',
              },
              '-LWDLWmGKAfgsLBDywig': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Evaporator changed (data from component type plate necessary)',
                options: ['Yes', 'No'],
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWm4ZIZzFmylC4sz',
                type: 'radio',
              },
              '-LWDLWmIT8tRBFapOXhx': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name:
                  'Power box / Electronic board changed (data from component type plate necessary)',
                options: ['Yes', 'No'],
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWm4ZIZzFmylC4sz',
                type: 'radio',
              },
              '-LWDLWmJA-3azXuwWBEQ': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Expansion valve changed (data from component type plate necessary)',
                options: ['Yes', 'No'],
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWm4ZIZzFmylC4sz',
                type: 'radio',
              },
              '-LWDLWmMU4VDRMLQmD7b': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Pump changed (fluid sample necessary)',
                options: ['Yes', 'No'],
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWm4ZIZzFmylC4sz',
                type: 'radio',
              },
              '-LWDLWmNyaC4RcevMAvo': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Air filter changed',
                options: ['Yes', 'No'],
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWm4ZIZzFmylC4sz',
                type: 'radio',
              },
              '-LWDLWmPppeUf9_Is7O1': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Sensor changed',
                options: ['B1', 'B2', 'B3', 'B4', 'B5', 'No'],
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWm4ZIZzFmylC4sz',
                type: 'radio',
              },
              '-LWDLWmSam_hwb9MO7hH': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Leakage in cooling circuit solved',
                options: [
                  'High pressure pipe',
                  'Condenser',
                  'Expansion valve',
                  'Evaporator',
                  'Compressor',
                  'No',
                ],
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWm4ZIZzFmylC4sz',
                type: 'radio',
              },
              '-LWDLWmUlOvn8CLLpEXE': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Append test plan',
                options: ['Yes', 'No'],
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWm4ZIZzFmylC4sz',
                type: 'radio',
              },
              '-LWDLWmW4BqL3ZR68hzE': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Measures taken',
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWm4ZIZzFmylC4sz',
                type: 'text',
              },
            },
            jobUID: '-LWDLWkXieJQHfcxwMig',
            name: 'Actions carried out',
            productUID: '-LWDLWkYw_HRe0lmQogN',
          },
          '-LWDLWmW4BqL3ZR68hzF': {
            InputArray: [
              '-LWDLWmZQZX_H1O645YB',
              '-LWDLWm_SUFvmpfbAtoC',
              '-LWDLWmbgtP8-R_xnKQA',
              '-LWDLWmdpQNGDaungLTF',
              '-LWDLWmfzEM5xt_A6Afx',
              '-LWDLWmiwriXNOSS_oQ1',
            ],
            Inputs: {
              '-LWDLWmZQZX_H1O645YB': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Leakage in cooling circuit',
                options: ['Yes', 'No'],
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWmW4BqL3ZR68hzF',
                type: 'radio',
              },
              '-LWDLWm_SUFvmpfbAtoC': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Maintenance interval overdue',
                options: ['Yes', 'No'],
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWmW4BqL3ZR68hzF',
                type: 'radio',
              },
              '-LWDLWmbgtP8-R_xnKQA': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Application problem',
                options: ['Yes', 'No'],
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWmW4BqL3ZR68hzF',
                type: 'radio',
              },
              '-LWDLWmdpQNGDaungLTF': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Faulty installation',
                options: ['Yes', 'No'],
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWmW4BqL3ZR68hzF',
                type: 'radio',
              },
              '-LWDLWmfzEM5xt_A6Afx': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Defective components',
                options: ['Yes', 'No'],
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWmW4BqL3ZR68hzF',
                type: 'radio',
              },
              '-LWDLWmiwriXNOSS_oQ1': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Comments about failure',
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWmW4BqL3ZR68hzF',
                type: 'textarea',
              },
            },
            jobUID: '-LWDLWkXieJQHfcxwMig',
            name: 'Cause of problem',
            productUID: '-LWDLWkYw_HRe0lmQogN',
          },
          '-LWDLWmiwriXNOSS_oQ2': {
            InputArray: [
              '-LWDLWmknuh24-8iaFR8',
              '-LWDLWmnvNCN5V5mV2rv',
              '-LWDLWmpNJsX-7X-72i4',
              '-LWDLWmq1BTYLylKbaKL',
              '-LWDLWmubjylVRosREpD',
              '-LWDLWmwX81qwWvcGoPo',
              '-LWDLWmyUZe3RsmIoQJG',
            ],
            Inputs: {
              '-LWDLWmknuh24-8iaFR8': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Service successful',
                options: ['Yes', 'No'],
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWmiwriXNOSS_oQ2',
                type: 'radio',
              },
              '-LWDLWmnvNCN5V5mV2rv': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Further service required',
                options: ['Yes', 'No'],
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWmiwriXNOSS_oQ2',
                type: 'radio',
              },
              '-LWDLWmpNJsX-7X-72i4': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'If yes, comments',
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWmiwriXNOSS_oQ2',
                type: 'textarea',
              },
              '-LWDLWmq1BTYLylKbaKL': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Defective parts taken',
                options: ['Yes', 'No'],
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWmiwriXNOSS_oQ2',
                type: 'radio',
              },
              '-LWDLWmubjylVRosREpD': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'If yes, tracking information',
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWmiwriXNOSS_oQ2',
                type: 'text',
              },
              '-LWDLWmwX81qwWvcGoPo': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Working hours',
                options: ['Yes', 'No'],
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWmiwriXNOSS_oQ2',
                type: 'radio',
              },
              '-LWDLWmyUZe3RsmIoQJG': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Comments',
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWmiwriXNOSS_oQ2',
                type: 'textarea',
              },
            },
            jobUID: '-LWDLWkXieJQHfcxwMig',
            name: 'Summary',
            productUID: '-LWDLWkYw_HRe0lmQogN',
          },
          '-LWDLWmyUZe3RsmIoQJH': {
            InputArray: [
              '-LWDLWn-0a24NbahpAZy',
              '-LWDLWn1i-XwuaV0Mn4g',
              '-LWDLWn3yaPbovXYXhEb',
              '-LWDLWn5QqRFQ5dU7qvA',
            ],
            Inputs: {
              '-LWDLWn-0a24NbahpAZy': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Name of engineer',
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWmyUZe3RsmIoQJH',
                type: 'text',
              },
              '-LWDLWn1i-XwuaV0Mn4g': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Name and signature of customer',
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWmyUZe3RsmIoQJH',
                type: 'text',
              },
              '-LWDLWn3yaPbovXYXhEb': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Report prepared by',
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWmyUZe3RsmIoQJH',
                type: 'text',
              },
              '-LWDLWn5QqRFQ5dU7qvA': {
                inputType: 'datetimePicker',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Date',
                productUID: '-LWDLWkYw_HRe0lmQogN',
                sectionUID: '-LWDLWmyUZe3RsmIoQJH',
                type: 'date',
              },
            },
            jobUID: '-LWDLWkXieJQHfcxwMig',
            name: 'Responsible parties',
            productUID: '-LWDLWkYw_HRe0lmQogN',
          },
        },
        jobUID: '-LWDLWkXieJQHfcxwMig',
        name: 'AC',
      },
      '-LWDLWn6j51t-esWyExc': {
        SectionArray: [
          '-LWDLWn6j51t-esWyExd',
          '-LWDLWnCtX6WV0dkNH_v',
          '-LWDLWnwjOCG8YREmqkx',
          '-LWDLWo0rpVKkHNA-ZIY',
          '-LWDLWoS18uW_dQsojCw',
          '-LWDLWoyRjEGMQcSj9gH',
          '-LWDLWpCHM1nX4EPA_Bt',
          '-LWDLWpTRvqSBm3eVJ3-',
        ],
        Sections: {
          '-LWDLWn6j51t-esWyExd': {
            InputArray: ['-LWDLWn7Yqhg0L8rZfZX', '-LWDLWnAGitJF3QzC4fD', '-LWDLWnBqT6gOAKQERpr'],
            Inputs: {
              '-LWDLWn7Yqhg0L8rZfZX': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Ticket number',
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWn6j51t-esWyExd',
                type: 'text',
              },
              '-LWDLWnAGitJF3QzC4fD': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Customer reference number',
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWn6j51t-esWyExd',
                type: 'text',
              },
              '-LWDLWnBqT6gOAKQERpr': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Purchase doc number',
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWn6j51t-esWyExd',
                type: 'text',
              },
            },
            jobUID: '-LWDLWkXieJQHfcxwMig',
            name: 'Service Numbers',
            productUID: '-LWDLWn6j51t-esWyExc',
          },
          '-LWDLWnCtX6WV0dkNH_v': {
            InputArray: [
              '-LWDLWnDJmJFVIRP3B2w',
              '-LWDLWnFgRmpw1dmgHIm',
              '-LWDLWnHOgIS3TFv9okC',
              '-LWDLWnJkVqRRYIRBgw6',
              '-LWDLWnKw0hZtEMPW3y6',
              '-LWDLWnNV-J7ktnuPia4',
              '-LWDLWnO3aOrbscWqXlZ',
              '-LWDLWnQQTSbstYBY1Pe',
              '-LWDLWnRFS3gDuRXgnBO',
              '-LWDLWnTZxi7LlLoXfcv',
              '-LWDLWnXijFd1heK1hRE',
              '-LWDLWnYOh1soR0Olo9J',
              '-LWDLWn_FJce-L6c_gH7',
              '-LWDLWnbo5S425UdrLiC',
              '-LWDLWnd8WYdWl70dWaU',
              '-LWDLWnef6pwU7Fuy-jI',
              '-LWDLWnh7E5S34HGHhBd',
              '-LWDLWniGN_ZbRjqrqps',
              '-LWDLWnkyQytHTnNcIbQ',
              '-LWDLWnlS3zgZIfRfYWW',
              '-LWDLWnn879EuVuVVoSd',
              '-LWDLWnpKc-O4GOpSeup',
              '-LWDLWnqgNXd5FGmeYuY',
              '-LWDLWnsYniGCfrPyaDy',
              '-LWDLWnu6lH4uJ8UiYMI',
              '-LWDLWnveIc-c1Lkhpay',
            ],
            Inputs: {
              '-LWDLWnDJmJFVIRP3B2w': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Company name - Rittal Customer',
                options: ['Install', 'Maintenance', 'Repair', 'Assembly'],
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWnCtX6WV0dkNH_v',
                type: 'radio',
              },
              '-LWDLWnFgRmpw1dmgHIm': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Contact person - Rittal Customer',
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWnCtX6WV0dkNH_v',
                type: 'text',
              },
              '-LWDLWnHOgIS3TFv9okC': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Customer - Rittal Customer',
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWnCtX6WV0dkNH_v',
                type: 'text',
              },
              '-LWDLWnJkVqRRYIRBgw6': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Telephone number - Rittal Customer',
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWnCtX6WV0dkNH_v',
                type: 'text',
              },
              '-LWDLWnKw0hZtEMPW3y6': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Address - Rittal Customer',
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWnCtX6WV0dkNH_v',
                type: 'text',
              },
              '-LWDLWnNV-J7ktnuPia4': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Email - Rittal Customer',
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWnCtX6WV0dkNH_v',
                type: 'text',
              },
              '-LWDLWnO3aOrbscWqXlZ': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Company name - Service carried out by (company)',
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWnCtX6WV0dkNH_v',
                type: 'text',
              },
              '-LWDLWnQQTSbstYBY1Pe': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Contact person - Service carried out by (company)',
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWnCtX6WV0dkNH_v',
                type: 'text',
              },
              '-LWDLWnRFS3gDuRXgnBO': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Customer - Service carried out by (company)',
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWnCtX6WV0dkNH_v',
                type: 'text',
              },
              '-LWDLWnTZxi7LlLoXfcv': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Telephone number - Service carried out by (company)',
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWnCtX6WV0dkNH_v',
                type: 'text',
              },
              '-LWDLWnXijFd1heK1hRE': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Address - Service carried out by (company)',
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWnCtX6WV0dkNH_v',
                type: 'text',
              },
              '-LWDLWnYOh1soR0Olo9J': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Email - Service carried out by (company)',
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWnCtX6WV0dkNH_v',
                type: 'text',
              },
              '-LWDLWn_FJce-L6c_gH7': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Company name - Service carried out at (location)',
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWnCtX6WV0dkNH_v',
                type: 'text',
              },
              '-LWDLWnbo5S425UdrLiC': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Contact person - Service carried out at (location)',
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWnCtX6WV0dkNH_v',
                type: 'text',
              },
              '-LWDLWnd8WYdWl70dWaU': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Customer - Service carried out at (location)',
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWnCtX6WV0dkNH_v',
                type: 'text',
              },
              '-LWDLWnef6pwU7Fuy-jI': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Telephone number - Service carried out at (location)',
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWnCtX6WV0dkNH_v',
                type: 'text',
              },
              '-LWDLWnh7E5S34HGHhBd': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Address - Service carried out at (location)',
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWnCtX6WV0dkNH_v',
                type: 'text',
              },
              '-LWDLWniGN_ZbRjqrqps': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Email - Service carried out at (location)',
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWnCtX6WV0dkNH_v',
                type: 'text',
              },
              '-LWDLWnkyQytHTnNcIbQ': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Model number',
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWnCtX6WV0dkNH_v',
                type: 'text',
              },
              '-LWDLWnlS3zgZIfRfYWW': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Quantity',
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWnCtX6WV0dkNH_v',
                type: 'text',
              },
              '-LWDLWnn879EuVuVVoSd': {
                inputType: 'datetimePicker',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Date of production',
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWnCtX6WV0dkNH_v',
                type: 'date',
              },
              '-LWDLWnpKc-O4GOpSeup': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Customer machine number',
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWnCtX6WV0dkNH_v',
                type: 'text',
              },
              '-LWDLWnqgNXd5FGmeYuY': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'WA-Nr.',
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWnCtX6WV0dkNH_v',
                type: 'text',
              },
              '-LWDLWnsYniGCfrPyaDy': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Serial Number',
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWnCtX6WV0dkNH_v',
                type: 'text',
              },
              '-LWDLWnu6lH4uJ8UiYMI': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Service order prepared by (Hub name)',
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWnCtX6WV0dkNH_v',
                type: 'text',
              },
              '-LWDLWnveIc-c1Lkhpay': {
                inputType: 'datetimePicker',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Date',
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWnCtX6WV0dkNH_v',
                type: 'date',
              },
            },
            jobUID: '-LWDLWkXieJQHfcxwMig',
            name: 'Basic info',
            productUID: '-LWDLWn6j51t-esWyExc',
          },
          '-LWDLWnwjOCG8YREmqkx': {
            InputArray: ['-LWDLWnzpO8DELaYoAAl', '-LWDLWo0rpVKkHNA-ZIX'],
            Inputs: {
              '-LWDLWnzpO8DELaYoAAl': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Photo Necessary',
                options: ['Yes', 'No'],
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWnwjOCG8YREmqkx',
                type: 'radio',
              },
              '-LWDLWo0rpVKkHNA-ZIX': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Problem description',
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWnwjOCG8YREmqkx',
                type: 'text',
                summary: true,
              },
            },
            jobUID: '-LWDLWkXieJQHfcxwMig',
            name: 'Work to be done',
            productUID: '-LWDLWn6j51t-esWyExc',
          },
          '-LWDLWo0rpVKkHNA-ZIY': {
            InputArray: [
              '-LWDLWo3KqDRXzGpob3b',
              '-LWDLWo5WvVX79GpEzYS',
              '-LWDLWo6C-9M27vcqJx9',
              '-LWDLWo9k-YH9bQfsaAf',
              '-LWDLWoBoLM1LGc4jaZj',
              '-LWDLWoCNoHn-eadcikX',
              '-LWDLWoE-ldmOcLxlu39',
              '-LWDLWoGSZNXcw03qrNY',
              '-LWDLWoIZ4u21NnxAv2I',
              '-LWDLWoKcDdyV1QdaevE',
              '-LWDLWoMmKvrCZ2IWUqo',
              '-LWDLWoOkLtekGhMQY_T',
              '-LWDLWoQZy_YpBCYc_79',
              '-LWDLWoR8NplksCEF-Dh',
            ],
            Inputs: {
              '-LWDLWo3KqDRXzGpob3b': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Installed condition',
                options: ['Assembly', 'Complete Assembly'],
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWo0rpVKkHNA-ZIY',
                type: 'radio',
              },
              '-LWDLWo5WvVX79GpEzYS': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Grade of dirt',
                options: ['Low (clean)', 'Medium', 'Heavy'],
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWo0rpVKkHNA-ZIY',
                type: 'radio',
              },
              '-LWDLWo6C-9M27vcqJx9': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Grade of maintenance',
                options: ['Good', 'Medium', 'Poor'],
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWo0rpVKkHNA-ZIY',
                type: 'radio',
              },
              '-LWDLWo9k-YH9bQfsaAf': {
                inputType: 'datetimePicker',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Last maintenance on (date)',
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWo0rpVKkHNA-ZIY',
                type: 'date',
              },
              '-LWDLWoBoLM1LGc4jaZj': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Room temp',
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWo0rpVKkHNA-ZIY',
                type: 'text',
              },
              '-LWDLWoCNoHn-eadcikX': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Temp inside enclosure',
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWo0rpVKkHNA-ZIY',
                type: 'text',
              },
              '-LWDLWoE-ldmOcLxlu39': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Air Filter',
                options: ['Yes', 'No'],
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWo0rpVKkHNA-ZIY',
                type: 'radio',
              },
              '-LWDLWoGSZNXcw03qrNY': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Right air filter',
                options: ['Yes', 'No'],
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWo0rpVKkHNA-ZIY',
                type: 'radio',
              },
              '-LWDLWoIZ4u21NnxAv2I': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Air filter dirty',
                options: ['Low (clean)', 'Medium', 'Heavy'],
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWo0rpVKkHNA-ZIY',
                type: 'radio',
              },
              '-LWDLWoKcDdyV1QdaevE': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Failure indication (display)',
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWo0rpVKkHNA-ZIY',
                type: 'text',
              },
              '-LWDLWoMmKvrCZ2IWUqo': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Noise level',
                options: ['Normal', 'Noisy'],
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWo0rpVKkHNA-ZIY',
                type: 'radio',
              },
              '-LWDLWoOkLtekGhMQY_T': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Vibration on each unit',
                options: ['Low', 'Medium', 'Heavy'],
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWo0rpVKkHNA-ZIY',
                type: 'radio',
              },
              '-LWDLWoQZy_YpBCYc_79': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Electrical connection, incoming voltage (V,Hz)',
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWo0rpVKkHNA-ZIY',
                type: 'text',
              },
              '-LWDLWoR8NplksCEF-Dh': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Comments',
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWo0rpVKkHNA-ZIY',
                type: 'textarea',
              },
            },
            jobUID: '-LWDLWkXieJQHfcxwMig',
            name: 'Status prior to servicing',
            productUID: '-LWDLWn6j51t-esWyExc',
          },
          '-LWDLWoS18uW_dQsojCw': {
            InputArray: [
              '-LWDLWoUN5KgfJnLeUBT',
              '-LWDLWoWw12VsE2e_muM',
              '-LWDLWoZvQOMuozciQ3n',
              '-LWDLWo_3c0hJS_R35hd',
              '-LWDLWocTx1yvSglDahc',
              '-LWDLWoeWr1_1Gr3aRkl',
              '-LWDLWohx7siMkGaNax-',
              '-LWDLWojQq5NQN6MF7xI',
              '-LWDLWolbmtH6hU6IaFl',
              '-LWDLWonJFqFKSnDegeu',
              '-LWDLWoqXMd6wFwcKFRJ',
              '-LWDLWosx_yN6fzWgTct',
              '-LWDLWou3Jm6EzcALg7u',
              '-LWDLWowyLx9Vv-4bvMO',
              '-LWDLWoyRjEGMQcSj9gG',
            ],
            Inputs: {
              '-LWDLWoUN5KgfJnLeUBT': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Cooling unit changed complete',
                options: ['Yes', 'No'],
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWoS18uW_dQsojCw',
                type: 'radio',
              },
              '-LWDLWoWw12VsE2e_muM': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Refrigerant replaced',
                options: ['Yes', 'No'],
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWoS18uW_dQsojCw',
                type: 'radio',
              },
              '-LWDLWoZvQOMuozciQ3n': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Compressor changed',
                options: ['Yes', 'No'],
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWoS18uW_dQsojCw',
                type: 'radio',
              },
              '-LWDLWo_3c0hJS_R35hd': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Condenser fan changed',
                options: ['Yes', 'No'],
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWoS18uW_dQsojCw',
                type: 'radio',
              },
              '-LWDLWocTx1yvSglDahc': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Evaporator fan changed',
                options: ['Yes', 'No'],
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWoS18uW_dQsojCw',
                type: 'radio',
              },
              '-LWDLWoeWr1_1Gr3aRkl': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Condenser changed (data from component type plate necessary)',
                options: ['Yes', 'No'],
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWoS18uW_dQsojCw',
                type: 'radio',
              },
              '-LWDLWohx7siMkGaNax-': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Evaporator changed (data from component type plate necessary)',
                options: ['Yes', 'No'],
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWoS18uW_dQsojCw',
                type: 'radio',
              },
              '-LWDLWojQq5NQN6MF7xI': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name:
                  'Power box / Electronic board changed (data from component type plate necessary)',
                options: ['Yes', 'No'],
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWoS18uW_dQsojCw',
                type: 'radio',
              },
              '-LWDLWolbmtH6hU6IaFl': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Expansion valve changed (data from component type plate necessary)',
                options: ['Yes', 'No'],
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWoS18uW_dQsojCw',
                type: 'radio',
              },
              '-LWDLWonJFqFKSnDegeu': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Pump changed (fluid sample necessary)',
                options: ['Yes', 'No'],
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWoS18uW_dQsojCw',
                type: 'radio',
              },
              '-LWDLWoqXMd6wFwcKFRJ': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Air filter changed',
                options: ['Yes', 'No'],
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWoS18uW_dQsojCw',
                type: 'radio',
              },
              '-LWDLWosx_yN6fzWgTct': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Sensor changed',
                options: ['B1', 'B2', 'B3', 'B4', 'B5', 'No'],
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWoS18uW_dQsojCw',
                type: 'radio',
              },
              '-LWDLWou3Jm6EzcALg7u': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Leakage in cooling circuit solved',
                options: [
                  'High pressure pipe',
                  'Condenser',
                  'Expansion valve',
                  'Evaporator',
                  'Compressor',
                  'No',
                ],
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWoS18uW_dQsojCw',
                type: 'radio',
              },
              '-LWDLWowyLx9Vv-4bvMO': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Append test plan',
                options: ['Yes', 'No'],
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWoS18uW_dQsojCw',
                type: 'radio',
              },
              '-LWDLWoyRjEGMQcSj9gG': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Measures taken',
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWoS18uW_dQsojCw',
                type: 'text',
              },
            },
            jobUID: '-LWDLWkXieJQHfcxwMig',
            name: 'Actions carried out',
            productUID: '-LWDLWn6j51t-esWyExc',
          },
          '-LWDLWoyRjEGMQcSj9gH': {
            InputArray: [
              '-LWDLWp0u0U_Yt1jByWf',
              '-LWDLWp4KUzc7UK2B3xy',
              '-LWDLWp6xwAuKQnfO3-v',
              '-LWDLWp8UOFnMjvtKLM2',
              '-LWDLWpAVFtBzeVUmqwK',
              '-LWDLWpCHM1nX4EPA_Bs',
            ],
            Inputs: {
              '-LWDLWp0u0U_Yt1jByWf': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Leakage in cooling circuit',
                options: ['Yes', 'No'],
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWoyRjEGMQcSj9gH',
                type: 'radio',
              },
              '-LWDLWp4KUzc7UK2B3xy': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Maintenance interval overdue',
                options: ['Yes', 'No'],
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWoyRjEGMQcSj9gH',
                type: 'radio',
              },
              '-LWDLWp6xwAuKQnfO3-v': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Application problem',
                options: ['Yes', 'No'],
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWoyRjEGMQcSj9gH',
                type: 'radio',
              },
              '-LWDLWp8UOFnMjvtKLM2': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Faulty installation',
                options: ['Yes', 'No'],
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWoyRjEGMQcSj9gH',
                type: 'radio',
              },
              '-LWDLWpAVFtBzeVUmqwK': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Defective components',
                options: ['Yes', 'No'],
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWoyRjEGMQcSj9gH',
                type: 'radio',
              },
              '-LWDLWpCHM1nX4EPA_Bs': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Comments about failure',
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWoyRjEGMQcSj9gH',
                type: 'textarea',
              },
            },
            jobUID: '-LWDLWkXieJQHfcxwMig',
            name: 'Cause of problem',
            productUID: '-LWDLWn6j51t-esWyExc',
          },
          '-LWDLWpCHM1nX4EPA_Bt': {
            InputArray: [
              '-LWDLWpEF1a3JgcX1UBQ',
              '-LWDLWpGHnSnvS8AAMS1',
              '-LWDLWpJgeI-xEHZxEwh',
              '-LWDLWpKxM3OSh-JHdPm',
              '-LWDLWpOr5Yq0jaoKLuA',
              '-LWDLWpRPsr9usMT5Pp1',
              '-LWDLWpTRvqSBm3eVJ2z',
            ],
            Inputs: {
              '-LWDLWpEF1a3JgcX1UBQ': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Service successful',
                options: ['Yes', 'No'],
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWpCHM1nX4EPA_Bt',
                type: 'radio',
              },
              '-LWDLWpGHnSnvS8AAMS1': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Further service required',
                options: ['Yes', 'No'],
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWpCHM1nX4EPA_Bt',
                type: 'radio',
              },
              '-LWDLWpJgeI-xEHZxEwh': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'If yes, comments',
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWpCHM1nX4EPA_Bt',
                type: 'textarea',
              },
              '-LWDLWpKxM3OSh-JHdPm': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Defective parts taken',
                options: ['Yes', 'No'],
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWpCHM1nX4EPA_Bt',
                type: 'radio',
              },
              '-LWDLWpOr5Yq0jaoKLuA': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'If yes, tracking information',
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWpCHM1nX4EPA_Bt',
                type: 'text',
              },
              '-LWDLWpRPsr9usMT5Pp1': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Working hours',
                options: ['Yes', 'No'],
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWpCHM1nX4EPA_Bt',
                type: 'radio',
              },
              '-LWDLWpTRvqSBm3eVJ2z': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Comments',
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWpCHM1nX4EPA_Bt',
                type: 'textarea',
              },
            },
            jobUID: '-LWDLWkXieJQHfcxwMig',
            name: 'Summary',
            productUID: '-LWDLWn6j51t-esWyExc',
          },
          '-LWDLWpTRvqSBm3eVJ3-': {
            InputArray: [
              '-LWDLWpVR6T4sLxwt8-G',
              '-LWDLWpXY-svgpxkmcx9',
              '-LWDLWp_19bu0wNXa6h8',
              '-LWDLWpbH3zRUp3NzQDQ',
            ],
            Inputs: {
              '-LWDLWpVR6T4sLxwt8-G': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Name of engineer',
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWpTRvqSBm3eVJ3-',
                type: 'text',
              },
              '-LWDLWpXY-svgpxkmcx9': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Name and signature of customer',
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWpTRvqSBm3eVJ3-',
                type: 'text',
              },
              '-LWDLWp_19bu0wNXa6h8': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Report prepared by',
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWpTRvqSBm3eVJ3-',
                type: 'text',
              },
              '-LWDLWpbH3zRUp3NzQDQ': {
                inputType: 'datetimePicker',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Date',
                productUID: '-LWDLWn6j51t-esWyExc',
                sectionUID: '-LWDLWpTRvqSBm3eVJ3-',
                type: 'date',
              },
            },
            jobUID: '-LWDLWkXieJQHfcxwMig',
            name: 'Responsible parties',
            productUID: '-LWDLWn6j51t-esWyExc',
          },
        },
        jobUID: '-LWDLWkXieJQHfcxwMig',
        name: 'CMC',
      },
      '-LWDLWpbH3zRUp3NzQDR': {
        SectionArray: [
          '-LWDLWpbH3zRUp3NzQDS',
          '-LWDLWph9JfxbqPXPtOc',
          '-LWDLWqknSWdllch_3ty',
          '-LWDLWqq91LTAZWZV9Pe',
          '-LWDLWrR5m4jc8nPYL8L',
          '-LWDLWrz00w73ZY1sv0_',
          '-LWDLWsE9kctkih_stcu',
          '-LWDLWsTTlVLt9EbJoqp',
        ],
        Sections: {
          '-LWDLWpbH3zRUp3NzQDS': {
            InputArray: ['-LWDLWpeTFxj1CCt7Oqp', '-LWDLWpfIRZcxFLfpeZ9', '-LWDLWph9JfxbqPXPtOb'],
            Inputs: {
              '-LWDLWpeTFxj1CCt7Oqp': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Ticket number',
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWpbH3zRUp3NzQDS',
                type: 'text',
              },
              '-LWDLWpfIRZcxFLfpeZ9': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Customer reference number',
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWpbH3zRUp3NzQDS',
                type: 'text',
              },
              '-LWDLWph9JfxbqPXPtOb': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Purchase doc number',
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWpbH3zRUp3NzQDS',
                type: 'text',
              },
            },
            jobUID: '-LWDLWkXieJQHfcxwMig',
            name: 'Service Numbers',
            productUID: '-LWDLWpbH3zRUp3NzQDR',
          },
          '-LWDLWph9JfxbqPXPtOc': {
            InputArray: [
              '-LWDLWpkE-nc82gg6rMT',
              '-LWDLWpmZT-0G3G62bod',
              '-LWDLWpoPEQLx_w-1Aa0',
              '-LWDLWprsQvT8KZZUvoD',
              '-LWDLWpvWvB3_xBE1kW4',
              '-LWDLWpy7yjNbXKsVHE-',
              '-LWDLWq-y80GODhw3cog',
              '-LWDLWq1rX7V0HMcAE3n',
              '-LWDLWq40gnJIjrGolQk',
              '-LWDLWq6_oiucxnj0ZJ8',
              '-LWDLWq8T-idro9UMkvL',
              '-LWDLWqAF-EJ8N6pHWJ8',
              '-LWDLWqCkVKLfj_XigRo',
              '-LWDLWqFB1f08AOKj6Wt',
              '-LWDLWqHAOLU5fdJVG96',
              '-LWDLWqLYbpdA1IcgQKb',
              '-LWDLWqNipK-LP4jbo7B',
              '-LWDLWqQFeTPm-oS721V',
              '-LWDLWqS4F2rR-pCnau8',
              '-LWDLWqVfd5VtAVQpjYM',
              '-LWDLWqXCfrxeOS_tWNl',
              '-LWDLWq_CZZeYcFlKvct',
              '-LWDLWqcZn8HyLMOPc_R',
              '-LWDLWqe_Y5J8rhLzZCW',
              '-LWDLWqin6K-P2ftA1q_',
              '-LWDLWqjuUX5RFIJTnUs',
            ],
            Inputs: {
              '-LWDLWpkE-nc82gg6rMT': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Company name - Rittal Customer',
                options: ['Install', 'Maintenance', 'Repair', 'Assembly'],
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWph9JfxbqPXPtOc',
                type: 'radio',
              },
              '-LWDLWpmZT-0G3G62bod': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Contact person - Rittal Customer',
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWph9JfxbqPXPtOc',
                type: 'text',
              },
              '-LWDLWpoPEQLx_w-1Aa0': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Customer - Rittal Customer',
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWph9JfxbqPXPtOc',
                type: 'text',
              },
              '-LWDLWprsQvT8KZZUvoD': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Telephone number - Rittal Customer',
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWph9JfxbqPXPtOc',
                type: 'text',
              },
              '-LWDLWpvWvB3_xBE1kW4': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Address - Rittal Customer',
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWph9JfxbqPXPtOc',
                type: 'text',
              },
              '-LWDLWpy7yjNbXKsVHE-': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Email - Rittal Customer',
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWph9JfxbqPXPtOc',
                type: 'text',
              },
              '-LWDLWq-y80GODhw3cog': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Company name - Service carried out by (company)',
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWph9JfxbqPXPtOc',
                type: 'text',
              },
              '-LWDLWq1rX7V0HMcAE3n': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Contact person - Service carried out by (company)',
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWph9JfxbqPXPtOc',
                type: 'text',
              },
              '-LWDLWq40gnJIjrGolQk': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Customer - Service carried out by (company)',
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWph9JfxbqPXPtOc',
                type: 'text',
              },
              '-LWDLWq6_oiucxnj0ZJ8': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Telephone number - Service carried out by (company)',
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWph9JfxbqPXPtOc',
                type: 'text',
              },
              '-LWDLWq8T-idro9UMkvL': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Address - Service carried out by (company)',
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWph9JfxbqPXPtOc',
                type: 'text',
              },
              '-LWDLWqAF-EJ8N6pHWJ8': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Email - Service carried out by (company)',
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWph9JfxbqPXPtOc',
                type: 'text',
              },
              '-LWDLWqCkVKLfj_XigRo': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Company name - Service carried out at (location)',
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWph9JfxbqPXPtOc',
                type: 'text',
              },
              '-LWDLWqFB1f08AOKj6Wt': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Contact person - Service carried out at (location)',
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWph9JfxbqPXPtOc',
                type: 'text',
              },
              '-LWDLWqHAOLU5fdJVG96': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Customer - Service carried out at (location)',
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWph9JfxbqPXPtOc',
                type: 'text',
              },
              '-LWDLWqLYbpdA1IcgQKb': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Telephone number - Service carried out at (location)',
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWph9JfxbqPXPtOc',
                type: 'text',
              },
              '-LWDLWqNipK-LP4jbo7B': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Address - Service carried out at (location)',
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWph9JfxbqPXPtOc',
                type: 'text',
              },
              '-LWDLWqQFeTPm-oS721V': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Email - Service carried out at (location)',
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWph9JfxbqPXPtOc',
                type: 'text',
              },
              '-LWDLWqS4F2rR-pCnau8': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Model number',
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWph9JfxbqPXPtOc',
                type: 'text',
              },
              '-LWDLWqVfd5VtAVQpjYM': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Quantity',
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWph9JfxbqPXPtOc',
                type: 'text',
              },
              '-LWDLWqXCfrxeOS_tWNl': {
                inputType: 'datetimePicker',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Date of production',
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWph9JfxbqPXPtOc',
                type: 'date',
              },
              '-LWDLWq_CZZeYcFlKvct': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Customer machine number',
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWph9JfxbqPXPtOc',
                type: 'text',
              },
              '-LWDLWqcZn8HyLMOPc_R': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'WA-Nr.',
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWph9JfxbqPXPtOc',
                type: 'text',
              },
              '-LWDLWqe_Y5J8rhLzZCW': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Serial Number',
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWph9JfxbqPXPtOc',
                type: 'text',
              },
              '-LWDLWqin6K-P2ftA1q_': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Service order prepared by (Hub name)',
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWph9JfxbqPXPtOc',
                type: 'text',
              },
              '-LWDLWqjuUX5RFIJTnUs': {
                inputType: 'datetimePicker',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Date',
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWph9JfxbqPXPtOc',
                type: 'date',
              },
            },
            jobUID: '-LWDLWkXieJQHfcxwMig',
            name: 'Basic info',
            productUID: '-LWDLWpbH3zRUp3NzQDR',
          },
          '-LWDLWqknSWdllch_3ty': {
            InputArray: ['-LWDLWqm4MVQwLxvYIyc', '-LWDLWqq91LTAZWZV9Pd'],
            Inputs: {
              '-LWDLWqm4MVQwLxvYIyc': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Photo Necessary',
                options: ['Yes', 'No'],
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWqknSWdllch_3ty',
                type: 'radio',
              },
              '-LWDLWqq91LTAZWZV9Pd': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Problem description',
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWqknSWdllch_3ty',
                type: 'text',
                summary: true,
              },
            },
            jobUID: '-LWDLWkXieJQHfcxwMig',
            name: 'Work to be done',
            productUID: '-LWDLWpbH3zRUp3NzQDR',
          },
          '-LWDLWqq91LTAZWZV9Pe': {
            InputArray: [
              '-LWDLWqsBpqDtH-AhkpA',
              '-LWDLWquS508swgdKrhN',
              '-LWDLWqx08x6ugULCVuD',
              '-LWDLWr-b0Jfuhl3JY20',
              '-LWDLWr1EBjG6t-wEemw',
              '-LWDLWr3p8IFEtiEREBV',
              '-LWDLWr60tmOGWYY7BQx',
              '-LWDLWr9u5NJbMzTPqVT',
              '-LWDLWrBdfRAwUsTVUgw',
              '-LWDLWrFaytNrH6x17xI',
              '-LWDLWrIqptoDJYrJSNv',
              '-LWDLWrLjJLc5bSiDSRZ',
              '-LWDLWrON74itinz1qB1',
              '-LWDLWrQ9TkPElm4zfIE',
            ],
            Inputs: {
              '-LWDLWqsBpqDtH-AhkpA': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Installed condition',
                options: ['Assembly', 'Complete Assembly'],
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWqq91LTAZWZV9Pe',
                type: 'radio',
              },
              '-LWDLWquS508swgdKrhN': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Grade of dirt',
                options: ['Low (clean)', 'Medium', 'Heavy'],
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWqq91LTAZWZV9Pe',
                type: 'radio',
              },
              '-LWDLWqx08x6ugULCVuD': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Grade of maintenance',
                options: ['Good', 'Medium', 'Poor'],
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWqq91LTAZWZV9Pe',
                type: 'radio',
              },
              '-LWDLWr-b0Jfuhl3JY20': {
                inputType: 'datetimePicker',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Last maintenance on (date)',
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWqq91LTAZWZV9Pe',
                type: 'date',
              },
              '-LWDLWr1EBjG6t-wEemw': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Room temp',
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWqq91LTAZWZV9Pe',
                type: 'text',
              },
              '-LWDLWr3p8IFEtiEREBV': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Temp inside enclosure',
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWqq91LTAZWZV9Pe',
                type: 'text',
              },
              '-LWDLWr60tmOGWYY7BQx': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Air Filter',
                options: ['Yes', 'No'],
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWqq91LTAZWZV9Pe',
                type: 'radio',
              },
              '-LWDLWr9u5NJbMzTPqVT': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Right air filter',
                options: ['Yes', 'No'],
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWqq91LTAZWZV9Pe',
                type: 'radio',
              },
              '-LWDLWrBdfRAwUsTVUgw': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Air filter dirty',
                options: ['Low (clean)', 'Medium', 'Heavy'],
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWqq91LTAZWZV9Pe',
                type: 'radio',
              },
              '-LWDLWrFaytNrH6x17xI': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Failure indication (display)',
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWqq91LTAZWZV9Pe',
                type: 'text',
              },
              '-LWDLWrIqptoDJYrJSNv': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Noise level',
                options: ['Normal', 'Noisy'],
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWqq91LTAZWZV9Pe',
                type: 'radio',
              },
              '-LWDLWrLjJLc5bSiDSRZ': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Vibration on each unit',
                options: ['Low', 'Medium', 'Heavy'],
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWqq91LTAZWZV9Pe',
                type: 'radio',
              },
              '-LWDLWrON74itinz1qB1': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Electrical connection, incoming voltage (V,Hz)',
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWqq91LTAZWZV9Pe',
                type: 'text',
              },
              '-LWDLWrQ9TkPElm4zfIE': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Comments',
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWqq91LTAZWZV9Pe',
                type: 'textarea',
              },
            },
            jobUID: '-LWDLWkXieJQHfcxwMig',
            name: 'Status prior to servicing',
            productUID: '-LWDLWpbH3zRUp3NzQDR',
          },
          '-LWDLWrR5m4jc8nPYL8L': {
            InputArray: [
              '-LWDLWrUgCjC4ftL5GQJ',
              '-LWDLWrWVqQ2PAEku-1v',
              '-LWDLWrX-I7RxwbMBHGo',
              '-LWDLWr_pTyFEEt2ySi7',
              '-LWDLWrbfzaWlQiqudCF',
              '-LWDLWrexJn5FMnNFLOM',
              '-LWDLWrhtcKVIzqnda8B',
              '-LWDLWrjmi8RpHRSzZ5o',
              '-LWDLWrkDHaFN0BFI31k',
              '-LWDLWrmaS34u2DvLv1m',
              '-LWDLWrp-KgIrYmOl7CZ',
              '-LWDLWrsYjQiQI1_4teQ',
              '-LWDLWru50-Z3DofokyC',
              '-LWDLWrwrZYiSt_yNq-B',
              '-LWDLWrz00w73ZY1sv0Z',
            ],
            Inputs: {
              '-LWDLWrUgCjC4ftL5GQJ': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Cooling unit changed complete',
                options: ['Yes', 'No'],
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWrR5m4jc8nPYL8L',
                type: 'radio',
              },
              '-LWDLWrWVqQ2PAEku-1v': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Refrigerant replaced',
                options: ['Yes', 'No'],
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWrR5m4jc8nPYL8L',
                type: 'radio',
              },
              '-LWDLWrX-I7RxwbMBHGo': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Compressor changed',
                options: ['Yes', 'No'],
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWrR5m4jc8nPYL8L',
                type: 'radio',
              },
              '-LWDLWr_pTyFEEt2ySi7': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Condenser fan changed',
                options: ['Yes', 'No'],
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWrR5m4jc8nPYL8L',
                type: 'radio',
              },
              '-LWDLWrbfzaWlQiqudCF': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Evaporator fan changed',
                options: ['Yes', 'No'],
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWrR5m4jc8nPYL8L',
                type: 'radio',
              },
              '-LWDLWrexJn5FMnNFLOM': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Condenser changed (data from component type plate necessary)',
                options: ['Yes', 'No'],
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWrR5m4jc8nPYL8L',
                type: 'radio',
              },
              '-LWDLWrhtcKVIzqnda8B': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Evaporator changed (data from component type plate necessary)',
                options: ['Yes', 'No'],
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWrR5m4jc8nPYL8L',
                type: 'radio',
              },
              '-LWDLWrjmi8RpHRSzZ5o': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name:
                  'Power box / Electronic board changed (data from component type plate necessary)',
                options: ['Yes', 'No'],
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWrR5m4jc8nPYL8L',
                type: 'radio',
              },
              '-LWDLWrkDHaFN0BFI31k': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Expansion valve changed (data from component type plate necessary)',
                options: ['Yes', 'No'],
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWrR5m4jc8nPYL8L',
                type: 'radio',
              },
              '-LWDLWrmaS34u2DvLv1m': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Pump changed (fluid sample necessary)',
                options: ['Yes', 'No'],
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWrR5m4jc8nPYL8L',
                type: 'radio',
              },
              '-LWDLWrp-KgIrYmOl7CZ': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Air filter changed',
                options: ['Yes', 'No'],
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWrR5m4jc8nPYL8L',
                type: 'radio',
              },
              '-LWDLWrsYjQiQI1_4teQ': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Sensor changed',
                options: ['B1', 'B2', 'B3', 'B4', 'B5', 'No'],
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWrR5m4jc8nPYL8L',
                type: 'radio',
              },
              '-LWDLWru50-Z3DofokyC': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Leakage in cooling circuit solved',
                options: [
                  'High pressure pipe',
                  'Condenser',
                  'Expansion valve',
                  'Evaporator',
                  'Compressor',
                  'No',
                ],
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWrR5m4jc8nPYL8L',
                type: 'radio',
              },
              '-LWDLWrwrZYiSt_yNq-B': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Append test plan',
                options: ['Yes', 'No'],
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWrR5m4jc8nPYL8L',
                type: 'radio',
              },
              '-LWDLWrz00w73ZY1sv0Z': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Measures taken',
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWrR5m4jc8nPYL8L',
                type: 'text',
              },
            },
            jobUID: '-LWDLWkXieJQHfcxwMig',
            name: 'Actions carried out',
            productUID: '-LWDLWpbH3zRUp3NzQDR',
          },
          '-LWDLWrz00w73ZY1sv0_': {
            InputArray: [
              '-LWDLWs0hLaNtNxiBjrm',
              '-LWDLWs2V5JMm-TvgOQP',
              '-LWDLWs5AIjS8Chm2TNh',
              '-LWDLWs745_4g0Ve4ME0',
              '-LWDLWsBH8lVFLCVnNVG',
              '-LWDLWsE9kctkih_stct',
            ],
            Inputs: {
              '-LWDLWs0hLaNtNxiBjrm': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Leakage in cooling circuit',
                options: ['Yes', 'No'],
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWrz00w73ZY1sv0_',
                type: 'radio',
              },
              '-LWDLWs2V5JMm-TvgOQP': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Maintenance interval overdue',
                options: ['Yes', 'No'],
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWrz00w73ZY1sv0_',
                type: 'radio',
              },
              '-LWDLWs5AIjS8Chm2TNh': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Application problem',
                options: ['Yes', 'No'],
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWrz00w73ZY1sv0_',
                type: 'radio',
              },
              '-LWDLWs745_4g0Ve4ME0': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Faulty installation',
                options: ['Yes', 'No'],
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWrz00w73ZY1sv0_',
                type: 'radio',
              },
              '-LWDLWsBH8lVFLCVnNVG': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Defective components',
                options: ['Yes', 'No'],
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWrz00w73ZY1sv0_',
                type: 'radio',
              },
              '-LWDLWsE9kctkih_stct': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Comments about failure',
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWrz00w73ZY1sv0_',
                type: 'textarea',
              },
            },
            jobUID: '-LWDLWkXieJQHfcxwMig',
            name: 'Cause of problem',
            productUID: '-LWDLWpbH3zRUp3NzQDR',
          },
          '-LWDLWsE9kctkih_stcu': {
            InputArray: [
              '-LWDLWsGaPrHjxx0nztq',
              '-LWDLWsIJjukuB8Qpls7',
              '-LWDLWsLSUCDJeDImmOu',
              '-LWDLWsNSHyey5sP_jXC',
              '-LWDLWsPUtgD87uKfaEG',
              '-LWDLWsRRyT_jkkAd5bp',
              '-LWDLWsTTlVLt9EbJoqo',
            ],
            Inputs: {
              '-LWDLWsGaPrHjxx0nztq': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Service successful',
                options: ['Yes', 'No'],
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWsE9kctkih_stcu',
                type: 'radio',
              },
              '-LWDLWsIJjukuB8Qpls7': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Further service required',
                options: ['Yes', 'No'],
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWsE9kctkih_stcu',
                type: 'radio',
              },
              '-LWDLWsLSUCDJeDImmOu': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'If yes, comments',
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWsE9kctkih_stcu',
                type: 'textarea',
              },
              '-LWDLWsNSHyey5sP_jXC': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Defective parts taken',
                options: ['Yes', 'No'],
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWsE9kctkih_stcu',
                type: 'radio',
              },
              '-LWDLWsPUtgD87uKfaEG': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'If yes, tracking information',
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWsE9kctkih_stcu',
                type: 'text',
              },
              '-LWDLWsRRyT_jkkAd5bp': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Working hours',
                options: ['Yes', 'No'],
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWsE9kctkih_stcu',
                type: 'radio',
              },
              '-LWDLWsTTlVLt9EbJoqo': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Comments',
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWsE9kctkih_stcu',
                type: 'textarea',
              },
            },
            jobUID: '-LWDLWkXieJQHfcxwMig',
            name: 'Summary',
            productUID: '-LWDLWpbH3zRUp3NzQDR',
          },
          '-LWDLWsTTlVLt9EbJoqp': {
            InputArray: [
              '-LWDLWsWPF5dX5M-5rJc',
              '-LWDLWsYdQQNKvNxWjC6',
              '-LWDLWsZyNOOR4ibuErr',
              '-LWDLWsaYBOhISkfkm9l',
            ],
            Inputs: {
              '-LWDLWsWPF5dX5M-5rJc': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Name of engineer',
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWsTTlVLt9EbJoqp',
                type: 'text',
              },
              '-LWDLWsYdQQNKvNxWjC6': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Name and signature of customer',
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWsTTlVLt9EbJoqp',
                type: 'text',
              },
              '-LWDLWsZyNOOR4ibuErr': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Report prepared by',
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWsTTlVLt9EbJoqp',
                type: 'text',
              },
              '-LWDLWsaYBOhISkfkm9l': {
                inputType: 'datetimePicker',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Date',
                productUID: '-LWDLWpbH3zRUp3NzQDR',
                sectionUID: '-LWDLWsTTlVLt9EbJoqp',
                type: 'date',
              },
            },
            jobUID: '-LWDLWkXieJQHfcxwMig',
            name: 'Responsible parties',
            productUID: '-LWDLWpbH3zRUp3NzQDR',
          },
        },
        jobUID: '-LWDLWkXieJQHfcxwMig',
        name: 'Chiller',
      },
      '-LWDLWsaYBOhISkfkm9m': {
        SectionArray: [
          '-LWDLWsbkqJZLQunRrQN',
          '-LWDLWsjA550-tUhJV3e',
          '-LWDLWtcSgCsjlphds2J',
          '-LWDLWtgifhyAlLQErXn',
          '-LWDLWuDcSh9evYcuHw8',
          '-LWDLWuhp4aYAj2nwxsb',
          '-LWDLWutPIBFLmV1IKlG',
          '-LWDLWv6mfVNAdIHNaNu',
        ],
        Sections: {
          '-LWDLWsbkqJZLQunRrQN': {
            InputArray: ['-LWDLWscJNo0t8gA6LGY', '-LWDLWsg7i6I9D0y9Oil', '-LWDLWsiDpb8lN3_9nLE'],
            Inputs: {
              '-LWDLWscJNo0t8gA6LGY': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Ticket number',
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWsbkqJZLQunRrQN',
                type: 'text',
              },
              '-LWDLWsg7i6I9D0y9Oil': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Customer reference number',
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWsbkqJZLQunRrQN',
                type: 'text',
              },
              '-LWDLWsiDpb8lN3_9nLE': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Purchase doc number',
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWsbkqJZLQunRrQN',
                type: 'text',
              },
            },
            jobUID: '-LWDLWkXieJQHfcxwMig',
            name: 'Service Numbers',
            productUID: '-LWDLWsaYBOhISkfkm9m',
          },
          '-LWDLWsjA550-tUhJV3e': {
            InputArray: [
              '-LWDLWslsVj0di209rCr',
              '-LWDLWsnTHJzfSUCD8xf',
              '-LWDLWspfkzRb_xC6yVQ',
              '-LWDLWsrbxjTSpsPVZ7K',
              '-LWDLWsuBRiFAE8PGNAI',
              '-LWDLWswL65YB1uo20jN',
              '-LWDLWsyZJX9aprWRDjY',
              '-LWDLWt-jDSxfk67OPlN',
              '-LWDLWt1HkyQdvV002aW',
              '-LWDLWt42Ofw0SC80XFI',
              '-LWDLWt6BqKPQZjnI4Vi',
              '-LWDLWt8uh9U_HmNPnqw',
              '-LWDLWtBdiohvsdXdO4N',
              '-LWDLWtD4ypulH6Np4he',
              '-LWDLWtF0qNkLt6pqPsm',
              '-LWDLWtHt9okV9hs0VhU',
              '-LWDLWtJKhe7W3CauJl2',
              '-LWDLWtLTKT2sJ6t-RdW',
              '-LWDLWtMFPyRRhGOXkOU',
              '-LWDLWtP45G2weKfBKIW',
              '-LWDLWtR1Py5HThi0TJa',
              '-LWDLWtUfEUkj2AzL9Ah',
              '-LWDLWtXOOgcMn3L-Oz-',
              '-LWDLWtY22NyX9pqkNy-',
              '-LWDLWtaejUk_vlmLpCI',
              '-LWDLWtcSgCsjlphds2I',
            ],
            Inputs: {
              '-LWDLWslsVj0di209rCr': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Company name - Rittal Customer',
                options: ['Install', 'Maintenance', 'Repair', 'Assembly'],
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWsjA550-tUhJV3e',
                type: 'radio',
              },
              '-LWDLWsnTHJzfSUCD8xf': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Contact person - Rittal Customer',
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWsjA550-tUhJV3e',
                type: 'text',
              },
              '-LWDLWspfkzRb_xC6yVQ': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Customer - Rittal Customer',
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWsjA550-tUhJV3e',
                type: 'text',
              },
              '-LWDLWsrbxjTSpsPVZ7K': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Telephone number - Rittal Customer',
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWsjA550-tUhJV3e',
                type: 'text',
              },
              '-LWDLWsuBRiFAE8PGNAI': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Address - Rittal Customer',
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWsjA550-tUhJV3e',
                type: 'text',
              },
              '-LWDLWswL65YB1uo20jN': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Email - Rittal Customer',
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWsjA550-tUhJV3e',
                type: 'text',
              },
              '-LWDLWsyZJX9aprWRDjY': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Company name - Service carried out by (company)',
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWsjA550-tUhJV3e',
                type: 'text',
              },
              '-LWDLWt-jDSxfk67OPlN': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Contact person - Service carried out by (company)',
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWsjA550-tUhJV3e',
                type: 'text',
              },
              '-LWDLWt1HkyQdvV002aW': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Customer - Service carried out by (company)',
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWsjA550-tUhJV3e',
                type: 'text',
              },
              '-LWDLWt42Ofw0SC80XFI': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Telephone number - Service carried out by (company)',
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWsjA550-tUhJV3e',
                type: 'text',
              },
              '-LWDLWt6BqKPQZjnI4Vi': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Address - Service carried out by (company)',
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWsjA550-tUhJV3e',
                type: 'text',
              },
              '-LWDLWt8uh9U_HmNPnqw': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Email - Service carried out by (company)',
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWsjA550-tUhJV3e',
                type: 'text',
              },
              '-LWDLWtBdiohvsdXdO4N': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Company name - Service carried out at (location)',
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWsjA550-tUhJV3e',
                type: 'text',
              },
              '-LWDLWtD4ypulH6Np4he': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Contact person - Service carried out at (location)',
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWsjA550-tUhJV3e',
                type: 'text',
              },
              '-LWDLWtF0qNkLt6pqPsm': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Customer - Service carried out at (location)',
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWsjA550-tUhJV3e',
                type: 'text',
              },
              '-LWDLWtHt9okV9hs0VhU': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Telephone number - Service carried out at (location)',
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWsjA550-tUhJV3e',
                type: 'text',
              },
              '-LWDLWtJKhe7W3CauJl2': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Address - Service carried out at (location)',
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWsjA550-tUhJV3e',
                type: 'text',
              },
              '-LWDLWtLTKT2sJ6t-RdW': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Email - Service carried out at (location)',
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWsjA550-tUhJV3e',
                type: 'text',
              },
              '-LWDLWtMFPyRRhGOXkOU': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Model number',
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWsjA550-tUhJV3e',
                type: 'text',
              },
              '-LWDLWtP45G2weKfBKIW': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Quantity',
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWsjA550-tUhJV3e',
                type: 'text',
              },
              '-LWDLWtR1Py5HThi0TJa': {
                inputType: 'datetimePicker',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Date of production',
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWsjA550-tUhJV3e',
                type: 'date',
              },
              '-LWDLWtUfEUkj2AzL9Ah': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Customer machine number',
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWsjA550-tUhJV3e',
                type: 'text',
              },
              '-LWDLWtXOOgcMn3L-Oz-': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'WA-Nr.',
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWsjA550-tUhJV3e',
                type: 'text',
              },
              '-LWDLWtY22NyX9pqkNy-': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Serial Number',
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWsjA550-tUhJV3e',
                type: 'text',
              },
              '-LWDLWtaejUk_vlmLpCI': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Service order prepared by (Hub name)',
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWsjA550-tUhJV3e',
                type: 'text',
              },
              '-LWDLWtcSgCsjlphds2I': {
                inputType: 'datetimePicker',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Date',
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWsjA550-tUhJV3e',
                type: 'date',
              },
            },
            jobUID: '-LWDLWkXieJQHfcxwMig',
            name: 'Basic info',
            productUID: '-LWDLWsaYBOhISkfkm9m',
          },
          '-LWDLWtcSgCsjlphds2J': {
            InputArray: ['-LWDLWtfbiNRVnufOg4d', '-LWDLWtgifhyAlLQErXm'],
            Inputs: {
              '-LWDLWtfbiNRVnufOg4d': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Photo Necessary',
                options: ['Yes', 'No'],
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWtcSgCsjlphds2J',
                type: 'radio',
              },
              '-LWDLWtgifhyAlLQErXm': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Problem description',
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWtcSgCsjlphds2J',
                type: 'text',
                summary: true,
              },
            },
            jobUID: '-LWDLWkXieJQHfcxwMig',
            name: 'Work to be done',
            productUID: '-LWDLWsaYBOhISkfkm9m',
          },
          '-LWDLWtgifhyAlLQErXn': {
            InputArray: [
              '-LWDLWtjn2Kb9FBWCWbl',
              '-LWDLWtqMCWSbFlWc33q',
              '-LWDLWttejKkaQ-gOw7R',
              '-LWDLWtuMVhGVZLDpKw4',
              '-LWDLWtwubkZyWbRmE-u',
              '-LWDLWtzxyD71x-xFo-k',
              '-LWDLWu-7EhbRtAPZCCW',
              '-LWDLWu1oWnIp0JRkhdA',
              '-LWDLWu3gyhsUj3EsA_i',
              '-LWDLWu5KvpLXBdEjBwx',
              '-LWDLWu6WdVwjfUIn5o8',
              '-LWDLWu8SRMUVq_a9ww_',
              '-LWDLWuAqn5LfT9VUXjm',
              '-LWDLWuCzMC3jH2Fb3Uq',
            ],
            Inputs: {
              '-LWDLWtjn2Kb9FBWCWbl': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Installed condition',
                options: ['Assembly', 'Complete Assembly'],
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWtgifhyAlLQErXn',
                type: 'radio',
              },
              '-LWDLWtqMCWSbFlWc33q': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Grade of dirt',
                options: ['Low (clean)', 'Medium', 'Heavy'],
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWtgifhyAlLQErXn',
                type: 'radio',
              },
              '-LWDLWttejKkaQ-gOw7R': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Grade of maintenance',
                options: ['Good', 'Medium', 'Poor'],
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWtgifhyAlLQErXn',
                type: 'radio',
              },
              '-LWDLWtuMVhGVZLDpKw4': {
                inputType: 'datetimePicker',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Last maintenance on (date)',
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWtgifhyAlLQErXn',
                type: 'date',
              },
              '-LWDLWtwubkZyWbRmE-u': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Room temp',
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWtgifhyAlLQErXn',
                type: 'text',
              },
              '-LWDLWtzxyD71x-xFo-k': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Temp inside enclosure',
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWtgifhyAlLQErXn',
                type: 'text',
              },
              '-LWDLWu-7EhbRtAPZCCW': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Air Filter',
                options: ['Yes', 'No'],
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWtgifhyAlLQErXn',
                type: 'radio',
              },
              '-LWDLWu1oWnIp0JRkhdA': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Right air filter',
                options: ['Yes', 'No'],
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWtgifhyAlLQErXn',
                type: 'radio',
              },
              '-LWDLWu3gyhsUj3EsA_i': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Air filter dirty',
                options: ['Low (clean)', 'Medium', 'Heavy'],
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWtgifhyAlLQErXn',
                type: 'radio',
              },
              '-LWDLWu5KvpLXBdEjBwx': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Failure indication (display)',
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWtgifhyAlLQErXn',
                type: 'text',
              },
              '-LWDLWu6WdVwjfUIn5o8': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Noise level',
                options: ['Normal', 'Noisy'],
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWtgifhyAlLQErXn',
                type: 'radio',
              },
              '-LWDLWu8SRMUVq_a9ww_': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Vibration on each unit',
                options: ['Low', 'Medium', 'Heavy'],
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWtgifhyAlLQErXn',
                type: 'radio',
              },
              '-LWDLWuAqn5LfT9VUXjm': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Electrical connection, incoming voltage (V,Hz)',
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWtgifhyAlLQErXn',
                type: 'text',
              },
              '-LWDLWuCzMC3jH2Fb3Uq': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Comments',
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWtgifhyAlLQErXn',
                type: 'textarea',
              },
            },
            jobUID: '-LWDLWkXieJQHfcxwMig',
            name: 'Status prior to servicing',
            productUID: '-LWDLWsaYBOhISkfkm9m',
          },
          '-LWDLWuDcSh9evYcuHw8': {
            InputArray: [
              '-LWDLWuFsTwq1KjjQQi9',
              '-LWDLWuHPNbX5NlUSAK0',
              '-LWDLWuJ2U6XToSYTBwQ',
              '-LWDLWuLacYc7TzdOshO',
              '-LWDLWuNnWkdkzee_Q-Y',
              '-LWDLWuP81MSEawjuhw7',
              '-LWDLWuRwI699xWZgZy2',
              '-LWDLWuTOtHJKzBQJHjR',
              '-LWDLWuWJ5XIzLp5GMIK',
              '-LWDLWuXNzBd1OxKYIf8',
              '-LWDLWuZYx4wlYTAGKSO',
              '-LWDLWuaqKEJWDk768Ir',
              '-LWDLWuc8oUg45Cux9ua',
              '-LWDLWueCYnbhNxK6Wul',
              '-LWDLWughAliop2hfJW3',
            ],
            Inputs: {
              '-LWDLWuFsTwq1KjjQQi9': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Cooling unit changed complete',
                options: ['Yes', 'No'],
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWuDcSh9evYcuHw8',
                type: 'radio',
              },
              '-LWDLWuHPNbX5NlUSAK0': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Refrigerant replaced',
                options: ['Yes', 'No'],
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWuDcSh9evYcuHw8',
                type: 'radio',
              },
              '-LWDLWuJ2U6XToSYTBwQ': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Compressor changed',
                options: ['Yes', 'No'],
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWuDcSh9evYcuHw8',
                type: 'radio',
              },
              '-LWDLWuLacYc7TzdOshO': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Condenser fan changed',
                options: ['Yes', 'No'],
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWuDcSh9evYcuHw8',
                type: 'radio',
              },
              '-LWDLWuNnWkdkzee_Q-Y': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Evaporator fan changed',
                options: ['Yes', 'No'],
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWuDcSh9evYcuHw8',
                type: 'radio',
              },
              '-LWDLWuP81MSEawjuhw7': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Condenser changed (data from component type plate necessary)',
                options: ['Yes', 'No'],
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWuDcSh9evYcuHw8',
                type: 'radio',
              },
              '-LWDLWuRwI699xWZgZy2': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Evaporator changed (data from component type plate necessary)',
                options: ['Yes', 'No'],
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWuDcSh9evYcuHw8',
                type: 'radio',
              },
              '-LWDLWuTOtHJKzBQJHjR': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name:
                  'Power box / Electronic board changed (data from component type plate necessary)',
                options: ['Yes', 'No'],
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWuDcSh9evYcuHw8',
                type: 'radio',
              },
              '-LWDLWuWJ5XIzLp5GMIK': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Expansion valve changed (data from component type plate necessary)',
                options: ['Yes', 'No'],
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWuDcSh9evYcuHw8',
                type: 'radio',
              },
              '-LWDLWuXNzBd1OxKYIf8': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Pump changed (fluid sample necessary)',
                options: ['Yes', 'No'],
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWuDcSh9evYcuHw8',
                type: 'radio',
              },
              '-LWDLWuZYx4wlYTAGKSO': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Air filter changed',
                options: ['Yes', 'No'],
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWuDcSh9evYcuHw8',
                type: 'radio',
              },
              '-LWDLWuaqKEJWDk768Ir': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Sensor changed',
                options: ['B1', 'B2', 'B3', 'B4', 'B5', 'No'],
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWuDcSh9evYcuHw8',
                type: 'radio',
              },
              '-LWDLWuc8oUg45Cux9ua': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Leakage in cooling circuit solved',
                options: [
                  'High pressure pipe',
                  'Condenser',
                  'Expansion valve',
                  'Evaporator',
                  'Compressor',
                  'No',
                ],
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWuDcSh9evYcuHw8',
                type: 'radio',
              },
              '-LWDLWueCYnbhNxK6Wul': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Append test plan',
                options: ['Yes', 'No'],
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWuDcSh9evYcuHw8',
                type: 'radio',
              },
              '-LWDLWughAliop2hfJW3': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Measures taken',
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWuDcSh9evYcuHw8',
                type: 'text',
              },
            },
            jobUID: '-LWDLWkXieJQHfcxwMig',
            name: 'Actions carried out',
            productUID: '-LWDLWsaYBOhISkfkm9m',
          },
          '-LWDLWuhp4aYAj2nwxsb': {
            InputArray: [
              '-LWDLWuj2SaKkDiTEAYv',
              '-LWDLWulhw5kOEp7B9CH',
              '-LWDLWunJrSUVB8eNMbO',
              '-LWDLWuphdKI2SDXlYjg',
              '-LWDLWurG7b_fILTtchC',
              '-LWDLWutPIBFLmV1IKlF',
            ],
            Inputs: {
              '-LWDLWuj2SaKkDiTEAYv': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Leakage in cooling circuit',
                options: ['Yes', 'No'],
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWuhp4aYAj2nwxsb',
                type: 'radio',
              },
              '-LWDLWulhw5kOEp7B9CH': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Maintenance interval overdue',
                options: ['Yes', 'No'],
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWuhp4aYAj2nwxsb',
                type: 'radio',
              },
              '-LWDLWunJrSUVB8eNMbO': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Application problem',
                options: ['Yes', 'No'],
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWuhp4aYAj2nwxsb',
                type: 'radio',
              },
              '-LWDLWuphdKI2SDXlYjg': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Faulty installation',
                options: ['Yes', 'No'],
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWuhp4aYAj2nwxsb',
                type: 'radio',
              },
              '-LWDLWurG7b_fILTtchC': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Defective components',
                options: ['Yes', 'No'],
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWuhp4aYAj2nwxsb',
                type: 'radio',
              },
              '-LWDLWutPIBFLmV1IKlF': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Comments about failure',
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWuhp4aYAj2nwxsb',
                type: 'textarea',
              },
            },
            jobUID: '-LWDLWkXieJQHfcxwMig',
            name: 'Cause of problem',
            productUID: '-LWDLWsaYBOhISkfkm9m',
          },
          '-LWDLWutPIBFLmV1IKlG': {
            InputArray: [
              '-LWDLWuv8Th37RzWkIzT',
              '-LWDLWuxqyq8idIy33QW',
              '-LWDLWuz7dbBf1eLwWoF',
              '-LWDLWv02EdRkemsaOi2',
              '-LWDLWv2-5AVsxQ8vv6L',
              '-LWDLWv4ykBzJwtLIDz6',
              '-LWDLWv6mfVNAdIHNaNt',
            ],
            Inputs: {
              '-LWDLWuv8Th37RzWkIzT': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Service successful',
                options: ['Yes', 'No'],
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWutPIBFLmV1IKlG',
                type: 'radio',
              },
              '-LWDLWuxqyq8idIy33QW': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Further service required',
                options: ['Yes', 'No'],
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWutPIBFLmV1IKlG',
                type: 'radio',
              },
              '-LWDLWuz7dbBf1eLwWoF': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'If yes, comments',
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWutPIBFLmV1IKlG',
                type: 'textarea',
              },
              '-LWDLWv02EdRkemsaOi2': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Defective parts taken',
                options: ['Yes', 'No'],
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWutPIBFLmV1IKlG',
                type: 'radio',
              },
              '-LWDLWv2-5AVsxQ8vv6L': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'If yes, tracking information',
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWutPIBFLmV1IKlG',
                type: 'text',
              },
              '-LWDLWv4ykBzJwtLIDz6': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Working hours',
                options: ['Yes', 'No'],
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWutPIBFLmV1IKlG',
                type: 'radio',
              },
              '-LWDLWv6mfVNAdIHNaNt': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Comments',
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWutPIBFLmV1IKlG',
                type: 'textarea',
              },
            },
            jobUID: '-LWDLWkXieJQHfcxwMig',
            name: 'Summary',
            productUID: '-LWDLWsaYBOhISkfkm9m',
          },
          '-LWDLWv6mfVNAdIHNaNu': {
            InputArray: [
              '-LWDLWv7rTW8pGBozD_g',
              '-LWDLWv96plEq6n1F-BT',
              '-LWDLWvB5FcBgkNrqIxm',
              '-LWDLWvDEvXiW3g2r-1u',
            ],
            Inputs: {
              '-LWDLWv7rTW8pGBozD_g': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Name of engineer',
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWv6mfVNAdIHNaNu',
                type: 'text',
              },
              '-LWDLWv96plEq6n1F-BT': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Name and signature of customer',
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWv6mfVNAdIHNaNu',
                type: 'text',
              },
              '-LWDLWvB5FcBgkNrqIxm': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Report prepared by',
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWv6mfVNAdIHNaNu',
                type: 'text',
              },
              '-LWDLWvDEvXiW3g2r-1u': {
                inputType: 'datetimePicker',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Date',
                productUID: '-LWDLWsaYBOhISkfkm9m',
                sectionUID: '-LWDLWv6mfVNAdIHNaNu',
                type: 'date',
              },
            },
            jobUID: '-LWDLWkXieJQHfcxwMig',
            name: 'Responsible parties',
            productUID: '-LWDLWsaYBOhISkfkm9m',
          },
        },
        jobUID: '-LWDLWkXieJQHfcxwMig',
        name: 'LCP-CW',
      },
      '-LWDLWvE0cpNQaAX32Ni': {
        SectionArray: [
          '-LWDLWvE0cpNQaAX32Nj',
          '-LWDLWvJ7szcyRvvyBfX',
          '-LWDLWw6aMV2SHtWT1Sb',
          '-LWDLWwCfn0HM5ncv9ov',
          '-LWDLWwgvq5VhEsS8s_A',
          '-LWDLWx9x463MLvrbSIE',
          '-LWDLWxK5g7M1mVgWkTy',
          '-LWDLWxZKUt12VTf7ebZ',
        ],
        Sections: {
          '-LWDLWvE0cpNQaAX32Nj': {
            InputArray: ['-LWDLWvGZDqAiZUcin-T', '-LWDLWvHBizzhRgUw4Lc', '-LWDLWvIBjKXfpNEfUPC'],
            Inputs: {
              '-LWDLWvGZDqAiZUcin-T': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Ticket number',
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWvE0cpNQaAX32Nj',
                type: 'text',
              },
              '-LWDLWvHBizzhRgUw4Lc': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Customer reference number',
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWvE0cpNQaAX32Nj',
                type: 'text',
              },
              '-LWDLWvIBjKXfpNEfUPC': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Purchase doc number',
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWvE0cpNQaAX32Nj',
                type: 'text',
              },
            },
            jobUID: '-LWDLWkXieJQHfcxwMig',
            name: 'Service Numbers',
            productUID: '-LWDLWvE0cpNQaAX32Ni',
          },
          '-LWDLWvJ7szcyRvvyBfX': {
            InputArray: [
              '-LWDLWvLhgEpu1mZe-aA',
              '-LWDLWvNCBG7n28_gk3j',
              '-LWDLWvPu5WlGn85NapK',
              '-LWDLWvRDTYXAi0ZYkVr',
              '-LWDLWvTbmd5thd-3hVo',
              '-LWDLWvUXUhftHLiVBt9',
              '-LWDLWvX3DP4hlj8oUzI',
              '-LWDLWvZzl1VTqIbdyIT',
              '-LWDLWvaKLadX5gxibY3',
              '-LWDLWvcTRCv6-UxpgII',
              '-LWDLWvd2pWOGcvw9HxZ',
              '-LWDLWvf0rq8lvDkPJFZ',
              '-LWDLWvh0Y2CYS98Cuv6',
              '-LWDLWvjR9QfxxD1qRoM',
              '-LWDLWvl8pv3UK-Cqjm-',
              '-LWDLWvn81DLOOSnF33u',
              '-LWDLWvp5mJQ8hZmIYLb',
              '-LWDLWvr7_jYEfDPSM-g',
              '-LWDLWvtF-Y6dpJ5Z_ue',
              '-LWDLWvv7l_YeRGIj9F3',
              '-LWDLWvwJNukgQzTWZ8L',
              '-LWDLWvyM906jX-9zTDN',
              '-LWDLWw0gzPoIheLKxcv',
              '-LWDLWw1qkfk632rdQtX',
              '-LWDLWw4V0CsnEJZJKGj',
              '-LWDLWw6aMV2SHtWT1Sa',
            ],
            Inputs: {
              '-LWDLWvLhgEpu1mZe-aA': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Company name - Rittal Customer',
                options: ['Install', 'Maintenance', 'Repair', 'Assembly'],
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWvJ7szcyRvvyBfX',
                type: 'radio',
              },
              '-LWDLWvNCBG7n28_gk3j': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Contact person - Rittal Customer',
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWvJ7szcyRvvyBfX',
                type: 'text',
              },
              '-LWDLWvPu5WlGn85NapK': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Customer - Rittal Customer',
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWvJ7szcyRvvyBfX',
                type: 'text',
              },
              '-LWDLWvRDTYXAi0ZYkVr': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Telephone number - Rittal Customer',
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWvJ7szcyRvvyBfX',
                type: 'text',
              },
              '-LWDLWvTbmd5thd-3hVo': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Address - Rittal Customer',
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWvJ7szcyRvvyBfX',
                type: 'text',
              },
              '-LWDLWvUXUhftHLiVBt9': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Email - Rittal Customer',
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWvJ7szcyRvvyBfX',
                type: 'text',
              },
              '-LWDLWvX3DP4hlj8oUzI': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Company name - Service carried out by (company)',
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWvJ7szcyRvvyBfX',
                type: 'text',
              },
              '-LWDLWvZzl1VTqIbdyIT': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Contact person - Service carried out by (company)',
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWvJ7szcyRvvyBfX',
                type: 'text',
              },
              '-LWDLWvaKLadX5gxibY3': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Customer - Service carried out by (company)',
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWvJ7szcyRvvyBfX',
                type: 'text',
              },
              '-LWDLWvcTRCv6-UxpgII': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Telephone number - Service carried out by (company)',
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWvJ7szcyRvvyBfX',
                type: 'text',
              },
              '-LWDLWvd2pWOGcvw9HxZ': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Address - Service carried out by (company)',
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWvJ7szcyRvvyBfX',
                type: 'text',
              },
              '-LWDLWvf0rq8lvDkPJFZ': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Email - Service carried out by (company)',
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWvJ7szcyRvvyBfX',
                type: 'text',
              },
              '-LWDLWvh0Y2CYS98Cuv6': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Company name - Service carried out at (location)',
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWvJ7szcyRvvyBfX',
                type: 'text',
              },
              '-LWDLWvjR9QfxxD1qRoM': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Contact person - Service carried out at (location)',
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWvJ7szcyRvvyBfX',
                type: 'text',
              },
              '-LWDLWvl8pv3UK-Cqjm-': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Customer - Service carried out at (location)',
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWvJ7szcyRvvyBfX',
                type: 'text',
              },
              '-LWDLWvn81DLOOSnF33u': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Telephone number - Service carried out at (location)',
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWvJ7szcyRvvyBfX',
                type: 'text',
              },
              '-LWDLWvp5mJQ8hZmIYLb': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Address - Service carried out at (location)',
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWvJ7szcyRvvyBfX',
                type: 'text',
              },
              '-LWDLWvr7_jYEfDPSM-g': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Email - Service carried out at (location)',
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWvJ7szcyRvvyBfX',
                type: 'text',
              },
              '-LWDLWvtF-Y6dpJ5Z_ue': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Model number',
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWvJ7szcyRvvyBfX',
                type: 'text',
              },
              '-LWDLWvv7l_YeRGIj9F3': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Quantity',
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWvJ7szcyRvvyBfX',
                type: 'text',
              },
              '-LWDLWvwJNukgQzTWZ8L': {
                inputType: 'datetimePicker',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Date of production',
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWvJ7szcyRvvyBfX',
                type: 'date',
              },
              '-LWDLWvyM906jX-9zTDN': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Customer machine number',
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWvJ7szcyRvvyBfX',
                type: 'text',
              },
              '-LWDLWw0gzPoIheLKxcv': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'WA-Nr.',
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWvJ7szcyRvvyBfX',
                type: 'text',
              },
              '-LWDLWw1qkfk632rdQtX': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Serial Number',
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWvJ7szcyRvvyBfX',
                type: 'text',
              },
              '-LWDLWw4V0CsnEJZJKGj': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Service order prepared by (Hub name)',
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWvJ7szcyRvvyBfX',
                type: 'text',
              },
              '-LWDLWw6aMV2SHtWT1Sa': {
                inputType: 'datetimePicker',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Date',
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWvJ7szcyRvvyBfX',
                type: 'date',
              },
            },
            jobUID: '-LWDLWkXieJQHfcxwMig',
            name: 'Basic info',
            productUID: '-LWDLWvE0cpNQaAX32Ni',
          },
          '-LWDLWw6aMV2SHtWT1Sb': {
            InputArray: ['-LWDLWw9n2fDWs47CoAb', '-LWDLWwCfn0HM5ncv9ou'],
            Inputs: {
              '-LWDLWw9n2fDWs47CoAb': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Photo Necessary',
                options: ['Yes', 'No'],
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWw6aMV2SHtWT1Sb',
                type: 'radio',
              },
              '-LWDLWwCfn0HM5ncv9ou': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Problem description',
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWw6aMV2SHtWT1Sb',
                type: 'text',
                summary: true,
              },
            },
            jobUID: '-LWDLWkXieJQHfcxwMig',
            name: 'Work to be done',
            productUID: '-LWDLWvE0cpNQaAX32Ni',
          },
          '-LWDLWwCfn0HM5ncv9ov': {
            InputArray: [
              '-LWDLWwEDarfhWSZBJ5L',
              '-LWDLWwHkSfjq884d9-l',
              '-LWDLWwKAI78uEd3REbk',
              '-LWDLWwMuzFjTgKYcqFB',
              '-LWDLWwOKyY6CtEo5EOb',
              '-LWDLWwPFQKG22hwe9Hx',
              '-LWDLWwRrRkHV7W1ksN_',
              '-LWDLWwUVPsKJMjwH6Kj',
              '-LWDLWwWEuob20zTKhPf',
              '-LWDLWwXVtV9bowFKoco',
              '-LWDLWw_73p4x-b9bJSu',
              '-LWDLWwczfoPbdqAGXEY',
              '-LWDLWweujP3ZmkwtMDK',
              '-LWDLWwgvq5VhEsS8s_9',
            ],
            Inputs: {
              '-LWDLWwEDarfhWSZBJ5L': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Installed condition',
                options: ['Assembly', 'Complete Assembly'],
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWwCfn0HM5ncv9ov',
                type: 'radio',
              },
              '-LWDLWwHkSfjq884d9-l': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Grade of dirt',
                options: ['Low (clean)', 'Medium', 'Heavy'],
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWwCfn0HM5ncv9ov',
                type: 'radio',
              },
              '-LWDLWwKAI78uEd3REbk': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Grade of maintenance',
                options: ['Good', 'Medium', 'Poor'],
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWwCfn0HM5ncv9ov',
                type: 'radio',
              },
              '-LWDLWwMuzFjTgKYcqFB': {
                inputType: 'datetimePicker',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Last maintenance on (date)',
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWwCfn0HM5ncv9ov',
                type: 'date',
              },
              '-LWDLWwOKyY6CtEo5EOb': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Room temp',
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWwCfn0HM5ncv9ov',
                type: 'text',
              },
              '-LWDLWwPFQKG22hwe9Hx': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Temp inside enclosure',
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWwCfn0HM5ncv9ov',
                type: 'text',
              },
              '-LWDLWwRrRkHV7W1ksN_': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Air Filter',
                options: ['Yes', 'No'],
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWwCfn0HM5ncv9ov',
                type: 'radio',
              },
              '-LWDLWwUVPsKJMjwH6Kj': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Right air filter',
                options: ['Yes', 'No'],
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWwCfn0HM5ncv9ov',
                type: 'radio',
              },
              '-LWDLWwWEuob20zTKhPf': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Air filter dirty',
                options: ['Low (clean)', 'Medium', 'Heavy'],
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWwCfn0HM5ncv9ov',
                type: 'radio',
              },
              '-LWDLWwXVtV9bowFKoco': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Failure indication (display)',
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWwCfn0HM5ncv9ov',
                type: 'text',
              },
              '-LWDLWw_73p4x-b9bJSu': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Noise level',
                options: ['Normal', 'Noisy'],
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWwCfn0HM5ncv9ov',
                type: 'radio',
              },
              '-LWDLWwczfoPbdqAGXEY': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Vibration on each unit',
                options: ['Low', 'Medium', 'Heavy'],
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWwCfn0HM5ncv9ov',
                type: 'radio',
              },
              '-LWDLWweujP3ZmkwtMDK': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Electrical connection, incoming voltage (V,Hz)',
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWwCfn0HM5ncv9ov',
                type: 'text',
              },
              '-LWDLWwgvq5VhEsS8s_9': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Comments',
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWwCfn0HM5ncv9ov',
                type: 'textarea',
              },
            },
            jobUID: '-LWDLWkXieJQHfcxwMig',
            name: 'Status prior to servicing',
            productUID: '-LWDLWvE0cpNQaAX32Ni',
          },
          '-LWDLWwgvq5VhEsS8s_A': {
            InputArray: [
              '-LWDLWwiKQ5nEcMZhHXi',
              '-LWDLWwkl_KM203uDVPr',
              '-LWDLWwmK2vxH0GXWyND',
              '-LWDLWwo8HoYU-CVGzJ9',
              '-LWDLWwqc1QaINKiqdAK',
              '-LWDLWwsrlOeNivzDuHI',
              '-LWDLWwuzlm7Uchp6lul',
              '-LWDLWwwPLuSM_UZd2wb',
              '-LWDLWwxbXLWUH8zFfP0',
              '-LWDLWx-tE3xzn4ydVXp',
              '-LWDLWx1eFW8d6xejRxv',
              '-LWDLWx2PTdmaxPAmX5t',
              '-LWDLWx55Ug5d6W6aYEU',
              '-LWDLWx6JRw2W8fhev6k',
              '-LWDLWx8uMhiuFlc9wm8',
            ],
            Inputs: {
              '-LWDLWwiKQ5nEcMZhHXi': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Cooling unit changed complete',
                options: ['Yes', 'No'],
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWwgvq5VhEsS8s_A',
                type: 'radio',
              },
              '-LWDLWwkl_KM203uDVPr': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Refrigerant replaced',
                options: ['Yes', 'No'],
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWwgvq5VhEsS8s_A',
                type: 'radio',
              },
              '-LWDLWwmK2vxH0GXWyND': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Compressor changed',
                options: ['Yes', 'No'],
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWwgvq5VhEsS8s_A',
                type: 'radio',
              },
              '-LWDLWwo8HoYU-CVGzJ9': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Condenser fan changed',
                options: ['Yes', 'No'],
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWwgvq5VhEsS8s_A',
                type: 'radio',
              },
              '-LWDLWwqc1QaINKiqdAK': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Evaporator fan changed',
                options: ['Yes', 'No'],
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWwgvq5VhEsS8s_A',
                type: 'radio',
              },
              '-LWDLWwsrlOeNivzDuHI': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Condenser changed (data from component type plate necessary)',
                options: ['Yes', 'No'],
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWwgvq5VhEsS8s_A',
                type: 'radio',
              },
              '-LWDLWwuzlm7Uchp6lul': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Evaporator changed (data from component type plate necessary)',
                options: ['Yes', 'No'],
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWwgvq5VhEsS8s_A',
                type: 'radio',
              },
              '-LWDLWwwPLuSM_UZd2wb': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name:
                  'Power box / Electronic board changed (data from component type plate necessary)',
                options: ['Yes', 'No'],
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWwgvq5VhEsS8s_A',
                type: 'radio',
              },
              '-LWDLWwxbXLWUH8zFfP0': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Expansion valve changed (data from component type plate necessary)',
                options: ['Yes', 'No'],
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWwgvq5VhEsS8s_A',
                type: 'radio',
              },
              '-LWDLWx-tE3xzn4ydVXp': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Pump changed (fluid sample necessary)',
                options: ['Yes', 'No'],
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWwgvq5VhEsS8s_A',
                type: 'radio',
              },
              '-LWDLWx1eFW8d6xejRxv': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Air filter changed',
                options: ['Yes', 'No'],
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWwgvq5VhEsS8s_A',
                type: 'radio',
              },
              '-LWDLWx2PTdmaxPAmX5t': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Sensor changed',
                options: ['B1', 'B2', 'B3', 'B4', 'B5', 'No'],
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWwgvq5VhEsS8s_A',
                type: 'radio',
              },
              '-LWDLWx55Ug5d6W6aYEU': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Leakage in cooling circuit solved',
                options: [
                  'High pressure pipe',
                  'Condenser',
                  'Expansion valve',
                  'Evaporator',
                  'Compressor',
                  'No',
                ],
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWwgvq5VhEsS8s_A',
                type: 'radio',
              },
              '-LWDLWx6JRw2W8fhev6k': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Append test plan',
                options: ['Yes', 'No'],
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWwgvq5VhEsS8s_A',
                type: 'radio',
              },
              '-LWDLWx8uMhiuFlc9wm8': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Measures taken',
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWwgvq5VhEsS8s_A',
                type: 'text',
              },
            },
            jobUID: '-LWDLWkXieJQHfcxwMig',
            name: 'Actions carried out',
            productUID: '-LWDLWvE0cpNQaAX32Ni',
          },
          '-LWDLWx9x463MLvrbSIE': {
            InputArray: [
              '-LWDLWxAUV8CSBcycg70',
              '-LWDLWxCHdbcqKD164mQ',
              '-LWDLWxDkXgNcgxOQ1si',
              '-LWDLWxFY0YK408CWv3-',
              '-LWDLWxHKu2jbLdjX9XU',
              '-LWDLWxJHTWRafzmJGFZ',
            ],
            Inputs: {
              '-LWDLWxAUV8CSBcycg70': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Leakage in cooling circuit',
                options: ['Yes', 'No'],
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWx9x463MLvrbSIE',
                type: 'radio',
              },
              '-LWDLWxCHdbcqKD164mQ': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Maintenance interval overdue',
                options: ['Yes', 'No'],
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWx9x463MLvrbSIE',
                type: 'radio',
              },
              '-LWDLWxDkXgNcgxOQ1si': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Application problem',
                options: ['Yes', 'No'],
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWx9x463MLvrbSIE',
                type: 'radio',
              },
              '-LWDLWxFY0YK408CWv3-': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Faulty installation',
                options: ['Yes', 'No'],
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWx9x463MLvrbSIE',
                type: 'radio',
              },
              '-LWDLWxHKu2jbLdjX9XU': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Defective components',
                options: ['Yes', 'No'],
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWx9x463MLvrbSIE',
                type: 'radio',
              },
              '-LWDLWxJHTWRafzmJGFZ': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Comments about failure',
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWx9x463MLvrbSIE',
                type: 'textarea',
              },
            },
            jobUID: '-LWDLWkXieJQHfcxwMig',
            name: 'Cause of problem',
            productUID: '-LWDLWvE0cpNQaAX32Ni',
          },
          '-LWDLWxK5g7M1mVgWkTy': {
            InputArray: [
              '-LWDLWxMDw4PWZGcugt2',
              '-LWDLWxOurRa3FWGSBYY',
              '-LWDLWxP9BUBMr811SCo',
              '-LWDLWxRGewWYRXHDOXc',
              '-LWDLWxWFZ5i5KYtajJz',
              '-LWDLWxXBkidunAjRJMR',
              '-LWDLWxZKUt12VTf7ebY',
            ],
            Inputs: {
              '-LWDLWxMDw4PWZGcugt2': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Service successful',
                options: ['Yes', 'No'],
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWxK5g7M1mVgWkTy',
                type: 'radio',
              },
              '-LWDLWxOurRa3FWGSBYY': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Further service required',
                options: ['Yes', 'No'],
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWxK5g7M1mVgWkTy',
                type: 'radio',
              },
              '-LWDLWxP9BUBMr811SCo': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'If yes, comments',
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWxK5g7M1mVgWkTy',
                type: 'textarea',
              },
              '-LWDLWxRGewWYRXHDOXc': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Defective parts taken',
                options: ['Yes', 'No'],
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWxK5g7M1mVgWkTy',
                type: 'radio',
              },
              '-LWDLWxWFZ5i5KYtajJz': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'If yes, tracking information',
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWxK5g7M1mVgWkTy',
                type: 'text',
              },
              '-LWDLWxXBkidunAjRJMR': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Working hours',
                options: ['Yes', 'No'],
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWxK5g7M1mVgWkTy',
                type: 'radio',
              },
              '-LWDLWxZKUt12VTf7ebY': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Comments',
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWxK5g7M1mVgWkTy',
                type: 'textarea',
              },
            },
            jobUID: '-LWDLWkXieJQHfcxwMig',
            name: 'Summary',
            productUID: '-LWDLWvE0cpNQaAX32Ni',
          },
          '-LWDLWxZKUt12VTf7ebZ': {
            InputArray: [
              '-LWDLWxasFOdO191IfcZ',
              '-LWDLWxcZ-u2YrtmOmSh',
              '-LWDLWxeDwiX8M0Zn2Z4',
              '-LWDLWxgxKgUZt2CnNB3',
            ],
            Inputs: {
              '-LWDLWxasFOdO191IfcZ': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Name of engineer',
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWxZKUt12VTf7ebZ',
                type: 'text',
              },
              '-LWDLWxcZ-u2YrtmOmSh': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Name and signature of customer',
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWxZKUt12VTf7ebZ',
                type: 'text',
              },
              '-LWDLWxeDwiX8M0Zn2Z4': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Report prepared by',
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWxZKUt12VTf7ebZ',
                type: 'text',
              },
              '-LWDLWxgxKgUZt2CnNB3': {
                inputType: 'datetimePicker',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Date',
                productUID: '-LWDLWvE0cpNQaAX32Ni',
                sectionUID: '-LWDLWxZKUt12VTf7ebZ',
                type: 'date',
              },
            },
            jobUID: '-LWDLWkXieJQHfcxwMig',
            name: 'Responsible parties',
            productUID: '-LWDLWvE0cpNQaAX32Ni',
          },
        },
        jobUID: '-LWDLWkXieJQHfcxwMig',
        name: 'LCP-DX',
      },
      '-LWDLWxgxKgUZt2CnNB4': {
        SectionArray: [
          '-LWDLWxgxKgUZt2CnNB5',
          '-LWDLWxlMerRGhWRnj7x',
          '-LWDLWyYThDm84Vbbhu6',
          '-LWDLWybSR11919UzsFJ',
          '-LWDLWz2jd7REKM0jK_4',
          '-LWDLWzeP2zLoVenDNX2',
          '-LWDLWzuE4ZEt8ER7xRo',
          '-LWDLX-D9SqnN1CQnFUA',
        ],
        Sections: {
          '-LWDLWxgxKgUZt2CnNB5': {
            InputArray: ['-LWDLWxiPAsWqg37fOSl', '-LWDLWxk1HIBvWrOBBS3', '-LWDLWxlMerRGhWRnj7w'],
            Inputs: {
              '-LWDLWxiPAsWqg37fOSl': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Ticket number',
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLWxgxKgUZt2CnNB5',
                type: 'text',
              },
              '-LWDLWxk1HIBvWrOBBS3': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Customer reference number',
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLWxgxKgUZt2CnNB5',
                type: 'text',
              },
              '-LWDLWxlMerRGhWRnj7w': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Purchase doc number',
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLWxgxKgUZt2CnNB5',
                type: 'text',
              },
            },
            jobUID: '-LWDLWkXieJQHfcxwMig',
            name: 'Service Numbers',
            productUID: '-LWDLWxgxKgUZt2CnNB4',
          },
          '-LWDLWxlMerRGhWRnj7x': {
            InputArray: [
              '-LWDLWxophqbs9b3gLO4',
              '-LWDLWxq6KkMAo5ZIYmB',
              '-LWDLWxsc-d4ExNx6FDD',
              '-LWDLWxuPWwv2mbdf1Wa',
              '-LWDLWxwGnW7DLk0Ye1a',
              '-LWDLWxxu-3wk-t8ZcfW',
              '-LWDLWy--pYg3mesCJVw',
              '-LWDLWy035XOzzpgRLpW',
              '-LWDLWy2D1GhG9v8vGtm',
              '-LWDLWy3EraPLBlAnz_3',
              '-LWDLWy5JMd-qVpPVRQx',
              '-LWDLWy7wraVrAjVg5OK',
              '-LWDLWyAt0kSlkoOOaMg',
              '-LWDLWyC6Ba2KMp6QYAW',
              '-LWDLWyC6Ba2KMp6QYAX',
              '-LWDLWyF7XaXUuHwrb3_',
              '-LWDLWyGhVeRPsRJs7ew',
              '-LWDLWyHVyMSNyOM4H4Y',
              '-LWDLWyKIiIEgterFdEH',
              '-LWDLWyMR447r2FwqKte',
              '-LWDLWyPCYsFncygWzcp',
              '-LWDLWyQH_BTV_Hobxx6',
              '-LWDLWySAg05dWy9xOMD',
              '-LWDLWyUfcGEan_E7hFy',
              '-LWDLWyWTzMxxMh78Ap5',
              '-LWDLWyYThDm84Vbbhu5',
            ],
            Inputs: {
              '-LWDLWxophqbs9b3gLO4': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Company name - Rittal Customer',
                options: ['Install', 'Maintenance', 'Repair', 'Assembly'],
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLWxlMerRGhWRnj7x',
                type: 'radio',
              },
              '-LWDLWxq6KkMAo5ZIYmB': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Contact person - Rittal Customer',
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLWxlMerRGhWRnj7x',
                type: 'text',
              },
              '-LWDLWxsc-d4ExNx6FDD': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Customer - Rittal Customer',
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLWxlMerRGhWRnj7x',
                type: 'text',
              },
              '-LWDLWxuPWwv2mbdf1Wa': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Telephone number - Rittal Customer',
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLWxlMerRGhWRnj7x',
                type: 'text',
              },
              '-LWDLWxwGnW7DLk0Ye1a': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Address - Rittal Customer',
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLWxlMerRGhWRnj7x',
                type: 'text',
              },
              '-LWDLWxxu-3wk-t8ZcfW': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Email - Rittal Customer',
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLWxlMerRGhWRnj7x',
                type: 'text',
              },
              '-LWDLWy--pYg3mesCJVw': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Company name - Service carried out by (company)',
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLWxlMerRGhWRnj7x',
                type: 'text',
              },
              '-LWDLWy035XOzzpgRLpW': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Contact person - Service carried out by (company)',
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLWxlMerRGhWRnj7x',
                type: 'text',
              },
              '-LWDLWy2D1GhG9v8vGtm': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Customer - Service carried out by (company)',
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLWxlMerRGhWRnj7x',
                type: 'text',
              },
              '-LWDLWy3EraPLBlAnz_3': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Telephone number - Service carried out by (company)',
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLWxlMerRGhWRnj7x',
                type: 'text',
              },
              '-LWDLWy5JMd-qVpPVRQx': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Address - Service carried out by (company)',
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLWxlMerRGhWRnj7x',
                type: 'text',
              },
              '-LWDLWy7wraVrAjVg5OK': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Email - Service carried out by (company)',
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLWxlMerRGhWRnj7x',
                type: 'text',
              },
              '-LWDLWyAt0kSlkoOOaMg': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Company name - Service carried out at (location)',
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLWxlMerRGhWRnj7x',
                type: 'text',
              },
              '-LWDLWyC6Ba2KMp6QYAW': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Contact person - Service carried out at (location)',
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLWxlMerRGhWRnj7x',
                type: 'text',
              },
              '-LWDLWyC6Ba2KMp6QYAX': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Customer - Service carried out at (location)',
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLWxlMerRGhWRnj7x',
                type: 'text',
              },
              '-LWDLWyF7XaXUuHwrb3_': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Telephone number - Service carried out at (location)',
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLWxlMerRGhWRnj7x',
                type: 'text',
              },
              '-LWDLWyGhVeRPsRJs7ew': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Address - Service carried out at (location)',
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLWxlMerRGhWRnj7x',
                type: 'text',
              },
              '-LWDLWyHVyMSNyOM4H4Y': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Email - Service carried out at (location)',
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLWxlMerRGhWRnj7x',
                type: 'text',
              },
              '-LWDLWyKIiIEgterFdEH': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Model number',
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLWxlMerRGhWRnj7x',
                type: 'text',
              },
              '-LWDLWyMR447r2FwqKte': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Quantity',
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLWxlMerRGhWRnj7x',
                type: 'text',
              },
              '-LWDLWyPCYsFncygWzcp': {
                inputType: 'datetimePicker',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Date of production',
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLWxlMerRGhWRnj7x',
                type: 'date',
              },
              '-LWDLWyQH_BTV_Hobxx6': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Customer machine number',
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLWxlMerRGhWRnj7x',
                type: 'text',
              },
              '-LWDLWySAg05dWy9xOMD': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'WA-Nr.',
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLWxlMerRGhWRnj7x',
                type: 'text',
              },
              '-LWDLWyUfcGEan_E7hFy': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Serial Number',
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLWxlMerRGhWRnj7x',
                type: 'text',
              },
              '-LWDLWyWTzMxxMh78Ap5': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Service order prepared by (Hub name)',
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLWxlMerRGhWRnj7x',
                type: 'text',
              },
              '-LWDLWyYThDm84Vbbhu5': {
                inputType: 'datetimePicker',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Date',
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLWxlMerRGhWRnj7x',
                type: 'date',
              },
            },
            jobUID: '-LWDLWkXieJQHfcxwMig',
            name: 'Basic info',
            productUID: '-LWDLWxgxKgUZt2CnNB4',
          },
          '-LWDLWyYThDm84Vbbhu6': {
            InputArray: ['-LWDLWy_raKG5I6keuZA', '-LWDLWybSR11919UzsFI'],
            Inputs: {
              '-LWDLWy_raKG5I6keuZA': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Photo Necessary',
                options: ['Yes', 'No'],
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLWyYThDm84Vbbhu6',
                type: 'radio',
              },
              '-LWDLWybSR11919UzsFI': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Problem description',
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLWyYThDm84Vbbhu6',
                type: 'text',
                summary: true,
              },
            },
            jobUID: '-LWDLWkXieJQHfcxwMig',
            name: 'Work to be done',
            productUID: '-LWDLWxgxKgUZt2CnNB4',
          },
          '-LWDLWybSR11919UzsFJ': {
            InputArray: [
              '-LWDLWydrgY3BFl5EjSX',
              '-LWDLWygHp63FfLGAxTe',
              '-LWDLWyh6VMPo_NepoXj',
              '-LWDLWyifAMbSyOq8L_-',
              '-LWDLWykqGAQTJFeKPKv',
              '-LWDLWylRLzGZdeLDpPC',
              '-LWDLWyoybbw3Md_tpRg',
              '-LWDLWyqbps3GWmg5qz3',
              '-LWDLWyr9wxCnuaxKX7c',
              '-LWDLWytLi7YnicD0Jev',
              '-LWDLWyv2rxmhDGOdQT9',
              '-LWDLWyyn8y4gKsNAEzi',
              '-LWDLWz0YCns0AHWAccY',
              '-LWDLWz2jd7REKM0jK_3',
            ],
            Inputs: {
              '-LWDLWydrgY3BFl5EjSX': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Installed condition',
                options: ['Assembly', 'Complete Assembly'],
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLWybSR11919UzsFJ',
                type: 'radio',
              },
              '-LWDLWygHp63FfLGAxTe': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Grade of dirt',
                options: ['Low (clean)', 'Medium', 'Heavy'],
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLWybSR11919UzsFJ',
                type: 'radio',
              },
              '-LWDLWyh6VMPo_NepoXj': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Grade of maintenance',
                options: ['Good', 'Medium', 'Poor'],
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLWybSR11919UzsFJ',
                type: 'radio',
              },
              '-LWDLWyifAMbSyOq8L_-': {
                inputType: 'datetimePicker',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Last maintenance on (date)',
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLWybSR11919UzsFJ',
                type: 'date',
              },
              '-LWDLWykqGAQTJFeKPKv': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Room temp',
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLWybSR11919UzsFJ',
                type: 'text',
              },
              '-LWDLWylRLzGZdeLDpPC': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Temp inside enclosure',
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLWybSR11919UzsFJ',
                type: 'text',
              },
              '-LWDLWyoybbw3Md_tpRg': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Air Filter',
                options: ['Yes', 'No'],
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLWybSR11919UzsFJ',
                type: 'radio',
              },
              '-LWDLWyqbps3GWmg5qz3': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Right air filter',
                options: ['Yes', 'No'],
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLWybSR11919UzsFJ',
                type: 'radio',
              },
              '-LWDLWyr9wxCnuaxKX7c': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Air filter dirty',
                options: ['Low (clean)', 'Medium', 'Heavy'],
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLWybSR11919UzsFJ',
                type: 'radio',
              },
              '-LWDLWytLi7YnicD0Jev': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Failure indication (display)',
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLWybSR11919UzsFJ',
                type: 'text',
              },
              '-LWDLWyv2rxmhDGOdQT9': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Noise level',
                options: ['Normal', 'Noisy'],
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLWybSR11919UzsFJ',
                type: 'radio',
              },
              '-LWDLWyyn8y4gKsNAEzi': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Vibration on each unit',
                options: ['Low', 'Medium', 'Heavy'],
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLWybSR11919UzsFJ',
                type: 'radio',
              },
              '-LWDLWz0YCns0AHWAccY': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Electrical connection, incoming voltage (V,Hz)',
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLWybSR11919UzsFJ',
                type: 'text',
              },
              '-LWDLWz2jd7REKM0jK_3': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Comments',
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLWybSR11919UzsFJ',
                type: 'textarea',
              },
            },
            jobUID: '-LWDLWkXieJQHfcxwMig',
            name: 'Status prior to servicing',
            productUID: '-LWDLWxgxKgUZt2CnNB4',
          },
          '-LWDLWz2jd7REKM0jK_4': {
            InputArray: [
              '-LWDLWz5xgH78MBn9fN-',
              '-LWDLWz8Mq_ZkQZlODrF',
              '-LWDLWzAwjYt39dwuz2a',
              '-LWDLWzCFgyILrZrL2Xa',
              '-LWDLWzDkG2ZJ0zpEYPb',
              '-LWDLWzGyaBsmzx6vNDk',
              '-LWDLWzJZVbe_DnyPyIH',
              '-LWDLWzLshg1dwonpXHt',
              '-LWDLWzNntzGSt67U5jX',
              '-LWDLWzQzfbL0Xay16Xm',
              '-LWDLWzSOQHfZaOnYbmD',
              '-LWDLWzWMoVQXb53-xK3',
              '-LWDLWz_6W7GVV2Be5mO',
              '-LWDLWzbo8hLr_hd8tjI',
              '-LWDLWzeP2zLoVenDNX1',
            ],
            Inputs: {
              '-LWDLWz5xgH78MBn9fN-': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Cooling unit changed complete',
                options: ['Yes', 'No'],
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLWz2jd7REKM0jK_4',
                type: 'radio',
              },
              '-LWDLWz8Mq_ZkQZlODrF': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Refrigerant replaced',
                options: ['Yes', 'No'],
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLWz2jd7REKM0jK_4',
                type: 'radio',
              },
              '-LWDLWzAwjYt39dwuz2a': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Compressor changed',
                options: ['Yes', 'No'],
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLWz2jd7REKM0jK_4',
                type: 'radio',
              },
              '-LWDLWzCFgyILrZrL2Xa': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Condenser fan changed',
                options: ['Yes', 'No'],
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLWz2jd7REKM0jK_4',
                type: 'radio',
              },
              '-LWDLWzDkG2ZJ0zpEYPb': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Evaporator fan changed',
                options: ['Yes', 'No'],
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLWz2jd7REKM0jK_4',
                type: 'radio',
              },
              '-LWDLWzGyaBsmzx6vNDk': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Condenser changed (data from component type plate necessary)',
                options: ['Yes', 'No'],
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLWz2jd7REKM0jK_4',
                type: 'radio',
              },
              '-LWDLWzJZVbe_DnyPyIH': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Evaporator changed (data from component type plate necessary)',
                options: ['Yes', 'No'],
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLWz2jd7REKM0jK_4',
                type: 'radio',
              },
              '-LWDLWzLshg1dwonpXHt': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name:
                  'Power box / Electronic board changed (data from component type plate necessary)',
                options: ['Yes', 'No'],
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLWz2jd7REKM0jK_4',
                type: 'radio',
              },
              '-LWDLWzNntzGSt67U5jX': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Expansion valve changed (data from component type plate necessary)',
                options: ['Yes', 'No'],
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLWz2jd7REKM0jK_4',
                type: 'radio',
              },
              '-LWDLWzQzfbL0Xay16Xm': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Pump changed (fluid sample necessary)',
                options: ['Yes', 'No'],
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLWz2jd7REKM0jK_4',
                type: 'radio',
              },
              '-LWDLWzSOQHfZaOnYbmD': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Air filter changed',
                options: ['Yes', 'No'],
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLWz2jd7REKM0jK_4',
                type: 'radio',
              },
              '-LWDLWzWMoVQXb53-xK3': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Sensor changed',
                options: ['B1', 'B2', 'B3', 'B4', 'B5', 'No'],
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLWz2jd7REKM0jK_4',
                type: 'radio',
              },
              '-LWDLWz_6W7GVV2Be5mO': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Leakage in cooling circuit solved',
                options: [
                  'High pressure pipe',
                  'Condenser',
                  'Expansion valve',
                  'Evaporator',
                  'Compressor',
                  'No',
                ],
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLWz2jd7REKM0jK_4',
                type: 'radio',
              },
              '-LWDLWzbo8hLr_hd8tjI': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Append test plan',
                options: ['Yes', 'No'],
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLWz2jd7REKM0jK_4',
                type: 'radio',
              },
              '-LWDLWzeP2zLoVenDNX1': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Measures taken',
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLWz2jd7REKM0jK_4',
                type: 'text',
              },
            },
            jobUID: '-LWDLWkXieJQHfcxwMig',
            name: 'Actions carried out',
            productUID: '-LWDLWxgxKgUZt2CnNB4',
          },
          '-LWDLWzeP2zLoVenDNX2': {
            InputArray: [
              '-LWDLWzhoHKu6q41tazb',
              '-LWDLWzkSJjEwDXsXwbI',
              '-LWDLWznWCO7DEPgBVG-',
              '-LWDLWzpBDm3efkp0ALw',
              '-LWDLWzrkkP7bRkI3uQh',
              '-LWDLWzuE4ZEt8ER7xRn',
            ],
            Inputs: {
              '-LWDLWzhoHKu6q41tazb': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Leakage in cooling circuit',
                options: ['Yes', 'No'],
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLWzeP2zLoVenDNX2',
                type: 'radio',
              },
              '-LWDLWzkSJjEwDXsXwbI': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Maintenance interval overdue',
                options: ['Yes', 'No'],
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLWzeP2zLoVenDNX2',
                type: 'radio',
              },
              '-LWDLWznWCO7DEPgBVG-': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Application problem',
                options: ['Yes', 'No'],
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLWzeP2zLoVenDNX2',
                type: 'radio',
              },
              '-LWDLWzpBDm3efkp0ALw': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Faulty installation',
                options: ['Yes', 'No'],
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLWzeP2zLoVenDNX2',
                type: 'radio',
              },
              '-LWDLWzrkkP7bRkI3uQh': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Defective components',
                options: ['Yes', 'No'],
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLWzeP2zLoVenDNX2',
                type: 'radio',
              },
              '-LWDLWzuE4ZEt8ER7xRn': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Comments about failure',
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLWzeP2zLoVenDNX2',
                type: 'textarea',
              },
            },
            jobUID: '-LWDLWkXieJQHfcxwMig',
            name: 'Cause of problem',
            productUID: '-LWDLWxgxKgUZt2CnNB4',
          },
          '-LWDLWzuE4ZEt8ER7xRo': {
            InputArray: [
              '-LWDLWzxaiNOzLsBZ283',
              '-LWDLX--WwSTSRiCMp0S',
              '-LWDLX-1sNlHya0kLh2T',
              '-LWDLX-48NZrDqYsWvZT',
              '-LWDLX-6wPr7JC5BRMeH',
              '-LWDLX-9xkoJzhDEZ13f',
              '-LWDLX-CKadnfpMtp2fY',
            ],
            Inputs: {
              '-LWDLWzxaiNOzLsBZ283': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Service successful',
                options: ['Yes', 'No'],
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLWzuE4ZEt8ER7xRo',
                type: 'radio',
              },
              '-LWDLX--WwSTSRiCMp0S': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Further service required',
                options: ['Yes', 'No'],
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLWzuE4ZEt8ER7xRo',
                type: 'radio',
              },
              '-LWDLX-1sNlHya0kLh2T': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'If yes, comments',
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLWzuE4ZEt8ER7xRo',
                type: 'textarea',
              },
              '-LWDLX-48NZrDqYsWvZT': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Defective parts taken',
                options: ['Yes', 'No'],
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLWzuE4ZEt8ER7xRo',
                type: 'radio',
              },
              '-LWDLX-6wPr7JC5BRMeH': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'If yes, tracking information',
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLWzuE4ZEt8ER7xRo',
                type: 'text',
              },
              '-LWDLX-9xkoJzhDEZ13f': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Working hours',
                options: ['Yes', 'No'],
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLWzuE4ZEt8ER7xRo',
                type: 'radio',
              },
              '-LWDLX-CKadnfpMtp2fY': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Comments',
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLWzuE4ZEt8ER7xRo',
                type: 'textarea',
              },
            },
            jobUID: '-LWDLWkXieJQHfcxwMig',
            name: 'Summary',
            productUID: '-LWDLWxgxKgUZt2CnNB4',
          },
          '-LWDLX-D9SqnN1CQnFUA': {
            InputArray: [
              '-LWDLX-ExUc2camMPVa9',
              '-LWDLX-Gby37wlukxLB4',
              '-LWDLX-HtHBXFjtVnltK',
              '-LWDLX-Kdvqdr44VUXAd',
            ],
            Inputs: {
              '-LWDLX-ExUc2camMPVa9': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Name of engineer',
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLX-D9SqnN1CQnFUA',
                type: 'text',
              },
              '-LWDLX-Gby37wlukxLB4': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Name and signature of customer',
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLX-D9SqnN1CQnFUA',
                type: 'text',
              },
              '-LWDLX-HtHBXFjtVnltK': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Report prepared by',
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLX-D9SqnN1CQnFUA',
                type: 'text',
              },
              '-LWDLX-Kdvqdr44VUXAd': {
                inputType: 'datetimePicker',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Date',
                productUID: '-LWDLWxgxKgUZt2CnNB4',
                sectionUID: '-LWDLX-D9SqnN1CQnFUA',
                type: 'date',
              },
            },
            jobUID: '-LWDLWkXieJQHfcxwMig',
            name: 'Responsible parties',
            productUID: '-LWDLWxgxKgUZt2CnNB4',
          },
        },
        jobUID: '-LWDLWkXieJQHfcxwMig',
        name: 'Micro-Data-Center',
      },
      '-LWDLX-Kdvqdr44VUXAe': {
        SectionArray: [
          '-LWDLX-LyvJx14zFGvN0',
          '-LWDLX-Q3SW6SO1SR-Dm',
          '-LWDLX0HYn9I4LungK0h',
          '-LWDLX0QW8b3nzz_NLLN',
          '-LWDLX0tAzrjLvTtnX81',
          '-LWDLX1Nyk4e52kAYvTW',
          '-LWDLX1_P6TGtYiLOjc9',
          '-LWDLX1nybNL12O51beU',
        ],
        Sections: {
          '-LWDLX-LyvJx14zFGvN0': {
            InputArray: ['-LWDLX-Na0r1CLRpawZu', '-LWDLX-OK31wJGiyWc5Z', '-LWDLX-Q3SW6SO1SR-Dl'],
            Inputs: {
              '-LWDLX-Na0r1CLRpawZu': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Ticket number',
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX-LyvJx14zFGvN0',
                type: 'text',
              },
              '-LWDLX-OK31wJGiyWc5Z': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Customer reference number',
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX-LyvJx14zFGvN0',
                type: 'text',
              },
              '-LWDLX-Q3SW6SO1SR-Dl': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Purchase doc number',
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX-LyvJx14zFGvN0',
                type: 'text',
              },
            },
            jobUID: '-LWDLWkXieJQHfcxwMig',
            name: 'Service Numbers',
            productUID: '-LWDLX-Kdvqdr44VUXAe',
          },
          '-LWDLX-Q3SW6SO1SR-Dm': {
            InputArray: [
              '-LWDLX-T0L9dKsyeHta-',
              '-LWDLX-VEDeJh8M-eIWk',
              '-LWDLX-Wv2XhdCCj2lnf',
              '-LWDLX-YxQbcxCueZSC_',
              '-LWDLX-Z3NMYqgrK26k4',
              '-LWDLX-blbU3wfFphZcw',
              '-LWDLX-d33WJS5bpvl7o',
              '-LWDLX-gslHhTV8DaZjL',
              '-LWDLX-hgrpkWizJ6JIE',
              '-LWDLX-kALn6HEP6uiux',
              '-LWDLX-mNUUSN9g5Yoot',
              '-LWDLX-otJsV0aJgzFWZ',
              '-LWDLX-qI2WLNTJhV8ZZ',
              '-LWDLX-sHi4BRS3N5r6D',
              '-LWDLX-uMxhxekzyAAcK',
              '-LWDLX-wmZSbfj24Q2Aw',
              '-LWDLX-yYA2WpOMROD-E',
              '-LWDLX0-7_ujNERB8N2Z',
              '-LWDLX01bpMGvPJYaqp9',
              '-LWDLX03yeIypvjX5eoV',
              '-LWDLX05sHwfYcU0b4v1',
              '-LWDLX07gTKavcHw9HHQ',
              '-LWDLX0A26FKFjlsBqot',
              '-LWDLX0DIu2ql4z7hc8u',
              '-LWDLX0EqNQuEyVZdJfQ',
              '-LWDLX0HYn9I4LungK0g',
            ],
            Inputs: {
              '-LWDLX-T0L9dKsyeHta-': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Company name - Rittal Customer',
                options: ['Install', 'Maintenance', 'Repair', 'Assembly'],
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX-Q3SW6SO1SR-Dm',
                type: 'radio',
              },
              '-LWDLX-VEDeJh8M-eIWk': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Contact person - Rittal Customer',
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX-Q3SW6SO1SR-Dm',
                type: 'text',
              },
              '-LWDLX-Wv2XhdCCj2lnf': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Customer - Rittal Customer',
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX-Q3SW6SO1SR-Dm',
                type: 'text',
              },
              '-LWDLX-YxQbcxCueZSC_': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Telephone number - Rittal Customer',
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX-Q3SW6SO1SR-Dm',
                type: 'text',
              },
              '-LWDLX-Z3NMYqgrK26k4': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Address - Rittal Customer',
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX-Q3SW6SO1SR-Dm',
                type: 'text',
              },
              '-LWDLX-blbU3wfFphZcw': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Email - Rittal Customer',
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX-Q3SW6SO1SR-Dm',
                type: 'text',
              },
              '-LWDLX-d33WJS5bpvl7o': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Company name - Service carried out by (company)',
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX-Q3SW6SO1SR-Dm',
                type: 'text',
              },
              '-LWDLX-gslHhTV8DaZjL': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Contact person - Service carried out by (company)',
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX-Q3SW6SO1SR-Dm',
                type: 'text',
              },
              '-LWDLX-hgrpkWizJ6JIE': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Customer - Service carried out by (company)',
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX-Q3SW6SO1SR-Dm',
                type: 'text',
              },
              '-LWDLX-kALn6HEP6uiux': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Telephone number - Service carried out by (company)',
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX-Q3SW6SO1SR-Dm',
                type: 'text',
              },
              '-LWDLX-mNUUSN9g5Yoot': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Address - Service carried out by (company)',
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX-Q3SW6SO1SR-Dm',
                type: 'text',
              },
              '-LWDLX-otJsV0aJgzFWZ': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Email - Service carried out by (company)',
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX-Q3SW6SO1SR-Dm',
                type: 'text',
              },
              '-LWDLX-qI2WLNTJhV8ZZ': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Company name - Service carried out at (location)',
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX-Q3SW6SO1SR-Dm',
                type: 'text',
              },
              '-LWDLX-sHi4BRS3N5r6D': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Contact person - Service carried out at (location)',
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX-Q3SW6SO1SR-Dm',
                type: 'text',
              },
              '-LWDLX-uMxhxekzyAAcK': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Customer - Service carried out at (location)',
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX-Q3SW6SO1SR-Dm',
                type: 'text',
              },
              '-LWDLX-wmZSbfj24Q2Aw': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Telephone number - Service carried out at (location)',
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX-Q3SW6SO1SR-Dm',
                type: 'text',
              },
              '-LWDLX-yYA2WpOMROD-E': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Address - Service carried out at (location)',
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX-Q3SW6SO1SR-Dm',
                type: 'text',
              },
              '-LWDLX0-7_ujNERB8N2Z': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Email - Service carried out at (location)',
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX-Q3SW6SO1SR-Dm',
                type: 'text',
              },
              '-LWDLX01bpMGvPJYaqp9': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Model number',
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX-Q3SW6SO1SR-Dm',
                type: 'text',
              },
              '-LWDLX03yeIypvjX5eoV': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Quantity',
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX-Q3SW6SO1SR-Dm',
                type: 'text',
              },
              '-LWDLX05sHwfYcU0b4v1': {
                inputType: 'datetimePicker',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Date of production',
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX-Q3SW6SO1SR-Dm',
                type: 'date',
              },
              '-LWDLX07gTKavcHw9HHQ': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Customer machine number',
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX-Q3SW6SO1SR-Dm',
                type: 'text',
              },
              '-LWDLX0A26FKFjlsBqot': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'WA-Nr.',
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX-Q3SW6SO1SR-Dm',
                type: 'text',
              },
              '-LWDLX0DIu2ql4z7hc8u': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Serial Number',
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX-Q3SW6SO1SR-Dm',
                type: 'text',
              },
              '-LWDLX0EqNQuEyVZdJfQ': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Service order prepared by (Hub name)',
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX-Q3SW6SO1SR-Dm',
                type: 'text',
              },
              '-LWDLX0HYn9I4LungK0g': {
                inputType: 'datetimePicker',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Date',
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX-Q3SW6SO1SR-Dm',
                type: 'date',
              },
            },
            jobUID: '-LWDLWkXieJQHfcxwMig',
            name: 'Basic info',
            productUID: '-LWDLX-Kdvqdr44VUXAe',
          },
          '-LWDLX0HYn9I4LungK0h': {
            InputArray: ['-LWDLX0K4W2KC0XxawGs', '-LWDLX0M2f3lwuWGufYE'],
            Inputs: {
              '-LWDLX0K4W2KC0XxawGs': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Photo Necessary',
                options: ['Yes', 'No'],
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX0HYn9I4LungK0h',
                type: 'radio',
              },
              '-LWDLX0M2f3lwuWGufYE': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Problem description',
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX0HYn9I4LungK0h',
                type: 'text',
                summary: true,
              },
            },
            jobUID: '-LWDLWkXieJQHfcxwMig',
            name: 'Work to be done',
            productUID: '-LWDLX-Kdvqdr44VUXAe',
          },
          '-LWDLX0QW8b3nzz_NLLN': {
            InputArray: [
              '-LWDLX0TWzRPPTYMITLc',
              '-LWDLX0WJSRyk-Zaldep',
              '-LWDLX0XmosZhrmv4fH5',
              '-LWDLX0ZqKUQB_hqPap2',
              '-LWDLX0a3F6GySZ-MF-j',
              '-LWDLX0cVYGxdT1ls8Nf',
              '-LWDLX0enDt_m28oZSxv',
              '-LWDLX0gzXf7NFgImHDp',
              '-LWDLX0hHZLaBuT-TvFh',
              '-LWDLX0kpLzyC1jg_kZ3',
              '-LWDLX0mGVWxbsEyrv-X',
              '-LWDLX0oDR6TdbObbjlD',
              '-LWDLX0qt1Az1SDH17-Y',
              '-LWDLX0tAzrjLvTtnX80',
            ],
            Inputs: {
              '-LWDLX0TWzRPPTYMITLc': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Installed condition',
                options: ['Assembly', 'Complete Assembly'],
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX0QW8b3nzz_NLLN',
                type: 'radio',
              },
              '-LWDLX0WJSRyk-Zaldep': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Grade of dirt',
                options: ['Low (clean)', 'Medium', 'Heavy'],
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX0QW8b3nzz_NLLN',
                type: 'radio',
              },
              '-LWDLX0XmosZhrmv4fH5': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Grade of maintenance',
                options: ['Good', 'Medium', 'Poor'],
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX0QW8b3nzz_NLLN',
                type: 'radio',
              },
              '-LWDLX0ZqKUQB_hqPap2': {
                inputType: 'datetimePicker',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Last maintenance on (date)',
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX0QW8b3nzz_NLLN',
                type: 'date',
              },
              '-LWDLX0a3F6GySZ-MF-j': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Room temp',
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX0QW8b3nzz_NLLN',
                type: 'text',
              },
              '-LWDLX0cVYGxdT1ls8Nf': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Temp inside enclosure',
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX0QW8b3nzz_NLLN',
                type: 'text',
              },
              '-LWDLX0enDt_m28oZSxv': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Air Filter',
                options: ['Yes', 'No'],
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX0QW8b3nzz_NLLN',
                type: 'radio',
              },
              '-LWDLX0gzXf7NFgImHDp': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Right air filter',
                options: ['Yes', 'No'],
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX0QW8b3nzz_NLLN',
                type: 'radio',
              },
              '-LWDLX0hHZLaBuT-TvFh': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Air filter dirty',
                options: ['Low (clean)', 'Medium', 'Heavy'],
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX0QW8b3nzz_NLLN',
                type: 'radio',
              },
              '-LWDLX0kpLzyC1jg_kZ3': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Failure indication (display)',
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX0QW8b3nzz_NLLN',
                type: 'text',
              },
              '-LWDLX0mGVWxbsEyrv-X': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Noise level',
                options: ['Normal', 'Noisy'],
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX0QW8b3nzz_NLLN',
                type: 'radio',
              },
              '-LWDLX0oDR6TdbObbjlD': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Vibration on each unit',
                options: ['Low', 'Medium', 'Heavy'],
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX0QW8b3nzz_NLLN',
                type: 'radio',
              },
              '-LWDLX0qt1Az1SDH17-Y': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Electrical connection, incoming voltage (V,Hz)',
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX0QW8b3nzz_NLLN',
                type: 'text',
              },
              '-LWDLX0tAzrjLvTtnX80': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Comments',
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX0QW8b3nzz_NLLN',
                type: 'textarea',
              },
            },
            jobUID: '-LWDLWkXieJQHfcxwMig',
            name: 'Status prior to servicing',
            productUID: '-LWDLX-Kdvqdr44VUXAe',
          },
          '-LWDLX0tAzrjLvTtnX81': {
            InputArray: [
              '-LWDLX0uwYwT0npsNlk4',
              '-LWDLX0xQ6Dhh-PO1f6w',
              '-LWDLX0y4N49YgrXRQAP',
              '-LWDLX1-kzNZIP0NQM49',
              '-LWDLX12zhBnyOp-1yw0',
              '-LWDLX14LynZhGesOd-z',
              '-LWDLX16F0QS1GkXVIeL',
              '-LWDLX18b-5zT3tVKD4H',
              '-LWDLX1AgSDJxLo-r7xY',
              '-LWDLX1CS34Ygv_Z33O_',
              '-LWDLX1FONmhvgaQMSyB',
              '-LWDLX1HtnWQjtedyXwY',
              '-LWDLX1JdelYB1_JPP_v',
              '-LWDLX1KsJu52y6mzfns',
              '-LWDLX1M2gMh6Map0j-k',
            ],
            Inputs: {
              '-LWDLX0uwYwT0npsNlk4': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Cooling unit changed complete',
                options: ['Yes', 'No'],
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX0tAzrjLvTtnX81',
                type: 'radio',
              },
              '-LWDLX0xQ6Dhh-PO1f6w': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Refrigerant replaced',
                options: ['Yes', 'No'],
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX0tAzrjLvTtnX81',
                type: 'radio',
              },
              '-LWDLX0y4N49YgrXRQAP': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Compressor changed',
                options: ['Yes', 'No'],
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX0tAzrjLvTtnX81',
                type: 'radio',
              },
              '-LWDLX1-kzNZIP0NQM49': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Condenser fan changed',
                options: ['Yes', 'No'],
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX0tAzrjLvTtnX81',
                type: 'radio',
              },
              '-LWDLX12zhBnyOp-1yw0': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Evaporator fan changed',
                options: ['Yes', 'No'],
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX0tAzrjLvTtnX81',
                type: 'radio',
              },
              '-LWDLX14LynZhGesOd-z': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Condenser changed (data from component type plate necessary)',
                options: ['Yes', 'No'],
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX0tAzrjLvTtnX81',
                type: 'radio',
              },
              '-LWDLX16F0QS1GkXVIeL': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Evaporator changed (data from component type plate necessary)',
                options: ['Yes', 'No'],
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX0tAzrjLvTtnX81',
                type: 'radio',
              },
              '-LWDLX18b-5zT3tVKD4H': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name:
                  'Power box / Electronic board changed (data from component type plate necessary)',
                options: ['Yes', 'No'],
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX0tAzrjLvTtnX81',
                type: 'radio',
              },
              '-LWDLX1AgSDJxLo-r7xY': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Expansion valve changed (data from component type plate necessary)',
                options: ['Yes', 'No'],
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX0tAzrjLvTtnX81',
                type: 'radio',
              },
              '-LWDLX1CS34Ygv_Z33O_': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Pump changed (fluid sample necessary)',
                options: ['Yes', 'No'],
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX0tAzrjLvTtnX81',
                type: 'radio',
              },
              '-LWDLX1FONmhvgaQMSyB': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Air filter changed',
                options: ['Yes', 'No'],
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX0tAzrjLvTtnX81',
                type: 'radio',
              },
              '-LWDLX1HtnWQjtedyXwY': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Sensor changed',
                options: ['B1', 'B2', 'B3', 'B4', 'B5', 'No'],
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX0tAzrjLvTtnX81',
                type: 'radio',
              },
              '-LWDLX1JdelYB1_JPP_v': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Leakage in cooling circuit solved',
                options: [
                  'High pressure pipe',
                  'Condenser',
                  'Expansion valve',
                  'Evaporator',
                  'Compressor',
                  'No',
                ],
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX0tAzrjLvTtnX81',
                type: 'radio',
              },
              '-LWDLX1KsJu52y6mzfns': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Append test plan',
                options: ['Yes', 'No'],
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX0tAzrjLvTtnX81',
                type: 'radio',
              },
              '-LWDLX1M2gMh6Map0j-k': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Measures taken',
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX0tAzrjLvTtnX81',
                type: 'text',
              },
            },
            jobUID: '-LWDLWkXieJQHfcxwMig',
            name: 'Actions carried out',
            productUID: '-LWDLX-Kdvqdr44VUXAe',
          },
          '-LWDLX1Nyk4e52kAYvTW': {
            InputArray: [
              '-LWDLX1QuhWkb6sr930W',
              '-LWDLX1SWd4jwipO3riS',
              '-LWDLX1UMZQiF-tAQubq',
              '-LWDLX1WJXdMJuhaXXTz',
              '-LWDLX1YSu-F7c1gHBn0',
              '-LWDLX1_P6TGtYiLOjc8',
            ],
            Inputs: {
              '-LWDLX1QuhWkb6sr930W': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Leakage in cooling circuit',
                options: ['Yes', 'No'],
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX1Nyk4e52kAYvTW',
                type: 'radio',
              },
              '-LWDLX1SWd4jwipO3riS': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Maintenance interval overdue',
                options: ['Yes', 'No'],
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX1Nyk4e52kAYvTW',
                type: 'radio',
              },
              '-LWDLX1UMZQiF-tAQubq': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Application problem',
                options: ['Yes', 'No'],
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX1Nyk4e52kAYvTW',
                type: 'radio',
              },
              '-LWDLX1WJXdMJuhaXXTz': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Faulty installation',
                options: ['Yes', 'No'],
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX1Nyk4e52kAYvTW',
                type: 'radio',
              },
              '-LWDLX1YSu-F7c1gHBn0': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Defective components',
                options: ['Yes', 'No'],
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX1Nyk4e52kAYvTW',
                type: 'radio',
              },
              '-LWDLX1_P6TGtYiLOjc8': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Comments about failure',
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX1Nyk4e52kAYvTW',
                type: 'textarea',
              },
            },
            jobUID: '-LWDLWkXieJQHfcxwMig',
            name: 'Cause of problem',
            productUID: '-LWDLX-Kdvqdr44VUXAe',
          },
          '-LWDLX1_P6TGtYiLOjc9': {
            InputArray: [
              '-LWDLX1bMeS0nYPir7yw',
              '-LWDLX1dlO9jmw7524SI',
              '-LWDLX1fztfntaGnmbs5',
              '-LWDLX1hJERMsgrk5pDD',
              '-LWDLX1jZMKS6GTOmr0K',
              '-LWDLX1lYME9aZPQOvL5',
              '-LWDLX1m2fZUrwzYtS8X',
            ],
            Inputs: {
              '-LWDLX1bMeS0nYPir7yw': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Service successful',
                options: ['Yes', 'No'],
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX1_P6TGtYiLOjc9',
                type: 'radio',
              },
              '-LWDLX1dlO9jmw7524SI': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Further service required',
                options: ['Yes', 'No'],
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX1_P6TGtYiLOjc9',
                type: 'radio',
              },
              '-LWDLX1fztfntaGnmbs5': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'If yes, comments',
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX1_P6TGtYiLOjc9',
                type: 'textarea',
              },
              '-LWDLX1hJERMsgrk5pDD': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Defective parts taken',
                options: ['Yes', 'No'],
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX1_P6TGtYiLOjc9',
                type: 'radio',
              },
              '-LWDLX1jZMKS6GTOmr0K': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'If yes, tracking information',
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX1_P6TGtYiLOjc9',
                type: 'text',
              },
              '-LWDLX1lYME9aZPQOvL5': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Working hours',
                options: ['Yes', 'No'],
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX1_P6TGtYiLOjc9',
                type: 'radio',
              },
              '-LWDLX1m2fZUrwzYtS8X': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Comments',
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX1_P6TGtYiLOjc9',
                type: 'textarea',
              },
            },
            jobUID: '-LWDLWkXieJQHfcxwMig',
            name: 'Summary',
            productUID: '-LWDLX-Kdvqdr44VUXAe',
          },
          '-LWDLX1nybNL12O51beU': {
            InputArray: [
              '-LWDLX1pVOGkK9VqBRI1',
              '-LWDLX1rAwO4CyUGVWka',
              '-LWDLX1tXSqxiBZQeOJ_',
              '-LWDLX1vECJlC9AkKDZR',
            ],
            Inputs: {
              '-LWDLX1pVOGkK9VqBRI1': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Name of engineer',
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX1nybNL12O51beU',
                type: 'text',
              },
              '-LWDLX1rAwO4CyUGVWka': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Name and signature of customer',
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX1nybNL12O51beU',
                type: 'text',
              },
              '-LWDLX1tXSqxiBZQeOJ_': {
                inputType: 'input',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Report prepared by',
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX1nybNL12O51beU',
                type: 'text',
              },
              '-LWDLX1vECJlC9AkKDZR': {
                inputType: 'datetimePicker',
                jobUID: '-LWDLWkXieJQHfcxwMig',
                name: 'Date',
                productUID: '-LWDLX-Kdvqdr44VUXAe',
                sectionUID: '-LWDLX1nybNL12O51beU',
                type: 'date',
              },
            },
            jobUID: '-LWDLWkXieJQHfcxwMig',
            name: 'Responsible parties',
            productUID: '-LWDLX-Kdvqdr44VUXAe',
          },
        },
        jobUID: '-LWDLWkXieJQHfcxwMig',
        name: 'DET-AC',
      },
    },
    name: 'Service Order / Report',
  },
  '-NAA8oPA7-MrbJ7qkCqm': {
    ProductTypeArray: [
      '-NAA8oqj7r-SVn3uAo7l'
    ],
    ProductTypes: {
      '-NAA8oqj7r-SVn3uAo7l': {
        SectionArray: [
          '-NAGA2Mhh4f_2zY_BhvS',
          '-NAEq5mKPySi0UDpCUO0',
          '-NAEqV8orc-acaTuERhF',
          '-NMOXXp6O-mAkfKzUpQx',
          '-NAEqXzJMn6IDuSIbFSZ',
          '-NAEq_Qg5g2WELq2ivFs',
          '-NMOmrp0FDcATvBazN5v',
          '-NAEqahgZIkV37qah1Dk',
          '-NAFTDe9hJd3hZtQQKAM'
        ],
        Sections: {
          "-NAGA2Mhh4f_2zY_BhvS": {
            InputArray: [
              "-NBO6f6C5npDghnpYtZd",
              "-NAGAJ9gX8iQnqC7arBN",
              "-NAGAKN3u_-CHaJXAtlq",
              "-NAGALHJsUkOzxo6yLHI",
              "-NMOSQZzn6Z4vo65HJO_"
            ],
            Inputs: {
              "-NBO6f6C5npDghnpYtZd": {
                "inputType": "input",
                "type": "text",
                "jobUID": "-NAA8oPA7-MrbJ7qkCqm",
                "name": "Location/AC/Cabinet ID",
                "notes": false,
                "productUID": "-NAA8oqj7r-SVn3uAo7l",
                "sectionUID": "-NAGA2Mhh4f_2zY_BhvS"
              },
              "-NAGAJ9gX8iQnqC7arBN": {
                "inputType": "input",
                "type": "text",
                "jobUID": "-NAA8oPA7-MrbJ7qkCqm",
                "name": "Serial No.",
                "notes": false,
                "productUID": "-NAA8oqj7r-SVn3uAo7l",
                "sectionUID": "-NAGA2Mhh4f_2zY_BhvS"
              },
              "-NAGAKN3u_-CHaJXAtlq": {
                "inputType": "input",
                "type": "text",
                "jobUID": "-NAA8oPA7-MrbJ7qkCqm",
                "name": "Model No.",
                "notes": false,
                "productUID": "-NAA8oqj7r-SVn3uAo7l",
                "sectionUID": "-NAGA2Mhh4f_2zY_BhvS"
              },
              "-NAGALHJsUkOzxo6yLHI": {
                  "inputType": "datetimePicker",
                  "jobUID": "-NAA8oPA7-MrbJ7qkCqm",
                  "name": "Production Date",
                  "productUID": "-NAA8oqj7r-SVn3uAo7l",
                  "sectionUID": "-NAGA2Mhh4f_2zY_BhvS",
                  "type": "date"
              },
              "-NMOSQZzn6Z4vo65HJO_": {
                "inputType": "input",
                "jobUID": "-NAA8oPA7-MrbJ7qkCqm",
                "name": "Manufacturer",
                "productUID": "-NAA8oqj7r-SVn3uAo7l",
                "sectionUID": "-NAGA2Mhh4f_2zY_BhvS",
                "type": "text",
                "notes": false
              }
            },
            name: "General Data",
            jobUID: "-NAA8oPA7-MrbJ7qkCqm",
            productUID: "-NAA8oqj7r-SVn3uAo7l"
          },
          '-NAEq5mKPySi0UDpCUO0': {
            InputArray: [
              '-NAEtFbnf5lPvwo3SeNS',
              '-NAEtHAn4_afAcWZd4HB',
              '-NAEtJNQOIAeMDpRkOSj',
              '-NAEtL4vPnWTSlCG9Pq-'
            ],
            Inputs: {
              '-NAEtFbnf5lPvwo3SeNS': {
                inputType: 'input',
                jobUID: '-NAA8oPA7-MrbJ7qkCqm',
                name: 'Air filter present',
                notes: false,
                options: ['Yes', 'No'],
                productUID: '-NAA8oqj7r-SVn3uAo7l',
                sectionUID: '-NAEq5mKPySi0UDpCUO0',
                type: 'radio'
              },
              '-NAEtHAn4_afAcWZd4HB': {
                inputType: 'input',
                jobUID: '-NAA8oPA7-MrbJ7qkCqm',
                name: 'Type',
                notes: false,
                options: ['Metal', 'Foam', 'Other'],
                productUID: '-NAA8oqj7r-SVn3uAo7l',
                sectionUID: '-NAEq5mKPySi0UDpCUO0',
                type: 'radio'
              },
              '-NAEtJNQOIAeMDpRkOSj': {
                inputType: 'input',
                jobUID: '-NAA8oPA7-MrbJ7qkCqm',
                name: 'Clean / Dusty / Clogged',
                notes: false,
                options: ['Clean', 'Dusty', 'Clogged'],
                productUID: '-NAA8oqj7r-SVn3uAo7l',
                sectionUID: '-NAEq5mKPySi0UDpCUO0',
                type: 'radio'
              },
              '-NAEtL4vPnWTSlCG9Pq-': {
                inputType: 'input',
                jobUID: '-NAA8oPA7-MrbJ7qkCqm',
                name: 'Filter Changed / Installed',
                notes: false,
                options: ['Yes', 'No'],
                productUID: '-NAA8oqj7r-SVn3uAo7l',
                sectionUID: '-NAEq5mKPySi0UDpCUO0',
                type: 'radio'
              }
            },
            jobUID: '-NAA8oPA7-MrbJ7qkCqm',
            name: 'Air Filter',
            productUID: '-NAA8oqj7r-SVn3uAo7l'
          },
          '-NAEqV8orc-acaTuERhF': {
            InputArray: [
              '-NAEtM2vKiJLlS6X1uu7',
              '-NMOWu_EYaobnaF33wsu',
              '-NAEtN_vgZ4VCHDnTyPI',
              '-NAEtPAvyOsvZwo9Rp9I',
              '-NAEtQ7AtzW60AuQPLYE',
              '-NAEwQ8fy5mcjC8haj6W'
            ],
            Inputs: {
              '-NAEtM2vKiJLlS6X1uu7': {
                inputType: 'input',
                jobUID: '-NAA8oPA7-MrbJ7qkCqm',
                name: 'Setpoint °F',
                productUID: '-NAA8oqj7r-SVn3uAo7l',
                sectionUID: '-NAEqV8orc-acaTuERhF',
                type: 'text'
              },
              '-NMOWu_EYaobnaF33wsu': {
                inputType: 'input',
                jobUID: '-NAA8oPA7-MrbJ7qkCqm',
                name: 'Hysteresis °F',
                productUID: '-NAA8oqj7r-SVn3uAo7l',
                sectionUID: '-NAEqV8orc-acaTuERhF',
                type: 'text'
              },
              '-NAEtN_vgZ4VCHDnTyPI': {
                inputType: 'input',
                jobUID: '-NAA8oPA7-MrbJ7qkCqm',
                name: 'Display Function',
                notes: false,
                options: ['Ok', 'N. Ok'],
                productUID: '-NAA8oqj7r-SVn3uAo7l',
                sectionUID: '-NAEqV8orc-acaTuERhF',
                type: 'radio'
              },
              '-NAEtPAvyOsvZwo9Rp9I': {
                inputType: 'input',
                type: 'text',
                jobUID: '-NAA8oPA7-MrbJ7qkCqm',
                name: 'Software version',
                notes: false,
                productUID: '-NAA8oqj7r-SVn3uAo7l',
                sectionUID: '-NAEqV8orc-acaTuERhF'
              },
              '-NAEtQ7AtzW60AuQPLYE': {
                inputType: 'input',
                jobUID: '-NAA8oPA7-MrbJ7qkCqm',
                name: 'History Downloaded',
                notes: false,
                options: ['Yes', 'No'],
                productUID: '-NAA8oqj7r-SVn3uAo7l',
                sectionUID: '-NAEqV8orc-acaTuERhF',
                type: 'radio'
              },
              '-NAEwQ8fy5mcjC8haj6W': {
                inputType: 'input',
                jobUID: '-NAA8oPA7-MrbJ7qkCqm',
                name: 'Error Messages',
                notes: false,
                options: ['Yes', 'No'],
                productUID: '-NAA8oqj7r-SVn3uAo7l',
                sectionUID: '-NAEqV8orc-acaTuERhF',
                type: 'radio'
              }
            },
            jobUID: '-NAA8oPA7-MrbJ7qkCqm',
            name: 'System Controls',
            productUID: '-NAA8oqj7r-SVn3uAo7l'
          },
          '-NMOXXp6O-mAkfKzUpQx': {
            InputArray: [
              '-NMOXqPjPmMre1DEBUTq',
              '-NMOXtBzcx88oDiA7k8d',
              '-NMOXwEz1FUsJkRUpiJt',
              '-NMOXxjj5fEHJHEm5mza',
              '-NMOXzKLZiOw9BIMVhzP'
            ],
            Inputs: {
              '-NMOXqPjPmMre1DEBUTq': {
                inputType: 'input',
                jobUID: '-NAA8oPA7-MrbJ7qkCqm',
                name: 'Voltage Average (V)',
                productUID: '-NAA8oqj7r-SVn3uAo7l',
                sectionUID: '-NMOXXp6O-mAkfKzUpQx',
                type: 'text'
              },
              '-NMOXtBzcx88oDiA7k8d': {
                inputType: 'input',
                jobUID: '-NAA8oPA7-MrbJ7qkCqm',
                name: 'Compressor Amp Draw (Amps)',
                productUID: '-NAA8oqj7r-SVn3uAo7l',
                sectionUID: '-NMOXXp6O-mAkfKzUpQx',
                type: 'text'
              },
              '-NMOXwEz1FUsJkRUpiJt': {
                inputType: 'input',
                jobUID: '-NAA8oPA7-MrbJ7qkCqm',
                name: 'Evap Fan RPM',
                productUID: '-NAA8oqj7r-SVn3uAo7l',
                sectionUID: '-NMOXXp6O-mAkfKzUpQx',
                type: 'text'
              },
              '-NMOXxjj5fEHJHEm5mza': {
                inputType: 'input',
                jobUID: '-NAA8oPA7-MrbJ7qkCqm',
                name: 'Condenser Fan RPM',
                productUID: '-NAA8oqj7r-SVn3uAo7l',
                sectionUID: '-NMOXXp6O-mAkfKzUpQx',
                type: 'text'
              },
              '-NMOXzKLZiOw9BIMVhzP': {
                inputType: 'input',
                jobUID: '-NAA8oPA7-MrbJ7qkCqm',
                name: 'Error Messages (list all below)',
                notes: false,
                options: ['Yes', 'No'],
                productUID: '-NAA8oqj7r-SVn3uAo7l',
                sectionUID: '-NMOXXp6O-mAkfKzUpQx',
                type: 'radio'
              }
            },
            jobUID: '-NAA8oPA7-MrbJ7qkCqm',
            name: 'RiDiag III Device Cockpit',
            productUID: '-NAA8oqj7r-SVn3uAo7l'
          },
          '-NAEqXzJMn6IDuSIbFSZ': {
            InputArray: [
              '-NAEzVzoirnFG9sKvWHJ',
              '-NAEzXNBz9zBMbppGXpZ',
              '-NAEzYBJaGNXedLVnbB2'
            ],
            Inputs: {
              '-NAEzVzoirnFG9sKvWHJ': {
                inputType: 'input',
                type: 'text',
                jobUID: '-NAA8oPA7-MrbJ7qkCqm',
                name: 'Temperature °F',
                notes: false,
                productUID: '-NAA8oqj7r-SVn3uAo7l',
                sectionUID: '-NAEqXzJMn6IDuSIbFSZ'
              },
              '-NAEzXNBz9zBMbppGXpZ': {
                inputType: 'input',
                type: 'text',
                jobUID: '-NAA8oPA7-MrbJ7qkCqm',
                name: 'Humidity %',
                notes: false,
                productUID: '-NAA8oqj7r-SVn3uAo7l',
                sectionUID: '-NAEqXzJMn6IDuSIbFSZ'
              },
              '-NAEzYBJaGNXedLVnbB2': {
                inputType: 'input',
                jobUID: '-NAA8oPA7-MrbJ7qkCqm',
                name: 'Dusty',
                notes: false,
                options: ['Yes', 'No'],
                productUID: '-NAA8oqj7r-SVn3uAo7l',
                sectionUID: '-NAEqXzJMn6IDuSIbFSZ',
                type: 'radio'
              }
            },
            jobUID: '-NAA8oPA7-MrbJ7qkCqm',
            name: 'Ambient Conditions',
            productUID: '-NAA8oqj7r-SVn3uAo7l'
          },
          '-NAEq_Qg5g2WELq2ivFs': {
            InputArray: [
              '-NAF8Jc22LAwK93VIgiA',
              '-NMOZrvUFJbGanQKMA6A',
              '-NAF8LLYpYQgWBwEI7kc',
              '-NAF8MJAzo2A0KcQ7mjA',
              '-NAF8NBnnMWM6ycsHyGQ',
              '-NAF8OrAfDztzV-ETSbJ',
              '-NAF8QHAILAtGYVaQULU',
              '-NAF8RgIQq0P8Cm5U02K'
            ],
            Inputs: {
              '-NAF8Jc22LAwK93VIgiA': {
                inputType: 'input',
                type: 'text',
                jobUID: '-NAA8oPA7-MrbJ7qkCqm',
                name: 'Temp. air in °F',
                notes: false,
                productUID: '-NAA8oqj7r-SVn3uAo7l',
                sectionUID: '-NAEq_Qg5g2WELq2ivFs'
              },
              '-NMOZrvUFJbGanQKMA6A': {
                inputType: 'input',
                type: 'text',
                jobUID: '-NAA8oPA7-MrbJ7qkCqm',
                name: 'Temp. air out °F',
                notes: false,
                productUID: '-NAA8oqj7r-SVn3uAo7l',
                sectionUID: '-NAEq_Qg5g2WELq2ivFs'
              },
              '-NAF8LLYpYQgWBwEI7kc': {
                inputType: 'input',
                type: 'text',
                jobUID: '-NAA8oPA7-MrbJ7qkCqm',
                name: '∆T Condenser',
                notes: false,
                productUID: '-NAA8oqj7r-SVn3uAo7l',
                sectionUID: '-NAEq_Qg5g2WELq2ivFs'
              },
              '-NAF8MJAzo2A0KcQ7mjA': {
                inputType: 'input',
                jobUID: '-NAA8oPA7-MrbJ7qkCqm',
                name: 'Clean / Dusty / Clogged',
                notes: false,
                options: ['Clean', 'Dusty', 'Clogged'],
                productUID: '-NAA8oqj7r-SVn3uAo7l',
                sectionUID: '-NAEq_Qg5g2WELq2ivFs',
                type: 'radio'
              },
              '-NAF8NBnnMWM6ycsHyGQ': {
                inputType: 'input',
                jobUID: '-NAA8oPA7-MrbJ7qkCqm',
                name: 'Condenser cleaned',
                notes: false,
                options: ['Yes','No'],
                productUID: '-NAA8oqj7r-SVn3uAo7l',
                sectionUID: '-NAEq_Qg5g2WELq2ivFs',
                type: 'radio'
              },
              '-NAF8OrAfDztzV-ETSbJ': {
                inputType: 'input',
                jobUID: '-NAA8oPA7-MrbJ7qkCqm',
                name: 'Air circulation clear',
                notes: false,
                options: ['OK','N. OK'],
                productUID: '-NAA8oqj7r-SVn3uAo7l',
                sectionUID: '-NAEq_Qg5g2WELq2ivFs',
                type: 'radio'
              },
              '-NAF8QHAILAtGYVaQULU': {
                inputType: 'input',
                jobUID: '-NAA8oPA7-MrbJ7qkCqm',
                name: 'Condenser fan function',
                notes: false,
                options: ['OK','N. OK'],
                productUID: '-NAA8oqj7r-SVn3uAo7l',
                sectionUID: '-NAEq_Qg5g2WELq2ivFs',
                type: 'radio'
              },
              '-NAF8RgIQq0P8Cm5U02K': {
                inputType: 'input',
                type: 'radio',
                jobUID: '-NAA8oPA7-MrbJ7qkCqm',
                name: 'General Condition',
                notes: false,
                options: ['OK','N. OK'],
                productUID: '-NAA8oqj7r-SVn3uAo7l',
                sectionUID: '-NAEq_Qg5g2WELq2ivFs'
              }
            },
            jobUID: '-NAA8oPA7-MrbJ7qkCqm',
            name: 'Condenser Section',
            productUID: '-NAA8oqj7r-SVn3uAo7l'
          },
          '-NMOmrp0FDcATvBazN5v': {
            InputArray: [
              '-NMOnE3llRK64VhdyYZe',
              '-NMOnFm8fWg7RTJkfwTM',
              '-NMOnH6dKjhSNnau8TVs',
              '-NMOnKEtQAbbSSMJ2Djb',
              '-NMOnLPlQyPHY1oMXfUx',
              '-NMOnMkGixNzAQpTFPwx'
            ],
            Inputs: {
              '-NMOnE3llRK64VhdyYZe': {
                inputType: 'input',
                jobUID: '-NAA8oPA7-MrbJ7qkCqm',
                name: 'Check position',
                notes: false,
                options: ['OK','N. OK','NA'],
                productUID: '-NAA8oqj7r-SVn3uAo7l',
                sectionUID: '-NMOmrp0FDcATvBazN5v',
                type: 'radio'
              },
              '-NMOnFm8fWg7RTJkfwTM': {
                inputType: 'input',
                jobUID: '-NAA8oPA7-MrbJ7qkCqm',
                name: 'Clip installed for Blue E+?',
                notes: false,
                options: ['Yes','No','NA'],
                productUID: '-NAA8oqj7r-SVn3uAo7l',
                sectionUID: '-NMOmrp0FDcATvBazN5v',
                type: 'radio'
              },
              '-NMOnH6dKjhSNnau8TVs': {
                inputType: 'input',
                jobUID: '-NAA8oPA7-MrbJ7qkCqm',
                name: 'Functioning',
                notes: false,
                options: ['Yes','No','NA'],
                productUID: '-NAA8oqj7r-SVn3uAo7l',
                sectionUID: '-NMOmrp0FDcATvBazN5v',
                type: 'radio'
              },
              '-NMOnKEtQAbbSSMJ2Djb': {
                inputType: 'input',
                jobUID: '-NAA8oPA7-MrbJ7qkCqm',
                name: 'Draining',
                notes: false,
                productUID: '-NAA8oqj7r-SVn3uAo7l',
                sectionUID: '-NMOmrp0FDcATvBazN5v',
                type: 'radio',
                options: ['Yes','No','NA']
              },
              '-NMOnLPlQyPHY1oMXfUx': {
                inputType: 'input',
                jobUID: '-NAA8oPA7-MrbJ7qkCqm',
                name: 'Internal Drain Hose cleared',
                notes: false,
                options: ['Yes','No'],
                productUID: '-NAA8oqj7r-SVn3uAo7l',
                sectionUID: '-NMOmrp0FDcATvBazN5v',
                type: 'radio'
              },
              '-NMOnMkGixNzAQpTFPwx': {
                inputType: 'input',
                jobUID: '-NAA8oPA7-MrbJ7qkCqm',
                name: 'External Drain Hose cleared',
                notes: false,
                options: ['Yes','No'],
                productUID: '-NAA8oqj7r-SVn3uAo7l',
                sectionUID: '-NMOmrp0FDcATvBazN5v',
                type: 'radio'
              }
            },
            jobUID: '-NAA8oPA7-MrbJ7qkCqm',
            name: 'Condensate Evaporator',
            productUID: '-NAA8oqj7r-SVn3uAo7l'
          },
          '-NAEqahgZIkV37qah1Dk': {
            InputArray: [
              '-NAFRuUP-M8q7OfrGbcW',
              '-NAFRw-GR2MeDra6lG0S',
              '-NAFRx-W8_DYpGzyMRpg',
              '-NAFRy1GdkzkbyNsMT7T',
              '-NAFRyz0MwbaFuTbiEPe',
              '-NAFS-ql8ZOaBnMeqtvM',
              '-NAFS0qGynOOK5KzmTuP'
            ],
            Inputs: {
              '-NAFRuUP-M8q7OfrGbcW': {
                inputType: 'input',
                jobUID: '-NAA8oPA7-MrbJ7qkCqm',
                name: 'Visible Damages',
                notes: false,
                options: ['Yes','No'],
                productUID: '-NAA8oqj7r-SVn3uAo7l',
                sectionUID: '-NAEqahgZIkV37qah1Dk',
                type: 'radio'
              },
              '-NAFRw-GR2MeDra6lG0S': {
                inputType: 'input',
                type: 'radio',
                jobUID: '-NAA8oPA7-MrbJ7qkCqm',
                name: 'Plastic Grill Condition',
                notes: false,
                options: ['OK','N. OK'],
                productUID: '-NAA8oqj7r-SVn3uAo7l',
                sectionUID: '-NAEqahgZIkV37qah1Dk'
              },
              '-NAFRx-W8_DYpGzyMRpg': {
                inputType: 'input',
                jobUID: '-NAA8oPA7-MrbJ7qkCqm',
                name: 'Sealing to cabinet OK',
                notes: false,
                options: ['OK','N. OK'],
                productUID: '-NAA8oqj7r-SVn3uAo7l',
                sectionUID: '-NAEqahgZIkV37qah1Dk',
                type: 'radio'
              },
              '-NAFRy1GdkzkbyNsMT7T': {
                inputType: 'input',
                jobUID: '-NAA8oPA7-MrbJ7qkCqm',
                name: 'Rattling Noises',
                notes: false,
                options: ['Yes','No'],
                productUID: '-NAA8oqj7r-SVn3uAo7l',
                sectionUID: '-NAEqahgZIkV37qah1Dk',
                type: 'radio'
              },
              '-NAFRyz0MwbaFuTbiEPe': {
                inputType: 'input',
                jobUID: '-NAA8oPA7-MrbJ7qkCqm',
                name: 'Excess Vibration',
                notes: false,
                options: ['Yes','No'],
                productUID: '-NAA8oqj7r-SVn3uAo7l',
                sectionUID: '-NAEqahgZIkV37qah1Dk',
                type: 'radio'
              },
              '-NAFS-ql8ZOaBnMeqtvM': {
                inputType: 'input',
                jobUID: '-NAA8oPA7-MrbJ7qkCqm',
                name: 'Defective components (List any below)',
                notes: false,
                options: ['Yes','No'],
                productUID: '-NAA8oqj7r-SVn3uAo7l',
                sectionUID: '-NAEqahgZIkV37qah1Dk',
                type: 'radio'
              },
              '-NAFS0qGynOOK5KzmTuP': {
                inputType: 'input',
                jobUID: '-NAA8oPA7-MrbJ7qkCqm',
                name: 'Functional Test Successful',
                notes: false,
                options: ['OK','N. OK'],
                productUID: '-NAA8oqj7r-SVn3uAo7l',
                sectionUID: '-NAEqahgZIkV37qah1Dk',
                type: 'radio'
              }
            },
            jobUID: '-NAA8oPA7-MrbJ7qkCqm',
            name: 'General',
            productUID: '-NAA8oqj7r-SVn3uAo7l'
          },
          '-NAFTDe9hJd3hZtQQKAM': {
            InputArray: [
              '-NAFTR1eMSzsITuxWtQh',
              '-NAFTVm1Mi9xb01pFo4h'
            ],
            Inputs: {
              '-NAFTR1eMSzsITuxWtQh': {
                accept: 'image/*',
                inputType: 'input',
                jobUID: '-NAA8oPA7-MrbJ7qkCqm',
                maxNumber: 5,
                name: 'Images',
                pattern: 'image-*',
                productUID: '-NAA8oqj7r-SVn3uAo7l',
                sectionUID: '-NAFTDe9hJd3hZtQQKAM',
                type: 'upload'
              },
              '-NAFTVm1Mi9xb01pFo4h': {
                inputType: 'input',
                type: 'textarea',
                jobUID: '-NAA8oPA7-MrbJ7qkCqm',
                name: 'Notes',
                notes: false,
                productUID: '-NAA8oqj7r-SVn3uAo7l',
                sectionUID: '-NAFTDe9hJd3hZtQQKAM'
              }
            },
            jobUID: '-NAA8oPA7-MrbJ7qkCqm',
            name: 'Notes / recommendations / spare parts',
            productUID: '-NAA8oqj7r-SVn3uAo7l'
          }
        },
        jobUID: '-NAA8oPA7-MrbJ7qkCqm',
        name: 'Amazon AC'
      }
    },
    name: 'Amazon Service Order / Report'
  }
}
