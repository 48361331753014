import { colors } from './../../../config/styles'

export default {
    input: (disabled) => ({
        position: 'relative',
        display: 'block',
        width: '100%',
        paddingTop: 10,
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 10,
        fontSize: 14,
        backgroundColor: colors.white,
        minHeight: 140,
        borderRadius: 5,
        lineHeight: 1.5,
        cursor: disabled ? 'not-allowed' : 'text',

        borderTop: disabled ? `1px solid ${colors.lighterGray}` : `1px solid ${colors.lightGray}`,
        borderBottom: disabled ? `1px solid ${colors.lighterGray}` : `1px solid ${colors.lightGray}`,
        borderLeft: disabled ? `1px solid ${colors.lighterGray}` : `1px solid ${colors.lightGray}`,
        borderRight: disabled ? `1px solid ${colors.lighterGray}` : `1px solid ${colors.lightGray}`,

        transition: 'border-color 0.2s ease-in, box-shadow 0.2s ease-in',
        color: colors.gray,

        ':hover': {
            borderTop: `1px solid ${colors.primary}`,
            borderBottom: `1px solid ${colors.primary}`,
            borderLeft: `1px solid ${colors.primary}`,
            borderRight: `1px solid ${colors.primary}`,
            boxShadow: `0 0 0 1px ${colors.primary} inset`,
        },
        ':active': {
            borderTop: `1px solid ${colors.primary}`,
            borderBottom: `1px solid ${colors.primary}`,
            borderLeft: `1px solid ${colors.primary}`,
            borderRight: `1px solid ${colors.primary}`,
            boxShadow: `0 0 0 1px ${colors.primary} inset`,
        }
    })
}