import React from 'react'
import axios from 'axios'
import validate from 'validate.js'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import { renderEmail } from 'react-html-email'

import TESimpleEmail from './../component/Email/TESimpleEmail'
import settings from './../config/settings'
import { SHOW_ALERT, SHOW_NETWORK_ACTIVITY, HIDE_NETWORK_ACTIVITY } from './types'

export const saveProfile = (data) => {
	//Validate Data
	const validatorConstraints = {
		firstName: {
			presence: {
				allowEmpty: false,
			},
		},
		lastName: {
			presence: {
				allowEmpty: false,
			},
		},
	}
	const validationResponse = validate(data, validatorConstraints)
	if (validationResponse) {
		return (dispatch) => {
			dispatch({
				type: SHOW_ALERT,
				payload: {
					alertTitle: 'Error',
					alertMessage: Object.values(validationResponse)[0][0],
				},
			})
		}
	}

	return (dispatch) => {
		dispatch({
			type: SHOW_NETWORK_ACTIVITY,
			payload: 'Saving Profile Details...',
		})

		const { firstName, lastName } = data
		const { uid } = firebase.auth().currentUser
		firebase
			.database()
			.ref(`Users/${uid}`)
			.update({
				firstName,
				lastName,
			})
			.then(() => {
				dispatch({ type: HIDE_NETWORK_ACTIVITY })
				dispatch({
					type: SHOW_ALERT,
					payload: {
						alertTitle: 'Success',
						alertMessage: 'Profile Details Saved.',
					},
				})
			})
			.catch((error) => {
				console.log(error)
				dispatch({ type: HIDE_NETWORK_ACTIVITY })
				dispatch({
					type: SHOW_ALERT,
					payload: {
						alertTitle: 'Error',
						alertMessage: 'Error Saving Profile Details.',
					},
				})
			})
	}
}

export const sendAppCode = (data) => {
	const { email } = data

	//Validate Data
	const validatorConstraints = {
		email: {
			presence: {
				allowEmpty: false,
			},
			email: true,
		},
	}
	const validationResponse = validate(data, validatorConstraints)
	if (validationResponse) {
		return (dispatch) => {
			return new Promise((res, rej) => {
				dispatch({
					type: SHOW_ALERT,
					payload: {
						alertTitle: 'Error',
						alertMessage: Object.values(validationResponse)[0][0],
					},
				})
				return rej()
			})
		}
	}

	return (dispatch) => {
		return new Promise((res, rej) => {
			dispatch({
				type: SHOW_NETWORK_ACTIVITY,
				payload: 'Sending App Code...',
			})

			let appCode
			firebase
				.database()
				.ref(`appCodes/active`)
				.limitToFirst(1)
				.once('value')
				.then((snapshot) => {
					appCode = snapshot.val()
					console.log(appCode)
					if (
						appCode &&
						Object.keys(appCode)[0] &&
						appCode[Object.keys(appCode)[0]] &&
						appCode[Object.keys(appCode)[0]].code &&
						appCode[Object.keys(appCode)[0]].url
					) {
						const { code, url } = appCode[Object.keys(appCode)[0]]
						//Send Email
						const emailSubject = 'Rittal Service Visit iOS App Code'

						const emailString = `
							Rittal Service Visit iOS App Code\n\r\n\r
							On an iOS device click the link below:\n\r\n\r${url}\n\r\n\rIf that does not work try to redeem the following code through the app store. Go to your account and then tap "Redeeem Gift Card or Code". Then manually enter the following:\n\r\n\r${code}\n\r\n\rIf you have any issues please contact service@rittal.us.\n\r\n\r
							Rittal Service Visit App Team
						`

						const emailHTMLString = renderEmail(
							<TESimpleEmail
								emailTitle='Rittal Service Visit iOS App Code'
								title='Rittal Service Visit iOS App Code'
								body={
									<div>
										On an iOS device click the link below:
										<br />
										<br />
										{url}
										<br />
										<br />
										If the above link doesn't work try to redeem the following code
										through the app store. Go to your account and then tap "Redeem
										Gift Card or Code". Then manually enter the following:
										<br />
										<br />
										{code}
										<br />
										<br />
										If you have any issues please contact service@rittal.us.
									</div>
								}
								signature='Rittal Service Visit App Team'
							/>
						)

						const emailData = {
							bccEmailAddresses: [],
							ccEmailAdresses: [],
							toEmailAddresses: [email],
							subjectdata: emailSubject,
							bodyData: emailString,
							htmlData: emailHTMLString,
							bodyCharset: 'UTF-8',
							subjestCharset: 'UTF-8',
							sourceEmail: '"Rittal Service" <service@rittal.us>',
							replyToAddresses: ['"Rittal Service" <service@rittal.us>'],
						}
						// console.log(emailData)
						return axios.post(settings.EMAIL_API_ROUTE, emailData)
					} else {
						throw new Error('No App Codes Found')
					}
				})
				.then((resposne) => {
					console.log(resposne)
					const codeUID = Object.keys(appCode)[0]
					return firebase
						.database()
						.ref(`appCodes/used/${codeUID}`)
						.set(appCode[codeUID])
				})
				.then(() => {
					const codeUID = Object.keys(appCode)[0]
					return firebase
						.database()
						.ref(`appCodes/active/${codeUID}`)
						.remove()
				})
				.then(() => {
					dispatch({ type: HIDE_NETWORK_ACTIVITY })
					dispatch({
						type: SHOW_ALERT,
						payload: {
							alertTitle: 'Success',
							alertMessage: 'Email has been sent with the iOS access code.',
						},
					})
					return res()
				})
				.catch((error) => {
					console.log(error)
					dispatch({ type: HIDE_NETWORK_ACTIVITY })
					dispatch({
						type: SHOW_ALERT,
						payload: {
							alertTitle: 'Error',
							alertMessage: 'Error Loading App Codes',
						},
					})
					return rej()
				})
		})
	}
}
export const sendAdnroidDownload = (data) => {
	const { email } = data

	//Validate Data
	const validatorConstraints = {
		email: {
			presence: {
				allowEmpty: false,
			},
			email: true,
		},
	}
	const validationResponse = validate(data, validatorConstraints)
	if (validationResponse) {
		return (dispatch) => {
			return new Promise((res, rej) => {
				dispatch({
					type: SHOW_ALERT,
					payload: {
						alertTitle: 'Error',
						alertMessage: Object.values(validationResponse)[0][0],
					},
				})
				return rej()
			})
		}
	}

	return (dispatch) => {
		return new Promise((res, rej) => {
			dispatch({
				type: SHOW_NETWORK_ACTIVITY,
				payload: 'Sending App Link...',
			})

			const url =
				'https://service.tsitpro.com' +
				require('./../misc/rittal-servicing-visit-9106e5e9e45a4238a5961fa3331b8f3e-signed.apk')

			//Send Email
			const emailSubject = 'Rittal Service Visit Adnroid App Link'
			const emailString = `
							Rittal Service Visit Adnroid App Link\n\r\n\r
							On an android device click the link below:\n\r\n\r${url}\n\r\n\rIf you have any issues please contact service@rittal.us.\n\r\n\r
							Rittal Service Visit App Team
						`
			const emailHTMLString = renderEmail(
				<TESimpleEmail
					emailTitle='Rittal Service Visit Adnroid App Link'
					title='Rittal Service Visit Adnroid App Link'
					body={
						<div>
							On an android device click the link below:
							<br />
							<br />
							{url}
							<br />
							<br />
							If you have any issues please contact service@rittal.us.
						</div>
					}
					signature='Rittal Service Visit App Team'
				/>
			)

			const emailData = {
				bccEmailAddresses: [],
				ccEmailAdresses: [],
				toEmailAddresses: [email],
				subjectdata: emailSubject,
				bodyData: emailString,
				htmlData: emailHTMLString,
				bodyCharset: 'UTF-8',
				subjestCharset: 'UTF-8',
				sourceEmail: '"Rittal Service" <service@rittal.us>',
				replyToAddresses: ['"Rittal Service" <service@rittal.us>'],
			}

			axios
				.post(settings.EMAIL_API_ROUTE, emailData)
				.then(() => {
					dispatch({ type: HIDE_NETWORK_ACTIVITY })
					dispatch({
						type: SHOW_ALERT,
						payload: {
							alertTitle: 'Success',
							alertMessage: 'Email has been sent with the android app download link.',
						},
					})
					return res()
				})
				.catch((error) => {
					console.log(error)
					dispatch({ type: HIDE_NETWORK_ACTIVITY })
					dispatch({
						type: SHOW_ALERT,
						payload: {
							alertTitle: 'Error',
							alertMessage: 'Error Sending Android Download Link',
						},
					})
					return rej()
				})
		})
	}
}
