import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import Radium from 'radium'
import ReactTable from 'react-table'

import { getOrganizationUserArray } from './../../../../selectors/Admin'

import TEHelmet from './../../../../component/TEHelmet'
import TEButton from './../../../../component/Form/TEButton'

import PanelWrapper from './../../../../component/Layout/PanelWrapper'
import Panel from './../../../../component/Layout/Panel'

import CreateUserPopup from './CreateUserPopup'

import { tableColumns } from './tableConfigs'
import { customTableFilter } from './../../../../helpers'
import styles from './styles'

class Organizations extends Component {
	state = { createUserVisible: false }
	render() {
		const meta = {
			title: 'Rittal | Organization Users',
			description: '',
		}
		const { createUserVisible } = this.state
		const { userArray, userModel } = this.props
		const { organizationUID } = userModel
		return (
			<Fragment>
				<TEHelmet {...meta} />
				<PanelWrapper>
					<Panel
						title="Organization Users"
						rightComponent={
							<TEButton
								onClick={() => this.setState({ createUserVisible: true })}
								style={styles.actionButton}
							>
								Create User
							</TEButton>
						}
					>
						<div style={styles.tableContainer}>
							<ReactTable
								data={userArray}
								columns={tableColumns}
								defaultPageSize={10}
								filterable
								defaultFilterMethod={customTableFilter}
								defaultSorted={[
									{
										id: 'name',
										desc: false,
									},
								]}
							/>
						</div>
					</Panel>
				</PanelWrapper>

				<CreateUserPopup
					visible={createUserVisible}
					onClose={() => this.setState({ createUserVisible: false })}
					orgUID={organizationUID}
				/>
			</Fragment>
		)
	}
}

Organizations.propTypes = {}

Organizations.defaultProps = {}

const mapStateToProps = (state) => {
	const { userModel } = state.Loading
	const userArray = getOrganizationUserArray(state)
	return { userArray, userModel }
}

export default connect(
	mapStateToProps,
	{}
)(Radium(Organizations))
