//
// Version 0.3.0
//

import React, { Component } from 'react'
import Radium from 'radium'
import PropTypes from 'prop-types'
import { FaFileUpload, FaRegWindowClose } from 'react-icons/fa'

import TEButton from './../TEButton'

import styles from './styles'

class TEFileInput extends Component {
    state = {
        active: false,
        fileArray: [],
        error: false,
        errorMessage: '',
    }

    onDragEnter = (e) => {
        this.setState({ active: true })
    }

    onDragLeave = (e) => {
        this.setState({ active: false })
    }

    onDragOver = (e) => {
        e.preventDefault()
    }

    onDrop = (e) => {
        e.preventDefault()
        this.setState({ active: false })
        this.onFileChange(e, e.dataTransfer.files)
    }

    onFileChange = (e, droppedFiles) => {
        // console.log('onFileChange')
        const { pattern, onChange } = this.props
        const files = e.target.files || droppedFiles

        if (!files) {
            console.error('No file provided to input.')
            return
        }

        //Validate Files
        if (pattern && pattern instanceof RegExp) {
            for (let i = 0; i < files.length; i++) {
                if (!files[i].type.match(pattern)) {
                    console.warn('One of the selected files is not of the correct type.')
                    this.setState({
                        error: true,
                        errorMessage: 'One of the selected files is not of the correct type.',
                    })
                    return
                }
            }
        }

        this.setState({
            fileArray: files,
            error: false,
            errorMessage: '',
        })
        onChange(files)

        // const readFile = (file) => {
        //     const { name } = file
        //     const reader = new FileReader()

        //     reader.onload = (e) => {
        //         const { fileArray } = this.state
        //         const updateFileArray = [...fileArray, { name, value: reader.result }]
        //         this.setState({
        //             fileArray: updateFileArray,
        //             error: false,
        //             errorMessage: '',
        //         })
        //         onChange(updateFileArray)
        //     }

        //     reader.readAsDataURL(file)
        // }

        // for (let i = 0; i < files.length; i++) {
        //     readFile(files[i])
        // }
    }
    handleClearInput = () => {
        this.refs.input.value = null
        this.setState({ fileArray: [] })
    }

    render() {
        const { active, fileArray, error, errorMessage } = this.state
        const { style, buttonStyle, inputRef, ...rest } = this.props

        let labelCopy = rest.label !== '' ? rest.label : 'Choose a file...'
        if (fileArray && fileArray.length === 1) {
            labelCopy = fileArray[0].name
        } else if (fileArray && fileArray.length > 1) {
            labelCopy = `${fileArray.length} files selected`
        }

        return (
            <div style={{ ...styles.wrapper, ...style }}>
                <div
                    style={styles.inputWrapper(active, rest.disabled)}
                    onDragEnter={this.onDragEnter}
                    onDragLeave={this.onDragLeave}
                    onDragOver={this.onDragOver}
                    onDrop={this.onDrop}
                >
                    <label style={styles.label(active, rest.disabled)}>
                        <span style={styles.labelCopy}>
                            <FaFileUpload style={styles.labelIcon} />
                            {labelCopy}
                        </span>
                        <input
                            {...rest}
                            type="file"
                            ref="input"
                            style={styles.input}
                            onChange={this.onFileChange}
                        />
                    </label>
                    <TEButton
                        onClick={this.handleClearInput}
                        style={styles.clearButton}
                        disabled={rest.disabled}
                    >
                        <FaRegWindowClose style={styles.buttonIcon} />
                    </TEButton>
                </div>
                {error && <span style={styles.errorMessage}>{errorMessage}</span>}
            </div>
        )
    }
}

TEFileInput.propTypes = {
    style: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    accept: PropTypes.string, //OS Hint of file type for dialog - NOT safety check of type
    // pattern: PropTypes.RegExp, //This is the actual strict catch. Regex // Don't know regex proptype
    id: PropTypes.string,
    disabled: PropTypes.bool,
    label: PropTypes.string
}

TEFileInput.defaultProps = {}

export default Radium(TEFileInput)
