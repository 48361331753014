import { colors } from './../../../config/styles'

export default {
	container: {
		position: 'fixed',
		top: 0,
		bottom: 0,
		left: 0,
		maxHeight: '100vh',
		height: '100%',
		width: 200,
		display: 'flex',
		flexDirection: 'column',
		zIndex: 20,
		backgroundColor: colors.white,
		overflowX: 'hidden',
		overflowY: 'auto',
		boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 15px 0px, rgba(0, 0, 0, 0.05) 1px 0px 5px 0px',
	},
	brandContainer: {
		width: 50,
		height: 130,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	logo: {
		objectFit: 'contain',
		width: '100%',

		paddingLeft: 60,
		paddingRight: 60,
		paddingTop: 30,
		paddingBottom: 30,
	},
	navContainer: {
		height: '100%',
	},
	mainUl: {
		padding: 0,
		margin: 0,
	},
	footerLogo: {
		objectFit: 'contain',
		width: '100%',

		paddingLeft: 50,
		paddingRight: 50,
		paddingTop: 30,
		paddingBottom: 30,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	footerBrandContainer: {},
}
