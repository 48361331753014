import { colors } from './../../../config/styles'

export default {
    label: (disabled, htmlFor) => {
        const style = {
            position: 'relative',
            display: 'block',
            width: 'auto',
            marginBottom: 3,
            fontWeight: 100,
            letterSpacing: 0.5,
            fontSize: 18,
            color: colors.darkerGray,
            cursor: 'pointer',

            '@media (max-width: 550px)': {
                fontSize: 16,
            },
        }

        if (htmlFor) {
            style.cursor = 'default'
        }
        if (disabled) {
            style.cursor = 'not-allowed'
            style.color = colors.gray
        }

        return style
    },
}
