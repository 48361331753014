//
//TE Version 0.2.0
//

//
// NOTES:
//
// 10-11-18 SC
// - May be able to be improved by moving to a render prop form but seems fine for now.
// - Could also improve by adding react-transition-group around each step
//

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Radium from 'radium'

import TEForm from './../TEForm'
import TEButton from './../TEButton'

import styles from './styles'

class TEMultiStepForm extends Component {
    state = { currentStep: 0 }

    componentDidMount() {
        const { onStepChange } = this.props
        const { currentStep } = this.state
        if (onStepChange) {
            onStepChange(currentStep)
        }

        this._isMounted = true
    }
    componentWillUnmount() {
        this._isMounted = false
    }

    handlePreviousPressed = () => {
        const { handleCancelOnClick, stepData, onStepChange } = this.props
        const { currentStep } = this.state

        const moveBack = () => {
            if (currentStep === 0) {
                if (handleCancelOnClick) {
                    handleCancelOnClick()
                }
            } else {
                this.setState({ currentStep: currentStep - 1 })
                if (onStepChange) {
                    onStepChange(currentStep - 1)
                }
            }
        }

        if (stepData && stepData[currentStep] && stepData[currentStep].onPrevious) {
            stepData[currentStep]
                .onPrevious()
                .then(() => moveBack())
                .catch((error) => console.log(error))
        } else {
            moveBack()
        }
    }
    handleNextPressed = () => {
        const { onSubmit, stepData, onStepChange } = this.props
        const { currentStep } = this.state

        const moveForward = () => {
            if (currentStep + 1 === stepData.length) {
                onSubmit()
                    .then(() => {
                        if (this._isMounted) {
                            this.setState({ currentStep: 0 })
                        }
                        if (onStepChange) {
                            onStepChange(0)
                        }
                    })
                    .catch((error) => console.log(error))
            } else {
                this.setState({ currentStep: currentStep + 1 })
                if (onStepChange) {
                    onStepChange(currentStep + 1)
                }
            }
        }

        if (stepData && stepData[currentStep] && stepData[currentStep].onNext) {
            stepData[currentStep]
                .onNext()
                .then(() => moveForward())
                .catch((error) => console.log(error))
        } else {
            moveForward()
        }
    }
    render() {
        const { handleCancelOnClick, stepData, roundedButtons, containerStyles } = this.props
        const { currentStep } = this.state

        if (!stepData || (stepData && stepData.length === 0)) {
            return null
        }

        return (
            <div style={{ ...styles.container, ...containerStyles }}>
                <div style={styles.stepsContainer}>
                    <div style={styles.stepBar(stepData.length)} />
                    {stepData.map((step, index) => {
                        const { title } = step

                        return (
                            <div key={index} style={styles.stepWrapper(stepData.length)}>
                                <div style={styles.stepNumber(index === currentStep)}>
                                    {index + 1}
                                </div>
                                <div style={styles.stepTitle}>{title}</div>
                            </div>
                        )
                    })}
                </div>
                <TEForm style={styles.form}>
                    {stepData[currentStep] && stepData[currentStep].component}
                </TEForm>
                <div style={styles.buttonContainer}>
                    {(handleCancelOnClick || currentStep > 0) && (
                        <TEButton
                            onClick={this.handlePreviousPressed}
                            style={styles.stepButtons('left', roundedButtons)}
                        >
                            {currentStep === 0 ? 'cancel' : 'previous'}
                        </TEButton>
                    )}
                    <TEButton
                        onClick={this.handleNextPressed}
                        style={styles.stepButtons(
                            'right',
                            roundedButtons,
                            !handleCancelOnClick && currentStep === 0
                        )}
                    >
                        {currentStep + 1 === stepData.length ? 'submit' : 'next'}
                    </TEButton>
                </div>
            </div>
        )
    }
}

TEMultiStepForm.propTypes = {
    onStepChange: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
    stepData: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            component: PropTypes.element,
            onPrevious: PropTypes.func,
            onNext: PropTypes.func,
        }).isRequired
    ).isRequired,
    roundedButtons: PropTypes.bool,
}

TEMultiStepForm.defaultProps = {}

export default Radium(TEMultiStepForm)
