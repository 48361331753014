import { colors } from './../../../config/styles'
//import images from './../../../config/images';

export default {
	container: {
		alignItems: 'center',
		justifyContent: 'center',
		minHeight: 'calc(100vh - 160px)',
		backgroundColor: colors.lightestGray,
	},
	panel: {
		maxWidth: 700,
		borderRadius: 5,
		paddingTop: 60,
		paddingBottom: 60,
		paddingRight: 60,
		paddingLeft: 60,
	},
	title: {
		textAlign: 'center',
		marginTop: 0,
		marginBottom: 20,
		fontSize: 38,
		color: colors.primary,
	},
	subtitle: {
		textAlign: 'center',
		marginTop: 0,
		marginBottom: 0,
		fontSize: 20,
		color: colors.darkerGray,
	},
}
