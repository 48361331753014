import { createSelector } from 'reselect'

export const getStandupData = (state) => state.Manager.Static.standup
export const getStandupDataArray = createSelector(
	getStandupData,
	(json) => {
		const standupArray = []
		const rows = json != null ? JSON.parse(json) : []
		for (const row of rows) {
			if(row.site !== '')
				standupArray.push(row)
		}
		return standupArray
	}
)
