import React, { Component } from 'react'
import Radium from 'radium'
import { connect } from 'react-redux'

import { addProduct } from './../../../../../actions/Technician'

import TEPopupForm from './../../../../../component/Popup/TEPopupForm'
import TESearchSelectRow from './../../../../../component/Form/TESearchSelectRow'

import { JobTypes } from './../../../../../config/localData'
import { mapObjectToArray } from './../../../../../helpers'

import styles from './styles'

class AddProductsPopup extends Component {
	state = { selectedProduct: undefined }

	handleOnSubmit = () => {
		const { selectedProduct } = this.state
		const { jobUID, organizationUID, addProduct, onSubmit, history } = this.props

		const data = { selectedProduct, organizationUID, jobUID, history }
		addProduct(data)
			.then(() => {
				onSubmit()
				this.setState({ selectedProduct: undefined })
			})
			.catch((error) => console.log(error))
	}

	render() {
		const { selectedProduct } = this.state
		const { visible, onClose, productTypeArray } = this.props

		return (
			<TEPopupForm
				visible={visible}
				contentStyles={styles.content}
				onClose={onClose}
				onSubmit={this.handleOnSubmit}
				onSubmitTitle="Add Product"
			>
				<h1 style={styles.formTitle}>Add Product</h1>
				<TESearchSelectRow
					labelForKey="productsToAdd"
					title="Select a Product"
					value={selectedProduct}
					onChange={({ option }) => {
						this.setState({ selectedProduct: option })
					}}
					options={productTypeArray}
					optionLabelPath="name"
					placeholder="product"
					required
				/>
			</TEPopupForm>
		)
	}
}

const mapStateToProps = (state) => {
	const { job } = state.Technician.Jobs

	let productTypeArray = []
	if (job && job.jobTypeUID) {
		productTypeArray = mapObjectToArray(JobTypes[job.jobTypeUID].ProductTypes)
	}
	productTypeArray.sort((a, b) => {
		if (a.name < b.name) return -1
		if (a.name > b.name) return 1
		return 0
	})

	return { productTypeArray }
}

export default connect(
	mapStateToProps,
	{
		addProduct,
	}
)(Radium(AddProductsPopup))
