import React, { Component } from 'react'
import Radium from 'radium'
import { connect } from 'react-redux'

import { addSparePart, saveSparePart } from './../../../../../actions/Manager'

import TEPopupForm from './../../../../../component/Popup/TEPopupForm'
import TESegmentedGroup from './../../../../../component/Form/TESegmentedGroup'
import TEInputRow from './../../../../../component/Form/TEInputRow'

import styles from './styles'

class AddSparePartPopup extends Component {
	state = { 
		uid: '',
		partNumber: '',
		partDescription: '',
		quantity: '',
		inStock: true  
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		const { sparePart } = nextProps
		if (sparePart && sparePart !== prevState.sparePart) {
			const {
				uid = '',
				partNumber = '',
				partDescription = '',
				quantity = '',
				inStock = true
			} = sparePart

			return {
				uid,
				partNumber,
				partDescription,
				quantity,
				inStock,
				sparePart
			}
		}
		return { sparePart }
	}

	handleOnSubmit = () => {
		const { uid, partNumber, partDescription, quantity, inStock } = this.state
		const { jobUID, organizationUID, addSparePart, onSubmit, saveSparePart  } = this.props

		const data = { partNumber, partDescription, quantity, inStock, organizationUID, jobUID }
		if(uid === '') {
			addSparePart(data)
			.then(() => {
				onSubmit()
				this.setState({ 
					partNumber: '',
					partDescription: '',
					quantity: '',
					inStock: true 
				})
			})
			.catch((error) => console.log(error))
		}
		else {
			data['partUID'] = uid
			saveSparePart(data)
			.then(() => {
				onSubmit()
				this.setState({ 
					uid: '',
					partNumber: '',
					partDescription: '',
					quantity: '',
					inStock: true 
				})
			})
			.catch((error) => console.log(error))
		}
		
	}

	render() {
		const { uid, partNumber,partDescription,quantity,inStock } = this.state
		const { visible, onClose } = this.props
		return (
			<TEPopupForm
				visible={visible}
				contentStyles={styles.content}
				onClose={onClose}
				onSubmit={this.handleOnSubmit}
				onSubmitTitle={(uid !== '' ? 'Edit' : 'Add') + ' Spare Part'}
			>
				<h1 style={styles.formTitle}>{(uid !== '' ? 'Edit' : 'Add')} Spare Part</h1>
				<TEInputRow
					labelForKey="partNumber"
					title="Part Number"
					value={partNumber}
					onChange={(e) =>
						this.setState({
							partNumber: e.target.value,
						})
					}
					placeholder="Part Number"
				/>
				<TEInputRow
					labelForKey="partDescription"
					type="textarea"
					title="Part Description"
					value={partDescription}
					onChange={(e) =>
						this.setState({
							partDescription: e.target.value,
						})
					}
					placeholder="Description"
				/>
				<TEInputRow
					labelForKey="quantity"
					title="Quantity"
					type="number"
					value={quantity}
					onChange={(e) =>
						this.setState({
							quantity: e.target.value,
						})
					}
					placeholder="Quantity"
				/>
				<TESegmentedGroup
					labelForKey="inStock"
					title="In Stock?"
					checkedValue={(inStock ? 'Yes' : 'No')}
					onChange={(e) => {
							console.log(e.target.value) 
							this.setState({ inStock: (e.target.value === 'Yes' ? true : false) })
					}}
					buttonArray={['Yes', 'No']}
					inline
				/>
			</TEPopupForm>
		)
	}
}

const mapStateToProps = (state) => {
	return { }
}

export default connect(
	mapStateToProps,
	{
		addSparePart,
		saveSparePart
	}
)(Radium(AddSparePartPopup))
