import React, { Component } from 'react'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Radium from 'radium'
import firebase from 'firebase/app'
import 'firebase/auth'

import TELink from './../../../component/Navigation/TELink'
import TENavLink from './../../../component/Navigation/TENavLink'
import TESVG from './../../../component/TESVG'

import { logo, navIcon } from './../../../config/images'
import styles from './styles'

class Navbar extends Component {
	state = {
		mobileNavOpen: false,
		activeDropdown: '',
	}

	handleToggleNav = () => {
		this.setState({ mobileNavOpen: !this.state.mobileNavOpen })
	}
	handleCloseNavOnClick = () => {
		this.setState({ mobileNavOpen: false })
	}
	showDropdown = (dropdownString) => {
		this.setState({ activeDropdown: dropdownString })
	}
	hideDropdown = () => {
		this.setState({ activeDropdown: '' })
	}
	renderAuthButton = () => {
		const { mobileNavOpen } = this.state
		const { userPermissions } = this.props
		const user = firebase.auth().currentUser

		if (user) {
			if (userPermissions && userPermissions.manager) {
				return (
					<li style={[styles.li, mobileNavOpen && styles.liOpen]}>
						<TENavLink
							style={styles.link}
							wrapperStyle={styles.linkWrapper}
							activeStyle={styles.active}
							to="/manager/welcome"
							exact
							onClick={this.handleCloseNavOnClick}
						>
							Portal
						</TENavLink>
					</li>
				)
			} else if (userPermissions && userPermissions.technician) {
				return (
					<li style={[styles.li, mobileNavOpen && styles.liOpen]}>
						<TENavLink
							style={styles.link}
							wrapperStyle={styles.linkWrapper}
							activeStyle={styles.active}
							to="/technician /welcome"
							exact
							onClick={this.handleCloseNavOnClick}
						>
							Portal
						</TENavLink>
					</li>
				)
			} else if (userPermissions && userPermissions.admin) {
				return (
					<li style={[styles.li, mobileNavOpen && styles.liOpen]}>
						<TENavLink
							style={styles.link}
							wrapperStyle={styles.linkWrapper}
							activeStyle={styles.active}
							to="/admin/welcome"
							exact
							onClick={this.handleCloseNavOnClick}
						>
							Admin
						</TENavLink>
					</li>
				)
			}

			return null
		} else {
			return (
				<li style={[styles.li, mobileNavOpen && styles.liOpen]}>
					<TENavLink
						style={styles.link}
						wrapperStyle={styles.linkWrapper}
						activeStyle={styles.active}
						to="/signin"
						exact
						onClick={this.handleCloseNavOnClick}
					>
						Sign In
					</TENavLink>
				</li>
			)
		}
	}
	render() {
		const { mobileNavOpen /*activeDropdown*/ } = this.state

		return (
			<div style={styles.container}>
				<div style={styles.brandContainer}>
					<TELink to="/">
						<img style={styles.brandLogoWrapper} src={logo} alt="logo" title="Rittal" />
					</TELink>
				</div>
				<div style={styles.navContainer}>
					<ul style={styles.ul}>
						<li style={[styles.li, mobileNavOpen && styles.liOpen]}>
							<TENavLink
								style={styles.link}
								wrapperStyle={styles.linkWrapper}
								activeStyle={styles.active}
								to="/"
								exact
								onClick={this.handleCloseNavOnClick}
							>
								Home
							</TENavLink>
						</li>
						{this.renderAuthButton()}
					</ul>
				</div>
				<button
					onClick={this.handleToggleNav}
					style={[styles.mobileNavButton, mobileNavOpen && styles.activeMobileNavButton]}
				>
					<TESVG wrapperStyle={styles.navIcon} path={navIcon} />
				</button>
			</div>
		)
	}
}

Navbar.propTypes = {}

Navbar.defaultProps = {}

const mapStateToProps = (state) => {
	const { userPermissions } = state.Auth
	return { userPermissions }
}

export default connect(
	mapStateToProps,
	{}
)(Radium(Navbar))
