import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
//import PropTypes from 'prop-types'
import Radium from 'radium'

import { connect } from 'react-redux'

import {} from './../../../actions'

import TEAlert from './../../../component/Popup/TEAlert'
import Navbar from './../Navbar'
import SideNavbar from './../SideNavbar'
import Footer from './../Footer'

import Welcome from './../../../pages/Manager/Welcome'
import Profile from './../../../pages/Manager/Profile'
import Jobs from './../../../pages/Manager/Jobs'
import Organization from './../../../pages/Manager/Organization'
import User from './../../../pages/Manager/User'
import Mobile from './../../../pages/Manager/Mobile'
import Standup from './../../../pages/Manager/Standup'

import PrivateRoute from './../../../component/PrivateRoute'
import NotFound from './../../../pages/NotFound'

import { compareToLocalVersion } from './../../../helpers'

import styles from './styles'

class AdminContentWrapper extends Component {
	render() {
		const { history, location, appVersions, appVersionsLoaded } = this.props

		if (!appVersionsLoaded) {
			return null
		}

		const { behind } = compareToLocalVersion(appVersions.web.minimum)
		if (behind) {
			return (
				<TEAlert
					title="Major Version Update"
					message={`A newer version of the platform is available (v${
						appVersions.web.minimum
					}) and is required to continue using the platform. Press the okay button below to receive the most recent version. If you continue to see this prompt after many attempts, either clear your cache or contact technical support.`}
					onClick={() => window.location.reload(true)}
					visible
				/>
			)
		}

		return (
			<div style={styles.portalWrapper}>
				<SideNavbar location={location} />
				<div style={styles.mainPanel}>
					<Navbar history={history} />
					<div style={styles.portalContentWrapper}>
						<Switch>
							<PrivateRoute
								path="/manager/welcome"
								component={Welcome}
								requiredUserType="manager"
							/>
							<PrivateRoute
								path="/manager/profile"
								component={Profile}
								requiredUserType="manager"
							/>
							<PrivateRoute
								path="/manager/organization/users/:uid"
								component={User}
								requiredUserType="manager"
							/>
							<PrivateRoute
								path="/manager/jobs"
								component={Jobs}
								requiredUserType="manager"
							/>
							<PrivateRoute
								path="/manager/organization"
								component={Organization}
								requiredUserType="manager"
							/>
							<PrivateRoute
								path="/manager/mobile"
								component={Mobile}
								requiredUserType="manager"
							/>
							<PrivateRoute
								path="/manager/standup"
								component={Standup}
								requiredUserType="manager"
							/>
							<Route component={NotFound} />
						</Switch>
					</div>
					<Footer appVersions={appVersions} />
				</div>
			</div>
		)
	}
}

AdminContentWrapper.propTypes = {}

AdminContentWrapper.defaultProps = {}

const mapStateToProps = (state) => {
	const { appVersions, appVersionsLoaded } = state.Loading

	return {
		appVersions,
		appVersionsLoaded,
	}
}

export default connect(
	mapStateToProps,
	{}
)(Radium(AdminContentWrapper))
