import React, { Component } from 'react'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Radium from 'radium'

import TESideNavLink from './../../../component/Navigation/TESideNavLink'
import TELink from './../../../component/Navigation/TELink'

//import { } from './../../../actions'
import { brandmark } from './../../../config/images'
import styles from './styles'

class SideNavbar extends Component {
	render() {
		const { location } = this.props
		return (
			<div style={styles.container}>
				<TELink to="/">
					<img src={brandmark} style={styles.logo} alt="brandmark" title="Rittal" />
				</TELink>
				<div style={styles.navContainer}>
					<ul style={styles.mainUl}>
						<TESideNavLink
							to="/technician/welcome"
							activePath="/technician/welcome"
							location={location}
							title="Welcome"
						/>
						<TESideNavLink
							to="/technician/jobs/live"
							activePath="/technician/jobs"
							location={location}
							title="Service Cases"
						/>
						<TESideNavLink
							to="/technician/mobile"
							activePath="/technician/mobile"
							location={location}
							title="Mobile"
						/>{' '}
					</ul>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	//const { } = state.Auth
	return {}
}

export default connect(
	mapStateToProps,
	{}
)(Radium(SideNavbar))
