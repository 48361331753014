import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import Radium from 'radium'
import ReactTable from 'react-table'

import { startWatchingJobsLive, stopWatchingJobsLive } from './../../../../actions/Technician'
import { getJobLiveArray } from './../../../../selectors/Technician'

import TEHelmet from './../../../../component/TEHelmet'

import PanelWrapper from './../../../../component/Layout/PanelWrapper'
import Panel from './../../../../component/Layout/Panel'

import { tableColumns } from './tableConfigs'
import { customTableFilter } from './../../../../helpers'
import styles from './styles'

class Live extends Component {
	componentDidMount() {
		const { userModel, startWatchingJobsLive } = this.props
		startWatchingJobsLive(userModel.organizationUID)
	}
	componentWillUnmount() {
		this.props.stopWatchingJobsLive()
	}
	render() {
		const meta = {
			title: 'Rittal | Job Live',
			description: '',
		}
		const { jobArray } = this.props
		return (
			<Fragment>
				<TEHelmet {...meta} />
				<PanelWrapper>
					<Panel title="Live">
						<div style={styles.tableContainer}>
							<ReactTable
								data={jobArray}
								columns={tableColumns}
								defaultPageSize={10}
								filterable
								defaultFilterMethod={customTableFilter}
								defaultSorted={[
									{
										id: 'jobNumber',
										desc: false,
									},
								]}
							/>
						</div>
					</Panel>
				</PanelWrapper>
			</Fragment>
		)
	}
}

Live.propTypes = {}

Live.defaultProps = {}

const mapStateToProps = (state) => {
	const { userModel } = state.Loading
	const jobArray = getJobLiveArray(state)
	return { userModel, jobArray }
}

export default connect(
	mapStateToProps,
	{ startWatchingJobsLive, stopWatchingJobsLive }
)(Radium(Live))
