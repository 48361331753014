import React from 'react'
import validate from 'validate.js'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import { renderEmail } from 'react-html-email'
import axios from 'axios'

import {
	SHOW_ALERT,
	SHOW_CONFIRM,
	HIDE_CONFIRM,
	SHOW_NETWORK_ACTIVITY,
	HIDE_NETWORK_ACTIVITY,
	ManagerKeys,
} from './../types'

import SummaryEmail from './../../component/Email/SummaryEmail'

import settings from './../../config/settings'

export const startWatchingJobsLive = (uid) => {
	const { FETCH_JOBS_LIVE, SAVE_JOBS_LIVE_WATCHER } = ManagerKeys

	return (dispatch, getState) => {
		const jobsLiveWatcherRef = firebase.database().ref(`Jobs/Live/${uid}`)
		jobsLiveWatcherRef.on(
			'value',
			(snapshot) => {
				dispatch({
					type: FETCH_JOBS_LIVE,
					payload: snapshot.val(),
				})
			},
			(error) => {
				console.log('Loading Error: ', error)
			}
		)
		dispatch({
			type: SAVE_JOBS_LIVE_WATCHER,
			payload: jobsLiveWatcherRef,
		})
	}
}
export const stopWatchingJobsLive = () => {
	return (dispatch, getState) => {
		const { jobsLiveWatcherRef } = getState().Manager.Jobs
		if (jobsLiveWatcherRef) {
			jobsLiveWatcherRef.off()
			dispatch({ type: ManagerKeys.REMOVE_JOBS_LIVE_WATCHER })
		}
	}
}
export const startWatchingJobsHistory = (uid) => {
	const { FETCH_JOBS_HISTORY, SAVE_JOBS_HISTORY_WATCHER } = ManagerKeys

	return (dispatch, getState) => {
		const jobsHistoryWatcherRef = firebase.database().ref(`Jobs/History/${uid}`)
		jobsHistoryWatcherRef.on(
			'value',
			(snapshot) => {
				dispatch({
					type: FETCH_JOBS_HISTORY,
					payload: snapshot.val(),
				})
			},
			(error) => {
				console.log('Loading Error: ', error)
			}
		)
		dispatch({
			type: SAVE_JOBS_HISTORY_WATCHER,
			payload: jobsHistoryWatcherRef,
		})
	}
}
export const stopWatchingJobsHistory = () => {
	return (dispatch, getState) => {
		const { jobsHistoryWatcherRef } = getState().Manager.Jobs
		if (jobsHistoryWatcherRef) {
			jobsHistoryWatcherRef.off()
			dispatch({ type: ManagerKeys.REMOVE_JOBS_HISTORY_WATCHER })
		}
	}
}

export const startWatchingJobNumber = (uid) => {
	const { FETCH_JOB_NUMBER, SAVE_JOB_NUMBER_WATCHER } = ManagerKeys

	return (dispatch, getState) => {
		const jobNumberWatcherRef = firebase.database().ref(`Organizations/${uid}/jobNumber`)
		jobNumberWatcherRef.on(
			'value',
			(snapshot) => {
				dispatch({
					type: FETCH_JOB_NUMBER,
					payload: snapshot.val(),
				})
			},
			(error) => {
				console.log('Loading Error: ', error)
			}
		)
		dispatch({
			type: SAVE_JOB_NUMBER_WATCHER,
			payload: jobNumberWatcherRef,
		})
	}
}
export const stopWatchingJobsNumber = () => {
	return (dispatch, getState) => {
		const { jobNumberWatcherRef } = getState().Manager.Jobs
		if (jobNumberWatcherRef) {
			jobNumberWatcherRef.off()
			dispatch({ type: ManagerKeys.REMOVE_JOB_NUMBER_WATCHER })
		}
	}
}
export const createJob = (data) => {
	const {
		jobType,
		site,
		serviceType,
		equipmentType,
		region,
		dateOfService,
		poNumber,
		serviceStatus,
		billingDisposition,
		assignedTo,
		assignedBy,
		clientName,
		clientClientNumber,
		clientAddress,
		clientContact,
		clientPhone,
		clientEmail,
		jobDescription,
		jobNotes,
		organizationUID,
		history,
		eta
	} = data

	//Validate Data
	const validatorConstraints = {
		jobType: {
			presence: {
				allowEmpty: false,
			},
		},
	}
	const validationResponse = validate(data, validatorConstraints)
	if (validationResponse) {
		return (dispatch) => {
			return new Promise((res, rej) => {
				dispatch({
					type: SHOW_ALERT,
					payload: {
						alertTitle: 'Error',
						alertMessage: Object.values(validationResponse)[0][0],
					},
				})
				return rej()
			})
		}
	}

	return (dispatch) => {
		return new Promise((res, rej) => {
			dispatch({
				type: SHOW_NETWORK_ACTIVITY,
				payload: 'Creating Service Case...',
			})

			const assignedToObject = {}
			if (assignedTo) {
				for (let i = 0; i < assignedTo.length; i++) {
					assignedToObject[assignedTo[i].uid] = true
				}
			}
			let jobNumber
			let jobUID
			firebase
				.database()
				.ref(`Organizations/${organizationUID}/jobNumber`)
				.once('value')
				.then((snapshop) => {
					jobNumber = snapshop.val()
					const { uid } = firebase.auth().currentUser
					return firebase
						.database()
						.ref(`Jobs/Live/${organizationUID}`)
						.push({
							organizationUID,
							jobNumber,
							dateCreated: firebase.database.ServerValue.TIMESTAMP,
							createdByUID: uid,
							jobTypeUID: jobType.uid,
							site,
							serviceType,
							equipmentType,
							region,
							dateOfService: dateOfService || null,
							poNumber,
							serviceStatus,
							billingDisposition,
							assignedTo: assignedToObject,
							assignedBy,
							clientName,
							clientClientNumber,
							clientAddress,
							clientContact,
							clientPhone,
							clientEmail,
							jobDescription,
							jobNotes,
							eta,
						})
				})
				.then((response) => {
					jobUID = response.key
					//TODO: Look into handle this as a "transaction" - https://firebase.google.com/docs/database/web/read-and-write
					return firebase
						.database()
						.ref(`Organizations/${organizationUID}`)
						.update({ jobNumber: jobNumber + 1 })
				})
				.then(() => {
					//Assign Jobs
					const assignRequests = []
					if (assignedToObject) {
						for (const assignedToUID in assignedToObject) {
							assignRequests.push(
								firebase
									.database()
									.ref(
										`Jobs/LiveAssignments/${organizationUID}/${assignedToUID}/${jobUID}`
									)
									.set(true)
							)
						}
					}
					return Promise.all(assignRequests)
				})
				.then(() => {
					dispatch({ type: HIDE_NETWORK_ACTIVITY })
					dispatch({
						type: SHOW_CONFIRM,
						payload: {
							confirmTitle: 'Success',
							confirmMessage:
								'Service Case has been created. Would you like to view the case you just created?',
							confirmLeftTitle: 'Cancel',
							confirmRightOnClick: () => {
								dispatch({ type: HIDE_CONFIRM })
								history.push(`/manager/jobs/live/${jobUID}`)
							},
							confirmRightTitle: 'View Service Case',
						},
					})
					return res()
				})
				.catch((error) => {
					console.log(error)
					dispatch({ type: HIDE_NETWORK_ACTIVITY })
					dispatch({
						type: SHOW_ALERT,
						payload: {
							alertTitle: 'Error',
							alertMessage: 'Error Creating Service Case.',
						},
					})
					return rej()
				})
		})
	}
}

export const startWatchingJob = (organizationUID, uid, status) => {
	const { FETCH_JOB, SAVE_JOB_WATCHER } = ManagerKeys

	return (dispatch) => {
		let ref
		if (status === 'live') {
			ref = `Jobs/Live/${organizationUID}/${uid}`
		} else if (status === 'history') {
			ref = `Jobs/History/${organizationUID}/${uid}`
		} else {
			dispatch({
				type: FETCH_JOB,
				payload: null,
			})
			return
		}

		const jobWatcherRef = firebase.database().ref(ref)
		jobWatcherRef.on(
			'value',
			(snapshot) => {
				dispatch({
					type: FETCH_JOB,
					payload: snapshot.val(),
				})
			},
			(error) => {
				console.log('Loading Error: ', error)
			}
		)
		dispatch({
			type: SAVE_JOB_WATCHER,
			payload: jobWatcherRef,
		})
	}
}
export const stopWatchingJob = () => {
	const { REMOVE_JOB_WATCHER } = ManagerKeys
	return (dispatch, getState) => {
		const { jobWatcherRef } = getState().Manager.Jobs
		if (jobWatcherRef) {
			jobWatcherRef.off()
			dispatch({ type: REMOVE_JOB_WATCHER })
		}
	}
}
export const saveJobDetails = (data) => {
	const {
		dateOfService,
		site,
		serviceType,
		equipmentType,
		region,
		assignedTo,
		assignedBy,
		poNumber,
		billingDisposition,
		clientName,
		clientClientNumber,
		clientAddress,
		clientContact,
		clientPhone,
		clientEmail,
		jobDescription,
		jobNotes,
		eta,
		serviceStatus,
		organizationUID,
		jobUID,
		job,
	} = data

	return (dispatch) => {
		return new Promise((res, rej) => {
			dispatch({
				type: SHOW_NETWORK_ACTIVITY,
				payload: 'Saving Service Case Details...',
			})

			const assignedToObject = {}
			if (assignedTo) {
				for (let i = 0; i < assignedTo.length; i++) {
					assignedToObject[assignedTo[i].uid] = true
				}
			}

			//Compare Old and New assignTo to clean up unassigned and assign new
			const addedUIDArray = []
			const removedUIDArray = []
			if (job.assignedTo) {
				if (assignedToObject) {
					for (const prevAssignedToUID in job.assignedTo) {
						if (!assignedToObject[prevAssignedToUID]) {
							removedUIDArray.push(prevAssignedToUID)
						}
					}
					for (const newAssignedToUID in assignedToObject) {
						if (!job.assignedTo[newAssignedToUID]) {
							addedUIDArray.push(newAssignedToUID)
						}
					}
				} else {
					//All Previously assigned were removed.
					for (const assignedToUID in job.assignedTo) {
						removedUIDArray.push(assignedToUID)
					}
				}
			} else if (assignedToObject) {
				//All Are New
				for (const assignedToUID in assignedToObject) {
					addedUIDArray.push(assignedToUID)
				}
			}

			firebase
				.database()
				.ref(`Jobs/Live/${organizationUID}/${jobUID}`)
				.update({
					dateOfService: dateOfService || null,
					site,
					serviceType,
					equipmentType,
					region,
					assignedTo: assignedToObject,
					assignedBy,
					poNumber,
					serviceStatus,
					billingDisposition,
					clientName,
					clientClientNumber,
					clientAddress,
					clientContact,
					clientPhone,
					clientEmail,
					jobDescription,
					jobNotes,
					eta
				})
				.then(() => {
					const addAssignedToRequests = []
					for (let i = 0; i < addedUIDArray.length; i++) {
						addAssignedToRequests.push(
							firebase
								.database()
								.ref(
									`Jobs/LiveAssignments/${organizationUID}/${
										addedUIDArray[i]
									}/${jobUID}`
								)
								.set(true)
						)
					}
					Promise.all(addAssignedToRequests)
				})
				.then(() => {
					const removeAssignedToRequests = []
					for (let i = 0; i < removedUIDArray.length; i++) {
						removeAssignedToRequests.push(
							firebase
								.database()
								.ref(
									`Jobs/LiveAssignments/${organizationUID}/${
										removedUIDArray[i]
									}/${jobUID}`
								)
								.remove()
						)
					}
					Promise.all(removeAssignedToRequests)
				})
				.then(() => {
					dispatch({ type: HIDE_NETWORK_ACTIVITY })
					dispatch({
						type: SHOW_ALERT,
						payload: {
							alertTitle: 'Success',
							alertMessage: 'Case details saved',
						},
					})
					return res()
				})
				.catch((error) => {
					console.log(error)
					dispatch({ type: HIDE_NETWORK_ACTIVITY })
					dispatch({
						type: SHOW_ALERT,
						payload: {
							alertTitle: 'Error',
							alertMessage: 'Error saving case details',
						},
					})
					return rej()
				})
		})
	}
}

export const confirmCompleteJob = (data) => {
	const { jobUID, organizationUID, job, history } = data
	return (dispatch) => {
		const completeJob = () => {
			dispatch({ type: HIDE_CONFIRM })
			dispatch({
				type: SHOW_NETWORK_ACTIVITY,
				payload: 'Resolving Service Case...',
			})

			const { assignedTo } = job
			const assignedToArray = []
			if (assignedTo) {
				for (const assignedToUID in assignedTo) {
					assignedToArray.push(assignedToUID)
				}
			}

			firebase
				.database()
				.ref(`Jobs/Live/${organizationUID}/${jobUID}`)
				.once('value')
				.then((snapshot) => {
					return firebase
						.database()
						.ref(`Jobs/History/${organizationUID}/${jobUID}`)
						.set(snapshot.val())
				})
				.then((snapshot) => {
					return firebase
						.database()
						.ref(`Jobs/Live/${organizationUID}/${jobUID}`)
						.remove()
				})
				.then(() => {
					const updateAssignmentRequestArray = []
					for (let i = 0; i < assignedToArray.length; i++) {
						const assignedToUID = assignedToArray[i]
						updateAssignmentRequestArray.push(
							firebase
								.database()
								.ref(
									`Jobs/LiveAssignments/${organizationUID}/${assignedToUID}/${jobUID}`
								)
								.remove()
						)
						updateAssignmentRequestArray.push(
							firebase
								.database()
								.ref(
									`Jobs/HistoryAssignments/${organizationUID}/${assignedToUID}/${jobUID}`
								)
								.set(true)
						)
					}
					return Promise.all(updateAssignmentRequestArray)
				})
				.then(() => {
					dispatch({ type: HIDE_NETWORK_ACTIVITY })
					dispatch({
						type: SHOW_ALERT,
						payload: {
							alertTitle: 'Success',
							alertMessage: 'Service Case has been resolved and moved to history.',
						},
					})
					history.push('/manager/jobs/live')
				})
				.catch((error) => {
					console.log(error)
					dispatch({ type: HIDE_NETWORK_ACTIVITY })
					dispatch({
						type: SHOW_ALERT,
						payload: {
							alertTitle: 'Error',
							alertMessage: 'Error resolving service case.',
						},
					})
				})
		}

		dispatch({
			type: SHOW_CONFIRM,
			payload: {
				confirmTitle: 'Resolve Job',
				confirmMessage:
					'Are you sure you want to resolve this case. This will move the case to history.',
				confirmLeftTitle: 'Cancel',
				confirmRightOnClick: completeJob,
				confirmRightTitle: 'Resolve Job',
			},
		})
	}
}

export const confirmReopenJob = (data) => {
	const { jobUID, organizationUID, job, history } = data
	return (dispatch) => {
		const reopenJob = () => {
			dispatch({ type: HIDE_CONFIRM })
			dispatch({
				type: SHOW_NETWORK_ACTIVITY,
				payload: 'Reopening Details...',
			})

			const { assignedTo } = job
			const assignedToArray = []
			if (assignedTo) {
				for (const assignedToUID in assignedTo) {
					assignedToArray.push(assignedToUID)
				}
			}

			firebase
				.database()
				.ref(`Jobs/History/${organizationUID}/${jobUID}`)
				.once('value')
				.then((snapshot) => {
					let jobObj = snapshot.val()
					if(jobObj.pdfFile && jobObj.pdfFile !== '') {
						jobObj['pdfFile'] = '';
					}
					return firebase
						.database()
						.ref(`Jobs/Live/${organizationUID}/${jobUID}`)
						.set(jobObj)
				})
				.then((snapshot) => {
					return firebase
						.database()
						.ref(`Jobs/History/${organizationUID}/${jobUID}`)
						.remove()
				})
				.then(() => {
					const updateAssignmentRequestArray = []
					for (let i = 0; i < assignedToArray.length; i++) {
						const assignedToUID = assignedToArray[i]
						updateAssignmentRequestArray.push(
							firebase
								.database()
								.ref(
									`Jobs/HistoryAssignments/${organizationUID}/${assignedToUID}/${jobUID}`
								)
								.remove()
						)
						updateAssignmentRequestArray.push(
							firebase
								.database()
								.ref(
									`Jobs/LiveAssignments/${organizationUID}/${assignedToUID}/${jobUID}`
								)
								.set(true)
						)
					}
					return Promise.all(updateAssignmentRequestArray)
				})
				.then(() => {
					dispatch({ type: HIDE_NETWORK_ACTIVITY })
					dispatch({
						type: SHOW_ALERT,
						payload: {
							alertTitle: 'Success',
							alertMessage: 'Job has been reopened and moved to live.',
						},
					})
					history.push('/manager/jobs/live')
				})
				.catch((error) => {
					console.log(error)
					dispatch({ type: HIDE_NETWORK_ACTIVITY })
					dispatch({
						type: SHOW_ALERT,
						payload: {
							alertTitle: 'Error',
							alertMessage: 'Error reopening job.',
						},
					})
				})
		}

		dispatch({
			type: SHOW_CONFIRM,
			payload: {
				confirmTitle: 'Reopen Job',
				confirmMessage:
					'Are you sure you want to reopen this job. This will move the job to live.',
				confirmLeftTitle: 'Cancel',
				confirmRightOnClick: reopenJob,
				confirmRightTitle: 'Reopen Job',
			},
		})
	}
}
export const sendSummaryEmail = (data) => {
	const { summaryData, userModel, job, emails, ccMe, notes, emailSubject, emailText, isCreateEmail, customerName } = data
	//Validate Data
	let validatorConstraints = {
		emails: {
			presence: {
				allowEmpty: false,
			},
		},
		ccMe: {
			presence: {
				allowEmpty: false,
			},
		},
		userModel: {
			presence: {
				allowEmpty: false,
			},
		},
	}
	if(!isCreateEmail) {
		validatorConstraints['summaryData'] =  {
			presence: {
				allowEmpty: false,
			},
		};
	}
	const validationResponse = validate(data, validatorConstraints)
	if (validationResponse) {
		return (dispatch) => {
			return new Promise((res, rej) => {
				if(!isCreateEmail) {
					dispatch({
						type: SHOW_ALERT,
						payload: {
							alertTitle: 'Error',
							alertMessage: Object.values(validationResponse)[0][0],
						},
					})
				}
				return rej()
			})
		}
	}

	return (dispatch) => {
		return new Promise((res, rej) => {
			dispatch({
				type: SHOW_NETWORK_ACTIVITY,
				payload: 'Sending Email...',
			})

			const emailString = ``

			const emailHTMLString = renderEmail(
				<SummaryEmail
					summaryData={summaryData}
					notes={notes}
					userModel={userModel}
					job={job}
					emailSubject={emailSubject}
					emailText={emailText}
					customerName={customerName}
					jobDescription={(isCreateEmail ? 'Perform PM on cabinet A/Cs, check function/condition, change/install filter.' : '')}
				/>
			)

			const emailData = {
				bccEmailAddresses: [],
				ccEmailAdresses: ccMe === 'Yes' ? [userModel.email] : [],
				toEmailAddresses: emails.split(','),
				subjectdata: emailSubject,
				bodyData: emailString,
				htmlData: emailHTMLString,
				bodyCharset: 'UTF-8',
				subjestCharset: 'UTF-8',
				sourceEmail: '"Rittal Service" <service@rittal.us>',
				replyToAddresses: ['"Rittal Service" <service@rittal.us>'],
			}
			// console.log(emailData)
			axios
				.post(settings.EMAIL_API_ROUTE, emailData)
				.then((response) => {
					// console.log(response)
					if (response && response.data && response.data.errorType) {
						dispatch({ type: HIDE_NETWORK_ACTIVITY })
						if(!isCreateEmail) {
							dispatch({
								type: SHOW_ALERT,
								payload: {
									alertTitle: response.data.errorType,
									alertMessage: response.data.errorMessage,
								},
							})
						}
						return rej()
					}

					dispatch({ type: HIDE_NETWORK_ACTIVITY })
					if(!isCreateEmail) {
						dispatch({
							type: SHOW_ALERT,
							payload: {
								alertTitle: 'Success',
								alertMessage: 'Summary Email Sent.',
							},
						})
					}
					
					return res()
				})
				.catch((error) => {
					dispatch({ type: HIDE_NETWORK_ACTIVITY })
					if(!isCreateEmail) {
						dispatch({
							type: SHOW_ALERT,
							payload: {
								alertTitle: 'Error',
								alertMessage: 'Error Sending Summary Email.',
							},
						})
					}
					return rej(error)
				})
		})
	}
}

export const saveStandupData = (data) => {
	return (dispatch) => {
		return new Promise((res, rej) => {
			dispatch({
				type: SHOW_NETWORK_ACTIVITY,
				payload: 'Saving Data...',
			})
			const standupWatcherRef = firebase.database().ref(`StaticData/Standup`)
			standupWatcherRef.on(
				'value',
				(snapshot) => {
					return firebase
						.database()
						.ref(`StaticData/HistoricalStandupData`)
						.push({
							"date": firebase.database.ServerValue.TIMESTAMP,
							"data": snapshot.val(),
						})
				},
				(error) => {
					console.log('Loading Error: ', error)
				}
			)
			firebase
				.database()
				.ref(`StaticData/Standup`)
				.set(data)
				.then(() => {
					dispatch({ type: HIDE_NETWORK_ACTIVITY })
					dispatch({
						type: SHOW_ALERT,
						payload: {
							alertTitle: 'Success',
							alertMessage: 'Standup Data imported successfully.',
						},
					})
					return res()
				})
				.catch((error) => {
					dispatch({ type: HIDE_NETWORK_ACTIVITY })
					dispatch({
						type: SHOW_ALERT,
						payload: {
							alertTitle: 'Error',
							alertMessage: 'Error Saving Standup Data.',
						},
					})
					return rej()
				})
		})
	}
}

export const startWatchingStandup = () => {
	const { FETCH_STANDUP, STANDUP_WATCHER } = ManagerKeys

	return (dispatch) => {
		const standupWatcherRef = firebase.database().ref(`StaticData/Standup`)
		standupWatcherRef.on(
			'value',
			(snapshot) => {
				dispatch({
					type: FETCH_STANDUP,
					payload: snapshot.val(),
				})
			},
			(error) => {
				console.log('Loading Error: ', error)
			}
		)
		dispatch({
			type: STANDUP_WATCHER,
			payload: standupWatcherRef,
		})
	}
}

export const stopWatchingStandup = () => {
	return (dispatch, getState) => {
		const { standupWatcherRef } = getState().Manager.Static
		if (standupWatcherRef) {
			standupWatcherRef.off()
			dispatch({ type: ManagerKeys.REMOVE_STANDUP_WATCHER })
		}
	}
}

export const saveJobPdfFile = (data) => {
	const {
		pdfFile,
		organizationUID,
		jobUID,
	} = data

	return (dispatch) => {
		return new Promise((res, rej) => {
			dispatch({
				type: SHOW_NETWORK_ACTIVITY,
				payload: 'Generating PDF File...',
			})

			firebase
				.database()
				.ref(`Jobs/History/${organizationUID}/${jobUID}`)
				.update({
					pdfFile
				})
				.then(() => {
					dispatch({ type: HIDE_NETWORK_ACTIVITY })
					dispatch({
						type: SHOW_ALERT,
						payload: {
							alertTitle: 'Success',
							alertMessage: 'PDF file generated',
						},
					})
					return res()
				})
				.catch((error) => {
					console.log(error)
					dispatch({ type: HIDE_NETWORK_ACTIVITY })
					dispatch({
						type: SHOW_ALERT,
						payload: {
							alertTitle: 'Error',
							alertMessage: 'Error generating PDF file',
						},
					})
					return rej()
				})
		})
	}
}
export const createJobType = (data) => {
	const {
		jobType,
		productName
	} = data

	return (dispatch) => {
		return new Promise((res, rej) => {
			dispatch({
				type: SHOW_NETWORK_ACTIVITY,
				payload: 'Creating Job Type...',
			})

			firebase
				.database()
				.ref(`StaticData/JobTypes`)
				.push({
					"name": jobType,
					"ProductTypes": [],
				})
				.then((response) => {
					const typeID = response.key
					//TODO: Look into handle this as a "transaction" - https://firebase.google.com/docs/database/web/read-and-write
					return firebase
						.database()
						.ref(`StaticData/JobTypes/${typeID}/ProductTypes`)
						.push({
							"name": productName,
						})
				})
				.then(() => {
					dispatch({ type: HIDE_NETWORK_ACTIVITY })
					dispatch({
						type: SHOW_CONFIRM,
						payload: {
							confirmTitle: 'Success',
							confirmMessage:
								'Job Type created',
							confirmLeftTitle: 'Cancel',
							confirmRightOnClick: () => {
								dispatch({ type: HIDE_CONFIRM })
							},
							confirmRightTitle: 'Ok',
						},
					})
					return res()
				})
				.catch((error) => {
					console.log(error)
					dispatch({ type: HIDE_NETWORK_ACTIVITY })
					dispatch({
						type: SHOW_ALERT,
						payload: {
							alertTitle: 'Error',
							alertMessage: 'Error Creating Job Type.',
						},
					})
					return rej()
				})
		})
	}
}