import validate from 'validate.js'
import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/auth'

import { SHOW_ALERT, SHOW_NETWORK_ACTIVITY, HIDE_NETWORK_ACTIVITY, AuthKeys } from './types'

import { determineAuthErrorMessage } from './../helpers'

export const signInUser = (data) => {
	//Validate Data
	const validatorConstraints = {
		email: {
			presence: {
				allowEmpty: false,
			},
			email: true,
		},
		password: {
			presence: {
				allowEmpty: false,
			},
		},
	}
	const validationResponse = validate(data, validatorConstraints)
	if (validationResponse) {
		return (dispatch) => {
			return new Promise((res, rej) => {
				dispatch({
					type: SHOW_ALERT,
					payload: {
						alertTitle: 'Error',
						alertMessage: Object.values(validationResponse)[0][0],
					},
				})
				return rej()
			})
		}
	}

	return (dispatch, getState) => {
		return new Promise((res, rej) => {
			const { email, password, history } = data

			dispatch({
				type: SHOW_NETWORK_ACTIVITY,
				payload: 'Signing In...',
			})

			firebase
				.auth()
				.signInWithEmailAndPassword(email, password)
				.then((user) => {
					const { uid } = firebase.auth().currentUser
					firebase
						.database()
						.ref(`UserPermissions/${uid}`)
						.once('value', (snapshot) => {
							dispatch({
								type: AuthKeys.FETCH_USER_PERMISSIONS,
								payload: snapshot.val(),
							})

							const userPermissions = snapshot.val()
							if (userPermissions && userPermissions.admin) {
								dispatch({ type: HIDE_NETWORK_ACTIVITY })
								history.push('/admin/welcome')
								return res()
							} else if (userPermissions && userPermissions.manager) {
								dispatch({ type: HIDE_NETWORK_ACTIVITY })
								history.push('/manager/welcome')
								return res()
							} else if (userPermissions && userPermissions.technician) {
								dispatch({ type: HIDE_NETWORK_ACTIVITY })
								history.push('/technician/welcome')
								return res()
							} else {
								firebase.auth().signOut()
								dispatch({ type: HIDE_NETWORK_ACTIVITY })
								dispatch({
									type: SHOW_ALERT,
									payload: {
										alertTitle: 'Error Signing In',
										alertMessage:
											'This account is missing appropriate user permissions.',
									},
								})
								return rej()
							}
						})
				})
				.catch((error) => {
					dispatch({ type: HIDE_NETWORK_ACTIVITY })
					console.log(error)
					dispatch({
						type: SHOW_ALERT,
						payload: determineAuthErrorMessage(error),
					})
					return rej()
				})
		})
	}
}

export const signOutUser = (history) => {
	return (dispatch, getState) => {
		dispatch({
			type: SHOW_NETWORK_ACTIVITY,
			payload: 'Signing Out...',
		})

		firebase
			.auth()
			.signOut()
			.then(() => {
				dispatch({ type: HIDE_NETWORK_ACTIVITY })
				history.push('/')
			})
			.catch((error) => {
				dispatch({ type: HIDE_NETWORK_ACTIVITY })
				console.log(error)
				dispatch({
					type: SHOW_ALERT,
					payload: determineAuthErrorMessage(error),
				})
			})
	}
}

export const sendForgotPasswordEmail = (data) => {
	//Validate Data
	const validatorConstraints = {
		email: {
			presence: {
				allowEmpty: false,
			},
			email: true,
		},
	}
	const validationResponse = validate(data, validatorConstraints)
	if (validationResponse) {
		return (dispatch) => {
			return new Promise((res, rej) => {
				dispatch({
					type: SHOW_ALERT,
					payload: {
						alertTitle: 'Error',
						alertMessage: Object.values(validationResponse)[0][0],
					},
				})
				return rej()
			})
		}
	}

	return (dispatch) => {
		return new Promise((res, rej) => {
			const { email } = data

			dispatch({
				type: SHOW_NETWORK_ACTIVITY,
				payload: 'Sending Forgot Password Email...',
			})

			firebase
				.auth()
				.sendPasswordResetEmail(email)
				.then(() => {
					dispatch({ type: HIDE_NETWORK_ACTIVITY })
					dispatch({
						type: SHOW_ALERT,
						payload: {
							alertTitle: 'Success',
							alertMessage:
								'An email has been sent to the provided address. Follow the instructions in the email to reset your password.',
						},
					})
					return res()
				})
				.catch((error) => {
					dispatch({ type: HIDE_NETWORK_ACTIVITY })
					console.log(error)
					dispatch({
						type: SHOW_ALERT,
						payload: determineAuthErrorMessage(error),
					})
					return rej()
				})
		})
	}
}

export const resetPassword = (actionCode, newPasswordData) => {
	//TODO: Validate Data
	return (dispatch) => {
		dispatch({
			type: SHOW_NETWORK_ACTIVITY,
			payload: 'Resetting Password...',
		})

		firebase
			.auth()
			.verifyPasswordResetCode(actionCode)
			.then((email) => {
				return firebase.auth().confirmPasswordReset(actionCode, newPasswordData.value)
			})
			.then((resp) => {
				dispatch({ type: HIDE_NETWORK_ACTIVITY })
				dispatch({
					type: SHOW_ALERT,
					payload: {
						alertTitle: 'Success',
						alertMessage: 'Your password has been reset.',
					},
				})
			})
			.catch((error) => {
				console.log(error)
				dispatch({ type: HIDE_NETWORK_ACTIVITY })
				dispatch({
					type: SHOW_ALERT,
					payload: determineAuthErrorMessage(error),
				})
			})
	}
}
export const recoverEmail = (actionCode) => {
	return (dispatch) => {
		dispatch({
			type: SHOW_NETWORK_ACTIVITY,
			payload: 'Recovering Email...',
		})

		//TODO: Review this code
		let email
		firebase
			.auth()
			.checkActionCode(actionCode)
			.then((info) => {
				email = info.data.email
				return firebase.auth().applyActionCode(actionCode)
			})
			.then(() => {
				return firebase.auth().sendPasswordResetEmail(email)
			})
			.then(() => {
				dispatch({ type: HIDE_NETWORK_ACTIVITY })
				dispatch({
					type: SHOW_ALERT,
					payload: {
						alertTitle: 'Success',
						alertMessage:
							'Your email address has been recovered. For security we have also sent a password reset email.',
					},
				})
			})
			.catch((error) => {
				console.log(error)
				dispatch({ type: HIDE_NETWORK_ACTIVITY })
				dispatch({
					type: SHOW_ALERT,
					payload: determineAuthErrorMessage(error),
				})
			})
	}
}
export const verifyEmail = (actionCode) => {
	return (dispatch) => {
		dispatch({
			type: SHOW_NETWORK_ACTIVITY,
			payload: 'Verifying Email...',
		})

		firebase
			.auth()
			.applyActionCode(actionCode)
			.then((resp) => {
				dispatch({ type: HIDE_NETWORK_ACTIVITY })

				//
				//NOTE:
				//
				// This assumes that the account you are actively signed in as is the one you are verifying.
				// This should work for most cases but is a place that could be improved.
				//
				if (firebase.auth().currentUser) {
					// const { uid } = firebase.auth().currentUser
					// firebase.database().ref(`UserPermissions/${uid}`)
					// .once('value', snapshot => {
					//  if (snapshot.val()){
					//      if (snapshot.val().agent){
					//          firebase.database().ref(`Agents/${uid}/emailVerified`).set(true)
					//      }
					//      if (snapshot.val().client){
					//          firebase.database().ref(`Clients/${uid}/emailVerified`).set(true)
					//      }
					//  }
					// })
				}

				dispatch({
					type: SHOW_ALERT,
					payload: {
						alertTitle: 'Success',
						alertMessage: 'Your email has been verified.',
					},
				})
			})
			.catch((error) => {
				console.log(error)
				dispatch({ type: HIDE_NETWORK_ACTIVITY })
				dispatch({
					type: SHOW_ALERT,
					payload: determineAuthErrorMessage(error),
				})
			})
	}
}
