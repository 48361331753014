import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import Radium from 'radium'
import ReactTable from 'react-table'

import { setPageTitle } from './../../../actions'
import { startWatchingOrganizations, stopWatchingOrganizations } from './../../../actions/Admin'
import { getOrganizationArray } from './../../../selectors/Admin'

import TEHelmet from './../../../component/TEHelmet'
import TEButton from './../../../component/Form/TEButton'

import PanelWrapper from './../../../component/Layout/PanelWrapper'
import Panel from './../../../component/Layout/Panel'

import CreateOrganizationPopup from './CreateOrganizationPopup'

import { tableColumns } from './tableConfigs'
import { customTableFilter } from './../../../helpers'
import styles from './styles'

class Organizations extends Component {
	state = { createOrgVisible: false }
	componentDidMount() {
		this.props.startWatchingOrganizations()
		this.props.setPageTitle('Organizations')
	}
	componentWillUnmount() {
		this.props.stopWatchingOrganizations()
	}
	render() {
		const meta = {
			title: 'Rittal | Admin Organizations',
			description: '',
		}
		const { createOrgVisible } = this.state
		const { organizationArray } = this.props
		return (
			<Fragment>
				<TEHelmet {...meta} />
				<PanelWrapper>
					<Panel
						title="Organizations"
						rightComponent={
							<TEButton
								onClick={() => this.setState({ createOrgVisible: true })}
								style={styles.actionButton}
							>
								Create Organization
							</TEButton>
						}
					>
						<div style={styles.tableContainer}>
							<ReactTable
								data={organizationArray}
								columns={tableColumns}
								defaultPageSize={10}
								filterable
								defaultFilterMethod={customTableFilter}
								defaultSorted={[
									{
										id: 'name',
										desc: false,
									},
								]}
							/>
						</div>
					</Panel>
				</PanelWrapper>

				<CreateOrganizationPopup
					visible={createOrgVisible}
					onClose={() => this.setState({ createOrgVisible: false })}
				/>
			</Fragment>
		)
	}
}

Organizations.propTypes = {}

Organizations.defaultProps = {}

const mapStateToProps = (state) => {
	const organizationArray = getOrganizationArray(state)
	return { organizationArray }
}

export default connect(
	mapStateToProps,
	{
		setPageTitle,
		startWatchingOrganizations,
		stopWatchingOrganizations,
	}
)(Radium(Organizations))
