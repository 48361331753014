//
//TE Version 0.2.0
//

import React, { Component } from 'react'
import Radium from 'radium'
import PropTypes from 'prop-types'

import TERow from './../TERow'
import TELabel from './../TELabel'
import TEInput from './../TEInput'
import TETextarea from './../TETextarea'

//import styles from './styles'

class TEInputRow extends Component {
    render() {
        const {
            //Row
            rowStyles,
            size,
            last,

            //Label
            labelStyles,
            title,

            //Input
            inputStyles,
            type,
            onChange,
            disabled,

            //Both
            value,
            required,
            labelForKey,

            ...rest
        } = this.props

        return (
            <TERow size={size} last={last} style={rowStyles}>
                <TELabel
                    htmlFor={labelForKey}
                    labelText={title}
                    style={labelStyles}
                    required={required}
                    disabled={disabled}
                />
                {type !== 'textarea' ? (
                    <TEInput
                        type={type}
                        value={value}
                        onChange={onChange}
                        id={labelForKey}
                        disabled={disabled}
                        style={inputStyles}
                        {...rest}
                    />
                ) : (
                    <TETextarea
                        value={value}
                        onChange={onChange}
                        id={labelForKey}
                        disabled={disabled}
                        style={inputStyles}
                        {...rest}
                    />
                )}
            </TERow>
        )
    }
}

TEInputRow.propTypes = {
    //Row
    rowStyles: PropTypes.object,
    size: PropTypes.string,
    last: PropTypes.bool,

    //Input
    inputStyles: PropTypes.object,
    type: PropTypes.string,
    onChange: PropTypes.func,
    onChangeKey: PropTypes.string,
    disabled: PropTypes.bool,

    //Both
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    labelForKey: PropTypes.string,
}

TEInputRow.defaultProps = {}

export default Radium(TEInputRow)
