import { TechnicianKeys } from './../../actions/types'

const INITIAL_STATE = {
	products: undefined,
	productsLoaded: false,
	productsWatcherRef: undefined,

	product: undefined,
	productLoaded: false,
	productWatcherRef: undefined,
}

export default (state = INITIAL_STATE, action) => {
	const {
		FETCH_PRODUCTS,
		SAVE_PRODUCTS_WATCHER,
		REMOVE_PRODUCTS_WATCHER,

		FETCH_PRODUCT,
		SAVE_PRODUCT_WATCHER,
		REMOVE_PRODUCT_WATCHER,
	} = TechnicianKeys

	switch (action.type) {
		case FETCH_PRODUCTS:
			return { ...state, products: action.payload, productsLoaded: true }
		case SAVE_PRODUCTS_WATCHER:
			return { ...state, productsWatcherRef: action.payload }
		case REMOVE_PRODUCTS_WATCHER:
			return {
				...state,
				products: INITIAL_STATE.products,
				productsLoaded: INITIAL_STATE.productsLoaded,
				productsWatcherRef: INITIAL_STATE.productsWatcherRef,
			}

		case FETCH_PRODUCT:
			return { ...state, product: action.payload, productLoaded: true }
		case SAVE_PRODUCT_WATCHER:
			return { ...state, productWatcherRef: action.payload }
		case REMOVE_PRODUCT_WATCHER:
			return {
				...state,
				product: INITIAL_STATE.product,
				productLoaded: INITIAL_STATE.productLoaded,
				productWatcherRef: INITIAL_STATE.productWatcherRef,
			}

		default:
			return state
	}
}
