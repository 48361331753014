import React from 'react'
import validate from 'validate.js'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import axios from 'axios'
import { renderEmail } from 'react-html-email'

import TESimpleEmail from './../../component/Email/TESimpleEmail'

import settings from './../../config/settings'
import { SHOW_ALERT, SHOW_NETWORK_ACTIVITY, HIDE_NETWORK_ACTIVITY, AdminKeys } from './../types'

export const startWatchingOrganizations = () => {
	const { FETCH_ORGANIZATIONS, SAVE_ORGANIZATIONS_WATCHER } = AdminKeys

	return (dispatch, getState) => {
		const organizationsWatcherRef = firebase.database().ref('Organizations')
		organizationsWatcherRef.on(
			'value',
			(snapshot) => {
				dispatch({
					type: FETCH_ORGANIZATIONS,
					payload: snapshot.val(),
				})
			},
			(error) => {
				console.log('Loading Error: ', error)
			}
		)
		dispatch({
			type: SAVE_ORGANIZATIONS_WATCHER,
			payload: organizationsWatcherRef,
		})
	}
}
export const stopWatchingOrganizations = () => {
	return (dispatch, getState) => {
		const { organizationsWatcherRef } = getState().Admin.Organizations
		if (organizationsWatcherRef) {
			organizationsWatcherRef.off()
			dispatch({ type: AdminKeys.REMOVE_ORGANIZATIONS_WATCHER })
		}
	}
}

export const createOrganization = (data) => {
	//Validate Data
	const validatorConstraints = {
		name: {
			presence: {
				allowEmpty: false,
			},
		},
	}
	const validationResponse = validate(data, validatorConstraints)
	if (validationResponse) {
		return (dispatch) => {
			return new Promise((res, rej) => {
				dispatch({
					type: SHOW_ALERT,
					payload: {
						alertTitle: 'Error',
						alertMessage: Object.values(validationResponse)[0][0],
					},
				})
				return rej()
			})
		}
	}
	return (dispatch) => {
		return new Promise((res, rej) => {
			dispatch({
				type: SHOW_NETWORK_ACTIVITY,
				payload: 'Creating Organization...',
			})

			const { name } = data
			const { uid } = firebase.auth().currentUser
			firebase
				.database()
				.ref('Organizations')
				.push({
					name,
					dateCreated: new Date().getTime(),
					createdByUID: uid,
				})
				.then(() => {
					dispatch({ type: HIDE_NETWORK_ACTIVITY })
					dispatch({
						type: SHOW_ALERT,
						payload: {
							alertTitle: 'Success',
							alertMessage: 'Organization Created.',
						},
					})
					return res()
				})
				.catch((error) => {
					console.log(error)
					dispatch({ type: HIDE_NETWORK_ACTIVITY })
					dispatch({
						type: SHOW_ALERT,
						payload: {
							alertTitle: 'Error',
							alertMessage: 'Error Creating Organization.',
						},
					})
					return rej()
				})
		})
	}
}

export const startWatchingOrganization = (uid) => {
	const {
		FETCH_ORGANIZATION,
		SAVE_ORGANIZATION_WATCHER,
		FETCH_ORGANIZATION_USERS,
		SAVE_ORGANIZATION_USERS_WATCHER,
		FETCH_USERS_PERMISSIONS,
		SAVE_USERS_PERMISSIONS_WATCHER,
	} = AdminKeys

	return (dispatch, getState) => {
		const organizationWatcherRef = firebase.database().ref(`Organizations/${uid}`)
		organizationWatcherRef.on(
			'value',
			(snapshot) => {
				dispatch({
					type: FETCH_ORGANIZATION,
					payload: snapshot.val(),
				})
			},
			(error) => {
				console.log('Loading Error: ', error)
			}
		)
		dispatch({
			type: SAVE_ORGANIZATION_WATCHER,
			payload: organizationWatcherRef,
		})

		const organizationUsersWatcherRef = firebase
			.database()
			.ref('Users')
			.orderByChild('organizationUID')
			.equalTo(uid)
		organizationUsersWatcherRef.on(
			'value',
			(snapshot) => {
				dispatch({
					type: FETCH_ORGANIZATION_USERS,
					payload: snapshot.val(),
				})
			},
			(error) => {
				console.log('Loading Error: ', error)
			}
		)
		dispatch({
			type: SAVE_ORGANIZATION_USERS_WATCHER,
			payload: organizationUsersWatcherRef,
		})

		const userPermissionsWatcherRef = firebase.database().ref('UserPermissions')
		userPermissionsWatcherRef.on(
			'value',
			(snapshot) => {
				dispatch({
					type: FETCH_USERS_PERMISSIONS,
					payload: snapshot.val(),
				})
			},
			(error) => {
				console.log('Loading Error: ', error)
			}
		)
		dispatch({
			type: SAVE_USERS_PERMISSIONS_WATCHER,
			payload: userPermissionsWatcherRef,
		})
	}
}
export const stopWatchingOrganization = () => {
	return (dispatch, getState) => {
		const {
			REMOVE_ORGANIZATION_WATCHER,
			REMOVE_ORGANIZATION_USERS_WATCHER,
			REMOVE_USERS_PERMISSIONS_WATCHER,
		} = AdminKeys
		const {
			organizationWatcherRef,
			organizationUsersWatcherRef,
			usersPermissionsWatcherRef,
		} = getState().Admin.Organizations
		if (organizationWatcherRef) {
			organizationWatcherRef.off()
			dispatch({ type: REMOVE_ORGANIZATION_WATCHER })
		}
		if (organizationUsersWatcherRef) {
			organizationUsersWatcherRef.off()
			dispatch({ type: REMOVE_ORGANIZATION_USERS_WATCHER })
		}
		if (usersPermissionsWatcherRef) {
			usersPermissionsWatcherRef.off()
			dispatch({ type: REMOVE_USERS_PERMISSIONS_WATCHER })
		}
	}
}

export const saveOrganization = (data) => {
	const { name, jobNumber, organization, uid } = data
	//Validate Data
	const validatorConstraints = {
		name: {
			presence: {
				allowEmpty: false,
			},
		},
		jobNumber: {
			presence: {
				allowEmpty: false,
			},
			numericality: {
				onlyInteger: true,
				greaterThan: organization.jobNumber,
				notGreaterThan: 'must be larger than its previous value.',
			},
		},
	}
	const validationResponse = validate(data, validatorConstraints)
	if (validationResponse) {
		return (dispatch) => {
			dispatch({
				type: SHOW_ALERT,
				payload: {
					alertTitle: 'Error',
					alertMessage: Object.values(validationResponse)[0][0],
				},
			})
		}
	}
	return (dispatch) => {
		dispatch({
			type: SHOW_NETWORK_ACTIVITY,
			payload: 'Creating Organization...',
		})

		firebase
			.database()
			.ref(`Organizations/${uid}`)
			.update({ name, jobNumber: Number(jobNumber) })
			.then(() => {
				dispatch({ type: HIDE_NETWORK_ACTIVITY })
				dispatch({
					type: SHOW_ALERT,
					payload: {
						alertTitle: 'Success',
						alertMessage: 'Organization Details Saved.',
					},
				})
			})
			.catch((error) => {
				console.log(error)
				dispatch({ type: HIDE_NETWORK_ACTIVITY })
				dispatch({
					type: SHOW_ALERT,
					payload: {
						alertTitle: 'Error',
						alertMessage: 'Error Saving Organization Details.',
					},
				})
			})
	}
}

export const createUser = (data) => {
	//Validate Data
	validate.validators.arrayPresence = function(value, options, key, attributes) {
		if (validate.isArray(value) && !validate.isEmpty(value)) {
			return
		}

		return "can't be empty"
	}

	const validatorConstraints = {
		firstName: {
			presence: {
				allowEmpty: false,
			},
		},
		lastName: {
			presence: {
				allowEmpty: false,
			},
		},
		email: {
			presence: {
				allowEmpty: false,
			},
			email: true,
		},
		password: {
			presence: true,
			length: {
				minimum: 6,
				message: 'must be at least 6 characters',
			},
		},
		permissions: {
			arrayPresence: true,
		},
	}
	const validationResponse = validate(data, validatorConstraints)
	if (validationResponse) {
		return (dispatch) => {
			return new Promise((res, rej) => {
				dispatch({
					type: SHOW_ALERT,
					payload: {
						alertTitle: 'Error',
						alertMessage: Object.values(validationResponse)[0][0],
					},
				})
			})
		}
	}
	return (dispatch) => {
		return new Promise((res, rej) => {
			dispatch({
				type: SHOW_NETWORK_ACTIVITY,
				payload: 'Creating User...',
			})

			const { firstName, lastName, email, password, permissions, orgUID } = data
			const userPermissions = {}
			for (let i = 0; i < permissions.length; i++) {
				userPermissions[permissions[i]] = true
			}

			//Setup Secondary FB so that it doesn't sign out current user.
			const {
				FIREBASE_API_KEY,
				FIREBASE_AUTH_DOMAIN,
				FIREBASE_DATABASE_URL,
				FIREBASE_PROJECT_ID,
				FIREBASE_STORAGE_BUCKET,
				FIREBASE_MESSAGING_SENDER_ID,
			} = settings

			const secondaryApp = firebase.initializeApp(
				{
					apiKey: FIREBASE_API_KEY,
					authDomain: FIREBASE_AUTH_DOMAIN,
					databaseURL: FIREBASE_DATABASE_URL,
					projectId: FIREBASE_PROJECT_ID,
					storageBucket: FIREBASE_STORAGE_BUCKET,
					messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
				},
				'secondary'
			)

			secondaryApp
				.auth()
				.createUserWithEmailAndPassword(email, password)
				.then((response) => {
					const { uid } = secondaryApp.auth().currentUser
					return firebase
						.database()
						.ref(`UserPermissions/${uid}`)
						.set(userPermissions)
				})
				.then(() => {
					const { uid } = secondaryApp.auth().currentUser
					return firebase
						.database()
						.ref(`Users/${uid}`)
						.set({
							firstName,
							lastName,
							email,
							dateCreated: new Date().getTime(),
							organizationUID: orgUID,
							active: true,
						})
				})
				.then(() => {
					const emailString = `Welcome To Rittal Service Visit\n\r\n\rAn account has been created on your behalf. To activate your
										account:\n\r1) Navigate to https://service.tsitpro.com/forgot-password and enter your email address to receive an email to set your
											password.\n\r2) Once you have followed the instructions, return to the sign in page (https://service.tsitpro.com/signin). \n\r\n\rto Download the mobile app, after signing in, click "Mobile"
										on the left.\n\r\n\r - The Rittal Service Team`

					const emailHTMLString = renderEmail(
						<TESimpleEmail
							emailTitle="Welcome To Rittal Service Visit"
							title="Welcome To Rittal Service Visit"
							body={
								<div>
									<p>
										An account has been created on your behalf. To activate your
										account:
									</p>
									<ol>
										<li>
											Navigate to{' '}
											<a href="https://service.tsitpro.com/forgot-password">
												Rittal Service Portal Forgot Password Page
											</a>
											.
										</li>
										<li>
											Enter your email address to receive an email to set your
											password.
										</li>
										<li>
											Once you have followed the instructions, return to the{' '}
											<a href="https://service.tsitpro.com/signin">
												Sign In Page
											</a>{' '}
											and sign in.
										</li>
									</ol>
									<p>
										To Download the mobile app, after signing in, click "Mobile"
										on the left.
									</p>
								</div>
							}
							signature=" - The Rittal Service Team"
						/>
					)

					const emailData = {
						bccEmailAddresses: [],
						ccEmailAdresses: [],
						toEmailAddresses: [email],
						subjectdata: 'Welcome To Rittal Service Visit',
						bodyData: emailString,
						htmlData: emailHTMLString,
						bodyCharset: 'UTF-8',
						subjestCharset: 'UTF-8',
						sourceEmail: '"Rittal Service" <service@rittal.us>',
						replyToAddresses: ['"Rittal Service" <service@rittal.us>'],
					}
					return axios.post(settings.EMAIL_API_ROUTE, emailData)
				})
				.then((response) => {
					console.log(response)
					secondaryApp.delete()
					dispatch({ type: HIDE_NETWORK_ACTIVITY })
					dispatch({
						type: SHOW_ALERT,
						payload: {
							alertTitle: 'Success',
							alertMessage: 'User Created.',
						},
					})
					return res()
				})
				.catch((error) => {
					secondaryApp.delete()
					console.log(error)
					dispatch({ type: HIDE_NETWORK_ACTIVITY })
					dispatch({
						type: SHOW_ALERT,
						payload: {
							alertTitle: 'Error',
							alertMessage: 'Error Creating User.',
						},
					})
					return rej()
				})
		})
	}
}

export const startWatchingUser = (uid) => {
	const {
		FETCH_USER,
		SAVE_USER_WATCHER,
		FETCH_USER_PERMISSIONS,
		SAVE_USER_PERMISSIONS_WATCHER,
	} = AdminKeys

	return (dispatch, getState) => {
		const userWatcherRef = firebase.database().ref(`Users/${uid}`)
		userWatcherRef.on(
			'value',
			(snapshot) => {
				dispatch({
					type: FETCH_USER,
					payload: snapshot.val(),
				})
			},
			(error) => {
				console.log('Loading Error: ', error)
			}
		)
		dispatch({
			type: SAVE_USER_WATCHER,
			payload: userWatcherRef,
		})

		const userPermissionsWatcherRef = firebase.database().ref(`UserPermissions/${uid}`)
		userPermissionsWatcherRef.on(
			'value',
			(snapshot) => {
				dispatch({
					type: FETCH_USER_PERMISSIONS,
					payload: snapshot.val(),
				})
			},
			(error) => {
				console.log('Loading Error: ', error)
			}
		)
		dispatch({
			type: SAVE_USER_PERMISSIONS_WATCHER,
			payload: userPermissionsWatcherRef,
		})
	}
}
export const stopWatchingUser = () => {
	return (dispatch, getState) => {
		const { userWatcherRef, userPermissionsWatcherRef } = getState().Admin.Organizations
		if (userWatcherRef) {
			userWatcherRef.off()
			dispatch({ type: AdminKeys.REMOVE_USER_WATCHER })
		}
		if (userPermissionsWatcherRef) {
			userPermissionsWatcherRef.off()
			dispatch({ type: AdminKeys.REMOVE_USER_PERMISSIONS_WATCHER })
		}
	}
}

export const saveUser = (data) => {
	//Validate Data
	validate.validators.arrayPresence = function(value, options, key, attributes) {
		if (validate.isArray(value) && !validate.isEmpty(value)) {
			return
		}

		return "can't be empty"
	}

	const validatorConstraints = {
		firstName: {
			presence: {
				allowEmpty: false,
			},
		},
		lastName: {
			presence: {
				allowEmpty: false,
			},
		},
		permissions: {
			arrayPresence: true,
		},
		active: {
			presence: {
				allowEmpty: false,
			},
		},
	}
	const validationResponse = validate(data, validatorConstraints)
	if (validationResponse) {
		return (dispatch) => {
			dispatch({
				type: SHOW_ALERT,
				payload: {
					alertTitle: 'Error',
					alertMessage: Object.values(validationResponse)[0][0],
				},
			})
		}
	}

	return (dispatch) => {
		dispatch({
			type: SHOW_NETWORK_ACTIVITY,
			payload: 'Saving User Details...',
		})

		const { uid, firstName, lastName, permissions, active } = data
		const userPermissions = {}
		for (let i = 0; i < permissions.length; i++) {
			userPermissions[permissions[i]] = true
		}
		firebase
			.database()
			.ref(`UserPermissions/${uid}`)
			.set(userPermissions)
			.then(() => {
				return firebase
					.database()
					.ref(`Users/${uid}`)
					.update({
						firstName,
						lastName,
						active: active === 'Yes',
					})
			})
			.then(() => {
				dispatch({ type: HIDE_NETWORK_ACTIVITY })
				dispatch({
					type: SHOW_ALERT,
					payload: {
						alertTitle: 'Success',
						alertMessage: 'User Details Saved.',
					},
				})
			})
			.catch((error) => {
				console.log(error)
				dispatch({ type: HIDE_NETWORK_ACTIVITY })
				dispatch({
					type: SHOW_ALERT,
					payload: {
						alertTitle: 'Error',
						alertMessage: 'Error Saving User Details.',
					},
				})
			})
	}
}
