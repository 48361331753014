import { colors } from './../../../config/styles'

export default {
    container: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: colors.white,
        height: 100,
        width: '100%',
        zIndex: 100,
        paddingLeft: 20,
        paddingRight: 40,
        maxWidth: 1300,
        marginLeft: 'auto',
        marginRight: 'auto',

        '@media (max-width: 800px)': {
            paddingLeft: 15,
            paddingRight: 15,
        },
        '@media (max-width: 650px)': {
            height: 'auto',
            flexDirection: 'column',
            justifyContent: 'center',
            paddingLeft: 0,
            paddingRight: 0,
        },
        '@media (max-width: 500px)': {
            alignItems: 'flex-start',
        },
    },
    brandContainer: {
        height: '100%',
        top: '0px',
        paddingLeft: 10,
        paddingRight: 0,
        paddingTop: 10,
        paddingBottom: 10,
        zIndex: 10,
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        '@media (max-width: 800px)': {
            paddingLeft: 0,
        },
        '@media (max-width: 650px)': {
            paddingLeft: 0,
            paddingTop: 20,
            paddingBottom: 0,
        },
        '@media (max-width: 500px)': {
            position: 'relative',
            display: 'block',
            textAlign: 'center',
            paddingTop: 15,
            paddingBottom: 15,
            paddingLeft: 15,
        },
    },
    brandLogoWrapper: {
        position: 'relative',
        width: 130,

        '@media (max-width: 800px)': {
            width: 120,
        },
        '@media (max-width: 500px)': {
            width: 130,
        },
    },
    mobilePhoneLink: {
        position: 'absolute',
        right: 65,
        top: 19,
        whiteSpace: 'nowrap',
        alignItems: 'center',
        paddingTop: 5,
        paddingBottom: 5,
        fontSize: 12,
        color: colors.gray,
        textDecoration: 'none',
        display: 'none',

        '@media (max-width: 500px)': {
            display: 'flex',
        },
        '@media (max-width: 325px)': {
            display: 'none',
        },
    },
    phoneIcon: {
        width: 10,
        marginRight: 5,
        fill: colors.gray,
    },
    navContainer: {
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-end',

        '@media (max-width: 650px)': {
            marginTop: 10,
            alignItems: 'center',
        },
        '@media (max-width: 500px)': {
            marginTop: 0,
        },
    },

    //Nav
    ul: {
        position: 'relative',
        width: '100%',
        textAlign: 'right',
        margin: 0,
        padding: 0,

        '@media (max-width: 650px)': {
            textAlign: 'center',
            paddingTop: 0,
            paddingBottom: 15,
        },
        '@media (max-width: 500px)': {
            paddingTop: 0,
            paddingBottom: 0,
        },
    },
    li: {
        position: 'relative',
        display: 'inline-block',
        textAlign: 'center',
        paddingLeft: 30,
        paddingRight: 0,
        fontSize: 16,

        '@media (max-width: 1000px)': {
            paddingLeft: 16,
            paddingRight: 0,
        },
        '@media (max-width: 800px)': {
            paddingLeft: 10,
            paddingRight: 0,
            fontSize: 14,
        },
        '@media (max-width: 650px)': {
            paddingLeft: 8,
            paddingRight: 8,
        },
        '@media (max-width: 500px)': {
            padding: '10px 0px',
            fontSize: 18,
            lineHeight: 1,
            display: 'none',
            width: '100%',
            borderBottom: `1px solid ${colors.lightGray}`,
        },
    },
    liOpen: {
        '@media (max-width: 500px)': {
            display: 'block',
        },
    },
    linkWrapper: {
        color: colors.darkGray,
        transition: 'color 0.2s ease-in',

        ':hover': {
            color: colors.primary,
        },
    },
    link: {
        textAlign: 'center',
        textDecoration: 'none',
        fontWeight: 300,
    },
    active: {
        color: colors.primary,
    },
    dropdownSpanWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    dropDownSpan: {
        fontWeight: 300,
        marginRight: 15,
    },
    downArrow: {
        width: 10,
        height: 10,
        marginLeft: -5,
        marginTop: -5,
        marginRight: 5,
        borderBottom: `2px solid ${colors.darkGray}`,
        borderRight: `2px solid ${colors.darkGray}`,
        transform: 'rotate(45deg)',
    },
    dropdownUl: {
        position: 'absolute',
        right: 0,
        top: '100%',
        zIndex: 100,
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 5,
        paddingTop: 10,
        //marginLeft                : 30,
        backgroundColor: colors.white,
        //width                     : 200,
        boxShadow: '0px 2px 1px 0px rgba(0,0,0,0.2)',
        borderBottomLeftRadius: 3,
        borderBottomRightRadius: 3,

        '@media (max-width: 500px)': {
            position: 'relative',
            width: '90%',
            marginLeft: 'auto',
            marginRight: 'auto',
            boxShadow: 'none',
            paddingTop: 5,
            paddingLeft: 0,
            paddingRight: 0,
            marginTop: 10,
            borderTop: `1px solid ${colors.lightGray}`,
        },
    },
    dropdownLi: {
        listStyle: 'none',
        marginTop: 10,
        marginBottom: 10,
        paddingBottom: 10,
        fontWeight: 300,

        '@media (max-width: 500px)': {
            borderBottom: `1px solid ${colors.lightGray}`,
        },
    },
    dropdownLast: {
        borderBottom: 'none',
        paddingBottom: 0,

        '@media (max-width: 500px)': {
            marginBottom: 0,
            borderBottom: 'none',
        },
    },
    dropdownLinkWrapper: {
        color: colors.darkGray,
        transition: 'color 0.2s ease-in',
        textAlign: 'left',
        fontSize: 16,

        ':hover': {
            color: colors.primary,
        },
        ':active': {
            color: colors.primary,
        },

        '@media (max-width: 500px)': {
            textAlign: 'center',
            fontSize: 12,
        },
    },
    dropdownLink: {
        textDecoration: 'none',
        paddingLeft: 5,
        paddingRight: 5,
        lineHeight: 1,
    },
    mobileNavButton: {
        position: 'absolute',
        top: 14,
        right: 15,
        width: 35,
        height: 35,
        borderRadius: 3,
        cursor: 'pointer',
        display: 'none',
        zIndex: 100,
        padding: 5,
        border: 'none',
        backgroundColor: 'transparent',
        stroke: colors.primary,

        transition: 'transform 0.3s ease-in-out',

        '@media (max-width: 500px)': {
            display: 'block',
        },
    },
    navIcon: {
        width: '100%',
    },
    activeMobileNavButton: {},
}
