import { TechnicianKeys } from './../../actions/types'

const INITIAL_STATE = {
	spareParts: undefined,
	sparePartsLoaded: false,
	sparePartsWatcherRef: undefined,

	sparePart: undefined,
	sparePartLoaded: false,
	sparePartWatcherRef: undefined,
}

export default (state = INITIAL_STATE, action) => {
	const {
		FETCH_SPARE_PARTS,
		SAVE_SPARE_PARTS_WATCHER,
		REMOVE_SPARE_PARTS_WATCHER,

		FETCH_SPARE_PART,
		SAVE_SPARE_PART_WATCHER,
		REMOVE_SPARE_PART_WATCHER,
	} = TechnicianKeys

	switch (action.type) {
		case FETCH_SPARE_PARTS:
			return { ...state, spareParts: action.payload, sparePartsLoaded: true }
		case SAVE_SPARE_PARTS_WATCHER:
			return { ...state, sparePartsWatcherRef: action.payload }
		case REMOVE_SPARE_PARTS_WATCHER:
			return {
				...state,
				spareParts: INITIAL_STATE.spareParts,
				sparePartsLoaded: INITIAL_STATE.sparePartsLoaded,
				sparePartsWatcherRef: INITIAL_STATE.sparePartsWatcherRef,
			}

		case FETCH_SPARE_PART:
			return { ...state, sparePart: action.payload, sparePartLoaded: true }
		case SAVE_SPARE_PART_WATCHER:
			return { ...state, sparePartWatcherRef: action.payload }
		case REMOVE_SPARE_PART_WATCHER:
			return {
				...state,
				sparePart: INITIAL_STATE.sparePart,
				sparePartLoaded: INITIAL_STATE.sparePartLoaded,
				sparePartWatcherRef: INITIAL_STATE.sparePartWatcherRef,
			}
		default:
			return state
	}
}
