import { ManagerKeys } from './../../actions/types'

const INITIAL_STATE = {
	standup: null,
	standupWatcherRef: undefined
}

export default (state = INITIAL_STATE, action) => {
	const {
		FETCH_STANDUP,
		STANDUP_WATCHER,
		REMOVE_STANDUP_WATCHER
	} = ManagerKeys

	switch (action.type) {
		case FETCH_STANDUP:
			return { ...state, standup: action.payload }
		case STANDUP_WATCHER:
			return { ...state, standupWatcherRef: action.payload }
		case REMOVE_STANDUP_WATCHER:
			return {
				...state,
				standup: INITIAL_STATE.standup,
				standupWatcherRef: INITIAL_STATE.standupWatcherRef,
			}
		default:
			return state
	}
}
