import { colors } from './../../../config/styles'

export default {
	bodyContainer: {
		backgroundColor: '#f2f2f2',
	},
	titleCopy: {
		color: colors.primary,
		fontWeight: 'bold',
		fontSize: 32,
		textAlign: 'center',
	},
	sectionTitle: {
		color: colors.primary,
		fontWeight: 'bold',
		fontSize: 20,
	},
	bodyCopy: {
		color: '#666666',
		fontSize: 16,
		lineHeight: '150%',
		msoLineHeightRule: 'exactly !important',
	},
	coloredBodyCopy: {
		color: colors.primary,
		fontSize: 16,
		lineHeight: '150%',
		msoLineHeightRule: 'exactly !important',
	},
	linkButton: {
		textDecoration: 'none',
		padding: '15px 30px',
		backgroundColor: colors.primary,
		color: colors.white,
	},
	inlineLink: {
		color: '#666666',
	},
	th: {
		fontSize: 12,
		textAlign: 'left',
	},
	td: {
		fontSize: 12,
		textAlign: 'left',
		whiteSpace: 'pre-line',
	},
}
