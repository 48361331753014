import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import Radium from 'radium'
import ReactTable from 'react-table'
import CsvDownload from "react-json-to-csv";

import { startWatchingJobsLive, stopWatchingJobsLive } from './../../../../actions/Manager'
import { startWatchingOrganization, stopWatchingOrganization } from './../../../../actions/Admin'
import { getJobLiveArray } from './../../../../selectors/Manager'

import TEHelmet from './../../../../component/TEHelmet'

import PanelWrapper from './../../../../component/Layout/PanelWrapper'
import Panel from './../../../../component/Layout/Panel'

import { tableColumns } from './tableConfigs'
import { convertToDateShort, convertToDateTimeShort, customTableFilter } from './../../../../helpers'
import styles from './styles'

class Live extends Component {
	componentDidMount() {
		const { userModel, startWatchingJobsLive, startWatchingOrganization } = this.props
		startWatchingOrganization(userModel.organizationUID)
		startWatchingJobsLive(userModel.organizationUID)
	}
	componentWillUnmount() {
		this.props.stopWatchingJobsLive()
		this.props.stopWatchingOrganization()
	}
	render() {
		const meta = {
			title: 'Rittal | Job Live',
			description: '',
		}
		const { jobArray, organizationUsers } = this.props
		let exportArray = [];
		let assignedToString
		let assignedToArray
		jobArray.forEach((d) => {
			assignedToString = ' -- '
			if (d.assignedTo && organizationUsers) {
				assignedToArray = []
				for (const userUID in d.assignedTo) {
					const user = organizationUsers[userUID]
					if (user && (user.firstName || user.lastName)) {
						const { firstName, lastName } = user
						const displayName = `${firstName || ''} ${lastName || ''}`.trim()
						assignedToArray.push(`${displayName}`)
					}
				}
				assignedToString = assignedToArray.join('; ')
			}
			d.assignedToString = assignedToString
			exportArray.push({
				'Case Number': d.jobNumber,
				'Date Created': convertToDateTimeShort(d.dateCreated),
				'Request Type': d.serviceType,
				'Paying Customer': d.site,
				'Work Order Number': d.clientName,
				'ETA': convertToDateShort(d.dateOfService) + ' ' + (d.eta !== undefined ? d.eta : ''),
				'Tech/Service Partner': assignedToString,
				'Status': d.serviceStatus,
				'Customer PO #': d.poNumber,
				'Region': d.region,
				'Billing Disposition': d.billingDisposition
			});
		})
		
		return (
			<Fragment>
				<TEHelmet {...meta} />
				<PanelWrapper>
					<Panel title="Active"
						rightComponent={
							<div style={styles.flexBox}>
								<CsvDownload data={exportArray} style={styles.exportButton} filename="active_service_cases.csv">Export</CsvDownload>
						    </div>
						}
					>
						<div style={styles.tableContainer}>
							<ReactTable
								data={jobArray}
								columns={tableColumns}
								defaultPageSize={10}
								filterable
								defaultFilterMethod={customTableFilter}
								defaultSorted={[
									{
										id: 'jobNumber',
										desc: false,
									},
								]}
							/>
						</div>
					</Panel>
				</PanelWrapper>
			</Fragment>
		)
	}
}

Live.propTypes = {}

Live.defaultProps = {}

const mapStateToProps = (state) => {
	const { userModel } = state.Loading
	const jobArray = getJobLiveArray(state)
	const { organizationUsers } = state.Admin.Organizations
	return { userModel, jobArray, organizationUsers }
}

export default connect(
	mapStateToProps,
	{ startWatchingJobsLive, stopWatchingJobsLive, startWatchingOrganization, stopWatchingOrganization }
)(Radium(Live))
