import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'
import { Route } from 'react-router-dom'
import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import ReduxThunk from 'redux-thunk'
import { StyleRoot } from 'radium'
import { TEAppWrapper } from 'react-tec'

import 'react-table/react-table.css'
import 'react-datepicker/dist/react-datepicker.css'
import './reactPowerSelect.css'
import './react-datepicker.css'
import './react-table.css'
import './accordian.css'
import './index.css'

import ScrollToTop from './component/ScrollToTop'

import GATracking from './component/GATracking'
import App from './App'
import reducers from './reducers'

const store = createStore(reducers, applyMiddleware(ReduxThunk))

const Root = (
	<StyleRoot>
		<Provider store={store}>
			<TEAppWrapper theme={{ primary: '#E50043' }}>
				<ScrollToTop>
					<Route component={GATracking(App)} />
				</ScrollToTop>
			</TEAppWrapper>
		</Provider>
	</StyleRoot>
)

ReactDOM.render(Root, document.getElementById('root'))
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
