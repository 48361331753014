import { colors } from './../../../config/styles'

export default {
	portalWrapper: {
		backgroundColor: 	colors.lightestGray,
	},
	mainPanel : {
		position		: 'relative',
		width 			: 'calc(100% - 200px)',
		minHeight 		: '100%',
		marginLeft 		: 200,
	},
	portalContentWrapper : {
    	minHeight 	: 'calc(100vh - 160px)'
	}
}
