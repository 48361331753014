export const colors = {
    white: '#ffffff',
    lightestGray: '#f6f6f6',
    lighterGray: '#efefef',
    lightGray: '#e5e5e5',
    gray: '#757575',
    darkGray: '#615751',
    darkerGray: '#3f3935',
    darkestGray: '#555555',
    black: '#333333',
    errorRed: '#cc0000',

    primary: '#e50043',

    shadowBottom: 'rgba(0, 0, 0, 0.1) 0px 15px 15px -15px, rgba(0, 0, 0, 0.05) 0px 5px 5px -5px',
    shadowTop: 'rgba(0, 0, 0, 0.1) 0px -15px 15px -15px, rgba(0, 0, 0, 0.05) 0px -5px 5px -5px',
    shadowRight: 'rgba(0, 0, 0, 0.1) 15px 0px 15px -15px, rgba(0, 0, 0, 0.05) 5px 0px 5px -5px',
    shadowCenter: 'rgba(0, 0, 0, 0.1) 0px 0px 6px 0px, rgba(0, 0, 0, 0.05) 0px 0px 2px 0px',
}
