//
//TE Version 0.2.0
//

import React, { Component } from 'react'
import Radium from 'radium'
import PropTypes from 'prop-types'
import styles from './styles'

class TEInput extends Component {

    render() {
        const {
            style,
            value,
            ...rest
        } = this.props

        return (
            <input
				value={value}
				style={{ ...styles.input(rest.disabled), ...style }}
				{...rest}
			/>
        )
    }
}

TEInput.propTypes = {
    style: PropTypes.object,
    type: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool
}

TEInput.defaultProps = {
    type: 'text',
    disabled: false
}

export default Radium(TEInput)