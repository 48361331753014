import React, { Component } from 'react'
import Radium from 'radium'
import { connect } from 'react-redux'

import { sendSummaryEmail } from './../../../../../actions/Technician'

import TEPopupForm from './../../../../../component/Popup/TEPopupForm'
import TEInputRow from './../../../../../component/Form/TEInputRow'
import TESegmentedGroup from './../../../../../component/Form/TESegmentedGroup'

import styles from './styles'

class SendSummaryEmailPopup extends Component {
	state = { emails: '', ccMe: 'Yes', notes: '' }

	handleOnSubmit = () => {
		const { emails, ccMe, notes } = this.state
		const { summaryData, sendSummaryEmail, userModel, job, onSubmit } = this.props

		const data = { summaryData, userModel, job, emails, ccMe, notes }
		sendSummaryEmail(data)
			.then(() => {
				onSubmit()
				this.setState({ emails: '', ccMe: 'Yes', notes: '' })
			})
			.catch((error) => console.log(error))
	}

	render() {
		const { emails, ccMe, notes } = this.state
		const { visible, onClose } = this.props

		return (
			<TEPopupForm
				visible={visible}
				contentStyles={styles.content}
				onClose={onClose}
				onSubmit={this.handleOnSubmit}
				onSubmitTitle="Send Email"
			>
				<h1 style={styles.formTitle}>Send Summary Email</h1>
				<TEInputRow
					labelForKey="emails"
					title="Emails (separated by comma)"
					value={emails}
					onChange={(e) => {
						this.setState({ emails: e.target.value })
					}}
					required
				/>
				<TESegmentedGroup
					labelForKey="ccMe"
					title="CC Me on Email"
					checkedValue={ccMe}
					buttonArray={['Yes', 'No']}
					onChange={(e) => this.setState({ ccMe: e.target.value })}
					inline
					required
				/>
				<TEInputRow
					type="textarea"
					labelForKey="notes"
					title="Notes"
					value={notes}
					onChange={(e) => {
						this.setState({ notes: e.target.value })
					}}
				/>
			</TEPopupForm>
		)
	}
}

const mapStateToProps = (state) => {
	const { userModel } = state.Loading
	return { userModel }
}

export default connect(
	mapStateToProps,
	{
		sendSummaryEmail,
	}
)(Radium(SendSummaryEmailPopup))
