import { combineReducers } from 'redux'

import Jobs from './JobsReducer'
import Products from './ProductsReducer'
import SpareParts from './SparePartsReducer'

export default combineReducers({
	Jobs,
	Products,
	SpareParts
})
