import { colors } from './../../config/styles'

export default {
	container: {
		position: 'relative',
		width: '90%',
		maxWidth: 400,
		marginLeft: 'auto',
		marginRight: 'auto',
		paddingTop: 100,
		paddingBottom: 100,
		marginTop: 0,
		marginBottom: 0,
	},
	h1: {
		position: 'relative',
		textAlign: 'center',
		color: colors.black,
	},
}
