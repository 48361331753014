import React, { Component } from 'react'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Radium from 'radium'

import TELink from './../../../component/Navigation/TELink'

import { signInUser } from './../../../actions'

import TEHelmet from './../../../component/TEHelmet'

import PanelWrapper from './../../../component/Layout/PanelWrapper'
import Panel from './../../../component/Layout/Panel'

import TEForm from './../../../component/Form/TEForm'
import TEInputRow from './../../../component/Form/TEInputRow'
import TESubmit from './../../../component/Form/TESubmit'

import styles from './styles'

class SignIn extends Component {
	state = { email: '', password: '' }
	componentDidMount() {
		this._isMounted = true
	}
	componentWillUnmount() {
		this._isMounted = false
	}
	handleSignInSubmit = (e) => {
		e.preventDefault()
		const { email, password } = this.state
		const { signInUser, history } = this.props

		const data = { email, password, history }
		signInUser(data)
			.then(() => {
				if (this._isMounted) {
					this.setState({ email: '', password: '' })
				}
			})
			.catch((error) => console.log(error))
	}

	render() {
		const meta = {
			title: 'Rittal Service Visit | Sign In',
			description: '',
		}
		const { email, password } = this.state

		return (
			<React.Fragment>
				<TEHelmet {...meta} />
				<PanelWrapper style={styles.container}>
					<Panel containerStyle={styles.panel}>
						<TEForm style={styles.form} onSubmit={this.handleSignInSubmit}>
							<h1 style={styles.title}>Sign In</h1>
							<TEInputRow
								labelForKey="email"
								type="email"
								value={email}
								onChange={(e) => {
									this.setState({ email: e.target.value })
								}}
								placeholder="Email"
								labelStyles={styles.label}
								inputStyles={styles.input}
							/>
							<TEInputRow
								labelForKey="password"
								type="password"
								value={password}
								onChange={(e) => {
									this.setState({ password: e.target.value })
								}}
								placeholder="Password"
								labelStyles={styles.label}
								inputStyles={styles.input}
							/>
							<div style={styles.buttonContainer}>
								<TELink
									to="/forgot-password"
									wrapperStyle={styles.forgotWrapper}
									style={styles.forgot}
								>
									Forgot Password?
								</TELink>
								<TESubmit style={styles.button}>Sign In</TESubmit>
							</div>
						</TEForm>
					</Panel>
				</PanelWrapper>
			</React.Fragment>
		)
	}
}

SignIn.propTypes = {}

SignIn.defaultProps = {}

const mapStateToProps = (state) => {
	const { signInEmailData, signInPasswordData } = state.Auth

	return {
		signInEmailData,
		signInPasswordData,
	}
}
export default connect(
	mapStateToProps,
	{
		signInUser,
	}
)(Radium(SignIn))
