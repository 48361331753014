import { colors } from './../../../config/styles'

export default {
    button: (disabled) => ({
        position: 'relative',
        display: 'block',
        width: '100%',
        fontSize: 18,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 5,
        paddingTop: 5,
        textAlign: 'center',
        cursor: disabled ? 'not-allowed' : 'pointer',
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,

        color: disabled ? colors.gray : colors.darkGray,
        backgroundColor: colors.white,
        borderTop: disabled ? `1px solid ${colors.lighterGray}` : `1px solid ${colors.lightGray}`,
        borderRight: disabled ? `1px solid ${colors.lighterGray}` : `1px solid ${colors.lightGray}`,
        borderBottom: disabled
            ? `1px solid ${colors.lighterGray}`
            : `1px solid ${colors.lightGray}`,
        borderLeft: disabled ? `1px solid ${colors.lighterGray}` : `1px solid ${colors.lightGray}`,

        transition: 'color 0.2s ease-in, background-color 0.2s ease-in, border 0.2s ease-in',

        ':hover': {
            color: colors.white,
            backgroundColor: colors.primary,
            borderTop: `1px solid ${colors.primary}`,
            borderRight: `1px solid ${colors.primary}`,
            borderBottom: `1px solid ${colors.primary}`,
            borderLeft: `1px solid ${colors.primary}`,
        },
        ':active': {
            color: colors.white,
            backgroundColor: colors.primary,
            borderTop: `1px solid ${colors.primary}`,
            borderRight: `1px solid ${colors.primary}`,
            borderBottom: `1px solid ${colors.primary}`,
            borderLeft: `1px solid ${colors.primary}`,
        },
    }),
}
