import axios from 'axios'
import firebase from 'firebase/app'
import 'firebase/auth'

import settings from './../config/settings'

export const apiRequest = (service, method, path, data) => {
	const { dev, staging, DEVELOPMENT, PRODUCTION, apiDebugging } = settings

	let baseURL
	switch (service){
		case 'aws':
			baseURL = dev || staging ? DEVELOPMENT.AWS_INFRASTRUCTURE_API_URL : PRODUCTION.AWS_INFRASTRUCTURE_API_URL
			break
		case 'influence':
			baseURL = dev || staging ? DEVELOPMENT.INFLUENCE_API_URL : PRODUCTION.INFLUENCE_API_URL
			break
		case 'marketing':
			baseURL = dev || staging ? DEVELOPMENT.MARKETING_ASSISTANT_API_URL : PRODUCTION.MARKETING_ASSISTANT_API_URL
			break
		case 'jobPdf':
			// baseURL = dev || staging ? DEVELOPMENT.WEBSITE_URL : PRODUCTION.WEBSITE_URL
			baseURL = 'https://www.rittal.com/us_en/apps/services'
			break
		default:
			console.error("Invalid API Service")
			return
	}

	const url = baseURL + '/' + path
	const { uid } = firebase.auth().currentUser

	return firebase.auth().currentUser.getIdToken().then((token) => {
		const headers = { uid, token }

		if (apiDebugging){ console.log(`API REQUEST - ${path}:`, { method, url, headers, data }) }
		if (apiDebugging){
			return axios({ method, url, headers, data })
			.then( (response) => {
				console.log(`API RESPONSE - ${path}:`, response)
				return response
			})
		}else{
			return axios({ method, url, headers, data })
		}
	})
}


export const apiCatchAlert = ({ error, title: defaultTitle, message: defaultMessage }) => {
	const { dev } = settings

	if (dev){
		console.log(error)
	}

	if (error && error.response && error.response.data){
		const {
			title = defaultTitle,
			message = defaultMessage
		} = error.response.data

		return {
			alertTitle: title,
			alertMessage: message
		}
	}

	return {
		alertTitle: defaultTitle,
		alertMessage: defaultMessage
	}
}
