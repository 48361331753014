import React from 'react'

import TELink from './../../../component/Navigation/TELink'

import { convertToDateString } from './../../../helpers'
import styles from './styles'

export const tableColumns = [
	{
		id: 'dateCreated',
		Header: 'Date Created',
		filterable: false,
		accessor: 'dateCreated',
		Cell: (d) => convertToDateString(d.value),
	},
	{
		Header: 'Name',
		accessor: 'name',
	},
	{
		id: 'details',
		Header: '',
		accessor: 'uid',
		sortable: false,
		filterable: false,
		maxWidth: 140,
		className: 'actionCell',
		Cell: (d) => (
			<TELink
				to={`/admin/organizations/${d.value}/users`}
				style={styles.link}
				wrapperStyle={styles.linkWrapper}
			>
				Details
			</TELink>
		),
	},
]
