//GLOBAL
export const SHOW_ALERT = 'show_alert'
export const HIDE_ALERT = 'hide_alert'
export const SHOW_CONFIRM = 'show_confirm'
export const HIDE_CONFIRM = 'hide_confirm'
export const SHOW_NETWORK_ACTIVITY = 'show_network_activity'
export const HIDE_NETWORK_ACTIVITY = 'hide_network_activity'
export const SET_PAGE_TITLE = 'set_page_title'

//Auth
export const AuthKeys = {
	FETCH_USER_PERMISSIONS: 'auth__load_user_permissions',
	SAVE_USER_PERMISSIONS_WATCHER: 'auth__save_user_permissions_watcher',
	REMOVE_USER_PERMISSIONS_WATCHER: 'auth__remove_user_permissions_watcher',
}

//Loading
export const LoadingKeys = {
	USER_CHECK_STARTED: 'loading__user_check_started',
	USER_CHECK_COMPLETE: 'loading__user_check_complete',

	FETCH_APP_VERSIONS: 'loading__fetch_app_versions',

	FETCH_USER: 'loading__fetch_user',
	SAVE_USER_WATCHER: 'loading__save_user_watcher',
	REMOVE_USER_WATCHER: 'loading__remove_user_watcher',
}

//Admin
export const AdminKeys = {
	FETCH_ORGANIZATIONS: 'admin__fetch_organizations',
	SAVE_ORGANIZATIONS_WATCHER: 'admin__save_organizations_watcher',
	REMOVE_ORGANIZATIONS_WATCHER: 'admin__remove_organizations_watcher',

	FETCH_ORGANIZATION: 'admin__fetch_organization',
	SAVE_ORGANIZATION_WATCHER: 'admin__save_organization_watcher',
	REMOVE_ORGANIZATION_WATCHER: 'admin__remove_organization_watcher',

	FETCH_ORGANIZATION_USERS: 'admin__fetch_organization_users',
	SAVE_ORGANIZATION_USERS_WATCHER: 'admin__save_organization_users_watcher',
	REMOVE_ORGANIZATION_USERS_WATCHER: 'admin__remove_organization_users_watcher',

	FETCH_USERS_PERMISSIONS: 'admin__fetch_users_permissions',
	SAVE_USERS_PERMISSIONS_WATCHER: 'admin__save_users_permissions_watcher',
	REMOVE_USERS_PERMISSIONS_WATCHER: 'admin__remove_users_permissions_watcher',

	FETCH_USER: 'admin__fetch_user',
	SAVE_USER_WATCHER: 'admin__save_user_watcher',
	REMOVE_USER_WATCHER: 'admin__remove_user_watcher',

	FETCH_USER_PERMISSIONS: 'admin__fetch_user_permissions',
	SAVE_USER_PERMISSIONS_WATCHER: 'admin__save_user_permissions_watcher',
	REMOVE_USER_PERMISSIONS_WATCHER: 'admin__remove_user_permissions_watcher',
}

//Manager
export const ManagerKeys = {
	FETCH_JOBS_LIVE: 'manager__fetch_jobs_live',
	SAVE_JOBS_LIVE_WATCHER: 'manager__save_jobs_live_watcher',
	REMOVE_JOBS_LIVE_WATCHER: 'manager__remove_jobs_live_watcher',

	FETCH_JOBS_HISTORY: 'manager__fetch_jobs_history',
	SAVE_JOBS_HISTORY_WATCHER: 'manager__save_jobs_history_watcher',
	REMOVE_JOBS_HISTORY_WATCHER: 'manager__remove_jobs_history_watcher',

	FETCH_JOB_NUMBER: 'manager__fetch_job_number',
	SAVE_JOB_NUMBER_WATCHER: 'manager__save_job_number_watcher',
	REMOVE_JOB_NUMBER_WATCHER: 'manager__remove_job_number_watcher',

	FETCH_JOB: 'manager__fetch_job',
	SAVE_JOB_WATCHER: 'manager__save_job_watcher',
	REMOVE_JOB_WATCHER: 'manager__remove_job_watcher',

	FETCH_PRODUCTS: 'manager__fetch_products',
	SAVE_PRODUCTS_WATCHER: 'manager__save_products_watcher',
	REMOVE_PRODUCTS_WATCHER: 'manager__remove_products_watcher',

	FETCH_PRODUCT: 'manager__fetch_product',
	SAVE_PRODUCT_WATCHER: 'manager__save_product_watcher',
	REMOVE_PRODUCT_WATCHER: 'manager__remove_product_watcher',

	FETCH_STANDUP: 'manager__fetch_standup',
	STANDUP_WATCHER: 'manager__standup_watcher',
	REMOVE_STANDUP_WATCHER: 'manager__remove_standup_watcher',

	FETCH_SPARE_PARTS: 'manager__fetch_spare_parts',
	SAVE_SPARE_PARTS_WATCHER: 'manager__save_spare_parts_watcher',
	REMOVE_SPARE_PARTS_WATCHER: 'manager__remove_spare_parts_watcher',

	FETCH_SPARE_PART: 'manager__fetch_spare_part',
	SAVE_SPARE_PART_WATCHER: 'manager__save_spare_part_watcher',
	REMOVE_SPARE_PART_WATCHER: 'manager__remove_spare_part_watcher',
}

//Technician
export const TechnicianKeys = {
	FETCH_JOBS_LIVE: 'technician__fetch_jobs_live',
	SAVE_JOBS_LIVE_WATCHER: 'technician__save_jobs_live_watcher',
	REMOVE_JOBS_LIVE_WATCHER: 'technician__remove_jobs_live_watcher',

	FETCH_JOBS_HISTORY: 'technician__fetch_jobs_history',
	SAVE_JOBS_HISTORY_WATCHER: 'technician__save_jobs_history_watcher',
	REMOVE_JOBS_HISTORY_WATCHER: 'technician__remove_jobs_history_watcher',

	FETCH_JOB_NUMBER: 'technician__fetch_job_number',
	SAVE_JOB_NUMBER_WATCHER: 'technician__save_job_number_watcher',
	REMOVE_JOB_NUMBER_WATCHER: 'technician__remove_job_number_watcher',

	FETCH_JOB_LIVE: 'technician__fetch_job_live',
	SAVE_JOB_LIVE_WATCHER: 'technician__save_job_live_watcher',
	REMOVE_JOB_LIVE_WATCHER: 'technician__remove_job_live_watcher',

	FETCH_PRODUCTS: 'technician__fetch_products',
	SAVE_PRODUCTS_WATCHER: 'technician__save_products_watcher',
	REMOVE_PRODUCTS_WATCHER: 'technician__remove_products_watcher',

	FETCH_PRODUCT: 'technician__fetch_product',
	SAVE_PRODUCT_WATCHER: 'technician__save_product_watcher',
	REMOVE_PRODUCT_WATCHER: 'technician__remove_product_watcher',

	FETCH_SPARE_PARTS: 'technician__fetch_spare_parts',
	SAVE_SPARE_PARTS_WATCHER: 'technician__save_spare_parts_watcher',
	REMOVE_SPARE_PARTS_WATCHER: 'technician__remove_spare_parts_watcher',

	FETCH_SPARE_PART: 'technician__fetch_spare_part',
	SAVE_SPARE_PART_WATCHER: 'technician__save_spare_part_watcher',
	REMOVE_SPARE_PART_WATCHER: 'technician__remove_spare_part_watcher',
}
