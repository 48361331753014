import React, { Component, Fragment } from 'react'
import Radium from 'radium'

import TEPopupMultiStepForm from './../../../../../component/Popup/TEPopupMultiStepForm'
import TEInputRow from './../../../../../component/Form/TEInputRow'
import TEDatetimeRow from './../../../../../component/Form/TEDatetimeRow'
import TESearchSelectRow from './../../../../../component/Form/TESearchSelectRow'

import styles from './styles'

class EditJobDetailsPopup extends Component {
	state = {
		jobNumber: '',
		dateOfService: undefined,
		site: '',
		serviceType: '',
		assignedTo: [],
		assignedBy: '',
		clientName: '',
		clientClientNumber: '',
		clientAddress: '',
		clientContact: '',
		clientPhone: '',
		clientEmail: '',
		jobNotes: '',
		eta: '',
		standupObj: null,
		poNumber: '',
		serviceStatus: '',
		billingDisposition: '',
		equipmentType: '',
		region: '',
		jobDescription: ''
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		const { job, userArray, standupArray } = nextProps
		if (job && userArray && (job !== prevState.job || userArray !== prevState.userArray || standupArray !== prevState.standupArray)) {
			const {
				jobNumber = '',
				dateOfService,
				site = '',
				serviceType = '',
				assignedTo: assignedToObject,
				assignedBy = '',
				clientName = '',
				clientClientNumber = '',
				clientAddress = '',
				clientContact = '',
				clientPhone = '',
				clientEmail = '',
				jobNotes = '',
				eta = '',
				poNumber = '',
				serviceStatus = '',
				billingDisposition = '',
				equipmentType = '',
				region = '',
				jobDescription = ''
			} = job

			const users = {}
			for (let i = 0; i < userArray.length; i++) {
				const user = userArray[i]
				users[user.uid] = user
			}

			const assignedTo = []
			if (assignedToObject) {
				for (const assignedToUID in assignedToObject) {
					if (users[assignedToUID]) {
						assignedTo.push(users[assignedToUID])
					}
				}
			}

			let standupObj = null
			for (let i = 0; i < standupArray.length; i++) {
				if(standupArray[i].site === site) {
					standupObj = standupArray[i];
					break;
				}
			}

			return {
				jobNumber,
				dateOfService,
				site,
				serviceType,
				assignedTo,
				assignedBy,
				clientName,
				clientClientNumber,
				clientAddress,
				clientContact,
				clientPhone,
				clientEmail,
				jobNotes,
				eta,
				poNumber,
				serviceStatus,
				billingDisposition,
				equipmentType,
				region,
				jobDescription,
				standupObj,
				job,
				userArray,
				standupArray
			}
		}
		return { job, userArray, standupArray }
	}

	handleOnSubmit = () => {
		const {
			dateOfService,
			site,
			serviceType,
			assignedTo,
			assignedBy,
			clientName,
			clientClientNumber,
			clientAddress,
			clientContact,
			clientPhone,
			clientEmail,
			jobNotes,
			eta,
			poNumber,
			serviceStatus,
			billingDisposition,
			equipmentType,
			region,
			jobDescription
		} = this.state
		const { onSubmit } = this.props

		const data = {
			dateOfService,
			site,
			serviceType,
			assignedTo,
			assignedBy,
			clientName,
			clientClientNumber,
			clientAddress,
			clientContact,
			clientPhone,
			clientEmail,
			jobNotes,
			eta,
			poNumber,
			serviceStatus,
			billingDisposition,
			equipmentType,
			region,
			jobDescription
		}
		return onSubmit(data)
	}

	fillStandupData = (obj) => {
		const addressParts = [obj.address,obj.city,obj.st];
		const date = new Date(obj.pmServiceDate);
		this.setState({
			clientClientNumber: obj.accountNumber,
			clientAddress: (addressParts.join(', ') + ' ' + obj.zip).trim(),
			clientPhone: obj.amazonITPOCPhone,
			clientContact: obj.amazonITPOCName,
			dateOfService: date.getTime(),
			eta: obj.pmServiceETA,
			region: obj.region,
			poNumber: obj.poNumber,
			clientName: obj.pmWorkOrder
		});
	}

	handleSiteChange = (obj, jobType) => {
		this.setState({
			site: obj.site,
			standupObj: obj
		})
		this.fillStandupData(obj)
	}

	render() {
		const {
			jobNumber,
			dateOfService,
			site,
			serviceType,
			assignedTo,
			assignedBy,
			clientName,
			clientClientNumber,
			clientAddress,
			clientContact,
			clientPhone,
			clientEmail,
			jobNotes,
			eta,
			poNumber,
			serviceStatus,
			billingDisposition,
			equipmentType,
			region,
			jobDescription
		} = this.state
		const { visible, onClose, userArray, standupArray, jobType } = this.props
		return (
			<TEPopupMultiStepForm
				visible={visible}
				contentStyles={styles.content}
				onClose={onClose}
				onSubmit={this.handleOnSubmit}
				stepData={[
					{
						title: 'Details',
						component: (
							<Fragment>
								<TEInputRow
									labelForKey="jobNumber"
									title="Job Number"
									value={jobNumber}
									onChange={() => {}}
									size="third"
									disabled
								/>
								<TESearchSelectRow
									labelForKey="serviceType"
									title="Request Type"
									value={serviceType}
									onChange={({ option }) => {
										this.setState({
											serviceType: option
										})
									}}
									options={['Amazon PM','Field Repair','Shop Repair','Install','PM','Training']}
									placeholder="Request Type"
									size="third"
								/>
								<TESearchSelectRow
									labelForKey="equipmentType"
									title="Equipment Type"
									value={equipmentType}
									onChange={({ option }) => {
										this.setState({
											equipmentType: option
										})
									}}
									options={['Amazon PM','AC-RTT','AC-BE+','Chiller','LCP-CW','LCP-DX','LCU','DET-AC','RAS-SE','RAS-LC','RAS-MT','RAS-WT']}
									placeholder="Equipment Type"
									size="third"
								/>
								<TESearchSelectRow
									labelForKey="site"
									title="Site"
									value={site}
									onChange={({ option }) => {
										this.handleSiteChange(option,jobType)
									}}
									options={standupArray}
									optionLabelPath="site"
									placeholder="Site ID"
									size="third"
								/>
								<TEInputRow
									labelForKey="clientName"
									title="Work Order Number"
									value={clientName}
									onChange={(e) =>
										this.setState({
											clientName: e.target.value,
										})
									}
									placeholder="Work Order Number"
									size="third"
								/>
								<TESearchSelectRow
									labelForKey="region"
									title="Region"
									value={region}
									onChange={({ option }) => {
										this.setState({
											region: option
										})
									}}
									options={['NorthEast','SouthEast','South','MidWest','Pacific','RAS-US']}
									placeholder="Region"
									size="third"
								/>
								<TEDatetimeRow
									labelForKey="dateOfService"
									title="Service Date"
									value={dateOfService}
									onChange={(dateOfService) =>
										this.setState({
											dateOfService,
										})
									}
									placeholder={'1/1/2019'}
									size="third"
								/>
								<TEInputRow
									labelForKey="eta"
									title="Service ETA"
									value={eta}
									onChange={(e) =>
										this.setState({
											eta: e.target.value,
										})
									}
									placeholder="Service ETA"
									size="third"
								/>
								<TESearchSelectRow
									type="multi"
									labelForKey="assignedTo"
									title="Assign Job To"
									value={assignedTo}
									onChange={({ options }) => {
										this.setState({
											assignedTo: options,
										})
									}}
									options={userArray}
									optionLabelPath="displayName"
									placeholder="Assign Job To"
									size="full"
								/>
								<TEInputRow
									labelForKey="poNumber"
									title="Customer PO #"
									value={poNumber}
									onChange={(e) =>
										this.setState({
											poNumber: e.target.value,
										})
									}
									placeholder="Customer PO #"
									size="third"
								/>
								<TESearchSelectRow
									labelForKey="serviceStatus"
									title="Status"
									value={serviceStatus}
									onChange={({ option }) => {
										this.setState({
											serviceStatus: option
										})
									}}
									options={['Open','Insufficient Information','Requires Appointment','RMA Needed','Pending RMA Equipment','RMA Equipment Received','RMA Equipment Shipped back','SA assigned','SA dispatched','Suspended','Incomplete','Parts Needed','Parts Ordered','Parts Backordered','Parts Shipped','Parts Received','Parts Ordered by 3rd Party','Quote Needed','Quote Submitted','Quote Approved','Quote Denied','Resolved Pending Review','Completed','Invoiced','ZK Processed','Canceled']}
									placeholder="Status"
									size="third"
								/>
								<TESearchSelectRow
									labelForKey="billingDisposition"
									title="Billing Disposition"
									value={billingDisposition}
									onChange={({ option }) => {
										this.setState({
											billingDisposition: option
										})
									}}
									options={['Billable','Recall','Warranty Potential','Contract','Sales Accommodation','Refurb']}
									placeholder="Billing Disposition"
									size="third"
								/>
								<TEInputRow
									labelForKey="clientClientNumber"
									title="SAP Account Number"
									value={clientClientNumber}
									onChange={(e) =>
										this.setState({
											clientClientNumber: e.target.value,
										})
									}
									placeholder="number"
									size="half"
								/>
								<TEInputRow
									labelForKey="assignedBy"
									title="Created By"
									value={assignedBy}
									onChange={(e) =>
										this.setState({
											assignedBy: e.target.value,
										})
									}
									placeholder="name"
									size="half"
								/>
								<TEInputRow
									labelForKey="clientAddress"
									title="Address"
									value={clientAddress}
									onChange={(e) =>
										this.setState({
											clientAddress: e.target.value,
										})
									}
									placeholder="address"
									size="full"
								/>
								<TEInputRow
									labelForKey="clientContact"
									title="Contact Name"
									value={clientContact}
									onChange={(e) =>
										this.setState({
											clientContact: e.target.value,
										})
									}
									placeholder="name"
									size="third"
								/>
								<TEInputRow
									labelForKey="clientPhone"
									title="Contact Phone"
									value={clientPhone}
									onChange={(e) =>
										this.setState({
											clientPhone: e.target.value,
										})
									}
									placeholder="phone"
									size="third"
								/>
								<TEInputRow
									labelForKey="clientEmail"
									title="Contact Email"
									value={clientEmail}
									onChange={(e) =>
										this.setState({
											clientEmail: e.target.value,
										})
									}
									placeholder="Contact Email"
									size="third"
								/>
								<TEInputRow
									labelForKey="jobDescription"
									type="textarea"
									title="Problem Description"
									value={jobDescription}
									onChange={(e) =>
										this.setState({
											jobDescription: e.target.value,
										})
									}
									placeholder="Problem Description"
									disabled={(serviceType === 'Amazon PM')}
								/>
								<TEInputRow
									labelForKey="jobNotes"
									type="textarea"
									title="Internal Notes"
									value={jobNotes}
									onChange={(e) =>
										this.setState({
											jobNotes: e.target.value,
										})
									}
									placeholder="Notes"
								/>
							</Fragment>
						),
					}
				]}
			/>
		)
	}
}

export default Radium(EditJobDetailsPopup)
