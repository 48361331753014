import React, { Component } from 'react'
//import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import Radium from 'radium'
import { Link } from 'react-router-dom'

import { sendForgotPasswordEmail } from './../../../actions'

import TEHelmet from './../../../component/TEHelmet'

import PanelWrapper from './../../../component/Layout/PanelWrapper'
import Panel from './../../../component/Layout/Panel'

import TEForm from './../../../component/Form/TEForm'
import TEInputRow from './../../../component/Form/TEInputRow'
import TESubmit from './../../../component/Form/TESubmit'

import styles from './styles'

class ForgotPassword extends Component {
	state = { email: '' }
	componentDidMount() {
		this._isMounted = true
	}
	componentWillUnmount() {
		this._isMounted = false
	}
	handleForgotPasswordSubmit = (e) => {
		e.preventDefault()
		const { email } = this.state
		const { sendForgotPasswordEmail } = this.props

		const data = { email }
		sendForgotPasswordEmail(data)
			.then(() => {
				if (this._isMounted) {
					this.setState({ email: '' })
				}
			})
			.catch((error) => console.log(error))
	}
	render() {
		const meta = {
			title: 'Rittal Service Visit | Forgot Password',
			description: '',
		}
		const { email } = this.state

		return (
			<React.Fragment>
				<TEHelmet {...meta} />
				<PanelWrapper style={styles.container}>
					<Panel containerStyle={styles.panel}>
						<TEForm style={styles.form} onSubmit={this.handleForgotPasswordSubmit}>
							<h1 style={styles.title}>Forgot Password</h1>
							<Link to="/signIn" style={styles.signIn}>
								Sign In?
							</Link>
							<TEInputRow
								labelForKey="email"
								type="email"
								valie={email}
								onChange={(e) => {
									this.setState({ email: e.target.value })
								}}
								placeholder="Email"
								labelStyles={styles.label}
								inputStyles={styles.input}
							/>
							<TESubmit style={styles.button}>Submit</TESubmit>
						</TEForm>
					</Panel>
				</PanelWrapper>
			</React.Fragment>
		)
	}
}

ForgotPassword.propTypes = {}

ForgotPassword.defaultProps = {}

const mapStateToProps = (state) => {
	const { forgotEmailData } = state.Auth

	return { forgotEmailData }
}
export default connect(
	mapStateToProps,
	{
		sendForgotPasswordEmail,
	}
)(Radium(ForgotPassword))
