import { AdminKeys } from './../../actions/types'

const INITIAL_STATE = {
	organizations: undefined,
	organizationsLoaded: false,
	organizationsWatcherRef: undefined,

	organization: undefined,
	organizationLoaded: false,
	organizationWatcherRef: undefined,

	organizationUsers: undefined,
	organizationUsersLoaded: false,
	organizationUsersWatcherRef: undefined,

	usersPermissions: undefined,
	usersPermissionsLoaded: false,
	usersPermissionsWatcherRef: undefined,

	user: undefined,
	userLoaded: false,
	userWatcherRef: undefined,

	userPermissions: undefined,
	userPermissionsLoaded: false,
	userPermissionsWatcherRef: undefined,
}

export default (state = INITIAL_STATE, action) => {
	const {
		FETCH_ORGANIZATIONS,
		SAVE_ORGANIZATIONS_WATCHER,
		REMOVE_ORGANIZATIONS_WATCHER,

		FETCH_ORGANIZATION,
		SAVE_ORGANIZATION_WATCHER,
		REMOVE_ORGANIZATION_WATCHER,

		FETCH_ORGANIZATION_USERS,
		SAVE_ORGANIZATION_USERS_WATCHER,
		REMOVE_ORGANIZATION_USERS_WATCHER,

		FETCH_USERS_PERMISSIONS,
		SAVE_USERS_PERMISSIONS_WATCHER,
		REMOVE_USERS_PERMISSIONS_WATCHER,

		FETCH_USER,
		SAVE_USER_WATCHER,
		REMOVE_USER_WATCHER,

		FETCH_USER_PERMISSIONS,
		SAVE_USER_PERMISSIONS_WATCHER,
		REMOVE_USER_PERMISSIONS_WATCHER,
	} = AdminKeys

	switch (action.type) {
		case FETCH_ORGANIZATIONS:
			return {
				...state,
				organizations: action.payload,
				organizationsLoaded: true,
			}
		case SAVE_ORGANIZATIONS_WATCHER:
			return { ...state, organizationsWatcherRef: action.payload }
		case REMOVE_ORGANIZATIONS_WATCHER:
			return {
				...state,
				organizations: INITIAL_STATE.organizations,
				organizationsLoaded: INITIAL_STATE.organizationsLoaded,
				organizationsWatcherRef: INITIAL_STATE.organizationsWatcherRef,
			}

		case FETCH_ORGANIZATION:
			return { ...state, organization: action.payload, organizationLoaded: true }
		case SAVE_ORGANIZATION_WATCHER:
			return { ...state, organizationWatcherRef: action.payload }
		case REMOVE_ORGANIZATION_WATCHER:
			return {
				...state,
				organization: INITIAL_STATE.organization,
				organizationLoaded: INITIAL_STATE.organizationLoaded,
				organizationWatcherRef: INITIAL_STATE.organizationWatcherRef,
			}

		case FETCH_ORGANIZATION_USERS:
			return { ...state, organizationUsers: action.payload, organizationUsersLoaded: true }
		case SAVE_ORGANIZATION_USERS_WATCHER:
			return { ...state, organizationUsersWatcherRef: action.payload }
		case REMOVE_ORGANIZATION_USERS_WATCHER:
			return {
				...state,
				organizationUsers: INITIAL_STATE.organizationUsers,
				organizationUsersLoaded: INITIAL_STATE.organizationUsersLoaded,
				organizationUsersWatcherRef: INITIAL_STATE.organizationUsersWatcherRef,
			}

		case FETCH_USERS_PERMISSIONS:
			return { ...state, usersPermissions: action.payload, usersPermissionsLoaded: true }
		case SAVE_USERS_PERMISSIONS_WATCHER:
			return { ...state, usersPermissionsWatcherRef: action.payload }
		case REMOVE_USERS_PERMISSIONS_WATCHER:
			return {
				...state,
				usersPermissions: INITIAL_STATE.usersPermissions,
				usersPermissionsLoaded: INITIAL_STATE.usersPermissionsLoaded,
				usersPermissionsWatcherRef: INITIAL_STATE.usersPermissionsWatcherRef,
			}

		case FETCH_USER:
			return { ...state, user: action.payload, userLoaded: true }
		case SAVE_USER_WATCHER:
			return { ...state, userWatcherRef: action.payload }
		case REMOVE_USER_WATCHER:
			return {
				...state,
				user: INITIAL_STATE.user,
				userLoaded: INITIAL_STATE.userLoaded,
				userWatcherRef: INITIAL_STATE.userWatcherRef,
			}

		case FETCH_USER_PERMISSIONS:
			return { ...state, userPermissions: action.payload, userPermissionsLoaded: true }
		case SAVE_USER_PERMISSIONS_WATCHER:
			return { ...state, userPermissionsWatcherRef: action.payload }
		case REMOVE_USER_PERMISSIONS_WATCHER:
			return {
				...state,
				userPermissions: INITIAL_STATE.userPermissions,
				userPermissionsLoaded: INITIAL_STATE.userPermissionsLoaded,
				userPermissionsWatcherRef: INITIAL_STATE.userPermissionsWatcherRef,
			}

		default:
			return state
	}
}
