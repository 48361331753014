//
//TE Version 0.2.0
//

import React, { Component } from 'react'
import Radium from 'radium'
import PropTypes from 'prop-types'
import styles from './styles'

class TECheckboxInput extends Component {
    render() {
        const { style, ...rest } = this.props

        return (
            <input
                type="checkbox"
                style={{ ...styles.checkbox(rest.disabled), ...styles }}
                {...rest}
            />
        )
    }
}

TECheckboxInput.propTypes = {
    style: PropTypes.object,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    checked: PropTypes.bool,
    id: PropTypes.string,
}

TECheckboxInput.defaultProps = {}

export default Radium(TECheckboxInput)
