import React, { Component } from 'react'
import Radium from 'radium'
import { connect } from 'react-redux'

import { addProduct } from './../../../../../actions/Manager'

import TEPopupForm from './../../../../../component/Popup/TEPopupForm'
import TESearchSelectRow from './../../../../../component/Form/TESearchSelectRow'

import { JobTypes } from './../../../../../config/localData'
import { mapObjectToArray } from './../../../../../helpers'

import styles from './styles'

class AddProductsPopup extends Component {
	state = { selectedProduct: undefined }

	handleOnSubmit = () => {
		const { selectedProduct } = this.state
		const { jobUID, organizationUID, addProduct, onSubmit, history } = this.props

		const data = { selectedProduct, organizationUID, jobUID, history }
		addProduct(data)
			.then(() => {
				onSubmit()
				this.setState({ selectedProduct: undefined })
			})
			.catch((error) => console.log(error))
	}

	handleSingleProduct = () => {
		const { jobUID, organizationUID, addProduct, history, productTypeArray, onClose } = this.props

		const data = { selectedProduct: productTypeArray[0], organizationUID, jobUID, history }
		addProduct(data)
			.then(() => {
				onClose()
			})
			.catch((error) => console.log(error))
	}

	simulateClick(e) {
		if(e !== null)
	    	e.click()
	}

	render() {
		const { selectedProduct } = this.state
		const { visible, onClose, productTypeArray } = this.props

		if(productTypeArray && productTypeArray.length > 1) {
			return (
				<TEPopupForm
					visible={visible}
					contentStyles={styles.content}
					onClose={onClose}
					onSubmit={this.handleOnSubmit}
					onSubmitTitle="Add Products"
				>
					<h1 style={styles.formTitle}>Add Products</h1>
					<TESearchSelectRow
						labelForKey="productsToAdd"
						title="Select a Product"
						value={selectedProduct}
						onChange={({ option }) => {
							this.setState({ selectedProduct: option })
						}}
						options={productTypeArray}
						optionLabelPath="name"
						placeholder="product"
						required
					/>
				</TEPopupForm>
			)
		}
		else {
			if(visible) {
				return (<div style={{display: 'none'}}
			    ref={this.simulateClick} onClick={()=> this.handleSingleProduct()}></div>)
			}
			else {
				return null
			}
		}
	}
}

const mapStateToProps = (state) => {
	const { job } = state.Manager.Jobs

	let productTypeArray = []
	if (job && job.jobTypeUID) {
		productTypeArray = mapObjectToArray(JobTypes[job.jobTypeUID].ProductTypes)
	}

	return { productTypeArray }
}

export default connect(
	mapStateToProps,
	{
		addProduct,
	}
)(Radium(AddProductsPopup))
