import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import Radium from 'radium'

import TEHelmet from './../../../component/TEHelmet'

import PanelWrapper from './../../../component/Layout/PanelWrapper'
import Panel from './../../../component/Layout/Panel'

//import images from './../../../config/images'
import styles from './styles'

class Home extends Component {
    render() {
        const meta = {
            title: 'Rittal Service Visit | Home',
            description: '',
        }

        return (
            <Fragment>
                <TEHelmet {...meta} />
                <PanelWrapper style={styles.container}>
                    <Panel containerStyle={styles.panel}>
                        <h1 style={styles.title}>
                            Welcome to the Rittal Service Visit Web Platform
                        </h1>
                        <h1 style={styles.subtitle}>
                            If you have an account, please sign in.
                            <br />
                            If you wish you be granted access please contact Rittal IT staff at{' '}
                            <a href="mailto:service@rittal.us">service@rittal.us</a>.
                        </h1>
                    </Panel>
                </PanelWrapper>
            </Fragment>
        )
    }
}

Home.propTypes = {}

Home.defaultProps = {}

const mapStateToProps = (state) => {
    return {}
}

export default connect(
    mapStateToProps,
    {}
)(Radium(Home))
