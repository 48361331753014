import React from 'react'
import validate from 'validate.js'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import { renderEmail } from 'react-html-email'
import axios from 'axios'

import {
	SHOW_ALERT,
	SHOW_CONFIRM,
	HIDE_CONFIRM,
	SHOW_NETWORK_ACTIVITY,
	HIDE_NETWORK_ACTIVITY,
	TechnicianKeys,
} from './../types'

import SummaryEmail from './../../component/Email/SummaryEmail'

import settings from './../../config/settings'

export const startWatchingJobsLive = (organizationUID) => {
	const { FETCH_JOBS_LIVE, SAVE_JOBS_LIVE_WATCHER } = TechnicianKeys

	return (dispatch) => {
		const { uid } = firebase.auth().currentUser
		const jobsLiveWatcherRef = firebase
			.database()
			.ref(`Jobs/LiveAssignments/${organizationUID}/${uid}`)

		jobsLiveWatcherRef.on(
			'value',
			(snapshot) => {
				//List of Jobs
				//
				//TODO: If there are worries about performance look at optimizing this some.
				//	- For this use case, it shouldn't come into play.
				//
				//TODO: Abstract this type of data lookup to be reusable
				//
				if (snapshot.val()) {
					const jobRequests = []
					for (const jobUID in snapshot.val()) {
						jobRequests.push(
							firebase
								.database()
								.ref(`Jobs/Live/${organizationUID}/${jobUID}`)
								.once('value')
						)
					}
					Promise.all(jobRequests)
						.then((snapshotArray) => {
							if (snapshotArray) {
								const data = {}
								for (let i = 0; i < snapshotArray.length; i++) {
									const snapshot = snapshotArray[i]
									data[snapshot.key] = snapshot.val()
								}
								dispatch({
									type: FETCH_JOBS_LIVE,
									payload: data,
								})
							} else {
								dispatch({
									type: FETCH_JOBS_LIVE,
									payload: undefined,
								})
							}
						})
						.catch((error) => {
							console.log('Loading Error: ', error)
						})
				} else {
					dispatch({
						type: FETCH_JOBS_LIVE,
						payload: snapshot.val(),
					})
				}
			},
			(error) => {
				console.log('Loading Error: ', error)
			}
		)
		dispatch({
			type: SAVE_JOBS_LIVE_WATCHER,
			payload: jobsLiveWatcherRef,
		})
	}
}
export const stopWatchingJobsLive = () => {
	return (dispatch, getState) => {
		const { jobsLiveWatcherRef } = getState().Technician.Jobs
		if (jobsLiveWatcherRef) {
			jobsLiveWatcherRef.off()
			dispatch({ type: TechnicianKeys.REMOVE_JOBS_LIVE_WATCHER })
		}
	}
}
export const startWatchingJobsHistory = (organizationUID) => {
	const { FETCH_JOBS_HISTORY, SAVE_JOBS_HISTORY_WATCHER } = TechnicianKeys

	return (dispatch) => {
		const { uid } = firebase.auth().currentUser
		const jobsHistoryWatcherRef = firebase
			.database()
			.ref(`Jobs/HistoryAssignments/${organizationUID}/${uid}`)

		jobsHistoryWatcherRef.on(
			'value',
			(snapshot) => {
				//List of Jobs
				//
				//TODO: If there are worries about performance look at optimizing this some.
				//	- For this use case, it shouldn't come into play.
				//
				//TODO: Abstract this type of data lookup to be reusable
				//
				if (snapshot.val()) {
					const jobRequests = []
					for (const jobUID in snapshot.val()) {
						jobRequests.push(
							firebase
								.database()
								.ref(`Jobs/History/${organizationUID}/${jobUID}`)
								.once('value')
						)
					}
					Promise.all(jobRequests)
						.then((snapshotArray) => {
							if (snapshotArray) {
								const data = {}
								for (let i = 0; i < snapshotArray.length; i++) {
									const snapshot = snapshotArray[i]
									data[snapshot.key] = snapshot.val()
								}
								dispatch({
									type: FETCH_JOBS_HISTORY,
									payload: data,
								})
							} else {
								dispatch({
									type: FETCH_JOBS_HISTORY,
									payload: undefined,
								})
							}
						})
						.catch((error) => {
							console.log('Loading Error: ', error)
						})
				} else {
					dispatch({
						type: FETCH_JOBS_HISTORY,
						payload: snapshot.val(),
					})
				}
			},
			(error) => {
				console.log('Loading Error: ', error)
			}
		)
		dispatch({
			type: SAVE_JOBS_HISTORY_WATCHER,
			payload: jobsHistoryWatcherRef,
		})
	}
}
export const stopWatchingJobsHistory = () => {
	return (dispatch, getState) => {
		const { jobsHistoryWatcherRef } = getState().Technician.Jobs
		if (jobsHistoryWatcherRef) {
			jobsHistoryWatcherRef.off()
			dispatch({ type: TechnicianKeys.REMOVE_JOBS_HISTORY_WATCHER })
		}
	}
}

export const startWatchingJobNumber = (uid) => {
	const { FETCH_JOB_NUMBER, SAVE_JOB_NUMBER_WATCHER } = TechnicianKeys

	return (dispatch, getState) => {
		const jobNumberWatcherRef = firebase.database().ref(`Organizations/${uid}/jobNumber`)
		jobNumberWatcherRef.on(
			'value',
			(snapshot) => {
				dispatch({
					type: FETCH_JOB_NUMBER,
					payload: snapshot.val(),
				})
			},
			(error) => {
				console.log('Loading Error: ', error)
			}
		)
		dispatch({
			type: SAVE_JOB_NUMBER_WATCHER,
			payload: jobNumberWatcherRef,
		})
	}
}
export const stopWatchingJobsNumber = () => {
	return (dispatch, getState) => {
		const { jobNumberWatcherRef } = getState().Technician.Jobs
		if (jobNumberWatcherRef) {
			jobNumberWatcherRef.off()
			dispatch({ type: TechnicianKeys.REMOVE_JOB_NUMBER_WATCHER })
		}
	}
}
export const createJob = (data) => {
	const {
		jobType,
		dateOfService,
		assignedBy,
		distributionContact,
		contractNumber,
		notificationTicketNumber,
		businessTransactionNumber,
		clientName,
		clientClientNumber,
		clientAddress,
		clientContact,
		clientPhone,
		clientEmail,
		serviceAddress,
		serviceContact,
		serviceClientNumber,
		servicePhone,
		serviceEmail,
		locationAddress,
		locationContact,
		locationClientNumber,
		locationPhone,
		locationEmail,
		jobNotes,
		organizationUID,
		history,
	} = data

	//Validate Data
	const validatorConstraints = {
		jobType: {
			presence: {
				allowEmpty: false,
			},
		},
	}
	const validationResponse = validate(data, validatorConstraints)
	if (validationResponse) {
		return (dispatch) => {
			return new Promise((res, rej) => {
				dispatch({
					type: SHOW_ALERT,
					payload: {
						alertTitle: 'Error',
						alertMessage: Object.values(validationResponse)[0][0],
					},
				})
				return rej()
			})
		}
	}
	return (dispatch) => {
		return new Promise((res, rej) => {
			dispatch({
				type: SHOW_NETWORK_ACTIVITY,
				payload: 'Creating Job...',
			})

			let jobNumber
			let jobUID
			firebase
				.database()
				.ref(`Organizations/${organizationUID}/jobNumber`)
				.once('value')
				.then((snapshop) => {
					jobNumber = snapshop.val()
					const { uid } = firebase.auth().currentUser
					return firebase
						.database()
						.ref(`Jobs/Live/${organizationUID}`)
						.push({
							organizationUID,
							jobNumber,
							dateCreated: firebase.database.ServerValue.TIMESTAMP,
							createdByUID: uid,
							jobTypeUID: jobType.uid,
							dateOfService: dateOfService || null,
							assignedTo: { [uid]: true },
							assignedBy,
							distributionContact,
							contractNumber,
							notificationTicketNumber,
							businessTransactionNumber,
							clientName,
							clientClientNumber,
							clientAddress,
							clientContact,
							clientPhone,
							clientEmail,
							serviceAddress,
							serviceContact,
							serviceClientNumber,
							servicePhone,
							serviceEmail,
							locationAddress,
							locationContact,
							locationClientNumber,
							locationPhone,
							locationEmail,
							jobNotes,
						})
				})
				.then((response) => {
					jobUID = response.key
					return firebase
						.database()
						.ref(`Organizations/${organizationUID}`)
						.update({ jobNumber: jobNumber + 1 })
				})
				.then(() => {
					const { uid } = firebase.auth().currentUser
					return firebase
						.database()
						.ref(`Jobs/LiveAssignments/${organizationUID}/${uid}/${jobUID}`)
						.set(true)
				})
				.then(() => {
					dispatch({ type: HIDE_NETWORK_ACTIVITY })
					dispatch({
						type: SHOW_CONFIRM,
						payload: {
							confirmTitle: 'Success',
							confirmMessage:
								'Job has been created. Would you like to view the job you just created?',
							confirmLeftTitle: 'Cancel',
							confirmRightOnClick: () => {
								dispatch({ type: HIDE_CONFIRM })
								history.push(`/technician/jobs/live/${jobUID}`)
							},
							confirmRightTitle: 'View Job',
						},
					})
					return res()
				})
				.catch((error) => {
					console.log(error)
					dispatch({ type: HIDE_NETWORK_ACTIVITY })
					dispatch({
						type: SHOW_ALERT,
						payload: {
							alertTitle: 'Error',
							alertMessage: 'Error Creating Job.',
						},
					})
					return rej()
				})
		})
	}
}

export const startWatchingJob = (organizationUID, uid, status) => {
	const { FETCH_JOB_LIVE, SAVE_JOB_LIVE_WATCHER } = TechnicianKeys

	return (dispatch) => {
		let ref
		if (status === 'live') {
			ref = `Jobs/Live/${organizationUID}/${uid}`
		} else if (status === 'history') {
			ref = `Jobs/History/${organizationUID}/${uid}`
		} else {
			dispatch({
				type: FETCH_JOB_LIVE,
				payload: null,
			})
			return
		}

		const jobLiveWatcherRef = firebase.database().ref(ref)
		jobLiveWatcherRef.on(
			'value',
			(snapshot) => {
				dispatch({
					type: FETCH_JOB_LIVE,
					payload: snapshot.val(),
				})
			},
			(error) => {
				console.log('Loading Error: ', error)
			}
		)
		dispatch({
			type: SAVE_JOB_LIVE_WATCHER,
			payload: jobLiveWatcherRef,
		})
	}
}
export const stopWatchingJob = () => {
	const { REMOVE_JOB_LIVE_WATCHER } = TechnicianKeys
	return (dispatch, getState) => {
		const { jobLiveWatcherRef } = getState().Technician.Jobs
		if (jobLiveWatcherRef) {
			jobLiveWatcherRef.off()
			dispatch({ type: REMOVE_JOB_LIVE_WATCHER })
		}
	}
}
export const saveJobDetails = (data) => {
	const {
		dateOfService,
		assignedBy,
		distributionContact,
		contractNumber,
		notificationTicketNumber,
		businessTransactionNumber,
		clientName,
		clientClientNumber,
		clientAddress,
		clientContact,
		clientPhone,
		clientEmail,
		serviceAddress,
		serviceContact,
		serviceClientNumber,
		servicePhone,
		serviceEmail,
		locationAddress,
		locationContact,
		locationClientNumber,
		locationPhone,
		locationEmail,
		jobNotes,
		organizationUID,
		jobUID,
	} = data
	console.log(assignedBy)
	return (dispatch) => {
		return new Promise((res, rej) => {
			dispatch({
				type: SHOW_NETWORK_ACTIVITY,
				payload: 'Saving Job Details...',
			})
			firebase
				.database()
				.ref(`Jobs/Live/${organizationUID}/${jobUID}`)
				.update({
					dateOfService: dateOfService || null,
					assignedBy,
					distributionContact,
					contractNumber,
					notificationTicketNumber,
					businessTransactionNumber,
					clientName,
					clientClientNumber,
					clientAddress,
					clientContact,
					clientPhone,
					clientEmail,
					serviceAddress,
					serviceContact,
					serviceClientNumber,
					servicePhone,
					serviceEmail,
					locationAddress,
					locationContact,
					locationClientNumber,
					locationPhone,
					locationEmail,
					jobNotes,
				})
				.then(() => {
					dispatch({ type: HIDE_NETWORK_ACTIVITY })
					dispatch({
						type: SHOW_ALERT,
						payload: {
							alertTitle: 'Success',
							alertMessage: 'Job details saved',
						},
					})
					return res()
				})
				.catch((error) => {
					console.log(error)
					dispatch({ type: HIDE_NETWORK_ACTIVITY })
					dispatch({
						type: SHOW_ALERT,
						payload: {
							alertTitle: 'Error',
							alertMessage: 'Error saving job details',
						},
					})
					return rej()
				})
		})
	}
}

export const confirmCompleteJob = (data) => {
	const { jobUID, organizationUID, job, history } = data
	return (dispatch) => {
		const completeJob = () => {
			dispatch({ type: HIDE_CONFIRM })
			dispatch({
				type: SHOW_NETWORK_ACTIVITY,
				payload: 'Completing Job...',
			})

			const { assignedTo } = job
			const assignedToArray = []
			if (assignedTo) {
				for (const assignedToUID in assignedTo) {
					assignedToArray.push(assignedToUID)
				}
			}

			firebase
				.database()
				.ref(`Jobs/Live/${organizationUID}/${jobUID}`)
				.once('value')
				.then((snapshot) => {
					return firebase
						.database()
						.ref(`Jobs/History/${organizationUID}/${jobUID}`)
						.set(snapshot.val())
				})
				.then((snapshot) => {
					return firebase
						.database()
						.ref(`Jobs/Live/${organizationUID}/${jobUID}`)
						.remove()
				})
				.then(() => {
					const updateAssignmentRequestArray = []
					for (let i = 0; i < assignedToArray.length; i++) {
						const assignedToUID = assignedToArray[i]
						updateAssignmentRequestArray.push(
							firebase
								.database()
								.ref(
									`Jobs/LiveAssignments/${organizationUID}/${assignedToUID}/${jobUID}`
								)
								.remove()
						)
						updateAssignmentRequestArray.push(
							firebase
								.database()
								.ref(
									`Jobs/HistoryAssignments/${organizationUID}/${assignedToUID}/${jobUID}`
								)
								.set(true)
						)
					}
					return Promise.all(updateAssignmentRequestArray)
				})
				.then(() => {
					dispatch({ type: HIDE_NETWORK_ACTIVITY })
					dispatch({
						type: SHOW_ALERT,
						payload: {
							alertTitle: 'Success',
							alertMessage: 'Job has been completed and moved to history.',
						},
					})
					history.push('/technician/jobs/live')
				})
				.catch((error) => {
					console.log(error)
					dispatch({ type: HIDE_NETWORK_ACTIVITY })
					dispatch({
						type: SHOW_ALERT,
						payload: {
							alertTitle: 'Error',
							alertMessage: 'Error completing job.',
						},
					})
				})
		}

		dispatch({
			type: SHOW_CONFIRM,
			payload: {
				confirmTitle: 'Complete Job',
				confirmMessage:
					'Are you sure you want to complete this job. This will move the job to history.',
				confirmLeftTitle: 'Cancel',
				confirmRightOnClick: completeJob,
				confirmRightTitle: 'Complete Job',
			},
		})
	}
}

export const confirmReopenJob = (data) => {
	const { jobUID, organizationUID, job, history } = data
	return (dispatch) => {
		const reopenJob = () => {
			dispatch({ type: HIDE_CONFIRM })
			dispatch({
				type: SHOW_NETWORK_ACTIVITY,
				payload: 'Reopening Details...',
			})

			const { assignedTo } = job
			const assignedToArray = []
			if (assignedTo) {
				for (const assignedToUID in assignedTo) {
					assignedToArray.push(assignedToUID)
				}
			}

			firebase
				.database()
				.ref(`Jobs/History/${organizationUID}/${jobUID}`)
				.once('value')
				.then((snapshot) => {
					return firebase
						.database()
						.ref(`Jobs/Live/${organizationUID}/${jobUID}`)
						.set(snapshot.val())
				})
				.then((snapshot) => {
					return firebase
						.database()
						.ref(`Jobs/History/${organizationUID}/${jobUID}`)
						.remove()
				})
				.then(() => {
					const updateAssignmentRequestArray = []
					for (let i = 0; i < assignedToArray.length; i++) {
						const assignedToUID = assignedToArray[i]
						updateAssignmentRequestArray.push(
							firebase
								.database()
								.ref(
									`Jobs/HistoryAssignments/${organizationUID}/${assignedToUID}/${jobUID}`
								)
								.remove()
						)
						updateAssignmentRequestArray.push(
							firebase
								.database()
								.ref(
									`Jobs/LiveAssignments/${organizationUID}/${assignedToUID}/${jobUID}`
								)
								.set(true)
						)
					}
					return Promise.all(updateAssignmentRequestArray)
				})
				.then(() => {
					dispatch({ type: HIDE_NETWORK_ACTIVITY })
					dispatch({
						type: SHOW_ALERT,
						payload: {
							alertTitle: 'Success',
							alertMessage: 'Job has been reopened and moved to live.',
						},
					})
					history.push('/technician/jobs/live')
				})
				.catch((error) => {
					console.log(error)
					dispatch({ type: HIDE_NETWORK_ACTIVITY })
					dispatch({
						type: SHOW_ALERT,
						payload: {
							alertTitle: 'Error',
							alertMessage: 'Error reopening job.',
						},
					})
				})
		}

		dispatch({
			type: SHOW_CONFIRM,
			payload: {
				confirmTitle: 'Reopen Job',
				confirmMessage:
					'Are you sure you want to reopen this job. This will move the job to live.',
				confirmLeftTitle: 'Cancel',
				confirmRightOnClick: reopenJob,
				confirmRightTitle: 'Reopen Job',
			},
		})
	}
}
export const sendSummaryEmail = (data) => {
	const { summaryData, userModel, job, emails, ccMe, notes } = data

	//Validate Data
	const validatorConstraints = {
		emails: {
			presence: {
				allowEmpty: false,
			},
		},
		ccMe: {
			presence: {
				allowEmpty: false,
			},
		},
		summaryData: {
			presence: {
				allowEmpty: false,
			},
		},
		userModel: {
			presence: {
				allowEmpty: false,
			},
		},
	}
	const validationResponse = validate(data, validatorConstraints)
	if (validationResponse) {
		return (dispatch) => {
			return new Promise((res, rej) => {
				dispatch({
					type: SHOW_ALERT,
					payload: {
						alertTitle: 'Error',
						alertMessage: Object.values(validationResponse)[0][0],
					},
				})
				return rej()
			})
		}
	}

	return (dispatch) => {
		return new Promise((res, rej) => {
			dispatch({
				type: SHOW_NETWORK_ACTIVITY,
				payload: 'Sending Summary Email...',
			})

			const emailSubject = 'Rittal Servicing Job Summary'

			const emailString = ``

			const emailHTMLString = renderEmail(
				<SummaryEmail
					summaryData={summaryData}
					notes={notes}
					userModel={userModel}
					job={job}
				/>
			)

			const emailData = {
				bccEmailAddresses: [],
				ccEmailAdresses: ccMe === 'Yes' ? [userModel.email] : [],
				toEmailAddresses: emails.split(','),
				subjectdata: emailSubject,
				bodyData: emailString,
				htmlData: emailHTMLString,
				bodyCharset: 'UTF-8',
				subjestCharset: 'UTF-8',
				sourceEmail: '"Rittal Service" <service@rittal.us>',
				replyToAddresses: ['"Rittal Service" <service@rittal.us>'],
			}
			// console.log(emailData)
			axios
				.post(settings.EMAIL_API_ROUTE, emailData)
				.then((response) => {
					// console.log(response)
					if (response && response.data && response.data.errorType) {
						dispatch({ type: HIDE_NETWORK_ACTIVITY })
						dispatch({
							type: SHOW_ALERT,
							payload: {
								alertTitle: response.data.errorType,
								alertMessage: response.data.errorMessage,
							},
						})
						return rej()
					}

					dispatch({ type: HIDE_NETWORK_ACTIVITY })
					dispatch({
						type: SHOW_ALERT,
						payload: {
							alertTitle: 'Success',
							alertMessage: 'Summary Email Sent.',
						},
					})
					return res()
				})
				.catch((error) => {
					console.log(error)
					dispatch({ type: HIDE_NETWORK_ACTIVITY })
					dispatch({
						type: SHOW_ALERT,
						payload: {
							alertTitle: 'Error',
							alertMessage: 'Error Sending Summary Email.',
						},
					})
					return rej(error)
				})
		})
	}
}
