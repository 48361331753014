import axios from 'axios'
import settings from './../config/settings'

export const sendEmail = (data) => {
	/*if (settings.dev){
		console.log("Email Sending Blocked On Development")
		return
	}*/

	if (settings.dev){
		return axios.post(settings.EMAIL_API_ROUTE, data)
		.then((response) => {
			console.log(response)
			return response
		})
	}else{
		return axios.post(settings.EMAIL_API_ROUTE, data)
	}
}

