import ReactDOMServer from 'react-dom/server'
import moment from 'moment'

export const convertToDateTimeString = (epoch) => {
	if (!epoch) {
		return ' -- '
	}

	return moment(epoch).format('ddd, MMM D, YYYY, h:mm A')
}
export const convertToDateTimeShort = (epoch) => {
	if (!epoch) {
		return ' -- '
	}

	return moment(epoch).format('M/D/YYYY, h:mm A')
}
export const convertToDateString = (epoch) => {
	if (!epoch) {
		return ' -- '
	}

	return moment(epoch).format('ddd, MMM D, YYYY')
}
export const convertToDateShort = (epoch) => {
	if (!epoch) {
		return ' -- '
	}

	return moment(epoch).format('M/D/YYYY')
}

export const formatPhoneNumber = (phonenumber) => {
	let formatedNumber = phonenumber
	formatedNumber = formatedNumber.replace(/\D/g, '')
	if (formatedNumber.length === 10) {
		formatedNumber = '+1' + formatedNumber
	}
	if (formatedNumber.length === 11) {
		formatedNumber = '+' + formatedNumber
	}
	return formatedNumber
}

export const displayPhoneNumber = (phonenumber) => {
	const formatedNumber = ('' + phonenumber).replace('+1', '').replace(/\D/g, '')
	const m = formatedNumber.match(/^(\d{3})(\d{3})(\d{4})$/)
	return !m ? phonenumber : `${m[1]}-${m[2]}-${m[3]}`
}

export const formatEmailString = (Component) => {
	const headerString =
		'<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">'
	let htmlString = ReactDOMServer.renderToStaticMarkup(Component)

	htmlString = headerString + htmlString

	return htmlString
}

export const flattenObject = (ob) => {
	let toReturn = {}

	for (const i in ob) {
		if (!ob.hasOwnProperty(i)) continue

		if (typeof ob[i] === 'object') {
			let flatObject = flattenObject(ob[i])
			for (const x in flatObject) {
				if (!flatObject.hasOwnProperty(x)) continue

				toReturn[i + '.' + x] = flatObject[x]
			}
		} else {
			toReturn[i] = ob[i]
		}
	}
	return toReturn
}

export const moveItemInArrayFromIndexToIndex = (array, fromIndex, toIndex) => {
	const newArray = [...array]

	if (fromIndex === toIndex) return newArray

	const target = newArray[fromIndex]
	const inc = toIndex < fromIndex ? -1 : 1

	for (let i = fromIndex; i !== toIndex; i += inc) {
		newArray[i] = newArray[i + inc]
	}

	newArray[toIndex] = target

	return newArray
}

export const stripHtmlFromString = (html) => {
	const temporalDivElement = document.createElement('div')
	temporalDivElement.innerHTML = html
	return temporalDivElement.textContent || temporalDivElement.innerText || ''
}

export const mapObjectToArray = (object, keyMappingString = 'uid') => {
	const newArray = []
	for (const key in object) {
		const dataToMap = object[key]
		if (keyMappingString) {
			dataToMap[keyMappingString] = key
		}
		newArray.push(dataToMap)
	}

	return newArray
}
