import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
// import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'

import PrivateRoute from './../../../component/PrivateRoute'
import NotFound from './../../NotFound'

import { setPageTitle } from './../../../actions'

import TESubNavbar from './../../../component/Navigation/TESubNavbar'

import Create from './Create'
import Live from './Live'
import History from './History'
import Job from './Job'
import Product from './Product'

// import styles from './styles'

class Jobs extends Component {
	componentDidMount() {
		const { setPageTitle } = this.props
		setPageTitle('Service Cases')
	}

	render() {
		return (
			<Fragment>
				<TESubNavbar
					links={[
						{
							title: 'Create',
							to: `/manager/jobs/create`,
						},
						{
							title: 'Active',
							to: `/manager/jobs/live`,
						},

						{
							title: 'History',
							to: `/manager/jobs/history`,
						},
					]}
				/>
				<Switch>
					<PrivateRoute
						path="/manager/jobs/create"
						component={Create}
						requiredUserType="manager"
					/>
					<PrivateRoute
						path="/manager/jobs/:status/:jobUID/:productUID"
						component={Product}
						requiredUserType="manager"
					/>
					*
					<PrivateRoute
						path="/manager/jobs/:status/:uid"
						component={Job}
						requiredUserType="manager"
					/>
					<PrivateRoute
						path="/manager/jobs/live"
						component={Live}
						requiredUserType="manager"
					/>
					<PrivateRoute
						path="/manager/jobs/history"
						component={History}
						requiredUserType="manager"
					/>
					<Route component={NotFound} />
				</Switch>
			</Fragment>
		)
	}
}

Jobs.propTypes = {}

Jobs.defaultProps = {}

const mapStateToProps = (state) => {
	const { userModel } = state.Loading
	return { userModel }
}

export default connect(
	mapStateToProps,
	{ setPageTitle }
)(Jobs)
