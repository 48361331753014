import React, { Component } from 'react'
import Radium from 'radium'

import settings from './../../../config/settings'

import styles from './styles'

class Footer extends Component {
	render() {
		return (
			<div style={styles.container}>
				<div style={styles.contentContainer}>
					<p style={styles.version}>v{settings.VERSION}</p>
					<a href="mailto:service@rittal.us" style={styles.email}>
						service@rittal.us
					</a>
					<p style={styles.copyrightCopy}>
						© {new Date().getFullYear()} Rittal - All rights reserved.
					</p>
				</div>
			</div>
		)
	}
}

Footer.propTypes = {}

Footer.defaultProps = {}

export default Radium(Footer)
