import React from 'react'
import TELink from './../../../../component/Navigation/TELink'
import { convertToDateShort, convertToDateTimeShort, filterDateColumn } from './../../../../helpers'
import styles from './styles'

export const tableColumns = [
	{
		id: 'jobNumber',
		Header: 'Case Number',
		accessor: 'jobNumber',
	},
	{
		id: 'dateCreated',
		Header: 'Date Created',
		filterMethod: (filter, row) => filterDateColumn(convertToDateTimeShort, filter, row),
		accessor: 'dateCreated',
		Cell: (d) => { return (<span style={styles.wrapCell}>{convertToDateTimeShort(d.value)}</span>) }
	},
	{
		id: 'serviceType',
		Header: 'Request Type',
		accessor: 'serviceType',
		Cell: (d) => { return (<span style={styles.wrapCell}>{d.value}</span>) }
	},
	{
		id: 'site',
		Header: 'Paying Customer',
		accessor: 'site',
	},
	{
		id: 'clientName',
		Header: 'Work Order Number',
		accessor: 'clientName',
		Cell: (d) => { return (<span style={styles.wrapCell}>{d.value}</span>) }
	},
	{
		id: 'dateOfService',
		Header: 'ETA',
		filterMethod: (filter, row) => filterDateColumn(convertToDateShort, filter, row),
		accessor: (d) => (
			<span style={{ whiteSpace: 'normal' }}>{(convertToDateShort(d.dateOfService) + ' ' + (d.eta !== undefined ? d.eta : '')).trim()}</span>
		)
	},
	{
		id: 'serviceStatus',
		Header: 'Status',
		accessor: 'serviceStatus'
	},
	{
		id: 'poNumber',
		Header: 'Customer PO',
		accessor: 'poNumber'
	},
	{
		id: 'region',
		Header: 'Region',
		accessor: 'region'
	},
	{
		id: 'billingDisposition',
		Header: 'Billing Disposition',
		accessor: 'billingDisposition',
		Cell: (d) => { return (<span style={styles.wrapCell}>{d.value}</span>) }
	},
	{
		id: 'details',
		Header: '',
		accessor: 'uid',
		sortable: false,
		filterable: false,
		maxWidth: 140,
		className: 'actionCell',
		Cell: (d) => {
			return (<TELink
				to={`/technician/jobs/history/${d.value}`}
				style={styles.link}
				wrapperStyle={styles.linkWrapper}
			>
				Details
			</TELink>)
		},
	},
]