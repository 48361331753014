import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import Radium from 'radium'

import { showAlert } from './../../../../actions'
import { startWatchingOrganization, stopWatchingOrganization } from './../../../../actions/Admin'
import { startWatchingStandup,stopWatchingStandup } from './../../../../actions/Manager'
import { startWatchingJobNumber, createJob } from './../../../../actions/Manager'
import { getOrganizationUserArray } from './../../../../selectors/Admin'
import { getStandupDataArray } from './../../../../selectors/Manager'

import PanelWrapper from './../../../../component/Layout/PanelWrapper'
import Panel from './../../../../component/Layout/Panel'

import TEHelmet from './../../../../component/TEHelmet'
import TEMultiStepForm from './../../../../component/Form/TEMultiStepForm'
import TEInputRow from './../../../../component/Form/TEInputRow'
import TESearchSelectRow from './../../../../component/Form/TESearchSelectRow'
import TEDatetimeRow from './../../../../component/Form/TEDatetimeRow'

import { JobTypes } from './../../../../config/localData'
import { mapObjectToArray } from './../../../../helpers'
import { sendSummaryEmail } from './../../../../actions/Manager'
// import styles from './styles'

const jobTypeArray = mapObjectToArray(JobTypes)

class Create extends Component {
	state = {
		jobType: '',
		site: '',
		serviceType: '',
		equipmentType: '',
		region: '',
		dateOfService: undefined,
		assignedTo: [],
		poNumber: '',
		serviceStatus: 'Open',
		billingDisposition: '',

		assignedBy: '',
		distributionContact: '',

		contractNumber: '',
		notificationTicketNumber: '',
		businessTransactionNumber: '',

		clientName: '',
		clientClientNumber: '',
		clientAddress: '',
		clientContact: '',
		clientPhone: '',
		clientEmail: '',

		serviceAddress: '',
		serviceContact: '',
		serviceClientNumber: '',
		servicePhone: '',
		serviceEmail: '',

		locationAddress: '',
		locationContact: '',
		locationClientNumber: '',
		locationPhone: '',
		locationEmail: '',

		jobDescription: '',
		jobNotes: '',
		eta: '',
		aka: '',
		standupObj: null,
		isServiceJob: true
	}

	componentDidMount() {
		const { startWatchingJobNumber, startWatchingOrganization, userModel, startWatchingStandup } = this.props
		const { organizationUID } = userModel
		this.setState({ assignedBy: userModel.firstName + ' ' + userModel.lastName })
		startWatchingJobNumber(organizationUID)
		startWatchingOrganization(organizationUID)
		startWatchingStandup()
		jobTypeArray.forEach((d) => {
			if(d.name === 'Amazon Service Order / Report') {
				this.setState({
					jobType: d
				})
				return;
			}
		})
	}
	componentWillUnmount() {
		const { stopWatchingOrganization, stopWatchingStandup } = this.props
		stopWatchingOrganization()
		stopWatchingStandup()
	}

	handleVerfiyJobDetails = () =>
		new Promise((res, rej) => {
			const { jobType } = this.state
			const { showAlert } = this.props
			if (!jobType) {
				showAlert('Error', 'Job Type is Required')
				return rej()
			}
			return res()
		})

	fillStandupData = (obj) => {
		const addressParts = [obj.address,obj.city,obj.st];
		const date = new Date(obj.pmServiceDate);
		this.setState({
			clientClientNumber: obj.accountNumber,
			clientAddress: (addressParts.join(', ') + ' ' + obj.zip).trim(),
			clientPhone: obj.amazonITPOCPhone,
			clientContact: obj.amazonITPOCName,
			dateOfService: date.getTime(),
			eta: obj.pmServiceETA,
			region: obj.region,
			poNumber: obj.poNumber,
			clientName: obj.pmWorkOrder
		});
	}

	handleSiteChange = (obj) => {
		this.setState({
			site: obj.site,
			standupObj: obj
		})
		this.fillStandupData(obj)
	}

	handleCreate = () => {
		return new Promise((res, rej) => {
			const {
				jobType,
				site,
				serviceType,
				equipmentType,
				region,
				dateOfService,
				poNumber,
				serviceStatus,
				billingDisposition,
				assignedTo,
				assignedBy,
				distributionContact,
				contractNumber,
				notificationTicketNumber,
				businessTransactionNumber,
				clientName,
				clientClientNumber,
				clientAddress,
				clientContact,
				clientPhone,
				clientEmail,
				serviceAddress,
				serviceContact,
				serviceClientNumber,
				servicePhone,
				serviceEmail,
				locationAddress,
				locationContact,
				locationClientNumber,
				locationPhone,
				locationEmail,
				jobDescription,
				jobNotes,
				eta,
				aka,
				isServiceJob
			} = this.state
			const { createJob, userModel, history, sendSummaryEmail } = this.props
			const { organizationUID } = userModel

			const data = {
				jobType,
				site,
				serviceType,
				equipmentType,
				region,
				dateOfService,
				poNumber,
				serviceStatus,
				billingDisposition,
				assignedTo,
				assignedBy,
				distributionContact,
				contractNumber,
				notificationTicketNumber,
				businessTransactionNumber,
				clientName,
				clientClientNumber,
				clientAddress,
				clientContact,
				clientPhone,
				clientEmail,
				serviceAddress,
				serviceContact,
				serviceClientNumber,
				servicePhone,
				serviceEmail,
				locationAddress,
				locationContact,
				locationClientNumber,
				locationPhone,
				locationEmail,
				jobDescription,
				jobNotes,
				organizationUID,
				history,
				eta,
				aka
			}
			createJob(data)
				.then(() => {
					if(isServiceJob) {
						let techEmails = [];
						assignedTo.forEach((d) => {
							techEmails.push(d.email)
						})
						const emailData = { summaryData: {}, userModel, job: data, emails: techEmails.join(','), ccMe: true, notes: data.jobNotes, emailSubject: 'New Job Assigned', emailText: 'A new Rittal Amazon Service Order/Request Job has been assigned to you. Job is', isCreateEmail: true, customerName: (isServiceJob ? data.site : data.clientName) }
						sendSummaryEmail(emailData).catch((error) => console.log(error))
					}
					this.setState({
						jobType: '',
						site: '',
						serviceType: '',
						equipmentType: '',
						region: '',
						dateOfService: undefined,
						poNumber: '',
						serviceStatus: 'Open',
						billingDisposition: '',
						assignedTo: [],

						assignedBy: '',
						distributionContact: '',

						contractNumber: '',
						notificationTicketNumber: '',
						businessTransactionNumber: '',

						clientName: '',
						clientClientNumber: '',
						clientAddress: '',
						clientContact: '',
						clientPhone: '',
						clientEmail: '',

						serviceAddress: '',
						serviceContact: '',
						serviceClientNumber: '',
						servicePhone: '',
						serviceEmail: '',

						locationAddress: '',
						locationContact: '',
						locationClientNumber: '',
						locationPhone: '',
						locationEmail: '',

						jobDescription: '',
						jobNotes: '',
						eta: '',
						aka: ''
					})
					return res()
				})
				.catch((error) => {
					console.log(error)
					return rej(error)
				})
		})
	}

	render() {
		const meta = {
			title: 'Rittal | Create Service Case',
			description: '',
		}
		const {
			site,
			serviceType,
			equipmentType,
			region,
			dateOfService,
			poNumber,
			serviceStatus,
			billingDisposition,
			assignedTo,
			assignedBy,
			clientName,
			clientClientNumber,
			clientAddress,
			clientContact,
			clientPhone,
			clientEmail,
			jobDescription,
			jobNotes,
			eta
		} = this.state
		const { jobNumber, userArray, standupArray } = this.props
		let stepData = null;
		stepData = [
			{
				title: 'Case Details',
				component: (
					<Fragment>
						<TEInputRow
							labelForKey="jobNumber"
							title="Case Number"
							value={jobNumber || ''}
							onChange={() => {}}
							required
							size="third"
							disabled
						/>
						<TESearchSelectRow
							labelForKey="serviceType"
							title="Request Type"
							value={serviceType}
							onChange={({ option }) => {
								this.setState({
									serviceType: option
								})
							}}
							options={['Amazon PM','Field Repair','Shop Repair','Install','PM','Training']}
							placeholder="Request Type"
							size="third"
						/>
						<TESearchSelectRow
							labelForKey="equipmentType"
							title="Equipment Type"
							value={equipmentType}
							onChange={({ option }) => {
								this.setState({
									equipmentType: option
								})
							}}
							options={['Amazon PM','AC-RTT','AC-BE+','Chiller','LCP-CW','LCP-DX','LCU','DET-AC','RAS-SE','RAS-LC','RAS-MT','RAS-WT']}
							placeholder="Equipment Type"
							size="third"
						/>
						<TESearchSelectRow
							labelForKey="site"
							title="Site"
							value={site}
							onChange={({ option }) => {
								this.handleSiteChange(option)
							}}
							options={standupArray}
							optionLabelPath="site"
							placeholder="Site ID"
							size="third"
						/>
						<TEInputRow
							labelForKey="clientName"
							title="Work Order Number"
							value={clientName}
							onChange={(e) =>
								this.setState({
									clientName: e.target.value,
								})
							}
							placeholder="Work Order Number"
							size="third"
						/>
						<TESearchSelectRow
							labelForKey="region"
							title="Region"
							value={region}
							onChange={({ option }) => {
								this.setState({
									region: option
								})
							}}
							options={['NorthEast','SouthEast','South','MidWest','Pacific','RAS-US']}
							placeholder="Region"
							size="third"
						/>
						<TEDatetimeRow
							labelForKey="dateOfService"
							title="Service Date"
							value={dateOfService}
							onChange={(dateOfService) =>
								this.setState({
									dateOfService,
								})
							}
							placeholder={'MM/DD/YYYY'}
							size="third"
							dateFormat="MM/dd/yyyy"
						/>
						<TEInputRow
							labelForKey="eta"
							title="Service ETA"
							value={eta}
							onChange={(e) =>
								this.setState({
									eta: e.target.value,
								})
							}
							placeholder="Service ETA"
							size="third"
						/>
						<TESearchSelectRow
							type="multi"
							labelForKey="assignedTo"
							title="Assign Job To"
							value={assignedTo}
							onChange={({ options }) => {
								this.setState({
									assignedTo: options,
								})
							}}
							options={userArray}
							optionLabelPath="displayName"
							placeholder="Assign Job To"
							size="full"
						/>
						<TEInputRow
							labelForKey="poNumber"
							title="Customer PO #"
							value={poNumber}
							onChange={(e) =>
								this.setState({
									poNumber: e.target.value,
								})
							}
							placeholder="Customer PO #"
							size="third"
						/>
						<TESearchSelectRow
							labelForKey="serviceStatus"
							title="Status"
							value={serviceStatus}
							onChange={({ option }) => {
								this.setState({
									serviceStatus: option
								})
							}}
							options={['Open','Insufficient Information','Requires Appointment','RMA Needed','Pending RMA Equipment','RMA Equipment Received','RMA Equipment Shipped back','SA assigned','SA dispatched','Suspended','Incomplete','Parts Needed','Parts Ordered','Parts Backordered','Parts Shipped','Parts Received','Parts Ordered by 3rd Party','Quote Needed','Quote Submitted','Quote Approved','Quote Denied','Resolved Pending Review','Completed','Invoiced','ZK Processed','Canceled']}
							placeholder="Status"
							size="third"
						/>
						<TESearchSelectRow
							labelForKey="billingDisposition"
							title="Billing Disposition"
							value={billingDisposition}
							onChange={({ option }) => {
								this.setState({
									billingDisposition: option
								})
							}}
							options={['Billable','Recall','Warranty Potential','Contract','Sales Accommodation','Refurb']}
							placeholder="Billing Disposition"
							size="third"
						/>
						<TEInputRow
							labelForKey="assignedBy"
							title="Created By"
							value={assignedBy}
							onChange={(e) =>
								this.setState({
									assignedBy: e.target.value,
								})
							}
							placeholder="name"
							size="half"
						/>
						<TEInputRow
							labelForKey="clientClientNumber"
							title="SAP Account Number"
							value={clientClientNumber}
							onChange={(e) =>
								this.setState({
									clientClientNumber: e.target.value,
								})
							}
							placeholder="number"
							size="half"
						/>
						<TEInputRow
							labelForKey="clientAddress"
							title="Address"
							value={clientAddress}
							onChange={(e) =>
								this.setState({
									clientAddress: e.target.value,
								})
							}
							placeholder="address"
							size="full"
						/>
						<TEInputRow
							labelForKey="clientContact"
							title="Contact Name"
							value={clientContact}
							onChange={(e) =>
								this.setState({
									clientContact: e.target.value,
								})
							}
							placeholder="Contact Name"
							size="third"
						/>
						<TEInputRow
							type="tel"
							labelForKey="clientPhone"
							title="Contact Phone"
							value={clientPhone}
							onChange={(e) =>
								this.setState({
									clientPhone: e.target.value,
								})
							}
							placeholder="Contact Phone"
							size="third"
						/>
						<TEInputRow
							labelForKey="clientEmail"
							title="Contact Email"
							value={clientEmail}
							onChange={(e) =>
								this.setState({
									clientEmail: e.target.value,
								})
							}
							placeholder="Contact Email"
							size="third"
						/>
						<TEInputRow
							labelForKey="jobDescription"
							type="textarea"
							title="Problem Description"
							value={jobDescription}
							onChange={(e) =>
								this.setState({
									jobDescription: e.target.value,
								})
							}
							placeholder="Problem Description"
							disabled={(serviceType === 'Amazon PM')}
						/>
						<TEInputRow
							labelForKey="jobNotes"
							type="textarea"
							title="Internal Notes"
							value={jobNotes}
							onChange={(e) =>
								this.setState({
									jobNotes: e.target.value,
								})
							}
							placeholder="Notes"
						/>
					</Fragment>
				),
			}
		];
		return (
			<PanelWrapper>
				<TEHelmet {...meta} />
				<Panel title="Create Service Case">
					<TEMultiStepForm
						onSubmit={this.handleCreate}
						stepData={stepData}
					/>
				</Panel>
			</PanelWrapper>
		)
	}
}

const mapStateToProps = (state) => {
	const { userModel } = state.Loading
	const { jobNumber } = state.Manager.Jobs
	const standupArray = getStandupDataArray(state)

	const userArray = getOrganizationUserArray(state).filter(
		(user) => user.permissions && user.permissions.technician
	)

	return {
		jobNumber,
		userModel,
		userArray,
		standupArray
	}
}

export default connect(
	mapStateToProps,
	{
		showAlert,
		startWatchingJobNumber,
		startWatchingOrganization,
		stopWatchingOrganization,
		startWatchingStandup,
		stopWatchingStandup,
		createJob,
		sendSummaryEmail
	}
)(Radium(Create))
