import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import Radium from 'radium'
import ReactTable from 'react-table'
import CsvDownload from "react-json-to-csv";
import Papa from 'papaparse';

import { setPageTitle } from './../../../actions'
import { startWatchingStandup, saveStandupData,stopWatchingStandup } from './../../../actions/Manager'
import { getStandupDataArray } from './../../../selectors/Manager'

import TEHelmet from './../../../component/TEHelmet'

import PanelWrapper from './../../../component/Layout/PanelWrapper'
import Panel from './../../../component/Layout/Panel'
import TEFileInput from './../../../component/Form/TEFileInput'

import { tableColumns } from './tableConfigs'
import { customTableFilter } from './../../../helpers'

import styles from './styles'
import sample from './sample.json'

class Standup extends Component {

	componentDidMount() {
		const { startWatchingStandup } = this.props
		this.props.setPageTitle('Standup Data')
		startWatchingStandup()
	}
	componentWillUnmount() {
		this.props.stopWatchingStandup()
	}
	render() {
		const meta = {
			title: 'Rittal | Standup Data',
			description: '',
		}
		const { saveStandupData, standupArray } = this.props
		const accept = '.csv'
		const onFileChange = (fileArray) => {
	        Papa.parse(fileArray[0], {
		      complete: (result) => {
		      	var data = result.data;
		    	saveStandupData(JSON.stringify(data)).then(() => { }).catch((error) => { 
		    		console.log(error)
		    	})
		      },
		      header: true,
		      transformHeader: (header) => {
		      	return header.replace(/\s/g, '').trim().toLower();
		      }
		    });
	    }

		return (
			<Fragment>
				<TEHelmet {...meta} />
				<PanelWrapper>
					<Panel title="Standup Data"
						rightComponent={
							<div style={styles.flexBox}>
								<CsvDownload data={sample} style={styles.actionButton} filename="sample.csv">Sample</CsvDownload>
								<CsvDownload data={standupArray} style={styles.actionButton} filename="standup_data.csv">Export</CsvDownload>
								<TEFileInput
		                            onChange={(fileArray) => onFileChange(fileArray)}
		                            accept={accept}
		                            ref="input"
		                            label="Import Data"
		                        />
						    </div>
						}
					>
						<div style={styles.tableContainer}>
							<ReactTable
								data={standupArray}
								columns={tableColumns}
								defaultPageSize={10}
								filterable
								defaultFilterMethod={customTableFilter}
								defaultSorted={[
									{
										id: 'orderNumber',
										asc: true,
									},
								]}
							/>
						</div>
					</Panel>
				</PanelWrapper>
			</Fragment>
		)
	}
}
const mapStateToProps = (state) => {
	const standupArray = getStandupDataArray(state)
	return { standupArray }
}
export default connect(
	mapStateToProps,
	{ 
		setPageTitle,
		startWatchingStandup,
		saveStandupData,
		stopWatchingStandup
	}
)(Radium(Standup))