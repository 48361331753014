import React, { Component } from 'react'
import { AccordionItemBody } from 'react-accessible-accordion'

import Input from './Input'

import styles from './styles'

class Body extends Component {
	render() {
		const { Inputs, InputArray } = this.props.section

		return (
			<AccordionItemBody style={styles.sectionBodyWrapper}>
				{InputArray.map((inputUID) => {
					const inputData = Inputs[inputUID]

					return (
						<Input
							inputData={inputData}
							inputUID={inputUID}
							key={`input_${inputUID}`}
							{...this.props}
						/>
					)
				})}
			</AccordionItemBody>
		)
	}
}

export default Body
