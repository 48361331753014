import React, { Component } from 'react'
import { connect } from 'react-redux'
import Radium from 'radium'

import { createUser } from './../../../../../actions/Admin'

import TEPopupForm from './../../../../../component/Popup/TEPopupForm'

import TEInputRow from './../../../../../component/Form/TEInputRow'
import TECheckboxGroup from './../../../../../component/Form/TECheckboxGroup'

import styles from './styles'

class CreateUserPopup extends Component {
	state = { firstName: '', lastName: '', email: '', password: '', permissions: [] }

	handleSubmit = () => {
		const { firstName, lastName, email, password, permissions } = this.state
		const { createUser, onClose, orgUID } = this.props

		const data = { firstName, lastName, email, password, permissions, orgUID }

		createUser(data)
			.then(() => {
				onClose()
				this.setState({
					firstName: '',
					lastName: '',
					email: '',
					password: '',
					permissions: [],
				})
			})
			.catch((error) => console.log(error))
	}

	render() {
		const { firstName, lastName, email, password, permissions } = this.state
		const { visible, onClose } = this.props
		return (
			<TEPopupForm
				visible={visible}
				contentStyles={styles.content}
				onClose={onClose}
				onSubmit={this.handleSubmit}
				onSubmitTitle="Create User"
			>
				<h1 style={styles.formTitle}>Create User</h1>
				<TEInputRow
					labelForKey="firstName"
					title="First Name"
					value={firstName}
					onChange={(e) => {
						this.setState({ firstName: e.target.value })
					}}
					placeholder="Matthew"
					required
					size="half"
				/>
				<TEInputRow
					labelForKey="lastName"
					title="Last Name"
					value={lastName}
					onChange={(e) => {
						this.setState({ lastName: e.target.value })
					}}
					placeholder="Stevens"
					required
					size="half"
					last
				/>
				<TEInputRow
					labelForKey="email"
					title="Email"
					value={email}
					onChange={(e) => {
						this.setState({ email: e.target.value })
					}}
					placeholder="matthew.stevens@gmail.com"
					required
				/>
				<TEInputRow
					labelForKey="password"
					title="Password"
					value={password}
					onChange={(e) => {
						this.setState({ password: e.target.value })
					}}
					placeholder="******"
					required
				/>
				<TECheckboxGroup
					labelForKey="permissions"
					title="User Permissions"
					checkedValues={permissions}
					onChange={(permissions) => this.setState({ permissions })}
					buttonArray={[
						{ label: 'Manager', value: 'manager' },
						{ label: 'Technician', value: 'technician' },
					]}
					required
				/>
			</TEPopupForm>
		)
	}
}

CreateUserPopup.propTypes = {}

CreateUserPopup.defaultProps = {}

const mapStateToProps = (state) => {
	return {}
}

export default connect(
	mapStateToProps,
	{
		createUser,
	}
)(Radium(CreateUserPopup))
