import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import Radium from 'radium'
import ReactTable from 'react-table'
import Papa from 'papaparse'

import { startWatchingOrganization, stopWatchingOrganization } from './../../../../actions/Admin'
import {
	startWatchingJob,
	stopWatchingJob,
	startWatchingProducts,
	stopWatchingProducts,
	saveJobDetails,
	confirmDuplicateProduct,
	confirmRemoveProduct,
	confirmCompleteJob,
	confirmReopenJob,
	saveJobPdfFile,
	startWatchingSpareParts,
	stopWatchingSpareParts,
	confirmRemoveSparePart,
	startWatchingStandup,
	stopWatchingStandup
} from './../../../../actions/Manager'
import { getOrganizationUserArray } from './../../../../selectors/Admin'
import { getJobProductArray, getJobSummary, getJobSparePartArray, getStandupDataArray } from './../../../../selectors/Manager'

import PanelWrapper from './../../../../component/Layout/PanelWrapper'
import Panel from './../../../../component/Layout/Panel'

import TEHelmet from './../../../../component/TEHelmet'
import TEButton from './../../../../component/Form/TEButton'

import AddProductPopup from './AddProductPopup'
import AddSparePartPopup from './AddSparePartPopup'
import EditJobDetailsPopup from './EditJobDetailsPopup'
import SendSummaryEmailPopup from './SendSummaryEmailPopup'

import { JobTypes } from './../../../../config/localData'
import { tableColumns, sparePartTableColumns } from './tableConfigs'
import {
	convertToDateString,
	convertToDateShort,
	customTableFilter,
	downloadCSV,
	convertToDateTimeShort
} from './../../../../helpers'
import styles from './styles'
import axios from 'axios'

class Create extends Component {
	state = {
		editJobDetailsVisible: false,
		addProductPopupVisible: false,
		sendEmailPopupVisible: false,
		addSparePartPopupVisible: false,
		statePdfFile: '',
		editSparePartObj: {}
	}

	componentDidMount() {
		const {
			startWatchingJob,
			startWatchingProducts,
			startWatchingSpareParts,
			startWatchingOrganization,
			userModel,
			match,
			startWatchingStandup
		} = this.props
		const { organizationUID } = userModel
		const { uid, status } = match.params
		startWatchingOrganization(organizationUID)
		startWatchingJob(organizationUID, uid, status)
		startWatchingProducts(organizationUID, uid)
		startWatchingStandup()
		startWatchingSpareParts(organizationUID, uid)
	}
	componentWillUnmount() {
		const { stopWatchingJob, stopWatchingProducts, stopWatchingOrganization, stopWatchingStandup, stopWatchingSpareParts } = this.props
		stopWatchingJob()
		stopWatchingProducts()
		stopWatchingOrganization()
		stopWatchingStandup()
		stopWatchingSpareParts()
	}

	handleSaveJobDetails = (jobData) => {
		const { match, userModel, saveJobDetails, job } = this.props
		const { organizationUID } = userModel
		const { uid: jobUID } = match.params

		const data = { ...jobData, organizationUID, jobUID, job }
		console.log(data)
		return saveJobDetails(data)
			.then(() => {
				this.setState({ editJobDetailsVisible: false })
			})
			.catch((error) => console.log(error))
	}

	handleRemoveProduct = (product) => {
		const { userModel, job, confirmRemoveProduct } = this.props
		const { organizationUID } = userModel
		const { jobTypeUID } = job

		const data = { organizationUID, product, JobTypes, jobTypeUID }

		confirmRemoveProduct(data)
	}
	handleDuplicateProduct = (product) => {
		console.log(product)
		const { confirmDuplicateProduct } = this.props
		const { uid, organizationUID } = product

		const data = { uid, organizationUID }

		confirmDuplicateProduct(data)
	}

	handleRemoveSparePart = (part) => {
		const { userModel, confirmRemoveSparePart } = this.props
		const { organizationUID } = userModel

		const data = { organizationUID, uid: part.uid }

		confirmRemoveSparePart(data)
	}

	handleEditSparePart = (part) => {
		this.setState({
			editSparePartObj: part,
			addSparePartPopupVisible: true
		})
	}

	handleCompleteJob = () => {
		const { confirmCompleteJob, job, match, userModel, history } = this.props
		const { uid } = match.params
		const { organizationUID } = userModel

		const data = { jobUID: uid, organizationUID, job, history }

		confirmCompleteJob(data)
	}
	handleReopenJob = () => {
		const { confirmReopenJob, job, match, userModel, history } = this.props
		const { uid } = match.params
		const { organizationUID } = userModel

		const data = { jobUID: uid, organizationUID, job, history }

		confirmReopenJob(data)
	}
	handleDownload = () => {
		const { job, productArray, organizationUsers } = this.props
		const {
			jobTypeUID,
			jobNumber,
			site,
			dateCreated,
			createdByUID,
			dateOfService,
			assignedBy,
			assignedTo,
			clientClientNumber,
			clientName,
			clientAddress,
			clientContact,
			clientPhone,
			clientEmail,
			jobNotes,
			serviceType,
			equipmentType,
			region,
			serviceStatus,
			eta,
			poNumber,
			resolvedNotes,
			jobDescription
		} = job

		let assignedToString = ' -- '
		if (assignedTo && organizationUsers) {
			const assignedToArray = []
			for (const userUID in assignedTo) {
				const user = organizationUsers[userUID]
				if (user && (user.firstName || user.lastName)) {
					const { firstName, lastName } = user
					const displayName = `${firstName || ''} ${lastName || ''}`.trim()
					assignedToArray.push(`${displayName}`)
				}
			}
			assignedToString = assignedToArray.join('; ')
		}

		let data = []
		if(productArray.length > 0) {
			data = productArray
			.sort((a, b) => b.dateCreated - a.dateCreated)
			.map((product) => {
				const {
					createdByUID: productCreatedByUID,
					dateCreated: productDateCreated,
					productTypeUID,
					formData,
				} = product

				const productData = {}

				const { Sections } = JobTypes[jobTypeUID].ProductTypes[productTypeUID]
				for (const sectionUID in Sections) {
					const { Inputs } = Sections[sectionUID]
					for (const inputUID in Inputs) {
						const { type, name, notes } = Inputs[inputUID]
						const inputData = formData && formData[inputUID] ? formData[inputUID] : ''
						if (productData[name]) {
							console.warn('Duplicate Input Names', name, productData)
						}
						switch (type) {
							case 'text':
								productData[name] = inputData
								break
							case 'date':
								productData[name] = convertToDateString(inputData)
								break
							case 'textarea':
								productData[name] = inputData
								break
							case 'radio':
								if (notes) {
									productData[name] =
										inputData && inputData.value ? inputData.value : ''
									productData[`${name} Notes`] =
										inputData && inputData.note ? inputData.note : ''
									break
								}

								productData[name] = inputData
								break
							case 'upload':
								let fileString = ''
								for (const fileUID in inputData) {
									fileString += `${inputData[fileUID].src} `
								}
								productData[name] = fileString
								break
							default:
								console.warn(
									`Unhandled Input Type For UID: ${inputUID} and Type: ${type}`
								)
								break
						}
					}
				}

				return {
					'Case Number': jobNumber,
					'Date Created': convertToDateString(dateCreated),
					'Request Type': serviceType,
					'Equipment Type': equipmentType,
					'ETA': convertToDateString(dateOfService) + ' ' + (eta !== undefined ? eta : ''),
					'Created By':
						organizationUsers && createdByUID && organizationUsers[createdByUID]
							? `${organizationUsers[createdByUID].firstName ||
									''} ${organizationUsers[createdByUID].lastName || ''}`.trim()
							: createdByUID,
					'Assigned By': assignedBy,
					'Tech/Service Partner': assignedToString,
					'Region': region,
					'SAP Account Number': clientClientNumber,
					'Contact Email': clientEmail,
					'Paying Customer': site,
					'Work Order Number': clientName,
					'Address': clientAddress,
					'Status': serviceStatus,
					'Customer PO': poNumber,
					'Contact Name': clientContact,
					'Phone #': clientPhone,
					'Problem Description': jobDescription,
					'Internal Notes': jobNotes,
					'Resolved Notes': resolvedNotes,
					'Equipment Added At': convertToDateString(productDateCreated),
					'Equipment Added By':
						organizationUsers &&
						productCreatedByUID &&
						organizationUsers[productCreatedByUID]
							? `${organizationUsers[productCreatedByUID].firstName ||
									''} ${organizationUsers[productCreatedByUID].lastName ||
									''}`.trim()
							: productCreatedByUID,
					...productData,
				}
			})
		}
		else {
			data.push({
				'Case Number': jobNumber,
				'Date Created': convertToDateString(dateCreated),
				'Request Type': serviceType,
				'Equipment Type': equipmentType,
				'ETA': convertToDateString(dateOfService) + ' ' + (eta !== undefined ? eta : ''),
				'Created By':
					organizationUsers && createdByUID && organizationUsers[createdByUID]
						? `${organizationUsers[createdByUID].firstName ||
								''} ${organizationUsers[createdByUID].lastName || ''}`.trim()
						: createdByUID,
				'Assigned By': assignedBy,
				'Tech/Service Partner': assignedToString,
				'Region': region,
				'SAP Account Number': clientClientNumber,
				'Contact Email': clientEmail,
				'Paying Customer': site,
				'Work Order Number': clientName,
				'Address': clientAddress,
				'Status': serviceStatus,
				'Customer PO': poNumber,
				'Contact Name': clientContact,
				'Phone #': clientPhone,
				'Problem Description': jobDescription,
				'Internal Notes': jobNotes,
				'Resolved Notes': resolvedNotes,
			})
		}
		
		downloadCSV(Papa.unparse(data), `Case-${jobNumber}-Data`)
	}

	generatePdf = () => {
		const { job, productArray, sparePartArray, organizationUsers } = this.props

		let assignedToString = ' -- '
		if (job.assignedTo && organizationUsers) {
			const assignedToArray = []
			for (const userUID in job.assignedTo) {
				const user = organizationUsers[userUID]
				if (user && (user.firstName || user.lastName)) {
					const { firstName, lastName } = user
					const displayName = `${firstName || ''} ${lastName || ''}`.trim()
					assignedToArray.push(`${displayName}`)
				}
			}
			assignedToString = assignedToArray.join('; ')
		}

		const data = productArray
			.sort((a, b) => b.dateCreated - a.dateCreated)
			.map((product) => {
				const {
					createdByUID: productCreatedByUID,
					dateCreated: productDateCreated,
					productTypeUID,
				} = product

				return {
					'productType':
						JobTypes[job.jobTypeUID] &&
						JobTypes[job.jobTypeUID].ProductTypes[productTypeUID] &&
						JobTypes[job.jobTypeUID].ProductTypes[productTypeUID].name
							? JobTypes[job.jobTypeUID].ProductTypes[productTypeUID].name
							: productTypeUID,
					'dateCreated': convertToDateString(productDateCreated),
					'createdBy':
						organizationUsers &&
						productCreatedByUID &&
						organizationUsers[productCreatedByUID]
							? `${organizationUsers[productCreatedByUID].firstName ||
									''} ${organizationUsers[productCreatedByUID].lastName ||
									''}`.trim()
							: productCreatedByUID,
					...product.formData,
				}
			})
		axios({
			method: 'POST',
			url: 'https://www.tsitpro.com/tracking/',
			data: {
				job: job,
				assignedTo: assignedToString,
				products: data,
				spareParts: sparePartArray
			} 
		}).then((response) => {
			if(response.status !== 200 && (!response.data.file || response.data.file === '')) {
				return;
			}
			const { match, userModel, saveJobPdfFile } = this.props
			const { uid: jobUID } = match.params
			const { organizationUID } = userModel
			const data = { pdfFile: response.data.file, organizationUID, jobUID }
			return saveJobPdfFile(data)
			.then(() => {
				this.setState({ statePdfFile: response.data.file })
			})
			.catch((error) => console.log(error))
		})
	}

	render() {
		const meta = {
			title: 'Rittal | Service Case',
			description: '',
		}
		const { editJobDetailsVisible, addProductPopupVisible, sendEmailPopupVisible, statePdfFile, addSparePartPopupVisible, editSparePartObj } = this.state
		const {
			match,
			job,
			jobLoaded,
			productArray,
			productsLoaded,
			userModel,
			organizationUsers,
			userArray,
			history,
			summaryData,
			standupArray,
			sparePartArray,
			sparePartsLoaded
		} = this.props

		const { uid, status } = match.params
		const { organizationUID, firstName, lastName } = userModel
		if (!job) {
			if (jobLoaded) {
				return <h1 style={{ textAlign: 'center', marginTop: '50' }}>Error Loading Job.</h1>
			} else {
				return null //Still Loading
			}
		}
		const { jobTypeUID, pdfFile } = job
		const isAmazonJob = JobTypes[jobTypeUID].name === "Amazon Service Order / Report"
		let pdfButton;
		if(status === 'history' && isAmazonJob) {
			if((pdfFile && pdfFile !== '')) {
				pdfButton = <a
					href={pdfFile}
					style={styles.linkButton}
					download
					target="_blank"
					rel="noopener noreferrer"
				>
					View PDF
				</a>
			}
			else if((statePdfFile && statePdfFile !== '')) {
				pdfButton = <a
					href={statePdfFile}
					style={styles.linkButton}
					download
					target="_blank"
					rel="noopener noreferrer"
				>
					View PDF
				</a>
			}
			else {
				pdfButton = <TEButton
					onClick={this.generatePdf}
					style={styles.actionButton}
				>
					Generate PDF
				</TEButton>
			}
		}
		return (
			<PanelWrapper>
				<TEHelmet {...meta} />
				<Panel
					title="Details"
					rightComponent={
						<div style={styles.actionWrapper}>
							<TEButton onClick={this.handleDownload} style={styles.actionButton}>
								Download
							</TEButton>

							{status === 'live' && (
								<TEButton
									onClick={() =>
										this.setState({
											editJobDetailsVisible: true,
										})
									}
									style={styles.actionButton}
								>
									Edit
								</TEButton>
							)}
							{status === 'live' && (
								<TEButton
									onClick={this.handleCompleteJob}
									style={styles.actionButton}
								>
									Resolve
								</TEButton>
							)}
							{status === 'history' && (
								<TEButton
									onClick={this.handleReopenJob}
									style={styles.actionButton}
								>
									Reopen
								</TEButton>
							)}
							{pdfButton}
							{isAmazonJob && (
								<TEButton
									onClick={() =>
										this.setState({
											sendEmailPopupVisible: true,
										})
									}
									style={styles.actionButton}
								>
									Email
								</TEButton>
							)}
						</div>
					}
				>
					<ReactTable
						data={[job]}
						columns={[
							{
								Header: 'Case Number',
								accessor: 'jobNumber',
							},
							{
								Header: 'Date Created',
								accessor: 'dateCreated',
								Cell: (d) => (
									<span style={{ whiteSpace: 'normal' }}>{convertToDateTimeShort(d.value)}</span>
								)
							},
							{
								Header: 'Request Type',
								accessor: 'serviceType',
								Cell: (d) => (
									<span style={{ whiteSpace: 'normal' }}>{d.value}</span>
								)
							},
							{
								Header: 'Equipment Type',
								accessor: 'equipmentType',
								Cell: (d) => (
									<span style={{ whiteSpace: 'normal' }}>{d.value}</span>
								)
							},
							{
								id: 'dateOfService',
								Header: 'ETA',
								accessor: (d) => (
									<span style={{ whiteSpace: 'normal' }}>{convertToDateShort(d.dateOfService) + ' ' + (d.eta !== undefined ? d.eta : '')}</span>
								)
							},
							{
								id: 'assignedBy',
								Header: 'Created By',
								accessor: 'assignedBy',
								Cell: (d) => (
									<span style={{ whiteSpace: 'normal' }}>{d.value}</span>
								)
							},
							{
								id: 'assignedTo',
								Header: 'Tech/Service Partner',
								accessor: 'assignedTo',
								Cell: (d) => {
									if (d.value && organizationUsers) {
										const componentArray = []
										for (const userUID in d.value) {
											const user = organizationUsers[userUID]
											if (user && (user.firstName || user.lastName)) {
												const { firstName, lastName } = user
												const displayName = `${firstName ||
													''} ${lastName || ''}`.trim()
												componentArray.push(
													<span
														style={styles.assignedToName}
														key={userUID}
													>{`${displayName};`}</span>
												)
											}
										}
										return (<span style={{ whiteSpace: 'normal' }}>{componentArray}</span>)
									}
									return ' -- '
								},
							},
							{
								Header: 'Region',
								accessor: 'region',
								Cell: (d) => (
									<span style={{ whiteSpace: 'normal' }}>{d.value}</span>
								)
							},
						]}
						defaultPageSize={1}
						filterable={false}
						sortable={false}
						showPagination={false}
					/>
					<ReactTable
						data={[job]}
						columns={[
							{
								id: 'clientClientNumber',
								Header: 'SAP Account Number & Email',
								accessor: (d) => (
									<span style={{ whiteSpace: 'normal' }}>{d.clientClientNumber + (d.clientEmail !== '' ? ' - ' + d.clientEmail : '')}</span>
								),
							},
							{
								Header: 'Paying Customer',
								accessor: 'site',
								Cell: (d) => (
									<span style={{ whiteSpace: 'normal' }}>{d.value}</span>
								)
							},
							{
								Header: 'Work Order Number',
								accessor: 'clientName',
								Cell: (d) => (
									<span style={{ whiteSpace: 'normal' }}>{d.value}</span>
								)
							},
							{
								Header: 'Address',
								accessor: 'clientAddress',
								Cell: (d) => (
									<span style={{ whiteSpace: 'normal' }}>{d.value}</span>
								)
							},
							{
								Header: 'Status',
								accessor: 'serviceStatus',
								Cell: (d) => (
									<span style={{ whiteSpace: 'normal' }}>{d.value}</span>
								)
							},
							{
								Header: 'Customer PO',
								accessor: 'poNumber',
								Cell: (d) => (
									<span style={{ whiteSpace: 'normal' }}>{d.value}</span>
								)
							},
							{
								Header: 'Point of Contact Name',
								accessor: 'clientContact',
								Cell: (d) => (
									<span style={{ whiteSpace: 'normal' }}>{d.value}</span>
								)
							},
							{
								Header: 'Point of Contact Phone',
								accessor: 'clientPhone',
								Cell: (d) => (
									<span style={{ whiteSpace: 'normal' }}>{d.value}</span>
								)
							}
						]}
						defaultPageSize={1}
						filterable={false}
						sortable={false}
						showPagination={false}
					/>
					<ReactTable
						data={[job]}
						columns={[
							{
								accessor: 'jobDescription',
								Header: 'Problem Description',
								Cell: (d) => (
									<span style={{ whiteSpace: 'pre-line' }}>{(!isAmazonJob ? d.value : 'Perform PM on cabinet A/Cs, check function/condition, change/install filter.')}</span>
								)
							}
						]}
						defaultPageSize={1}
						filterable={false}
						sortable={false}
						showPagination={false}
					/>
					<ReactTable
						data={[job]}
						columns={[
							{
								Header: 'Internal Notes',
								accessor: 'jobNotes',
								Cell: (d) => (
									<span style={{ whiteSpace: 'pre-line' }}>{d.value}</span>
								),
							},
						]}
						defaultPageSize={1}
						filterable={false}
						sortable={false}
						showPagination={false}
					/>
					{status === 'history' && (
						<ReactTable
							data={[job]}
							columns={[
								{
									Header: 'Resolved Notes',
									accessor: 'resolvedNotes',
									Cell: (d) => (
										<span style={{ whiteSpace: 'pre-line' }}>{d.value}</span>
									),
								},
							]}
							defaultPageSize={1}
							filterable={false}
							sortable={false}
							showPagination={false}
						/>
					)}
				</Panel>
				<Panel
					title="Spare Parts"
					rightComponent={
						<div style={styles.actionWrapper}>
							{status === 'live' && (
								<TEButton
									onClick={() =>
										this.setState({
											addSparePartPopupVisible: true,
										})
									}
									style={styles.actionButton}
								>
									Add Spare Part
								</TEButton>
							)}
						</div>
					}
				>
					<ReactTable
						data={sparePartArray}
						columns={sparePartTableColumns({
							handleRemoveSparePart: this.handleRemoveSparePart,
							handleEditSparePart: this.handleEditSparePart,
							status,
							jobUID: uid, 
						})}
						defaultPageSize={10}
						filterable
						defaultFilterMethod={customTableFilter}
						defaultSorted={[
							{
								id: 'partNumber',
								desc: false,
							},
						]}
						loading={!sparePartsLoaded}
					/>
				</Panel>
				<Panel
					title="Equipments"
					rightComponent={
						<div style={styles.actionWrapper}>
							{status === 'live' && (
								<TEButton
									onClick={() =>
										this.setState({
											addProductPopupVisible: true,
										})
									}
									style={styles.actionButton}
								>
									Add Equipment
								</TEButton>
							)}
						</div>
					}
				>
					<ReactTable
						data={productArray}
						columns={tableColumns({
							handleRemoveProduct: this.handleRemoveProduct,
							handleDuplicateProduct: this.handleDuplicateProduct,
							status,
							jobTypeUID,
							jobUID: uid,
							isAmazonJob 
						})}
						defaultPageSize={10}
						filterable
						defaultFilterMethod={customTableFilter}
						defaultSorted={[
							{
								id: 'itemNumber',
								desc: false,
							},
						]}
						loading={!productsLoaded}
					/>
				</Panel>
				{!isAmazonJob && (<Panel
					title="Summary"
					rightComponent={
						<div style={styles.actionWrapper}>
							<TEButton
								onClick={() =>
									this.setState({
										sendEmailPopupVisible: true,
									})
								}
								style={styles.actionButton}
							>
								Email
							</TEButton>
						</div>
					}
				>
					<ReactTable
						data={[{ ...job, tech: `${firstName || ''} ${lastName || ''}`.trim() }]}
						columns={[
							{
								id: 'dateOfService',
								Header: 'Date of report',
								accessor: 'dateOfService',
								Cell: (d) => convertToDateShort(d.value),
							},
							{
								Header: 'Client Number',
								accessor: 'locationClientNumber',
							},
							{
								Header: 'Address',
								accessor: 'locationAddress',
							},
							{
								Header: 'Contact Person',
								accessor: 'locationContact',
							},
							{
								Header: 'Name of Tech',
								accessor: 'tech',
							},
						]}
						defaultPageSize={1}
						filterable={false}
						sortable={false}
						showPagination={false}
					/>
					{summaryData &&
						summaryData.productCountArray &&
						summaryData.productCountArray.length > 0 && (
							<ReactTable
								data={[summaryData.productData]}
								columns={summaryData.productCountArray.map((product) => ({
									id: product.uid,
									Header: JobTypes[jobTypeUID].ProductTypes[product.uid].name,
									accessor: `${product.uid}.count`,
								}))}
								defaultPageSize={1}
								filterable={false}
								sortable={false}
								showPagination={false}
							/>
						)}
					{summaryData.inputArray.map((inputTypeData) => {
						const { name, products } = inputTypeData

						if (!products || products.length === 0) {
							return null
						}

						return (
							<Fragment key={name}>
								<h3 style={styles.summaryTitle}>{name}</h3>
								<ReactTable
									data={products}
									columns={[
										{
											Header: 'Product',
											accessor: 'name',
										},
										...Object.keys(products[0])
											.filter((input) => input !== 'name' && input !== 'uid')
											.map((input) => ({
												Header: input,
												accessor: input,
												className: 'pre-line',
											})),
									]}
									defaultPageSize={10}
									filterable
									defaultFilterMethod={customTableFilter}
									defaultSorted={[
										{
											id: 'itemNumber',
											desc: false,
										},
									]}
									loading={!productsLoaded}
								/>
							</Fragment>
						)
					})}
				</Panel>)}
				<EditJobDetailsPopup
					visible={editJobDetailsVisible}
					job={job}
					onClose={() => this.setState({ editJobDetailsVisible: false })}
					onSubmit={this.handleSaveJobDetails}
					userArray={userArray}
					standupArray={standupArray}
					jobType={(JobTypes[jobTypeUID] && JobTypes[jobTypeUID].name)}
				/>
				<AddProductPopup
					visible={addProductPopupVisible}
					onClose={() => this.setState({ addProductPopupVisible: false })}
					onSubmit={() => this.setState({ addProductPopupVisible: false })}
					jobUID={uid}
					organizationUID={organizationUID}
					history={history}
				/>
				<AddSparePartPopup
					visible={addSparePartPopupVisible}
					onClose={() => this.setState({ 
							addSparePartPopupVisible: false,
							editSparePartObj: {}
						}
					)}
					onSubmit={() => this.setState({ 
							addSparePartPopupVisible: false,
							editSparePartObj: {}
						}
					)}
					jobUID={uid}
					sparePart={editSparePartObj}
					organizationUID={organizationUID}
				/>
				<SendSummaryEmailPopup
					visible={sendEmailPopupVisible}
					onClose={() => this.setState({ sendEmailPopupVisible: false })}
					onSubmit={() => this.setState({ sendEmailPopupVisible: false })}
					summaryData={summaryData}
					job={job}
				/>
			</PanelWrapper>
		)
	}
}

const mapStateToProps = (state) => {
	const { userModel } = state.Loading
	const { job, jobLoaded } = state.Manager.Jobs
	const productArray = getJobProductArray(state)
	const { productsLoaded } = state.Manager.Products
	const { sparePartsLoaded } = state.Manager.SpareParts
	const { organizationUsers } = state.Admin.Organizations
	const userArray = getOrganizationUserArray(state).filter(
		(user) => user.permissions && user.permissions.technician
	)
	const standupArray = getStandupDataArray(state)
	const sparePartArray = getJobSparePartArray(state)
	const summaryData = getJobSummary(state)

	return {
		userModel,
		job,
		jobLoaded,
		productArray,
		productsLoaded,
		organizationUsers,
		userArray,
		summaryData,
		standupArray,
		sparePartArray,
		sparePartsLoaded
	}
}

export default connect(
	mapStateToProps,
	{
		startWatchingOrganization,
		stopWatchingOrganization,
		startWatchingJob,
		stopWatchingJob,
		startWatchingProducts,
		stopWatchingProducts,
		saveJobDetails,
		confirmDuplicateProduct,
		confirmRemoveProduct,
		confirmCompleteJob,
		confirmReopenJob,
		startWatchingStandup,
		stopWatchingStandup,
		saveJobPdfFile,
		startWatchingSpareParts,
		stopWatchingSpareParts,
		confirmRemoveSparePart
	}
)(Radium(Create))
