// import { colors } from './../../../config/styles'

export default {
	container: {
		// border 		: `1px solid ${colors.red}`,
		color 		: '#666666',
		fontFamily 	: 'Tahoma, Verdana, Segoe, sans-serif'
	},
	bodyContainer: {
		backgroundColor 	: '#f2f2f2',
	},
	subFooterCopy: {
		color 				: '#888888',
		fontSize 			: 12,
	},
	supportEmailLink: {
		color 				: '#888888',
	},
	unsubscribeLink: {
		color 				: '#888888',
	},
}
