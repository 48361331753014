import { colors } from './../../../config/styles'

export default {
    container: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        color: colors.darkGray,
        backgroundColor: colors.lightGray,
        height: 100,

        '@media (max-width: 650px)': {
            height: 'auto',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
        },
    },
    contentContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
    },
    version: {
        fontSize: 12,
        marginTop: 0,
        marginBottom: 5,
    },
    email: {
        fontSize: 14,
        marginTop: 0,
        marginBottom: 5,
    },
    copyrightCopy: {
        fontSize: 14,
        marginTop: 0,
        marginBottom: 0,
    },
}
