import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import Radium from 'radium'
import firebase from 'firebase/app'
import 'firebase/database'

import { setPageTitle } from './../../../actions'

import TEHelmet from './../../../component/TEHelmet'

import PanelWrapper from './../../../component/Layout/PanelWrapper'
import Panel from './../../../component/Layout/Panel'

import styles from './styles'

class Welcome extends Component {
	componentDidMount() {
		this.props.setPageTitle('')

		let codes = ''
		for (let i = 0; i < 999; i++) {
			codes += `${
				firebase
					.database()
					.ref('appCodes/active')
					.push().key
			}\n`
		}
		console.log(codes)
	}

	render() {
		const meta = {
			title: 'Rittal | Welcome',
			description: '',
		}
		const { userModel } = this.props

		return (
			<Fragment>
				<TEHelmet {...meta} />
				<PanelWrapper style={styles.container}>
					<Panel containerStyle={styles.panel}>
						<h1 style={styles.title}>Welcome to Rittal Service Visit Web Platform</h1>
						<p style={styles.subtitle}>
							{userModel && userModel.email} - You are currently signed in as a
							Manager.
						</p>
					</Panel>
				</PanelWrapper>
			</Fragment>
		)
	}
}

Welcome.propTypes = {}

Welcome.defaultProps = {}

const mapStateToProps = (state) => {
	const { userModel } = state.Loading
	return { userModel }
}

export default connect(
	mapStateToProps,
	{ setPageTitle }
)(Radium(Welcome))
