import { combineReducers } from 'redux'

import Jobs from './JobsReducer'
import Products from './ProductsReducer'
import Static from './StaticReducer'
import SpareParts from './SparePartsReducer'

export default combineReducers({
	Jobs,
	Products,
	Static,
	SpareParts
})
