import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import Radium from 'radium'
import { TEForm, TESegmentedGroup, TEInputRow, TEButton } from 'react-tec'

import { setPageTitle, sendAppCode, sendAdnroidDownload } from './../../../actions'

import TEHelmet from './../../../component/TEHelmet'

import PanelWrapper from './../../../component/Layout/PanelWrapper'
import Panel from './../../../component/Layout/Panel'

import styles from './styles'

class Mobile extends Component {
	state = { appType: '', email: '' }
	componentDidMount() {
		this.props.setPageTitle('Mobile')
	}
	handleSendAppCode = (e) => {
		e.preventDefault()
		const { appType, email } = this.state
		const { sendAppCode, sendAdnroidDownload } = this.props

		const data = { email }

		if (appType === 'iOS') {
			sendAppCode(data)
				.then(() => this.setState({ appType: '', email: '' }))
				.catch((e) => console.log(e))
		} else if (appType === 'Android') {
			sendAdnroidDownload(data)
				.then(() => this.setState({ appType: '', email: '' }))
				.catch((e) => console.log(e))
		}
	}

	render() {
		const meta = {
			title: 'Rittal | Mobile',
			description: '',
		}
		const { appType, email } = this.state

		return (
			<Fragment>
				<TEHelmet {...meta} />
				<PanelWrapper style={styles.container}>
					<Panel containerStyle={styles.panel}>
						<TEForm onSubmit={this.handleSendAppCode}>
							<h1 style={styles.title}>Download the Mobile App</h1>
							<h1 style={styles.subtitle}>
								Send an email with the iOS access code or the Android download link.
								These emails should be opened on the device you intend to install
								the app.
							</h1>
							<TEInputRow
								labelForKey='email'
								title='Email'
								value={email}
								onChange={(e) => this.setState({ email: e.target.value })}
								required
							/>
							<TESegmentedGroup
								labelForKey='appType'
								title='App Type'
								onChange={(e) => this.setState({ appType: e.target.value })}
								checkedValue={appType}
								buttonArray={['iOS', 'Android']}
								inline
								required
							/>
							<TEButton
								onClick={this.handleSendAppCode}
								style={styles.activationKeyButton}
							>
								Send Code
							</TEButton>
						</TEForm>
					</Panel>
				</PanelWrapper>
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => {
	return {}
}

export default connect(
	mapStateToProps,
	{ setPageTitle, sendAppCode, sendAdnroidDownload }
)(Radium(Mobile))
