import validate from 'validate.js'
import firebase from 'firebase/app'
import 'firebase/auth'

export const resetPassword = (data) => {
	const { actionCode, password, popupDispatch, history } = data

	//Validate Data
	const validatorConstraints = {
		password: {
			presence: {
				allowEmpty: false,
			},
		},
	}
	const validationResponse = validate(data, validatorConstraints)
	if (validationResponse) {
		popupDispatch({
			type: 'show_alert',
			payload: {
				alertTitle: 'Error',
				alertMessage: Object.values(validationResponse)[0][0],
			},
		})
		return
	}

	popupDispatch({ type: 'show_network_activity', payload: 'Resetting Password...' })

	firebase
		.auth()
		.verifyPasswordResetCode(actionCode)
		.then((email) => {
			return firebase.auth().confirmPasswordReset(actionCode, password)
		})
		.then(() => {
			popupDispatch({ type: 'hide_network_activity' })
			popupDispatch({
				type: 'show_alert',
				payload: {
					alertTitle: 'Success',
					alertMessage: 'Your password has been reset.',
				},
			})
			history.push('/')
		})
		.catch((error) => {
			console.log(error)
			popupDispatch({ type: 'hide_network_activity' })
			popupDispatch({
				type: 'show_alert',
				payload: {
					alertTitle: 'Error',
					alertMessage: 'Error Resetting Password.',
				},
			})
		})
}
export const recoverEmail = (data) => {
	const { actionCode, popupDispatch, history } = data

	popupDispatch({ type: 'show_network_activity', payload: 'Recovering Email...' })

	let recoverEmail = null
	firebase
		.auth()
		.checkActionCode(actionCode)
		.then((info) => {
			recoverEmail = info.data.email

			return firebase.auth().applyActionCode(actionCode)
		})
		.then(() => {
			return firebase.auth().sendPasswordResetEmail(recoverEmail)
		})
		.then(() => {
			popupDispatch({ type: 'hide_network_activity' })
			popupDispatch({
				type: 'show_alert',
				payload: {
					alertTitle: 'Success',
					alertMessage:
						'Your email address has been recovered. For security we have also sent a password reset email.',
				},
			})
			history.push('/')
		})
		.catch((error) => {
			console.log(error)
			popupDispatch({ type: 'hide_network_activity' })
			popupDispatch({
				type: 'show_alert',
				payload: {
					alertTitle: 'Error',
					alertMessage: 'Error Recovering Email.',
				},
			})
		})
}
export const verifyEmail = (data) => {
	const { actionCode, popupDispatch, history } = data

	popupDispatch({ type: 'show_network_activity', payload: 'Verifying Email...' })

	firebase
		.auth()
		.applyActionCode(actionCode)
		.then((resp) => {
			popupDispatch({ type: 'hide_network_activity' })
			popupDispatch({
				type: 'show_alert',
				payload: {
					alertTitle: 'Success',
					alertMessage: 'Your email has been verified.',
				},
			})
			history.push('/')
		})
		.catch((error) => {
			console.log(error)
			popupDispatch({ type: 'hide_network_activity' })
			popupDispatch({
				type: 'show_alert',
				payload: {
					alertTitle: 'Error',
					alertMessage: 'Error Verifying Your Email.',
				},
			})
		})
}
