//import { colors } from './../../../config/styles'

export default {
	linkWrapper: {
		cursor: 'pointer',
	},
	link: {
		cursor: 'pointer',
		color: 'inherit',
		textDecoration: 'inherit',
		fontSize: 'inherit',
		display: 'inherit',
	},
}
