import { ManagerKeys } from './../../actions/types'

const INITIAL_STATE = {
	jobsLive: undefined,
	jobsLiveLoaded: undefined,
	jobsLiveWatcherRef: undefined,

	jobsHistory: undefined,
	jobsHistoryLoaded: undefined,
	jobsHistoryWatcherRef: undefined,

	jobNumber: undefined,
	jobNumberLoaded: undefined,
	jobNumberWatcherRef: undefined,

	job: undefined,
	jobLoaded: false,
	jobWatcherRef: undefined,
}

export default (state = INITIAL_STATE, action) => {
	const {
		FETCH_JOBS_LIVE,
		SAVE_JOBS_LIVE_WATCHER,
		REMOVE_JOBS_LIVE_WATCHER,

		FETCH_JOBS_HISTORY,
		SAVE_JOBS_HISTORY_WATCHER,
		REMOVE_JOBS_HISTORY_WATCHER,

		FETCH_JOB_NUMBER,
		SAVE_JOB_NUMBER_WATCHER,
		REMOVE_JOB_NUMBER_WATCHER,

		FETCH_JOB,
		SAVE_JOB_WATCHER,
		REMOVE_JOB_WATCHER,
	} = ManagerKeys

	switch (action.type) {
		case FETCH_JOBS_LIVE:
			return { ...state, jobsLive: action.payload, jobsLiveLoaded: true }
		case SAVE_JOBS_LIVE_WATCHER:
			return { ...state, jobsLiveWatcherRef: action.payload }
		case REMOVE_JOBS_LIVE_WATCHER:
			return {
				...state,
				jobsLive: INITIAL_STATE.jobsLive,
				jobsLiveLoaded: INITIAL_STATE.jobsLiveLoaded,
				jobsLiveWatcherRef: INITIAL_STATE.jobsLiveWatcherRef,
			}

		case FETCH_JOBS_HISTORY:
			return {
				...state,
				jobsHistory: action.payload,
				jobsHistoryLoaded: true,
			}
		case SAVE_JOBS_HISTORY_WATCHER:
			return { ...state, jobsHistoryWatcherRef: action.payload }
		case REMOVE_JOBS_HISTORY_WATCHER:
			return {
				...state,
				jobsHistory: INITIAL_STATE.jobsHistory,
				jobsHistoryLoaded: INITIAL_STATE.jobsHistoryLoaded,
				jobsHistoryWatcherRef: INITIAL_STATE.jobsHistoryWatcherRef,
			}

		case FETCH_JOB_NUMBER:
			return {
				...state,
				jobNumber: action.payload,
				jobNumberLoaded: true,
			}
		case SAVE_JOB_NUMBER_WATCHER:
			return { ...state, jobNumberWatcherRef: action.payload }
		case REMOVE_JOB_NUMBER_WATCHER:
			return {
				...state,
				jobNumber: INITIAL_STATE.jobNumber,
				jobNumberLoaded: INITIAL_STATE.jobNumberLoaded,
				jobNumberWatcherRef: INITIAL_STATE.jobNumberWatcherRef,
			}

		case FETCH_JOB:
			return { ...state, job: action.payload, jobLoaded: true }
		case SAVE_JOB_WATCHER:
			return { ...state, jobWatcherRef: action.payload }
		case REMOVE_JOB_WATCHER:
			return {
				...state,
				job: INITIAL_STATE.job,
				jobLoaded: INITIAL_STATE.jobLoaded,
				jobWatcherRef: INITIAL_STATE.jobWatcherRef,
			}

		default:
			return state
	}
}
