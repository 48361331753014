import { createSelector } from 'reselect'

export const getJobProducts = (state) => state.Technician.Products.products
export const getJobProductArray = createSelector(
	getJobProducts,
	(products) => {
		const productArray = []
		for (const key in products) {
			productArray.push({ ...products[key], uid: key })
		}
		return productArray
	}
)
