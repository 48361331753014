import { colors } from './../../../../config/styles'

export default {
	actionButton: {
		width: 'auto',
		fontSize: 16,
		paddingTop: 10,
		paddingBottom: 10,
	},
	exportButton: {
		width: 'auto',
		fontSize: 14,
		paddingTop: 10,
		paddingBottom: 10,
		paddingLeft: 20,
		paddingRight: 20,
    	borderRadius: 5,
    	color: colors.darkGray,
    	backgroundColor: colors.white,
    	transition: `color 0.2s ease-in 0s, background-color 0.2s ease-in 0s, border 0.2s ease-in 0s`,
    	cursor: `pointer`,
    	margin: `0 10px 0 0`,
    	border: `1px solid ${colors.lightGray}`,
    	':hover': {
			border: `1px solid ${colors.primary}`,
		},
		':active': {
			border: `1px solid ${colors.primary}`,
		}
	},
	linkWrapper: {
		color: colors.darkGray,
		border: `1px solid ${colors.lightGray}`,
		backgroundColor: colors.white,
		borderRadius: 5,

		transition: 'color 0.2s ease-in, background-color 0.2s ease-in, border 0.2s ease-in',

		':active': {
			color: colors.white,
			backgroundColor: colors.primary,
			border: `1px solid ${colors.primary}`,
		},
		':hover': {
			color: colors.white,
			backgroundColor: colors.primary,
			border: `1px solid ${colors.primary}`,
		},
	},
	link: {
		position: 'relative',
		width: '100%',
		display: 'block',
		textAlign: 'center',
		fontSize: 14,
		padding: 5,
	},
	wrapCell: {
		whiteSpace: 'normal'
	}
}
