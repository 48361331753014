import { colors } from './../../../config/styles'

export default {
	container: {
		position: 'relative',
		backgroundColor: colors.white,
		width: '100%',
		zIndex: 10,
		paddingLeft: 30,
	},
	contentWrapper: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		height: 60,
	},
	pageTitleWrapper: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexShrink: 0,
	},
	pageTitle: {
		marginTop: 0,
		marginBottom: 0,
		fontSize: 30,
		color: colors.primary,
		fontWeight: 300,
	},
	navContainer: {
		position: 'relative',
		width: '100%',
		height: '100%',
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
	},
	dropDownContainer: {
		height: '100%',
		width: 'auto',
		paddingRight: 30,
	},
	dropdownContentWrapper: {
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',

		backgroundColor: 'transparent',
		textAlign: 'center',
		cursor: 'pointer',

		color: colors.primary,
	},
	name: {
		marginTop: 0,
		marginBottom: 0,
		fontSize: 14,
		fontWeight: 300,
		marginRight: 25,
	},
	downArrow: {
		width: 10,
		height: 10,
		marginLeft: -5,
		marginTop: -5,
		borderBottom: `2px solid ${colors.primary}`,
		borderRight: `2px solid ${colors.primary}`,
		transform: 'rotate(45deg)',
	},
	ul: {
		position: 'absolute',
		padding: 0,
		right: 30,
		top: '100%',
		display: 'none',
		margin: 0,
		maxWidth: 125,
		width: '100%',
		borderTop: `1px solid ${colors.lightGray}`,
		boxShadow: colors.shadowBottom,
	},
	dropdownShown: {
		display: 'block',
	},
	adminUl: {
		margin: 0,
	},
	li: {
		position: 'relative',
		display: 'block',
		width: '100%',
		textAlign: 'center',
		padding: 0,
		listStyle: 'none',
		borderBottom: `1px solid ${colors.lightGray}`,
		borderRight: `1px solid ${colors.lightGray}`,
		borderLeft: `1px solid ${colors.lightGray}`,
	},
	linkWrapper: {
		fontSize: 14,
		backgroundColor: colors.white,
		color: colors.darkGray,

		':active': {
			backgroundColor: colors.primary,
			color: colors.white,
		},
		':hover': {
			backgroundColor: colors.primary,
			color: colors.white,
		},
		':focus': {
			outline: 'none',
		},
	},
	link: {
		textDecoration: 'none',
		transition: 'color 0.2s ease-in, background-color 0.2s ease-in',
		paddingTop: 15,
		paddingBottom: 15,
		paddingRight: 20,
		paddingLeft: 20,
		textAlign: 'left',
	},
	active: {
		color: colors.white,
		backgroundColor: colors.primary,
	},
	navButton: {
		fontSize: 14,
		textAlign: 'left',
		paddingTop: 15,
		paddingBottom: 15,
		paddingRight: 20,
		paddingLeft: 20,
		borderTopLeftRadius: 0,
		borderTopRightRadius: 0,
		borderBottomLeftRadius: 0,
		borderBottomRightRadius: 0,
		width: '100%',
		fontWeight: 300,
		letterSpacing: 0,

		borderLeft: 'none',
		borderRight: 'none',
		borderBottom: 'none',
		borderTop: 'none',
		backgroundColor: colors.white,
		color: colors.darkGray,

		backgroundImage: 'none',
		transition: 'border 0.2s ease-in, color 0.2s ease-in, background-color 0.2s ease-in',

		':active': {
			backgroundColor: colors.primary,
			color: colors.white,
			borderLeft: 'none',
			borderRight: 'none',
			borderBottom: 'none',
			borderTop: 'none',
		},
		':hover': {
			backgroundColor: colors.primary,
			color: colors.white,
			borderLeft: 'none',
			borderRight: 'none',
			borderBottom: 'none',
			borderTop: 'none',
		},
		':focus': {
			outline: 'none',
		},
	},
}
