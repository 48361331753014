import React, { Component } from 'react'
import { connect } from 'react-redux'
import Radium from 'radium'

import { setPageTitle, saveProfile } from './../../../actions'

import PanelWrapper from './../../../component/Layout/PanelWrapper'
import Panel from './../../../component/Layout/Panel'

import TEHelmet from './../../../component/TEHelmet'
import TEForm from './../../../component/Form/TEForm'
import TEInputRow from './../../../component/Form/TEInputRow'
import TEButton from './../../../component/Form/TEButton'

import styles from './styles'

class Profile extends Component {
	state = { firstName: '', lastName: '', email: '' }

	componentDidMount() {
		const { userModel, setPageTitle } = this.props
		const { firstName, lastName, email } = userModel

		setPageTitle('Profile')

		this.setState({
			firstName,
			lastName,
			email,
		})
	}

	handleSave = () => {
		const { saveProfile } = this.props
		const { firstName, lastName } = this.state

		const data = { firstName, lastName }

		saveProfile(data)
	}

	render() {
		const meta = {
			title: 'Rittal | Profile',
			description: '',
		}
		const { firstName, lastName, email } = this.state

		return (
			<PanelWrapper>
				<TEHelmet {...meta} />
				<Panel title="Profile" containerStyle={styles.editPanel}>
					<TEForm>
						<TEInputRow
							labelForKey="firstName"
							title="First Name"
							value={firstName}
							onChange={(e) => {
								this.setState({ firstName: e.target.value })
							}}
							// placeholder="Matthew"
							required
							size="half"
						/>
						<TEInputRow
							labelForKey="lastName"
							title="Last Name"
							value={lastName}
							onChange={(e) => {
								this.setState({ lastName: e.target.value })
							}}
							// placeholder="Stevens"
							required
							size="half"
							last
						/>
						<TEInputRow
							labelForKey="email"
							title="Email"
							value={email}
							onChange={() => {}}
							// placeholder="matthew.stevens@gmail.com"
							required
							disabled
						/>
						<TEButton onClick={this.handleSave}>Save</TEButton>
					</TEForm>
				</Panel>
			</PanelWrapper>
		)
	}
}

Profile.propTypes = {}

Profile.defaultProps = {}

const mapStateToProps = (state) => {
	const { userModel } = state.Loading
	return { userModel }
}

export default connect(
	mapStateToProps,
	{ setPageTitle, saveProfile }
)(Radium(Profile))
