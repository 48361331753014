//
//TE Version 0.2.0
//

import React, { Component } from 'react'
import Radium from 'radium'
import PropTypes from 'prop-types'

import TERow from './../TERow'
import TECheckboxInput from './../TECheckboxInput'
import TELabel from './../TELabel'

import styles from './styles'

class TECheckboxGroup extends Component {
    manipulateRowData = ({ rowData, labelForKey = '' }) => {
        //Allowing For Greater Shorthand
        if (typeof rowData === 'string') {
            const label = rowData
            const value = rowData
            const key = labelForKey + rowData
            return { label, value, key }
        } else if (typeof rowData === 'object') {
            let { label, value: value = label, key: key = labelForKey + label } = rowData
            return { label, value, key }
        }
    }
    handleInputChange = (event) => {
        const { checkedValues, onChange } = this.props
        const { value, id } = event.target

        let newValues = [...checkedValues]
        if (checkedValues) {
            if (checkedValues.includes(value)) {
                newValues = newValues.filter((e) => e !== value)
            } else {
                newValues.push(value)
            }
        }
        onChange(newValues, id)
    }

    render() {
        const {
            title,
            containerSize,
            containerStyle,

            //Row
            rowSize,
            rowStyle,

            //Scrolling
            inputWrapperStyle,
            scrolling,

            //Input
            checkedValues,
            required,
            disabled,

            //Input and Label
            buttonArray,
            labelForKey,
        } = this.props

        return (
            <TERow size={containerSize} style={{ ...styles.container, ...containerStyle }}>
                <span style={styles.title}>
                    {title}
                    {required ? ' *' : ''}
                </span>
                <div style={{ ...styles.inputWrapper(scrolling), ...inputWrapperStyle }}>
                    {buttonArray &&
                        buttonArray.map((rowData) => {
                            const { label, value, key } = this.manipulateRowData({
                                rowData,
                                labelForKey,
                            })

                            return (
                                <TERow
                                    key={key}
                                    size={rowSize}
                                    style={{ ...styles.rowStyle, ...rowStyle }}
                                >
                                    <TECheckboxInput
                                        value={value}
                                        onChange={this.handleInputChange}
                                        style={styles.input}
                                        id={key}
                                        checked={checkedValues && checkedValues.includes(value)}
                                        disabled={disabled}
                                    />
                                    <TELabel
                                        htmlFor={key}
                                        labelText={label}
                                        style={styles.label}
                                        disabled={disabled}
                                    />
                                </TERow>
                            )
                        })}
                </div>
            </TERow>
        )
    }
}

TECheckboxGroup.propTypes = {
    scrolling: PropTypes.bool,
    buttonArray: PropTypes.array.isRequired,
    checkedValues: PropTypes.array.isRequired,
}

TECheckboxGroup.defaultProps = {
    scrolling: false,
}

export default Radium(TECheckboxGroup)
