import { createSelector } from 'reselect'

import { getJobProducts } from './ProductsSelectors'

import { JobTypes } from './../../config/localData'

export const getJobsLive = (state) => state.Technician.Jobs.jobsLive
export const getJobLiveArray = createSelector(
	getJobsLive,
	(jobs) => {
		const jobArray = []
		for (const key in jobs) {
			jobArray.push({ ...jobs[key], uid: key })
		}
		return jobArray
	}
)

export const getJobsHistory = (state) => state.Technician.Jobs.jobsHistory
export const getJobHistoryArray = createSelector(
	getJobsHistory,
	(jobs) => {
		const jobArray = []
		for (const key in jobs) {
			jobArray.push({ ...jobs[key], uid: key })
		}
		return jobArray
	}
)

export const getJob = (state) => state.Manager.Jobs.job
export const getJobSummary = createSelector(
	getJob,
	getJobProducts,
	(job, products) => {
		// console.log(job)
		// console.log(products)

		const summaryData = {
			productData: {},
			productCountArray: [],
			inputData: {},
			inputArray: [],
		}

		if (job && products) {
			const { jobTypeUID } = job

			const { ProductTypes } = JobTypes[jobTypeUID]
			for (const productUID in products) {
				const { productTypeUID, formData } = products[productUID]

				//Add Product Count
				if (summaryData.productData[productTypeUID]) {
					summaryData.productData[productTypeUID].count += 1
				} else {
					summaryData.productData[productTypeUID] = {
						count: 1,
						inputs: {},
					}
				}

				//Look For Summary Fields
				const { Sections } = ProductTypes[productTypeUID]
				for (const sectionUID in Sections) {
					const { Inputs } = Sections[sectionUID]
					for (const inputUID in Inputs) {
						const { summary, name, type, options } = Inputs[inputUID]
						if (summary && formData && formData[inputUID]) {
							switch (type) {
								case 'radio': {
									if (!summaryData.inputData[name]) {
										const optionsData = {}
										for (let i = 0; i < options.length; i++) {
											optionsData[options[i]] = 0
										}
										summaryData.inputData[name] = {
											[productTypeUID]: optionsData,
										}
									} else if (!summaryData.inputData[name][productTypeUID]) {
										const optionsData = {}
										for (let i = 0; i < options.length; i++) {
											optionsData[options[i]] = 0
										}
										summaryData.inputData[name][productTypeUID] = optionsData
									}
									const value = formData[inputUID].value
										? formData[inputUID].value
										: formData[inputUID]

									summaryData.inputData[name][productTypeUID][value] += 1
									break
								}
								case 'text': {
									const value = formData[inputUID]
									if (!summaryData.inputData[name]) {
										summaryData.inputData[name] = {
											[productTypeUID]: { [name]: value },
										}
									} else if (!summaryData.inputData[name][productTypeUID]) {
										summaryData.inputData[name][productTypeUID] = {
											[name]: value,
										}
									} else if (summaryData.inputData[name][productTypeUID]) {
										summaryData.inputData[name][productTypeUID][
											name
										] += `\n\r${value}`
									}

									break
								}
								default:
									console.warn('Summary Input Type Not Handled: ', type, name)
									break
							}
						}
					}
				}
			}
			//Convert Objects to Arrays
			for (const uid in summaryData.productData) {
				const { count } = summaryData.productData[uid]
				summaryData.productCountArray.push({ count, uid })
			}

			for (const inputName in summaryData.inputData) {
				const dataToMap = { name: inputName, products: [] }
				for (const productUID in summaryData.inputData[inputName]) {
					dataToMap.products.push({
						name: JobTypes[jobTypeUID].ProductTypes[productUID].name,
						...summaryData.inputData[inputName][productUID],
						uid: productUID,
					})
				}
				summaryData.inputArray.push(dataToMap)
			}
		}

		// console.log(summaryData)
		return summaryData
	}
)
