import { colors } from './../../../config/styles'

export default {
    stepsContainer: {
        width: '100%',
        display: 'flex',
        position: 'relative',
        marginBottom: 30,

        '@media (max-width: 500px)': {
            marginBottom: 15,
        },
    },
    stepBar: (steps) => ({
        position: 'absolute',
        top: 14,
        backgroundColor: colors.primary,
        height: 2,
        width: `${100 - (100/steps)}%`,
        left: `${100/steps/2}%`
    }),
    stepWrapper: (steps) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: `${100/steps}%`
    }),
    stepNumber: (active) => ({
        textAlign: 'center',
        width: 30,
        height: 30,
        marginBottom: 10,
        lineHeight: '30px',
        border: `1px solid ${colors.primary}`,
        zIndex: 1,
        backgroundColor: active ? colors.primary : colors.white,
        color: active ? colors.white : colors.primary,
        transition: 'color 0.2s ease-in, background-color 0.2s ease-in',
    }),
    stepTitle: {
        textAlign: 'center',
        fontSize: 18,

        '@media (max-width: 500px)': {
            fontSize: 16,
        },
        '@media (max-width: 350px)': {
            fontSize: 14,
        },
    },
    form: {
        marginBottom: 30
    },
    buttonContainer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        display: 'flex',
    },
    stepButtons: (buttonType, rounded, singleButton) => {
        let style = {
            paddingTop: 10,
            paddingBottom: 10,
            paddingRight: 20,
            paddingLeft: 20,
            color: colors.primary,
            backgroundColor: colors.white,
            borderTop: `1px solid ${colors.primary}`,
            borderBottom: 'none',
            fontWeight: 100,
            letterSpacing: 0.5,
            backgroundImage: 'none',
            transition: 'border 0.2s ease-in, color 0.2s ease-in, background-color 0.2s ease-in',
        }

        switch (buttonType) {
            case 'left':
                style = { ...style,
                    borderLeft: 'none',
                    borderRight: `1px solid ${colors.primary}`,

                    borderBottomLeftRadius: rounded ? 5 : 0,
                    borderTopRightRadius: 0,
                    borderTopLeftRadius: 0,
                    borderBottomRightRadius: 0,

                    ':hover': {
                        color: colors.white,
                        backgroundColor: colors.primary,
                        borderTop: `1px solid ${colors.primary}`,
                        borderLeft: 'none',
                        borderRight: `1px solid ${colors.primary}`,
                        borderBottom: 'none',
                    },
                    ':active': {
                        color: colors.white,
                        backgroundColor: colors.primary,
                        borderTop: `1px solid ${colors.primary}`,
                        borderLeft: 'none',
                        borderRight: `1px solid ${colors.primary}`,
                        borderBottom: 'none',
                    }
                }
                break
            case 'right':
                style = { ...style,
                    borderLeft: singleButton ? 'none' : `1px solid ${colors.primary}`,
                    borderRight: 'none',

                    borderBottomRightRadius: rounded ? 5 : 0,
                    borderBottomLeftRadius: singleButton && rounded ? 5 : 0,
                    borderTopRightRadius: 0,
                    borderTopLeftRadius: 0,

                    ':hover': {
                        color: colors.white,
                        backgroundColor: colors.primary,
                        borderTop: `1px solid ${colors.primary}`,
                        borderLeft: singleButton ? 'none' : `1px solid ${colors.primary}`,
                        borderRight: 'none',
                        borderBottom: 'none',
                    },
                    ':active': {
                        color: colors.white,
                        backgroundColor: colors.primary,
                        borderTop: `1px solid ${colors.primary}`,
                        borderLeft: singleButton ? 'none' : `1px solid ${colors.primary}`,
                        borderRight: 'none',
                        borderBottom: 'none',
                    }
                }
                break
            default:
                break
        }
        return style
    }
}