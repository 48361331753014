// import { colors } from './../../../config/styles'

export default {
	content: {
		width: '90%',
		maxWidth: '900px',
		margin: '100px auto',
	},
	container: {
		padding: 30,
	},
}
