import { colors } from './../../../../config/styles'

export default {
	assignedToName: {
		display: 'inline-block',
		marginRight: 5,
		whiteSpace: 'normal'
	},
	actionWrapper: {
		display: 'flex',
	},
	actionButton: {
		width: 'auto',
		fontSize: 16,
		paddingTop: 5,
		paddingBottom: 5,
	},
	linkWrapper: {
		color: colors.darkGray,
		border: `1px solid ${colors.lightGray}`,
		backgroundColor: colors.white,
		borderRadius: 5,

		transition: 'color 0.2s ease-in, background-color 0.2s ease-in, border 0.2s ease-in',

		':active': {
			color: colors.white,
			backgroundColor: colors.primary,
			border: `1px solid ${colors.primary}`,
		},
		':hover': {
			color: colors.white,
			backgroundColor: colors.primary,
			border: `1px solid ${colors.primary}`,
		},
	},
	link: {
		position: 'relative',
		width: '100%',
		display: 'block',
		textAlign: 'center',
		fontSize: 14,
		paddingTop: 5,
		paddingBottom: 5,
		paddingLeft: 20,
		paddingRight: 20,
		fontWeight: 400,
	},
	summaryTitle: {
		fontSize: 24,
		marginTop: 30,
		marginBottom: 5,
	},
	linkButton: {
	    width: 'auto',
	    fontSize: 16,
	    padding: 5,
	    border: `1px solid ${colors.lightGray}`,
	    textDecoration: 'none',
	    borderRadius: 5,
	    ':hover': {
	    	color: colors.white,
			backgroundColor: colors.primary,
			border: `1px solid ${colors.primary}`,
	    }
	}
}
