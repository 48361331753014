import React, { Component } from 'react'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Radium from 'radium'
import firebase from 'firebase/app'
import 'firebase/auth'

import TENavLink from './../../../component/Navigation/TENavLink'
import TEButton from './../../../component/Form/TEButton'

import { signOutUser } from './../../../actions'
import styles from './styles'

class Navbar extends Component {
	state = { dropdownShown: false }

	handleSignOut = () => {
		const { history, signOutUser } = this.props
		signOutUser(history)
	}

	render() {
		const { dropdownShown } = this.state
		const { pageTitle, userModel } = this.props

		return (
			<div style={styles.container}>
				<div style={styles.contentWrapper}>
					<div style={styles.pageTitleWrapper}>
						<h1 style={styles.pageTitle}>{pageTitle}</h1>
					</div>
					<div style={styles.navContainer}>
						<div
							style={styles.dropDownContainer}
							onClick={() => {
								this.setState({ dropdownShown: !dropdownShown })
							}}
						>
							<div style={styles.dropdownContentWrapper}>
								<p style={styles.name}>{userModel && userModel.displayName}</p>
								<div style={styles.downArrow} />
							</div>
							<ul style={[styles.ul, dropdownShown && styles.dropdownShown]}>
								<li key="profileLi" style={styles.li}>
									<TENavLink
										style={styles.link}
										wrapperStyle={styles.linkWrapper}
										activeStyle={styles.active}
										to="/technician/profile"
										exact
									>
										Profile
									</TENavLink>
								</li>
								{firebase.auth().currentUser ? (
									<li key="signOutLiKey" style={styles.li}>
										<TEButton
											onClick={this.handleSignOut}
											style={styles.navButton}
										>
											Sign Out
										</TEButton>
									</li>
								) : (
									<li key="signInLiKey" style={styles.li}>
										<TENavLink
											style={styles.link}
											wrapperStyle={styles.linkWrapper}
											activeStyle={styles.active}
											to="/signin"
											exact
										>
											Sign In
										</TENavLink>
									</li>
								)}
							</ul>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

Navbar.propTypes = {}

Navbar.defaultProps = {}

const mapStateToProps = (state) => {
	const { pageTitle } = state.Global
	const { userModel } = state.Loading

	return { pageTitle, userModel }
}

export default connect(
	mapStateToProps,
	{ signOutUser }
)(Radium(Navbar))
