const DEVELOPMENT = {
	FIREBASE_API_KEY: 'AIzaSyB7TyLGtsPcP6TJOMg4_cPmmwgiw2wsLYQ',
	FIREBASE_AUTH_DOMAIN: 'rittal-dev.firebaseapp.com',
	FIREBASE_DATABASE_URL: 'https://rittal-dev.firebaseio.com',
	FIREBASE_PROJECT_ID: 'rittal-dev',
	FIREBASE_STORAGE_BUCKET: 'rittal-dev.appspot.com',
	FIREBASE_MESSAGING_SENDER_ID: '205238990904',
	BASE_URL: 'http://localhost:3010',
	WEBSITE_URL: 'https://rittal.com/us_en/apps/services'
}

const PRODUCTION = {
	FIREBASE_API_KEY: 'AIzaSyBI43itA3u6AJ9A7wsIiaSNizwLm8A1-_Y',
	FIREBASE_AUTH_DOMAIN: 'rittal-prod.firebaseapp.com',
	FIREBASE_DATABASE_URL: 'https://rittal-prod.firebaseio.com',
	FIREBASE_PROJECT_ID: 'rittal-prod',
	FIREBASE_STORAGE_BUCKET: 'rittal-prod.appspot.com',
	FIREBASE_MESSAGING_SENDER_ID: '267819159628',
	BASE_URL: 'https://service.tsitpro.com/',
	WEBSITE_URL: 'https://rittal.com/us_en/apps/services'
}

export default {
	// dev: true,
	dev: process.env.NODE_ENV === 'development',
	staging:
		window.location.origin ===
		'http://rittal-form-tool-staging.com.s3-website-us-east-1.amazonaws.com',
	// apiDebugging: true,
	apiDebugging:
		process.env.NODE_ENV === 'development' ||
		window.location.origin ===
			'http://rittal-form-tool-staging.com.s3-website-us-east-1.amazonaws.com',

	VERSION: '1.0.0',

	EMAIL_API_ROUTE: 'https://ov7qju62dl.execute-api.us-east-1.amazonaws.com/development/sendMail',

	// ...DEVELOPMENT,
	...(process.env.NODE_ENV === 'development' ? DEVELOPMENT : PRODUCTION),
}
