import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import Radium from 'radium'

import { setPageTitle } from './../../../actions'

import TEHelmet from './../../../component/TEHelmet'

import PanelWrapper from './../../../component/Layout/PanelWrapper'
import Panel from './../../../component/Layout/Panel'

import styles from './styles'

class Welcome extends Component {
	componentDidMount() {
		this.props.setPageTitle('')
	}

	render() {
		const meta = {
			title: 'Rittal | Welcome',
			description: '',
		}

		return (
			<Fragment>
				<TEHelmet {...meta} />
				<PanelWrapper style={styles.container}>
					<Panel containerStyle={styles.panel}>
						<h1 style={styles.title}>Welcome to Rittal Service Visit Web Platform</h1>
						<p style={styles.subtitle}>You are currently signed in as a Admin.</p>
					</Panel>
				</PanelWrapper>
			</Fragment>
		)
	}
}

Welcome.propTypes = {}

Welcome.defaultProps = {}

const mapStateToProps = (state) => {
	return {}
}

export default connect(
	mapStateToProps,
	{ setPageTitle }
)(Radium(Welcome))
