export const generateRandomCode = (length = 2) => {
	let code = ''
	for (let i = 0; i < length; i++) {
		code += s4()
	}
	return code
}

function s4() {
	return Math.floor((1 + Math.random()) * 0x10000)
		.toString(16)
		.substring(1)
}

export const downloadCSV = (csvString, fileName) => {
	const blob = new Blob([csvString])
	if (window.navigator.msSaveOrOpenBlob)
		// IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
		window.navigator.msSaveBlob(blob, `${fileName}.csv`)
	else {
		var a = window.document.createElement('a')
		a.href = window.URL.createObjectURL(blob, { type: 'text/plain' })
		a.download = `${fileName}.csv`
		document.body.appendChild(a)
		a.click() // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
		document.body.removeChild(a)
	}
}
