import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import Radium from 'radium'
import ReactTable from 'react-table'

import { startWatchingJobsHistory, stopWatchingJobsHistory } from './../../../../actions/Technician'
import { getJobHistoryArray } from './../../../../selectors/Technician'

import TEHelmet from './../../../../component/TEHelmet'

import PanelWrapper from './../../../../component/Layout/PanelWrapper'
import Panel from './../../../../component/Layout/Panel'

import { tableColumns } from './tableConfigs'
import { customTableFilter } from './../../../../helpers'
import styles from './styles'

class History extends Component {
	componentDidMount() {
		const { userModel, startWatchingJobsHistory } = this.props
		startWatchingJobsHistory(userModel.organizationUID)
	}
	componentWillUnmount() {
		this.props.stopWatchingJobsHistory()
	}
	render() {
		const meta = {
			title: 'Rittal | Job History',
			description: '',
		}
		const { jobArray } = this.props
		return (
			<Fragment>
				<TEHelmet {...meta} />
				<PanelWrapper>
					<Panel title="History">
						<div style={styles.tableContainer}>
							<ReactTable
								data={jobArray}
								columns={tableColumns}
								defaultPageSize={10}
								filterable
								defaultFilterMethod={customTableFilter}
								defaultSorted={[
									{
										id: 'jobNumber',
										desc: true,
									},
								]}
							/>
						</div>
					</Panel>
				</PanelWrapper>
			</Fragment>
		)
	}
}

History.propTypes = {}

History.defaultProps = {}

const mapStateToProps = (state) => {
	const { userModel } = state.Loading
	const jobArray = getJobHistoryArray(state)
	return { userModel, jobArray }
}

export default connect(
	mapStateToProps,
	{ startWatchingJobsHistory, stopWatchingJobsHistory }
)(Radium(History))
