import { colors } from './../../../../config/styles'

export default {
	sectionItem: {
		marginBottom: 5,
	},
	sectionTitleWrapper: {
		backgroundColor: colors.lightestGray,
		paddingTop: 15,
		paddingBottom: 15,
		paddingLeft: 15,
		paddingRight: 15,
		color: colors.primary,
	},
	sectionTitle: {
		marginTop: 0,
		marginBottom: 0,
		fontSize: 24,
	},
	sectionArrow: {},
	sectionBodyWrapper: {
		backgroundColor: colors.lightestGray,
		paddingTop: 15,
		paddingBottom: 15,
		paddingLeft: 15,
		paddingRight: 15,
	},
}
