import { colors } from './../../../config/styles'

export default {
	container: {
		position: 'relative',
		backgroundColor: colors.white,
		height: 100,
		width: '100%',
		zIndex: 10,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'flex-end',
		paddingLeft: 20,
		paddingRight: 20,
		paddingBottom: 10,
		paddingTop: 10,
		boxShadow: colors.shadowTop,

		'@media (max-width: 600px)': {
			display: 'block',
			textAlign: 'center',
			height: 150,
			justifyContent: 'center',
		},
		'@media (max-width: 450px)': {
			height: 'auto',
			paddingBottom: 20,
		},
	},
	copyrightContainer: {
		width: '100%',
		textAlign: 'right',
		fontWeight: 300,

		'@media (max-width: 600px)': {
			textAlign: 'center',
		},
	},
	version: {
		fontSize: 12,
		marginTop: 0,
		marginBottom: 0,
		lineHeight: 1.5,
	},
	updateButton: {
		border: 'none',
		marginLeft: 10,
		marginBottom: 0,
		marginRight: 0,
		marginTop: 0,
		padding: 0,
		display: 'inline-block',
		textDecoration: 'underline',
		cursor: 'pointer',
		color: colors.red,
		fontSize: 12,

		':active': {
			textDecoration: 'none',
		},
		':hover': {
			textDecoration: 'none',
		},
	},
	email: {
		marginTop: 0,
		marginBottom: 5,
		fontSize: 14,
		lineHeight: 1.5,
	},
	copyrightCopy: {
		marginTop: 0,
		marginBottom: 0,
		fontSize: 14,
		lineHeight: 1.5,
	},
}
