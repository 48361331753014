import React, { Component, Fragment } from 'react'
import Radium from 'radium'

import TEPopupMultiStepForm from './../../../../../component/Popup/TEPopupMultiStepForm'
import TEInputRow from './../../../../../component/Form/TEInputRow'
import TEDatetimeRow from './../../../../../component/Form/TEDatetimeRow'

import styles from './styles'

class EditJobDetailsPopup extends Component {
	state = {
		dateOfService: undefined,
		assignedBy: '',
		distributionContact: '',
		contractNumber: '',
		notificationTicketNumber: '',
		businessTransactionNumber: '',
		clientName: '',
		clientClientNumber: '',
		clientAddress: '',
		clientContact: '',
		clientPhone: '',
		clientEmail: '',
		serviceAddress: '',
		serviceContact: '',
		serviceClientNumber: '',
		servicePhone: '',
		serviceEmail: '',
		locationAddress: '',
		locationContact: '',
		locationClientNumber: '',
		locationPhone: '',
		locationEmail: '',
		jobNotes: '',
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		const { job } = nextProps
		if (job && job !== prevState.job) {
			const {
				dateOfService,
				assignedBy = '',
				distributionContact = '',
				contractNumber = '',
				notificationTicketNumber = '',
				businessTransactionNumber = '',
				clientName = '',
				clientClientNumber = '',
				clientAddress = '',
				clientContact = '',
				clientPhone = '',
				clientEmail = '',
				serviceAddress = '',
				serviceContact = '',
				serviceClientNumber = '',
				servicePhone = '',
				serviceEmail = '',
				locationAddress = '',
				locationContact = '',
				locationClientNumber = '',
				locationPhone = '',
				locationEmail = '',
				jobNotes = '',
			} = job

			return {
				dateOfService,
				assignedBy,
				distributionContact,
				contractNumber,
				notificationTicketNumber,
				businessTransactionNumber,
				clientName,
				clientClientNumber,
				clientAddress,
				clientContact,
				clientPhone,
				clientEmail,
				serviceAddress,
				serviceContact,
				serviceClientNumber,
				servicePhone,
				serviceEmail,
				locationAddress,
				locationContact,
				locationClientNumber,
				locationPhone,
				locationEmail,
				jobNotes,
				job,
			}
		}
		return { job }
	}

	handleOnSubmit = () => {
		const {
			dateOfService,
			assignedBy,
			distributionContact,
			contractNumber,
			notificationTicketNumber,
			businessTransactionNumber,
			clientName,
			clientClientNumber,
			clientAddress,
			clientContact,
			clientPhone,
			clientEmail,
			serviceAddress,
			serviceContact,
			serviceClientNumber,
			servicePhone,
			serviceEmail,
			locationAddress,
			locationContact,
			locationClientNumber,
			locationPhone,
			locationEmail,
			jobNotes,
		} = this.state
		const { onSubmit } = this.props

		const data = {
			dateOfService,
			assignedBy,
			distributionContact,
			contractNumber,
			notificationTicketNumber,
			businessTransactionNumber,
			clientName,
			clientClientNumber,
			clientAddress,
			clientContact,
			clientPhone,
			clientEmail,
			serviceAddress,
			serviceContact,
			serviceClientNumber,
			servicePhone,
			serviceEmail,
			locationAddress,
			locationContact,
			locationClientNumber,
			locationPhone,
			locationEmail,
			jobNotes,
		}
		return onSubmit(data)
	}

	render() {
		const {
			dateOfService,
			assignedBy,
			distributionContact,
			contractNumber,
			notificationTicketNumber,
			businessTransactionNumber,
			clientName,
			clientClientNumber,
			clientAddress,
			clientContact,
			clientPhone,
			clientEmail,
			serviceAddress,
			serviceContact,
			serviceClientNumber,
			servicePhone,
			serviceEmail,
			locationAddress,
			locationContact,
			locationClientNumber,
			locationPhone,
			locationEmail,
			jobNotes,
		} = this.state
		const { visible, onClose, jobType } = this.props
		const stepData = [
			{
				title: 'Job Details',
				component: (
					<Fragment>
						<TEDatetimeRow
							labelForKey="dateOfService"
							title="Date of Service"
							value={dateOfService}
							onChange={(dateOfService) =>
								this.setState({
									dateOfService,
								})
							}
							placeholder={'1/1/2019'}
						/>
						<TEInputRow
							labelForKey="assignedBy"
							title="Assigned By"
							value={assignedBy}
							onChange={(e) =>
								this.setState({
									assignedBy: e.target.value,
								})
							}
							placeholder="name"
						/>
						<TEInputRow
							labelForKey="distributionContact"
							title="Distribution Contact"
							value={distributionContact}
							onChange={(e) =>
								this.setState({
									distributionContact: e.target.value,
								})
							}
							placeholder="name"
							size="half"
						/>
						<TEInputRow
							labelForKey="contractNumber"
							title="Contract Number"
							value={contractNumber}
							onChange={(e) =>
								this.setState({
									contractNumber: e.target.value,
								})
							}
							placeholder="number"
							size="half"
							last
						/>
						<TEInputRow
							labelForKey="notificationTicketNumber"
							title="Notification / Ticket Number"
							value={notificationTicketNumber}
							onChange={(e) =>
								this.setState({
									notificationTicketNumber: e.target.value,
								})
							}
							placeholder="number"
							size="half"
						/>
						<TEInputRow
							labelForKey="businessTransactionNumber"
							title="Business Transaction Number"
							value={businessTransactionNumber}
							onChange={(e) =>
								this.setState({
									businessTransactionNumber: e.target.value,
								})
							}
							placeholder="number"
							size="half"
							last
						/>
						<TEInputRow
							labelForKey="jobNotes"
							type="textarea"
							title="Notes"
							value={jobNotes}
							onChange={(e) =>
								this.setState({
									jobNotes: e.target.value,
								})
							}
							placeholder="notes"
						/>
					</Fragment>
				),
			},
			{
				title: 'Client Details',
				component: (
					<Fragment>
						<TEInputRow
							labelForKey="clientClientNumber"
							title="Client Client Number"
							value={clientClientNumber}
							onChange={(e) =>
								this.setState({
									clientClientNumber: e.target.value,
								})
							}
							placeholder="number"
							size="half"
						/>
						<TEInputRow
							labelForKey="clientName"
							title="Client Name"
							value={clientName}
							onChange={(e) =>
								this.setState({
									clientName: e.target.value,
								})
							}
							placeholder="name"
							size="half"
							last
						/>
						<TEInputRow
							labelForKey="clientAddress"
							title="Client Address"
							value={clientAddress}
							onChange={(e) =>
								this.setState({
									clientAddress: e.target.value,
								})
							}
							placeholder="address"
							size="half"
						/>
						<TEInputRow
							labelForKey="clientContact"
							title="Client Contact"
							value={clientContact}
							onChange={(e) =>
								this.setState({
									clientContact: e.target.value,
								})
							}
							placeholder="name"
							size="half"
							last
						/>
						<TEInputRow
							labelForKey="clientPhone"
							title="Client Phone"
							value={clientPhone}
							onChange={(e) =>
								this.setState({
									clientPhone: e.target.value,
								})
							}
							placeholder="phone"
							size="half"
						/>
						<TEInputRow
							labelForKey="clientEmail"
							title="Client Email"
							value={clientEmail}
							onChange={(e) =>
								this.setState({
									clientEmail: e.target.value,
								})
							}
							placeholder="email"
							size="half"
							last
						/>
					</Fragment>
				),
			}
		]
		if(jobType !== "Amazon Service Order / Report") {
			stepData.push({
				title: 'Service Details',
				component: (
					<Fragment>
						<TEInputRow
							labelForKey="serviceClientNumber"
							title="Service Client Number"
							value={serviceClientNumber}
							onChange={(e) =>
								this.setState({
									serviceClientNumber: e.target.value,
								})
							}
							placeholder="number"
							size="half"
						/>
						<TEInputRow
							labelForKey="serviceContact"
							title="Service Contact"
							value={serviceContact}
							onChange={(e) =>
								this.setState({
									serviceContact: e.target.value,
								})
							}
							placeholder="name"
							size="half"
							last
						/>
						<TEInputRow
							labelForKey="serviceAddress"
							title="Service Address"
							value={serviceAddress}
							onChange={(e) =>
								this.setState({
									serviceAddress: e.target.value,
								})
							}
							placeholder="address"
						/>
						<TEInputRow
							labelForKey="servicePhone"
							title="Service Phone"
							value={servicePhone}
							onChange={(e) =>
								this.setState({
									servicePhone: e.target.value,
								})
							}
							placeholder="number"
							size="half"
						/>
						<TEInputRow
							labelForKey="serviceEmail"
							title="Service Email"
							value={serviceEmail}
							onChange={(e) =>
								this.setState({
									serviceEmail: e.target.value,
								})
							}
							placeholder="email"
							size="half"
							last
						/>
					</Fragment>
				),
			});
			stepData.push({
				title: 'Place of Service Details',
				component: (
					<Fragment>
						<TEInputRow
							labelForKey="locationClientNumber"
							title="Place of Service Number"
							value={locationClientNumber}
							onChange={(e) =>
								this.setState({
									locationClientNumber: e.target.value,
								})
							}
							placeholder="number"
							size="half"
						/>
						<TEInputRow
							labelForKey="locationContact"
							title="Place of Service Contact"
							value={locationContact}
							onChange={(e) =>
								this.setState({
									locationContact: e.target.value,
								})
							}
							placeholder="name"
							size="half"
							last
						/>
						<TEInputRow
							labelForKey="locationAddress"
							title="Place of Service Address"
							value={locationAddress}
							onChange={(e) =>
								this.setState({
									locationAddress: e.target.value,
								})
							}
							placeholder="address"
						/>
						<TEInputRow
							labelForKey="locationPhone"
							title="Place of Service Phone"
							value={locationPhone}
							onChange={(e) =>
								this.setState({
									locationPhone: e.target.value,
								})
							}
							placeholder="number"
							size="half"
						/>
						<TEInputRow
							labelForKey="locationEmail"
							title="Place of Service Email"
							value={locationEmail}
							onChange={(e) =>
								this.setState({
									locationEmail: e.target.value,
								})
							}
							placeholder="email"
							size="half"
							last
						/>
					</Fragment>
				),
			});
		}
		return (
			<TEPopupMultiStepForm
				visible={visible}
				contentStyles={styles.content}
				onClose={onClose}
				onSubmit={this.handleOnSubmit}
				stepData={stepData}
			/>
		)
	}
}

export default Radium(EditJobDetailsPopup)
