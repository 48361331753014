import React, { Component } from 'react'
import { connect } from 'react-redux'
import Radium from 'radium'

import { saveOrganization } from './../../../../actions/Admin'

import PanelWrapper from './../../../../component/Layout/PanelWrapper'
import Panel from './../../../../component/Layout/Panel'

import TEHelmet from './../../../../component/TEHelmet'
import TEForm from './../../../../component/Form/TEForm'
import TEInputRow from './../../../../component/Form/TEInputRow'
import TEButton from './../../../../component/Form/TEButton'

import styles from './styles'

class Details extends Component {
	state = { name: '', jobNumber: '', firstLoad: true }

	static getDerivedStateFromProps(nextProps, previousState) {
		if (
			nextProps.organizationLoaded &&
			nextProps.organization &&
			previousState.firstLoad
		) {
			return {
				name: nextProps.organization.name || '',
				jobNumber: nextProps.organization.jobNumber || '',
				firstLoad: false,
			}
		}

		return null
	}

	handleSave = () => {
		const { name, jobNumber } = this.state
		const { match, saveOrganization, organization } = this.props
		const { uid } = match.params

		const data = { uid, name, jobNumber, organization }

		saveOrganization(data)
	}

	render() {
		const meta = {
			title: 'Rittal | Admin Organization',
			description: '',
		}
		const { name, jobNumber } = this.state

		return (
			<PanelWrapper>
				<TEHelmet {...meta} />
				<Panel
					title="Organization Details"
					containerStyle={styles.editPanel}
				>
					<TEForm>
						<TEInputRow
							labelForKey="name"
							title="Organization Name"
							value={name}
							onChange={(e) =>
								this.setState({ name: e.target.value })
							}
							placeholder="name"
							required
						/>
						<TEInputRow
							type="number"
							labelForKey="jobNumber"
							title="Job Number"
							value={jobNumber}
							onChange={(e) =>
								this.setState({ jobNumber: e.target.value })
							}
							placeholder="1000"
							min="0"
							step="1"
							required
						/>
						<TEButton onClick={this.handleSave}>Save</TEButton>
					</TEForm>
				</Panel>
			</PanelWrapper>
		)
	}
}

Details.propTypes = {}

Details.defaultProps = {}

const mapStateToProps = (state) => {
	const { organization, organizationLoaded } = state.Admin.Organizations
	return { organization, organizationLoaded }
}

export default connect(
	mapStateToProps,
	{ saveOrganization }
)(Radium(Details))
