import React, { Component } from 'react'
//import PropTypes from 'prop-types'
import Radium from 'radium'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { Route, Switch } from 'react-router-dom'

import { connect } from 'react-redux'

import {} from './../../../actions'

import Navbar from './../Navbar'
import Footer from './../Footer'

import Home from './../../../pages/Front/Home'
import SignIn from './../../../pages/Front/SignIn'
import ForgotPassword from './../../../pages/Front/ForgotPassword'
import EmailActions from './../../../pages/Front/EmailActions'

import NotFound from './../../../pages/NotFound'

import styles from './styles'

class FrontContentWrapper extends Component {
	render() {
		const { location } = this.props

		return (
			<div className="front-wrapper" style={styles.frontWrapper}>
				<Navbar location={location} />
				<div className="front-content-wrapper" style={styles.frontContentWrapper}>
					<TransitionGroup>
						<CSSTransition
							key={location.pathname}
							timeout={{ enter: 500, exit: 0 }}
							classNames="fade"
							appear
						>
							<div className="AnimationWrapper">
								<Switch>
									<Route exact path="/" component={Home} />
									<Route path="/signin" component={SignIn} />
									<Route path="/forgot-password" component={ForgotPassword} />
									<Route path="/auth-actions" component={EmailActions} />

									{/* SHOULD BUILD IN 404, 403, Portal/403 PAGES */}
									<Route component={NotFound} />
								</Switch>
							</div>
						</CSSTransition>
					</TransitionGroup>
				</div>
				<Footer />
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {}
}

export default connect(
	mapStateToProps,
	{}
)(Radium(FrontContentWrapper))
