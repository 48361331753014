import { colors } from './../../../config/styles'
//import images from './../../../config/images';

export default {
    container: {
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 'calc(100vh - 200px)',
        backgroundColor: colors.lightestGray,
    },
    panel: {
        maxWidth: 700,
        borderRadius: 5,
        paddingTop: 60,
        paddingBottom: 60,
        paddingRight: 60,
        paddingLeft: 60,
    },
    title: {
        textAlign: 'center',
        marginTop: 0,
        marginBottom: 15,
        fontSize: 32,
        color: colors.primary,
        lineHeight: 1.25,
    },
    subtitle: {
        textAlign: 'center',
        marginTop: 0,
        marginBottom: 0,
        fontSize: 18,
        lineHeight: 1.5,
        color: colors.darkGray,
        fontWeight: 300,
    },
}
