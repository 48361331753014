//import { colors } from './../../../config/styles'

export default {
    frontWrapper: {
        position: 'relative',
        width: '100%',
        minHeight: '100%',
    },
    frontContentWrapper: {
        position: 'relative',
        minHeight: 'calc(100vh - 295px)',

        '@media (max-width: 650px)': {
            minHeight: 'calc(100vh - 664px)'
        },
        '@media (max-width: 450px)': {
            minHeight: 'calc(100vh - 615px)'
        },
    }
}