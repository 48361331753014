import validate from 'validate.js'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/storage'

import {
	SHOW_ALERT,
	SHOW_CONFIRM,
	HIDE_CONFIRM,
	SHOW_NETWORK_ACTIVITY,
	HIDE_NETWORK_ACTIVITY,
	TechnicianKeys,
} from './../types'

export const startWatchingSpareParts = (orgUID, jobUID) => {
	const { FETCH_SPARE_PARTS, SAVE_SPARE_PARTS_WATCHER } = TechnicianKeys

	return (dispatch) => {
		const sparePartsWatcherRef = firebase
			.database()
			.ref(`SpareParts/${orgUID}`)
			.orderByChild('jobUID')
			.equalTo(jobUID)
		sparePartsWatcherRef.on(
			'value',
			(snapshot) => {
				dispatch({
					type: FETCH_SPARE_PARTS,
					payload: snapshot.val(),
				})
			},
			(error) => {
				console.log('Loading Error: ', error)
			}
		)
		dispatch({
			type: SAVE_SPARE_PARTS_WATCHER,
			payload: sparePartsWatcherRef,
		})
	}
}
export const stopWatchingSpareParts = () => {
	const { REMOVE_SPARE_PARTS_WATCHER } = TechnicianKeys
	return (dispatch, getState) => {
		const { sparePartsWatcherRef } = getState().Technician.SpareParts
		if (sparePartsWatcherRef) {
			sparePartsWatcherRef.off()
			dispatch({ type: REMOVE_SPARE_PARTS_WATCHER })
		}
	}
}

export const addSparePart = (data) => {
	const { partNumber, partDescription, quantity, inStock, organizationUID, jobUID } = data

	const validatorConstraints = {
		partNumber: {
			presence: {
				allowEmpty: false,
			},
		},
		quantity: {
			presence: {
				allowEmpty: false,
			},
		}
	}
	const validationResponse = validate(data, validatorConstraints)
	if (validationResponse) {
		return (dispatch) => {
			return new Promise((res, rej) => {
				dispatch({
					type: SHOW_ALERT,
					payload: {
						alertTitle: 'Error',
						alertMessage: Object.values(validationResponse)[0][0],
					},
				})
				return rej()
			})
		}
	}

	return (dispatch) =>
		new Promise((res, rej) => {
			dispatch({
				type: SHOW_NETWORK_ACTIVITY,
				payload: 'Adding Spare Part...',
			})

			const { uid } = firebase.auth().currentUser
			firebase
				.database()
				.ref(`SpareParts/${organizationUID}`)
				.push({
					jobUID,
					partNumber,
					partDescription,
					quantity,
					inStock,
					dateCreated: new Date().getTime(),
					createdByUID: uid,
					organizationUID,
				})
				.then((response) => {
					dispatch({ type: HIDE_NETWORK_ACTIVITY })
					dispatch({
						type: SHOW_ALERT,
						payload: {
							alertTitle: 'Success',
							alertMessage: 'Spare part has been added.',
						},
					})
					return res()
				})
				.catch((error) => {
					console.log(error)
					dispatch({ type: HIDE_NETWORK_ACTIVITY })
					dispatch({
						type: SHOW_ALERT,
						payload: {
							alertTitle: 'Error',
							alertMessage: 'Error adding spare part',
						},
					})
					return rej()
				})
		})
}

export const confirmRemoveSparePart = (data) => {
	const { organizationUID, uid } = data

	return (dispatch) => {
		const removeSparePart = () => {
			dispatch({ type: HIDE_CONFIRM })
			dispatch({
				type: SHOW_NETWORK_ACTIVITY,
				payload: 'Deleting Spare Part...',
			})

			firebase
			.database()
			.ref(`SpareParts/${organizationUID}/${uid}`)
			.remove()
			.then(() => {
				dispatch({ type: HIDE_NETWORK_ACTIVITY })
				dispatch({
					type: SHOW_ALERT,
					payload: {
						alertTitle: 'Success',
						alertMessage: 'Spare part Deleted',
					},
				})
			})
			.catch((error) => {
				console.log(error)
				dispatch({ type: HIDE_NETWORK_ACTIVITY })
				dispatch({
					type: SHOW_ALERT,
					payload: {
						alertTitle: 'Error',
						alertMessage: 'Error deleting spare part',
					},
				})
			})
		}
		dispatch({
			type: SHOW_CONFIRM,
			payload: {
				confirmTitle: 'Delete Spare part',
				confirmMessage:
					'Are you sure you want to delete this spare part? This action is not reversible.',
				confirmLeftTitle: 'Cancel',
				confirmRightOnClick: removeSparePart,
				confirmRightTitle: 'Delete Spare Part',
			},
		})
	}
}

export const startWatchingSparePart = (orgUID, partUID) => {
	const { FETCH_SPARE_PART, SAVE_SPARE_PART_WATCHER } = TechnicianKeys

	return (dispatch) => {
		const sparePartWatcherRef = firebase.database().ref(`SpareParts/${orgUID}/${partUID}`)
		sparePartWatcherRef.on(
			'value',
			(snapshot) => {
				dispatch({
					type: FETCH_SPARE_PART,
					payload: snapshot.val(),
				})
			},
			(error) => {
				console.log('Loading Error: ', error)
			}
		)
		dispatch({
			type: SAVE_SPARE_PART_WATCHER,
			payload: sparePartWatcherRef,
		})
	}
}
export const stopWatchingSparePart = () => {
	const { REMOVE_SPARE_PART_WATCHER } = TechnicianKeys
	return (dispatch, getState) => {
		const { sparePartWatcherRef } = getState().Technician.SpareParts
		if (sparePartWatcherRef) {
			sparePartWatcherRef.off()
			dispatch({ type: REMOVE_SPARE_PART_WATCHER })
		}
	}
}

export const saveSparePart = (data) => {
	const { partNumber, partDescription, quantity, inStock, organizationUID, partUID } = data

	const validatorConstraints = {
		partNumber: {
			presence: {
				allowEmpty: false,
			},
		},
		quantity: {
			presence: {
				allowEmpty: false,
			},
		}
	}
	const validationResponse = validate(data, validatorConstraints)
	if (validationResponse) {
		return (dispatch) => {
			return new Promise((res, rej) => {
				dispatch({
					type: SHOW_ALERT,
					payload: {
						alertTitle: 'Error',
						alertMessage: Object.values(validationResponse)[0][0],
					},
				})
				return rej()
			})
		}
	}

	return (dispatch) =>
		new Promise((res, rej) => {
			dispatch({
				type: SHOW_NETWORK_ACTIVITY,
				payload: 'Saving Spare Part...',
			})
			
			firebase
				.database()
				.ref(`SpareParts/${organizationUID}/${partUID}`)
				.update({ 
					partNumber,
					partDescription,
					quantity,
					inStock
				})
				.then((response) => {
					dispatch({ type: HIDE_NETWORK_ACTIVITY })
					dispatch({
						type: SHOW_ALERT,
						payload: {
							alertTitle: 'Success',
							alertMessage: 'Spare part has been edited.',
						},
					})
					return res()
				})
				.catch((error) => {
					console.log(error)
					dispatch({ type: HIDE_NETWORK_ACTIVITY })
					dispatch({
						type: SHOW_ALERT,
						payload: {
							alertTitle: 'Error',
							alertMessage: 'Error saving spare part',
						},
					})
					return rej()
				})
		})
}