import React, { Component, Fragment } from 'react'
import { Route, withRouter, Switch } from 'react-router-dom'
import firebase from 'firebase/app'
import { connect } from 'react-redux'
// import Loadable from 'react-loadable'

import {
    hideAlert,
    hideConfirm,
    startFirebaseUserWatcher,
    startWatchingAppVersion,
} from './../actions'

import settings from './../config/settings'

//Utility
// import PrivateRoute from './../component/PrivateRoute'

//Global Components
import TEAlert from './../component/Popup/TEAlert'
import TEConfirm from './../component/Popup/TEConfirm'
import TENetworkActivity from './../component/Popup/TENetworkActivity'

import FrontContentWrapper from './../layout/Front/FrontContentWrapper'
import AdminContentWrapper from './../layout/Admin/AdminContentWrapper'
import TechnicianContentWrapper from './../layout/Technician/TechnicianContentWrapper'
import ManagerContentWrapper from './../layout/Manager/ManagerContentWrapper'

//Front End
// const FrontContentWrapper = Loadable({
//     loader: () => import('./../layout/Front/FrontContentWrapper'),
//     loading: () => null,
// })

//Admin
// const AdminContentWrapper = Loadable({
//     loader: () => import('./../layout/Admin/AdminContentWrapper'),
//     loading: () => null,
// })

//Technician
// const TechnicianContentWrapper = Loadable({
//     loader: () => import('./../layout/Technician/TechnicianContentWrapper'),
//     loading: () => null,
// })

//Manager
// const ManagerContentWrapper = Loadable({
//     loader: () => import('./../layout/Manager/ManagerContentWrapper'),
//     loading: () => null,
// })
class App extends Component {
    componentDidMount() {
        const { startFirebaseUserWatcher, startWatchingAppVersion } = this.props

        const {
            FIREBASE_API_KEY,
            FIREBASE_AUTH_DOMAIN,
            FIREBASE_DATABASE_URL,
            FIREBASE_PROJECT_ID,
            FIREBASE_STORAGE_BUCKET,
            FIREBASE_MESSAGING_SENDER_ID,
        } = settings

        firebase.initializeApp({
            apiKey: FIREBASE_API_KEY,
            authDomain: FIREBASE_AUTH_DOMAIN,
            databaseURL: FIREBASE_DATABASE_URL,
            projectId: FIREBASE_PROJECT_ID,
            storageBucket: FIREBASE_STORAGE_BUCKET,
            messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
        })

        startFirebaseUserWatcher()
        startWatchingAppVersion()
    }
    //Alert
    handleConfirmClose = () => {
        this.props.hideConfirm()
    }
    handleAlertClose = () => {
        this.props.hideAlert()
    }

    render() {
        const {
            confirmTitle,
            confirmMessage,
            confirmIsVisible,
            confirmLeftTitle,
            confirmRightOnClick,
            confirmRightTitle,
            alertIsVisible,
            alertTitle,
            alertMessage,
            networkActivityIsVisible,
            networkMessage,
            location,
            userCheckComplete,
        } = this.props

        //TODO: Look into how to improve this.
        if (!userCheckComplete) {
            return null
        }

        return (
            <Fragment>
                <Switch>
                    <Route path="/admin/" component={AdminContentWrapper} location={location} />
                    <Route path="/technician/" component={TechnicianContentWrapper} location={location} />
                    <Route path="/manager/" component={ManagerContentWrapper} location={location} />
                    <Route path="/" component={FrontContentWrapper} location={location} />
                </Switch>
                <TEConfirm
                    title={confirmTitle}
                    message={confirmMessage}
                    visible={confirmIsVisible}
                    leftOnClick={this.handleConfirmClose}
                    leftButtonTitle={confirmLeftTitle}
                    rightOnClick={confirmRightOnClick}
                    rightButtonTitle={confirmRightTitle}
                />
                <TEAlert
                    title={alertTitle}
                    message={alertMessage}
                    visible={alertIsVisible}
                    onClick={this.handleAlertClose}
                />
                <TENetworkActivity message={networkMessage} visible={networkActivityIsVisible} />
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    const {
        confirmTitle,
        confirmMessage,
        confirmIsVisible,
        confirmLeftTitle,
        confirmRightOnClick,
        confirmRightTitle,
        alertIsVisible,
        alertTitle,
        alertMessage,
        networkActivityIsVisible,
        networkMessage,
    } = state.Global
    const { userCheckComplete } = state.Loading

    return {
        confirmTitle,
        confirmMessage,
        confirmIsVisible,
        confirmLeftTitle,
        confirmRightOnClick,
        confirmRightTitle,
        alertIsVisible,
        alertTitle,
        alertMessage,
        networkActivityIsVisible,
        networkMessage,
        userCheckComplete,
    }
}

export default withRouter(
    connect(
        mapStateToProps,
        {
            hideAlert,
            hideConfirm,
            startFirebaseUserWatcher,
            startWatchingAppVersion,
        }
    )(App)
)
