import { colors } from './../../../config/styles'

export default {
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    inputWrapper: (active, disabled) => {
        const styles = {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            backgroundColor: colors.white,
            borderRadius: 5,
            paddingRight: 5,

            borderBottom: `1px solid ${colors.lightGray}`,
            borderTop: `1px solid ${colors.lightGray}`,
            borderLeft: `1px solid ${colors.lightGray}`,
            borderRight: `1px solid ${colors.lightGray}`,
            boxShadow: 'none',
            transition: 'border-color 0.2s ease-in, box-shadow 0.2s ease-in',

            ':hover': {
                borderBottom: `1px solid ${colors.primary}`,
                borderTop: `1px solid ${colors.primary}`,
                borderLeft: `1px solid ${colors.primary}`,
                borderRight: `1px solid ${colors.primary}`,
                boxShadow: `0 0 0 1px ${colors.primary} inset`,
            },
            ':active': {
                borderBottom: `1px solid ${colors.primary}`,
                borderTop: `1px solid ${colors.primary}`,
                borderLeft: `1px solid ${colors.primary}`,
                borderRight: `1px solid ${colors.primary}`,
                boxShadow: `0 0 0 1px ${colors.primary} inset`,
            },
        }
        if (disabled) {
            styles.borderBottom = `1px solid ${colors.lighterGray}`
            styles.borderTop = `1px solid ${colors.lighterGray}`
            styles.borderLeft = `1px solid ${colors.lighterGray}`
            styles.borderRight = `1px solid ${colors.lighterGray}`

            styles.boxShadow = 'none'
            styles[':hover'].borderBottom = `1px solid ${colors.lighterGray}`
            styles[':hover'].borderTop = `1px solid ${colors.lighterGray}`
            styles[':hover'].borderLeft = `1px solid ${colors.lighterGray}`
            styles[':hover'].borderRight = `1px solid ${colors.lighterGray}`
            styles[':hover'].boxShadow = 'none'

            styles[':active'].borderBottom = `1px solid ${colors.lighterGray}`
            styles[':active'].borderTop = `1px solid ${colors.lighterGray}`
            styles[':active'].borderLeft = `1px solid ${colors.lighterGray}`
            styles[':active'].borderRight = `1px solid ${colors.lighterGray}`
            styles[':active'].boxShadow = 'none'
        } else if (active) {
            styles.borderBottom = `1px solid ${colors.primary}`
            styles.borderTop = `1px solid ${colors.primary}`
            styles.borderLeft = `1px solid ${colors.primary}`
            styles.borderRight = `1px solid ${colors.primary}`

            styles.boxShadow = `0 0 0 1px ${colors.primary} inset`
        }

        return styles
    },
    labelIcon: {
        marginRight: 10,
        verticalAlign: 'middle',
        fontSize: 18,
    },
    label: (active, disabled) => {
        const styles = {
            display: 'block',
            width: '100%',
            fontSize: 14,
            color: colors.darkGray,
            paddingTop: 10,
            paddingLeft: 10,
            paddingRight: 10,
            paddingBottom: 10,
            cursor: 'pointer',
        }
        if (disabled) {
            styles.color = colors.gray
            styles.cursor = 'not-allowed'
        } else if (active) {
            styles.cursor = 'copy'
        }

        return styles
    },
    input: { display: 'none' },
    clearButton: {
        width: 'auto',
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 10,
        paddingRight: 10,
        textAlign: 'center',
    },
    buttonIcon: {
        color: 'inherit',
        display: 'block',
    },
    errorMessage: {
        display: 'block',
        paddingTop: 5,
        paddingBottom: 5,
        fontSize: 14,
        color: colors.errorRed,
    },
}
