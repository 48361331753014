import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'

// import { unregister } from './../serviceWorker'
// import { compareToLocalVersion } from './../helpers'

import {
    SHOW_ALERT,
    SHOW_NETWORK_ACTIVITY,
    HIDE_NETWORK_ACTIVITY,
    LoadingKeys,
    AuthKeys,
} from './types'

export const startWatchingAppVersion = () => {
    return (dispatch) => {
        firebase
            .database()
            .ref(`Versions`)
            .on(
                'value',
                (snapshot) => {
                    dispatch({
                        type: LoadingKeys.FETCH_APP_VERSIONS,
                        payload: snapshot.val(),
                    })
                },
                (error) => {
                    console.log(error)
                }
            )
    }
}

const startWatchingUser = (dispatch, getState) => {
    const { FETCH_USER, SAVE_USER_WATCHER } = LoadingKeys

    //Load User
    const { uid } = firebase.auth().currentUser
    const userWatcherRef = firebase.database().ref(`Users/${uid}`)

    userWatcherRef.on(
        'value',
        (snapshot) => {
            if (!snapshot.val() || (snapshot.val() && !snapshot.val().active)) {
                firebase.auth().signOut()

                dispatch({
                    type: SHOW_ALERT,
                    payload: {
                        alertTitle: 'Deactivated Account',
                        alertMessage:
                            'Your account has been deactivated. If you believe this was done in error, please contact service@rittal.us.',
                    },
                })
                userWatcherRef.off()
                dispatch({ type: LoadingKeys.REMOVE_USER_WATCHER })

                const { userCheckComplete } = getState().Loading
                if (!userCheckComplete) {
                    dispatch({ type: HIDE_NETWORK_ACTIVITY })
                    dispatch({ type: LoadingKeys.USER_CHECK_COMPLETE })
                }
                return
            }

            dispatch({
                type: FETCH_USER,
                payload: snapshot.val(),
            })

            //*******************//
            // COMPLETED LOADING //
            //*******************//
            // - User Loaded
            //
            const { userCheckComplete } = getState().Loading
            if (!userCheckComplete) {
                dispatch({ type: HIDE_NETWORK_ACTIVITY })
                dispatch({ type: LoadingKeys.USER_CHECK_COMPLETE })
            }
        },
        (error) => {
            console.log(error)
        }
    )
    dispatch({
        type: SAVE_USER_WATCHER,
        payload: userWatcherRef,
    })
}
const stopWatchingUser = (dispatch, getState) => {
    const { userWatcherRef } = getState().Loading
    if (userWatcherRef) {
        userWatcherRef.off()
        dispatch({ type: LoadingKeys.REMOVE_USER_WATCHER })
    }
}

//
//User Permissions
//
const startWatchingUserPermissions = (dispatch, getState) => {
    //Load User Type
    const { uid } = firebase.auth().currentUser
    const userPermissionsWatcherRef = firebase.database().ref(`UserPermissions/${uid}`)
    userPermissionsWatcherRef.on(
        'value',
        (snapshot) => {
            dispatch({
                type: AuthKeys.FETCH_USER_PERMISSIONS,
                payload: snapshot.val(),
            })

            const userPermissions = snapshot.val()
            if (userPermissions && (userPermissions.manager || userPermissions.technician)) {
                const { userWatcherRef } = getState().Loading
                if (!userWatcherRef) {
                    startWatchingUser(dispatch, getState)
                    firebase
                        .database()
                        .ref(`Users/${uid}`)
                        .update({ dateLastSignIn: firebase.database.ServerValue.TIMESTAMP })
                }
            } else if (userPermissions && userPermissions.admin) {
                const { userCheckComplete } = getState().Loading
                if (!userCheckComplete) {
                    dispatch({ type: HIDE_NETWORK_ACTIVITY })
                    dispatch({ type: LoadingKeys.USER_CHECK_COMPLETE })
                }
            } else {
                //*******************//
                // COMPLETED LOADING //
                //*******************//
                // - UserPermissions Loaded
                // - NEITHER AGENT OR CLIENT!?!?
                //
                const { userCheckComplete } = getState().Loading
                if (!userCheckComplete) {
                    dispatch({ type: HIDE_NETWORK_ACTIVITY })
                    dispatch({ type: LoadingKeys.USER_CHECK_COMPLETE })
                }
            }
        },
        (error) => {
            console.log(error)
        }
    )
    dispatch({
        type: AuthKeys.SAVE_USER_PERMISSIONS_WATCHER,
        payload: userPermissionsWatcherRef,
    })
}
const stopWatchingUserPermissions = (dispatch, getState) => {
    const { userPermissionsWatcherRef } = getState().Auth
    if (userPermissionsWatcherRef) {
        userPermissionsWatcherRef.off()
        dispatch({ type: AuthKeys.REMOVE_USER_PERMISSIONS_WATCHER })
    }
}

//
//Actions
//
//
// THIS STARTS OFF THE LOADING PROCESS.
//  - EVERY PAGE WAITS TO RENDER UNTIL LoadingKeys.USER_CHECK_COMPLETE
//      IS TRIGGERED. THIS HANDLES MANY BUGS OF NOT HAVING ALL THE
//      REQUIRED DATA NEEDED FOR A PAGE
//  - LIKELY NOT THE BEST WAY TO DO THIS BUT WORKS.... SOC
//
export const startFirebaseUserWatcher = () => {
    return (dispatch, getState) => {
        //console.log("Entered loading watcher")
        //Questionable if we want this here. Turning it off for now. This should be so quick that it doesn't matter.
        dispatch({
            type: SHOW_NETWORK_ACTIVITY,
            payload: 'Loading...',
        })

        //
        //USED FOR WORKING ON THE SITE OFFLINE
        //
        //dispatch({ type: HIDE_NETWORK_ACTIVITY })
        //dispatch({ type: LoadingKeys.USER_CHECK_COMPLETE})

        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                //console.log("USER SIGNED IN. START WATCHERS!")
                dispatch({ type: LoadingKeys.USER_CHECK_STARTED })
                startWatchingUserPermissions(dispatch, getState)
            } else {
                //*******************//
                // COMPLETED LOADING //
                //*******************//
                // - UserPermissions Failed To Load -> Not Signed In
                //
                const { userCheckComplete } = getState().Loading
                if (!userCheckComplete) {
                    dispatch({ type: HIDE_NETWORK_ACTIVITY })
                    dispatch({ type: LoadingKeys.USER_CHECK_COMPLETE })
                } else {
                    dispatch({ type: HIDE_NETWORK_ACTIVITY })
                }

                //console.log("USER SIGNED OUT. STOP WATCHERS!")
                stopWatchingUserPermissions(dispatch, getState)
                stopWatchingUser(dispatch, getState)
            }
        })
    }
}
