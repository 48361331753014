import { AuthKeys } from './../actions/types'

const INITIAL_STATE = {
    userPermissions: undefined,
    userPermissionsLoaded: false,
    userPermissionsWatcherRef: undefined,
}

export default (state = INITIAL_STATE, action) => {
    const {
        FETCH_USER_PERMISSIONS,
        SAVE_USER_PERMISSIONS_WATCHER,
        REMOVE_USER_PERMISSIONS_WATCHER,
    } = AuthKeys;

    switch (action.type) {

        case FETCH_USER_PERMISSIONS:
            return { ...state,
                userPermissions: action.payload,
                userPermissionsLoaded: true,
            }
        case SAVE_USER_PERMISSIONS_WATCHER:
            return { ...state, userPermissionsWatcherRef: action.payload }
        case REMOVE_USER_PERMISSIONS_WATCHER:
            return { ...state,
                userPermissions: INITIAL_STATE.userPermissions,
                userPermissionsLoaded: INITIAL_STATE.userPermissionsLoaded,
                userPermissionsWatcherRef: INITIAL_STATE.userPermissionsWatcherRef,
            }

        default:
            return state
    }
};