import React, { Component } from 'react'
import { AccordionItemTitle } from 'react-accessible-accordion'

import styles from './styles'

class Title extends Component {
	render() {
		const { section } = this.props
		return (
			<AccordionItemTitle style={styles.sectionTitleWrapper}>
				<h3 style={styles.sectionTitle}>{section.name}</h3>
				<div className="accordion__arrow" role="presentation" style={styles.sectionArrow} />
			</AccordionItemTitle>
		)
	}
}

export default Title
