import { createSelector } from 'reselect'

export const getJobSpareParts = (state) => state.Manager.SpareParts.spareParts
export const getJobSparePartArray = createSelector(
	getJobSpareParts,
	(parts) => {
		const sparePartArray = []
		for (const key in parts) {
			sparePartArray.push({ ...parts[key], uid: key })
		}
		return sparePartArray
	}
)
